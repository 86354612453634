import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import {
  axiosInstanceDocument,
  axiosInstanceDashboard
} from '../../../api/Axios';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';
import './index.css';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const CompleteUploadDocTable = (props) => {
  const { customerID, currobj } = props;

  const current = useSelector((state) => state?.storeActiveStep);
  const activeId = current.actionId;
  const [uploadShowList, setUploadShowList] = useState([]);
  const [isPreReqDoc,setisPreReqDoc] = useState(current?.actionId === 15 && current?.actionName === 'Pre-Req Docs');
  const [isNcobReview,setisNcobReview] = useState(current?.actionId === 19 && current?.actionName === 'NCOB Review');
  useEffect(() => {
    axiosInstanceDocument
      .request({
        method: 'GET',
        url: `/fetchTrackerDocuments/${customerID}/${currobj?.actionName}`
      })
      .then((response) => {
        if (response.data) {
          if (response?.data?.data) {
            if(isNcobReview){
              setUploadShowList(
                response?.data?.data['Tracker Document Downloaded'].filter(item=>item.isApproved === 'Approved')
              );
            }else{
              setUploadShowList(
                response?.data?.data['Tracker Document Downloaded']
              );
            }
          }
        }
      });
  }, [current]);

  function downFilesTarker({ templateId, docName, docStatusId, fileName }) {
   
    axiosInstanceDocument
      .request({
        method: 'GET',
        url: `/downloadTrackerDocument/${templateId}/${customerID}/${docName=='MSA/LOI'?'MSALOI':docName}/${docStatusId}`,
        responseType: "blob"
      })
      .then((response) => {
        const contentType = response?.headers['content-type'];
        const url = window.URL.createObjectURL(
          new Blob([response?.data], { type: contentType })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        link.click();
      });
  }
  return (
    <div className='doc-list'>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 230 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {(isPreReqDoc || isNcobReview) && 
                <StyledTableCell>Template Name</StyledTableCell>}
                <StyledTableCell>Name</StyledTableCell>
                {!isNcobReview &&
                <StyledTableCell align="left">Version Id</StyledTableCell>} 
                {(!isPreReqDoc && !isNcobReview) &&
                <StyledTableCell align="left">Date</StyledTableCell>}
                {(!isPreReqDoc && !isNcobReview) &&
                <StyledTableCell align="left">Time</StyledTableCell>}
                <StyledTableCell align="left">
                  Action
                            </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {uploadShowList.map((row) => {
                return (
                  <TableRow key={row.docStatusId}>
                    {(isPreReqDoc || isNcobReview) && 
                      <TableCell component="th" scope="row">
                      {row.docName}
                      </TableCell>
                      }
                    <TableCell component="th" scope="row">
                      {isNcobReview && 
                        <IconButton disabled>
                              <CheckCircleOutlinedIcon
                                sx={{
                                  color:'green'
                                }}
                              />
                            </IconButton>}
                      {row.fileName}
                    </TableCell>
                    {!isNcobReview &&
                    <TableCell align="left">
                      {row?.versionId}
                    </TableCell>}
                    {(!isPreReqDoc && !isNcobReview) &&
                    <TableCell align="left">
                      {row.formatModifiedDate}
                    </TableCell>}
                    {(!isPreReqDoc && !isNcobReview) &&
                    <TableCell align="left">
                      {row.formatModifiedTime}
                    </TableCell>}
                    <TableCell align="left">
                      <IconButton
                        edge="end"
                        data-testid="downloadfile"
                        aria-label="comments"
                        onClick={downFilesTarker.bind(null, row)}
                      >
                        <DownloadIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default CompleteUploadDocTable;
