import { createSlice } from '@reduxjs/toolkit';

//Initializing state
const initialState = {};

//Creating Reducers functions
export const templateSlice = createSlice({
  name: 'updateCustomerProgressstatus',
  initialState,
  reducers: {
    updateCustomerProgress(state, action) {
      return { ...state, ...action.payload };
    },
    updateCustomerProgressSuccess(state, action) {
      return { ...state, ...action.payload };
    },
    updateCustomerProgressError(state, action) {
      return { ...state, ...action.payload };
    },
    updateCustomerProgressReset(state, action) {
      return { ...initialState };
    },
    storeStepData(state, action) {
     
      return { ...state, 'id':action.payload };
    },
    storeActiveStep(state, action) {
      return { ...state, ...action.payload };
    },
    storeManualCreateNationalId(state, action) {
      return { ...state, ...action.payload };
    },
  }
});

//Action creators
export const {
  updateCustomerProgress,
  updateCustomerProgressSuccess,
  updateCustomerProgressError,
  updateCustomerProgressReset,
  storeStepData,
  storeActiveStep,
  storeManualCreateNationalId
} = templateSlice.actions;

export default templateSlice.reducer;
