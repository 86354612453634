import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardContent } from '@mui/material';
import { mapping } from '../../../Helper/mapping';
import { axiosInstanceDashboard } from '../../../api/Axios';
import CustomerDetails from '../CustomerDetails';
import CompleteUploadDocTable from '../CompleteUploadDocTable'
import {LINK_SALESFORCE} from '../../../constant/api';
import _ from 'lodash';
const CompletedView = (props) => {
  const {
    acctype,
    customerdetails,
    stepStatus,
    customerID,
    activeSection,
    snackbarShowMessage
  } = props;
  const currentstep = useSelector((state) => state?.storestep?.id);
  const currobj = mapping[acctype].find((obj) => obj.actionId === currentstep);
  const [businessEventId, setBusinessEventId] = useState();
  const [salesForceEventUrl, setSalesForceEventUrl] = useState();
  const [isBusinessEvent,setIsBusinessEvent] = useState(currobj?.isBusinessEvent);
  const [isCreateNationalId,setIsCreateNationalId] = useState(currobj?.actionName === 'Create National ID' && currobj?.actionId === 23);
  useEffect(() => {
    if ((currobj?.isBusinessEvent || isCreateNationalId) && activeSection?.action === 'COMPLETEDCARD') {
      axiosInstanceDashboard
        .request({
          method: 'GET',
          url: `/getSalesForceTaskId?customerId=${customerID}&actionId=${currobj.actionId}`
        })
        .then((response) => {
          if (response?.data?.data) {
            setBusinessEventId(response?.data?.data?.SalesForceTask['supportRequestNumber']);
            const complited = _.template(LINK_SALESFORCE);
            setSalesForceEventUrl(complited({ ['SalesForceTaskId']: _.get(response, 'data.data.SalesForceTask.salesforceTaskId') }))
          }else{
            snackbarShowMessage({
              message: _.get(response,'data.errorMessage'),
              type: 'error'
            });
          }
        });
    }
  }, [customerID]);
  return (
    <div>
      <Card>
        <CardContent>
          {(currobj?.actionName === 'Inventory Readiness Tracker' || currobj?.actionName === 'Inventory Snapshot' || currobj?.actionName === 'Pre-Req Docs' || currobj?.actionName === 'NCOB Review')&& 
            (<CompleteUploadDocTable 
            customerID = {customerID} 
            currobj = {currobj}/>
            )}
          {currobj?.actionName === 'Customer Details' &&
            currobj?.completedInformation && (
              <CustomerDetails detailsFilled={true} />
            )}
          {currobj?.completedInformation &&
            currobj.viewinformation.map((index) => {
              return (
                <div key={index}>
                  {index.includes('Opsite') &&
                    `${index} ${customerdetails.opSiteAlignment}`}
                  {index.includes('Finance') &&
                    `${index} ${customerdetails.financialApprover} `}
                  {index.includes('Credit') &&
                    `${index} ${customerdetails.creditAnalyst} `}
                  {index.includes('DPM') &&
                    `${index} ${customerdetails.dpmAnalyst} `}
                  {index.includes('Alignment') &&
                    `${index} ${customerdetails.aeAlignment} `}
                </div>
              );
            })}
          {!currobj?.completedInformation && (
            <div>
              {(!isBusinessEvent && !isCreateNationalId ) && currobj &&
                `${currobj.actionName}  is ${
                 stepStatus?.[currobj?.actionName]
                }`}
                { (isBusinessEvent || isCreateNationalId) && (<div>{currobj.actionName} with id <a href={salesForceEventUrl} target="_blank" rel="noreferrer">{businessEventId}</a> is created</div>)
                }
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default CompletedView;
