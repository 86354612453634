import awsAuth from './aws-auth';

const awsconfig = {
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_CLIENT_ID,
    mandatorySignIn: true,
    oauth: awsAuth,
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: 'DASHBOARD',
        endpoint: process.env.REACT_ENDPOINT_DASHBOARD,
        service: 'lambda',
        region: 'us-east-1'
      }
    ]
  }
};
let redirectSignIn = awsAuth.redirectSignIn;
if (window.location.hostname === 'localhost') {
  redirectSignIn = 'http://localhost:3000/';
}
let auth_domain = `https://ncob-dev-ws.auth.us-east-1.amazoncognito.com`;
 if (process.env.REACT_APP_ENV_ID === 'staging' || process.env.REACT_APP_ENV_ID === 'prod' )
  auth_domain = `https://ncob.auth.us-east-1.amazoncognito.com`;

const redirectUrl =
  auth_domain +
  '/oauth2/authorize?redirect_uri=' +
  redirectSignIn +
  '&response_type=' +
  awsAuth.responseType +
  '&client_id=' +
  process.env.REACT_APP_USER_CLIENT_ID +
  '&scope=aws.cognito.signin.user.admin+email+openid+profile';

export { awsconfig, redirectUrl };
