import { axiosInstanceImage } from '../api/Axios';

export const uploadImage = async (customerID, image) => {
 
  let formData = new FormData();
  formData.append('logo', image);
  const response = axiosInstanceImage
    .request({
      method: 'POST',
      url: `/uploadCustomerLogo/?customerId=${customerID}`,
      data: formData
    });

  return response;
};
