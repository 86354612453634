import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import DocumentUpload from '../DocumentUpload';
import {
  storeActiveStep,
  updateCustomerProgress
} from '../../containers/templateComponent/redux/slice';
import CustomAcordion from '../Accordion';
import {
  axiosInstanceDocument,
  axiosInstanceDashboard
} from '../../../api/Axios';
import { Button, Collapse, Grid, Input, TextField, Tooltip } from '@mui/material';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import { uploadICTfile } from '../../../utils/uploadICTfile';
import {
  uploadFile,
  uploadBOSSubRuleFile,
  uploadTrackerFile,
  uploadOtherDoc
} from '../../../utils/uploadFile';
import '../Select/index.css';
import templateidmapper from '../../../Helper/templateid';
import { withSnackbar } from '../SnackBar/SnackBar';
import { mapping } from '../../../Helper/mapping';
import { useDispatch, useSelector } from 'react-redux';
import EnableSkip from '../EnableSkip/index';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import { styled } from '@mui/material/styles';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';
import InventoryReadinessTrackerPopup from '../Popup/InventoryReadinessTrackerPopup'
import {LINK_SALESFORCE} from '../../../constant/api';
import { Field } from 'formik';
import { AddOutlined } from '@mui/icons-material';
import { MinimizeOutlined } from '@mui/icons-material';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));
function Selectq({
  reviewcompflag,
  nameList,
  activesection,
  hideButtons,
  sectionSubmit,
  status,
  closeHandler,
  customerID,
  snackbarShowMessage,
  setActiveSection = () => {},
  setDisplay,
  customerPersona,
  clickedcard,
  setDialogMes,
  isOthers,
}) {
  const ItemCodingflag = activesection?.enableICTcoding;
  let names = [];
  const [templateList, setTemplateList] = useState([]);
  const [documentId, setDocumentId] = useState(0);
  let isCustomerPortal = localStorage.getItem('customerPortalUser')
    ? true
    : false;
  const [tempIdMapperFromBackEnd, setTempIdMapper] = useState({});
  const [isPreReqDoc,setisPreReqDoc] = useState(activesection?.actionId === 15 && activesection?.actionName === 'Pre-Req Docs');
  let temparr = activesection?.templateId;
  for (let j = 0; j < temparr?.length; j++)
  names.push(templateidmapper[temparr[j]]);
  if (!names?.length) names = nameList;
  const dispatch = useDispatch();
  const [name, setname] = useState('');
  const [uploadedfiles, setuploadedfiles] = useState({});
  const [doctype, setdoctype] = useState([]);
  const [uploadShowList, setUploadShowList] = useState([]);
  const [finalList, setfinalList] = useState(names);
  const [uploading, setUploading] = useState(false);
  const [responseStatus, setResponseStatus] = useState({});
  const [responseStatusBOSSubRule, setResponseStatusBOSSubRule] = useState({});
  const [responseStatusICT, setResponseStatusICT] = useState({});
  const [SalesForceTaskUrl, setSalesForceTaskUrl] = useState('https://sysco--staging.sandbox.lightning.force.com');
  const acctype = useSelector((state) => state?.AccountType?.AccountType);
  const isSkipActive = mapping[acctype]?.find(
    (action) => action?.actionName === activesection?.actionName
  )?.enableSkip;
  const enableBosSubRule = activesection?.bosSubRule;
  const showMarkBtn = activesection?.showMarkBtn;
  const showUploadList = activesection?.showUploadList;
  const isMsRsOrNIRS = activesection?.isMsRsOrNIRS;
  const [popupopen, setpopupopen] = useState(false);
  useEffect(() => {
    setdoctype([]);
    setfinalList(names);
  }, [clickedcard]);
  async function submithandler() {
    const numUploadedFiles = Object.keys(uploadedfiles).length;
    if (numUploadedFiles) {
      setUploading(true);
      for (const key of Object.keys(uploadedfiles)) {
        const acceptedFiles = uploadedfiles[key]?.acceptedFiles;
        const documentID = uploadedfiles[key]?.documentID;
        let responseArr = [];
        for(let i = 0;i<acceptedFiles?.length;i++){
          let acceptedFile = acceptedFiles[i];
          let response;
          if (isOthers) {
            if (name?.length < 1 || name?.length > 20) {
              snackbarShowMessage({
                message: `Doc name should be greater than 0 and less than 20!`,
                type: 'error',
              });
              setUploading(false);
              return;
            } else if (!(/^[a-zA-Z\s]+$/g.test(name))) {
              snackbarShowMessage({
                message: `Doc name can only be character!`,
                type: 'error',
              });
              setUploading(false);
              return;
            } else {
              response = await uploadOtherDoc(customerID, acceptedFile, name);
              if(response.status === 200){
                const responseObj = {
                  fileName: acceptedFile.name,
                  errorCode: response?.data?.errorCode,
                  message: response?.data?.errorMessage
                  ? response?.data?.errorMessage
                  : response?.data?.data?.['Document Upload'].message,
                  document: response?.data?.errorMessage ? {}
                  : response?.data?.data?.['Document Upload']?.document};
                  responseArr.push(responseObj);
              }
              //change response structure as spec sheets can upload multiple files
              // setResponseStatus((prev) => ({
              //   ...prev,
              //   [key]: [
              //     acceptedFile.name,
              //     response?.data?.errorMessage
              //       ? response?.data?.errorMessage
              //       : response?.data?.data?.['Document Upload'].message,
              //     response?.data?.errorMessage
              //       ? {}
              //       : response?.data?.data?.['Document Upload']?.document
              //   ]
              // }));
            }
          } else if (ItemCodingflag) {
            response = await uploadICTfile(customerID, acceptedFile, documentID);
            setResponseStatusICT((prev) => ({
              ...prev,
              [key]: [
                acceptedFile.name,
                response?.data?.errorMessage
                  ? response?.data?.errorMessage
                  : response?.data?.data?.bulkRequestSearchResponse.response
              ]
            }));
          } else if (enableBosSubRule) {
            response = await uploadBOSSubRuleFile(customerID, acceptedFile);
            setResponseStatusBOSSubRule((prev) => ({
              ...prev,
              errorMessage:
                response?.data?.errorMessage || response?.data?.error,
              response: response?.data?.data?.['BOS']?.response
            }));

          } else if (showUploadList && !isPreReqDoc) {
            //log
            response = await uploadTrackerFile(
              customerID,
              activesection?.actionName,
              acceptedFile
            );
            if(response.status === 200){
              const responseObj = {
                fileName: acceptedFile.name,
                errorCode: response?.data?.errorCode,
                message: response?.data?.errorMessage
                ? response?.data?.errorMessage
                : response?.data?.data?.['Document Upload'].message,
                document: response?.data?.errorMessage ? {}
                : response?.data?.data?.['Document Upload']?.document};
                responseArr.push(responseObj);
            }
            //change response structure as spec sheets can upload multiple files
            // setResponseStatus((prev) => ({
            //   ...prev,
            //   [key]: [
            //     acceptedFile.name,
            //     response?.data?.errorMessage
            //       ? response?.data?.errorMessage
            //       : response?.data?.data?.['Document Upload'].message,
            //     response?.data?.errorMessage
            //       ? {}
            //       : response?.data?.data?.['Document Upload']?.document
            //   ]
            // }));
          } else {
            response = await uploadFile(customerID, acceptedFile, documentID);
            if(response.status === 200){
              const responseObj = {
                fileName: acceptedFile.name,
                errorCode: response?.data?.errorCode,
                message: response?.data?.errorMessage
                ? response?.data?.errorMessage
                : response?.data?.data?.['Document Upload'].message,
                document: response?.data?.errorMessage ? {}
                : response?.data?.data?.['Document Upload']?.document};
                responseArr.push(responseObj);
            }
          } 
        }
        if(!_.isEmpty(responseArr)){
          setResponseStatus((prev) => ({
            ...prev,
            [key]: responseArr
          }))
        }
      }
    } else {
      snackbarShowMessage({
        message: `No file is attached. Please upload the document`,
        type: 'error'
      });
    }
  }

  useEffect(() => {
    if (responseStatusBOSSubRule?.response) {
      setUploading(false);
      snackbarShowMessage({
        message: `Documents are uploaded successfully, Response has - ${responseStatusBOSSubRule?.response}`,
        type: 'success'
      });
      sectionSubmit && sectionSubmit(false, false, false);
    } else if (responseStatusBOSSubRule?.errorMessage) {
      snackbarShowMessage({
        message: `Unable to upload the document, Error Message has - ${responseStatusBOSSubRule?.errorMessage}`,
        type: 'error'
      });
      setUploading(false);
      setuploadedfiles({});
    }
  }, [responseStatusBOSSubRule]);

  let s = useSelector((state) => state?.storeActiveStep);
  useEffect(() => {
    let failedFile = {};
    if (
      Object.keys(uploadedfiles).length &&
      Object.keys(responseStatusICT).length ===
        Object.keys(uploadedfiles).length
    ) {
      setUploading(false);

      let count = 0;
      for (const [key, value] of Object.entries(responseStatusICT)) {
        if (value[1] === 'Uploaded Successfully') {
          count++;
          localStorage.setItem(
            `ICTdocname${customerID}`,
            JSON.stringify({ [key]: value[0] })
          );
        }else{
          failedFile[key] = value;
        }
      }

      if (count == Object.keys(uploadedfiles).length) {
        snackbarShowMessage({
          message: `All the documents were uploaded successfully`,
          type: 'success'
        });
        setuploadedfiles({});
        setResponseStatusICT({});
        if (s.fallbackScreen)
          sectionSubmit && sectionSubmit(false, false, false, true);
        else sectionSubmit && sectionSubmit(false, false, false);
      } else if (count > 0) {
        if (count < Object.keys(uploadedfiles).length) {
          if(formatErrorMessage(failedFile)){
            snackbarShowMessage({
              message: formatErrorMessage(failedFile),
              type: 'warning'
            });
          }else{
            snackbarShowMessage({
              message: `Some Documents weren't uploaded`,
              type: 'warning'
            });
          }
          if (s.fallbackScreen)
            sectionSubmit && sectionSubmit(false, false, false, true);
          else sectionSubmit && sectionSubmit(false, false, false);
        }
      } else if (count == 0) {
        if(formatErrorMessage(failedFile)) {
          snackbarShowMessage({
            message: formatErrorMessage(failedFile),
            type: 'error'
          });
        }else{
          snackbarShowMessage({
            message: `No Template Sheet Found`,
            type: 'error'
          });
        }
      }
    }
  }, [responseStatusICT]);
  const current = useSelector((state) => state?.storeActiveStep);
  const activeId = current.actionId;
  const currentstepstatus = useSelector(
    (state) =>
      state?.getCustomerProgress?.data?.[
        'Customer Progress'
      ]?.actionItems?.find((item) => item.actionId === activeId)?.sectionStatus
  );
  useEffect(() => {
    if (showUploadList) {
      axiosInstanceDocument
        .request({
          method: 'GET',
          url: `/fetchTrackerDocuments/${customerID}/${activesection?.actionName}`
        })
        .then((response) => {
          if (response.data) {
            if (response?.data?.data) {
              setUploadShowList(
                response?.data?.data['Tracker Document Downloaded']
              );
            }
          }
        });
    }

    if (activeId === 60) {
      const complited = _.template(LINK_SALESFORCE);
      axiosInstanceDashboard
        .request({
          method: 'GET',
          url: `/getSalesForceTaskId?customerId=${customerID}&actionId=18`
        })
        .then((response) => {
          if (_.get(response, 'data.data.SalesForceTask.salesforceTaskId')) {
            setSalesForceTaskUrl(complited({ ['SalesForceTaskId']: _.get(response, 'data.data.SalesForceTask.salesforceTaskId') }))
          } else {
            setSalesForceTaskUrl('https://sysco--staging.sandbox.lightning.force.com')
          }
        });
    }
  }, [current]);
  const mandatoryDoc = ['Customer contact information','Operating Site Alignment & Heat Map','Deep Dive Template',
  'Item list','MSA/LOI','Customer Accounts','Customer Vendor List'];
  const [notUploadMandatoryDocs,setnotUploadMandatoryDocs] = useState();
  useEffect(()=>{
    if(isPreReqDoc && uploadShowList){
      checkMandatoryFile();
      let notUploadMandatoryDocs = [];
      mandatoryDoc.filter(item => !uploadShowList.some(otherItem => otherItem.docName === item)).map(docItem => {
        notUploadMandatoryDocs.push({
          templateId: getKeybyValue(tempIdMapperFromBackEnd, docItem),
          docName: docItem,
        })
      });
      setnotUploadMandatoryDocs(notUploadMandatoryDocs);
    }
  },[uploadShowList,isPreReqDoc]);
  const checkMandatoryFile = ()=>{
        let notUploadFile = [];
        for(var m of mandatoryDoc){
          if(uploadShowList.findIndex(item=>item.docName === m) === -1){
            notUploadFile.push(m);
          }
        }
        if(notUploadFile.length !== 0){
          setDialogMes(`${notUploadFile} file(s) are mandatory!`);
        }else{
          setDialogMes('');
        }
        return notUploadFile;
  }
  const fetchTemplateList = () => {
    axiosInstanceDocument
      .request({
        method: 'GET',
        url: `/getAllMasterTemplate`
      })
      .then((response) => {
        if (response.data) {
          setTemplateList(
            _.map(
              _.filter(
                response.data.data.DOCUMENT,
                ({ customerVisible }) => customerVisible
              ),
              'templateName'
            )
          );
          const newTempMapper = {};
          const tempNameList = _.values(templateidmapper);
          _.each(response.data.data.DOCUMENT, (item) => {
            _.each(tempNameList, (tempName) => {
              if (tempName === _.get(item, 'templateName')) {
                newTempMapper[item.templateId] = tempName;
              }
            });
          });
          if(isOthers) setDocumentId(1000);
          else setDocumentId(getKeybyValue(newTempMapper, name));
          setTempIdMapper(newTempMapper);
        }
      });
  };
  useEffect(() => {
    fetchTemplateList();
  }, [name]);
  const formatErrorMessage =(failedFile)=>{
    let message=[];
    if(failedFile){
      for (const [key, value] of Object.entries(failedFile)) {
        if(value[1])
        message.push(value[1]);
      }
    }
    return message.length !== 0 ?message.join("; "): null;
  }
  useEffect(() => {
    let failedFile = [];
    let uploadFileCount = 0;
    let responseFileCount = 0;
    for (const [key, value] of Object.entries(uploadedfiles)) {
      uploadFileCount = uploadFileCount + value?.acceptedFiles?.length;
    }
    for (const [key, value] of Object.entries(responseStatus)) {
      responseFileCount = responseFileCount + value?.length;
    }
    if (
      Object.keys(uploadedfiles).length &&
      Object.keys(responseStatus).length === Object.keys(uploadedfiles).length &&
      uploadFileCount === responseFileCount
    ) {
      setUploading(false);
      let count = 0;
      let uploadedDocId = [];
      let uploadedDocname = [];
      for (const [key, value] of Object.entries(responseStatus)) {
        value.forEach(element => {
          if (
            element.message === 'Document Uploaded Successfully' ||
            element.message === 'Version Updated successfully'
          ) {
            count++;
            uploadedDocId.push(element.document.docStatusId);
            uploadedDocname.push({
              fileName: element.fileName,
              templateId: `${getKeybyValue(tempIdMapperFromBackEnd, key)}`,
              docName: name,
              docStatusId:element.document.docStatusId
            });
          }else{
            failedFile.push(element.message);
          }
        });
      }
      if (count === uploadFileCount) {
        snackbarShowMessage({
          message: `All the documents were uploaded successfully`,
          type: 'success'
        });
        setuploadedfiles({});
        setResponseStatus({});
        if (setDisplay) {
          dispatch(
            updateCustomerProgress({
              customerId: customerID,
              actionId: activesection.actionId,
              sectionId: activesection.sectionId,
              sectionStatus: 'Completed'
            })
          );
          setTimeout(() => {
            setDisplay(0);
          }, [1000]);
          setTimeout(() => {
            window.location.reload();
          }, [2000]);
        }
        if (s.fallbackScreen) {
          //big fix: if click on an action that fallbackscreen is true and status is not awaiting firstly, then upload file,after upload successfully have not call downloadDocument api
          sectionSubmit(false, false, false);
          if (currentstepstatus === 'Awaiting')
            sectionSubmit(false, false, false);
          if (s.fallbackTemplate === 'Mail') {
            localStorage.setItem(
              `docid${customerID}`,
              JSON.stringify(uploadedDocId)
            );
            localStorage.setItem(
              `docname${customerID}`,
              JSON.stringify(uploadedDocname)
            );
            let newactivestep = Object.assign({}, s);
            newactivestep['fallbackScreenactivated'] = true;
            dispatch(storeActiveStep(newactivestep));
            setActiveSection({ action: 'M' });
          }
          if(s.fallbackTemplate === 'URLredirect'){
            setActiveSection({ action: 'U' });
          }
        } else if (showUploadList) {
          axiosInstanceDocument
            .request({
              method: 'GET',
              url: `/fetchTrackerDocuments/${customerID}/${activesection?.actionName}`
            })
            .then((response) => {
              if (response.data) {
                if (response?.data?.data) {
                  setUploadShowList(
                    response?.data?.data['Tracker Document Downloaded']
                  );
                }
              }
            });
        } else sectionSubmit && sectionSubmit(false, false, false);
        //log
      } else if (count > 0) {
        if (count < uploadFileCount) {
          if(!_.isEmpty(failedFile)){
            snackbarShowMessage({
              message: failedFile.join("; "),
              type: 'warning'
            });
          }else{
            snackbarShowMessage({
              message: `Some Documents weren't uploaded`,
              type: 'warning'
            });
          }
          
          if (s.fallbackScreen) {
            if (s.fallbackTemplate === 'Mail_ATTACH') {
              localStorage.setItem(
                `docid${customerID}`,
                JSON.stringify(uploadedDocId)
              );
              localStorage.setItem(
                `docname${customerID}`,
                JSON.stringify(uploadedDocname)
              );
              let newactivestep = Object.assign({}, s);
              newactivestep['fallbackScreenactivated'] = true;
              dispatch(storeActiveStep(newactivestep));
              setActiveSection({ action: 'MA' });
            }
          } else if(showUploadList){
              axiosInstanceDocument
                .request({
                  method: 'GET',
                  url: `/fetchTrackerDocuments/${customerID}/${activesection?.actionName}`
                })
                .then((response) => {
                  if (response.data) {
                    if (response?.data?.data) {
                      setUploadShowList(
                        response?.data?.data['Tracker Document Downloaded']
                      );
                    }
                  }
                });
          }else sectionSubmit && sectionSubmit(false, false, false);
        }
      } else if (count === 0) {
        let transforData =  Object.entries(responseStatus)[0]
        if(transforData[0] && transforData[0] === 'Order Guide' ){
          snackbarShowMessage({
            message: transforData[1][0].message,
            type: 'error'
          });
        }else if (!_.isEmpty(failedFile)) {
              snackbarShowMessage({
                message: failedFile.join("; "),
                type: 'error'
              });
        }else{
          snackbarShowMessage({
            message: `None of the Documents were uploaded. Unable to proceed`,
            type: 'error'
          });
        }
      }
    }
  }, [responseStatus, tempIdMapperFromBackEnd]);
  function getKeybyValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  function handleChange(event) {
    const {
      target: { value }
    } = event;
    setname(event.target.value);
    setdoctype(typeof value === 'string' ? value.split(',') : value);
  }
  function handleSubmit() {
    if(name === 'Inventory Readiness Tracker' || 'Inventory Snapshot' === name){
      setpopupopen(true)
    }else{
      if(isPreReqDoc ){
        if(checkMandatoryFile()?.length !== 0){
          snackbarShowMessage({
            message: `${checkMandatoryFile().join(',')} file(s) are mandatory!`,
            type: 'error'
          });
          return;
        }
       sectionSubmit();
      }else  sectionSubmit();
     
    }
  }
  
  function IRTConfirmFunc(){
    sectionSubmit();
  }

  function deleteFilesTarker(
    templateId,
    customerId,
    templateName,
    docStatusId
  ) {
    axiosInstanceDocument
      .request({
        method: 'DELETE',
        url: `/deleteDocument/${customerId}/${templateId}/${templateName}/${docStatusId}`
      })
      .then((response) => {
        axiosInstanceDocument
          .request({
            method: 'GET',
            url: `/fetchTrackerDocuments/${customerID}/${activesection?.actionName}`
          })
          .then((response) => {
            if (response.data) {
              if (response?.data?.data) {
                setUploadShowList(
                  response?.data?.data['Tracker Document Downloaded']
                );
              } else {
                setUploadShowList([]);
              }
            }
          });
      });
  }
  function downFilesTarker({ templateId, docName, docStatusId, fileName }) {
    axiosInstanceDocument
      .request({
        method: 'GET',
        url: `/downloadTrackerDocument/${templateId}/${customerID}/${docName=='MSA/LOI'?'MSALOI':docName}/${docStatusId}`,
        responseType: "blob"
      })
      .then((response) => {
        const contentType = response?.headers['content-type'];
        const url = window.URL.createObjectURL(
          new Blob([response?.data], { type: contentType })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        link.click();
      });
  }
  const [error,setError] = useState(false);
  const [errorText,setErrorText] = useState(null);
  function handleBlur(){
    if(name?.length < 1){
      setError(true)
      setErrorText('Doc name is mandatory!')
    }else{
      setname(name.trim());
      setError(false)
      setErrorText(null)
    }
  }

  function sortClass(sortData){
    const groupBy = (array, f) => {
      let groups = {};
      array.forEach((o) => {
        let group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      // return groups
      return Object.keys(groups).map((group) => {
        return {
          name:group,
          value:groups[group]
        };
      });
    };
    if(sortData.length>0){
      const sorted = groupBy(sortData, (item) => {
        return item.docName; 
      });
      return sorted;
    }else{
      return sortData
    }
  };
  function UploadedCollapseTable(props) {
    return (
      <TableContainer component={Paper} sx={{ maxHeight: 230, marginBottom: 2 }} className='upload-collapse-table'>
        <Table aria-label="collapsible table" size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell width='5%' style={{minwidth:30}}></TableCell>
              <TableCell width='30%'>
                Template Name
              </TableCell>
              <TableCell width='35%'>
                Name
              </TableCell>
              <TableCell width='10%'>
                Version Id
              </TableCell>
              <TableCell width='10%'>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {notUploadMandatoryDocs && notUploadMandatoryDocs?.map((row) => {
              return (
                <TableRow>
                  <TableCell width='5%' style={{minwidth:30}} >
                    
                  </TableCell>
                  <TableCell width='30%' style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={row?.docName}>
                    <Tooltip
                      title={
                        ` ${row?.docName} is Not Uploaded`
                      }
                      placement="bottom"
                    >
                      <IconButton disabled>
                        <CheckCircleOutlinedIcon />
                      </IconButton>
                      {row.docName}
                    </Tooltip>
                  </TableCell>
                  <TableCell component="th" scope="row" style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={row?.fileName} >
                  </TableCell>
                  <TableCell align="left">
                  </TableCell>
                  <TableCell align="left" style={{ minWidth: 60 }}></TableCell>
                </TableRow>)
            })
            }
            {sortClass(uploadShowList).map(rows => {
              return (
                <CollapseRows rows={rows}></CollapseRows>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  } 
  function CollapseRows(props) {
    let { rows } = props;
    const [expand, setExpand] = useState(false);
    let staticRow = { ...rows };
    let expandRow = { ...rows };
    if (rows.value?.length > 1) {
      expandRow['value'] = rows.value.slice(1, rows.value?.length);
      staticRow['value'] = rows.value.slice(0, 1);
    } else {
      staticRow = rows;
      expandRow = {};
    }
    return (
      <>
      {staticRow.value?.map((row) => (
        <Row
          row={row}
          isStatic={true}
          setExpand={setExpand}
          expand={expand}
          isShowExpand={expandRow?.value?.length > 0}
        />
      ))}
        <TableRow>
          <TableCell colSpan={12} style={{ paddingLeft: 16, paddingRight: 16 }}>
            <Collapse in={expand} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 0 }}>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {expand && expandRow.value?.map((row) => (
                      <Row
                        row={row}
                      />
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        </>
    )
  }
  function Row({row,isStatic,expand,isShowExpand,setExpand}){
    return (
      <TableRow sx={{ '& .MuiTableCell-root': { pt: 0, pb: 0, } }} key={row?.docStatusId}>
        <TableCell width='5%' style={{ minWidth: 30 }}>
          {isShowExpand && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setExpand(!expand)}
            >
              {expand ? <MinimizeOutlined /> : <AddOutlined />}
            </IconButton>)}
          </TableCell>
        <TableCell width='30%' style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={row?.docName}>
          {isStatic && <><IconButton disabled>
            <CheckCircleOutlinedIcon
              sx={{
                color: 'green'
              }}
            />
          </IconButton>
            {row.docName}</>
          }
        </TableCell>
        <TableCell width ='35%' scope="row" style={{ maxWidth: 200,overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={row?.fileName} >
          {row.fileName}
        </TableCell>
        <TableCell width='10%' align="left">
          {row?.versionId}
        </TableCell>
        <TableCell align="left" width='10%' style={{ minWidth: 60 }} >
          {_.includes(activesection?.operations, 'download') && <IconButton
            edge="end"
            data-testid="downloadfile"
            aria-label="comments"
            onClick={downFilesTarker.bind(null, row)}
          >
            <DownloadIcon />
          </IconButton>}
          {
            _.includes(activesection?.operations, 'delete') && status !== 'Completed' && <IconButton
              edge="end"
              aria-label="comments"
              onClick={deleteFilesTarker.bind(
                null,
                row.templateId,
                customerID,
                row.docName,
                row.docStatusId
              )}
            >
              <DeleteIcon className="sls-icon" />
            </IconButton>
          }
        </TableCell>
      </TableRow>
    )
  }
  return (
    <div>
      <div className="Select-flex-tt">
        <div>
          <FormControl
            sx={{
              '& .MuiInputBase-root': {
                marginTop: 4,
                marginBottom: 4,
                marginLeft: 4
              }
            }}
          >
            {isOthers?
            <TextField
            sx={{
              '& .MuiFormLabel-root': {
                marginTop: 4,
                marginBottom: 4,
                marginLeft: 4
              },
              '& .MuiFormHelperText-root':{
                marginTop:'-18px',
                marginLeft: 4,
              }
            }}
            required
            label="Doc Name"
            value={name}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error}
            helperText={error ? errorText : ""}
            // className='other_doc_name'
          />
            
            :<Select
              className="input-select"
              sx={{
                fontFamily: 'MyriadPro',
                fontWeight: 400,
                fontSize: 14,
                width: 240,
                height: 40
              }}
              onChange={handleChange}
              value={name}
              displayEmpty={true}
              renderValue={
                doctype?.length
                  ? (selected) => {
                      return selected;
                    }
                  : finalList?.length === 1
                  ? () => {
                      const selectedVal = finalList[0];

                      setname(selectedVal);
                      setdoctype(
                        typeof selectedVal === 'string'
                          ? selectedVal.split(',')
                          : selectedVal
                      );
                      return selectedVal;
                    }
                  : () => 'Select Items'
              }
            >
              {names?.map((name) => {
                if (isCustomerPortal && !_.includes(templateList, name)) {
                  return null;
                }
                return (
                  <MenuItem key={name} value={name}>
                    {/* <Checkbox checked={doctype.indexOf(name) > -1} /> */}
                    <ListItemText
                      primary={name}
                      sx={{
                        '& .MuiTypography-root': {
                          fontFamily: 'MyriadPro',
                          fontWeight: 400,
                          fontSize: 14
                        }
                      }}
                    />
                  </MenuItem>
                );
              })}
            </Select>}
          </FormControl>
          
          { (isOthers || name) && (
            <div className="upload-card-container">
              <DocumentUpload
                doc={name}
                documentID={documentId}
                setuploadedfiles={setuploadedfiles}
                uploadedfiles={uploadedfiles}
                snackbarShowMessage={snackbarShowMessage}
                enableBosSubRule={enableBosSubRule}
                isMsRsOrNIRS={isMsRsOrNIRS}
                isOthers={isOthers}
                tempIdMapperFromBackEnd={tempIdMapperFromBackEnd}
              />
            </div>
          )}

          <div className="show-upload-list">
            {showUploadList && (
              <div className="upload-list-table">
                {isPreReqDoc && uploadShowList.length !== 0 && <UploadedCollapseTable></UploadedCollapseTable>}
                {!isPreReqDoc && uploadShowList.length !== 0 && (
                  <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 230 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell align="left">Version Id</StyledTableCell>
                            {/* <StyledTableCell align="left">Date</StyledTableCell>
                            <StyledTableCell align="left">Time</StyledTableCell> */}
                            {!isPreReqDoc &&
                             ( <StyledTableCell align="left">Date</StyledTableCell>
                            )}
                            {!isPreReqDoc &&
                             ( <StyledTableCell align="left">Time</StyledTableCell>
                            )}
                            <StyledTableCell align="left">
                              Action
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                      
                        <TableBody>
                         
                          {!isPreReqDoc && uploadShowList.map((row) => {
                            return (
                              <TableRow>
                                <TableCell component="th" scope="row" style={{ maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title ={row?.fileName} >
                                  {row.fileName}
                                </TableCell>
                                <TableCell align="left">
                                  {row?.versionId}
                                </TableCell>
                                {!isPreReqDoc &&
                                <TableCell align="left">
                                  {row.formatModifiedDate}
                                </TableCell>}
                                {!isPreReqDoc &&
                                <TableCell align="left">
                                  {row.formatModifiedTime}
                                </TableCell>}
                                <TableCell align="left" style={{ minWidth: 60}}>
                                  {_.includes(activesection?.operations, 'download') && <IconButton
                                    edge="end"
                                    data-testid="downloadfile"
                                    aria-label="comments"
                                    onClick={downFilesTarker.bind(null, row)}
                                  >
                                    <DownloadIcon />
                                  </IconButton>}
                                  
                                  
                                  {
                                    _.includes(activesection?.operations, 'delete') && status !== 'Completed'&& <IconButton
                                    edge="end"
                                    aria-label="comments"
                                    onClick={deleteFilesTarker.bind(
                                      null,
                                      row.templateId,
                                      customerID,
                                      row.docName,
                                      row.docStatusId
                                    )}
                                  >
                                    <DeleteIcon className="sls-icon" />
                                  </IconButton>
                                  }
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                )}
              </div>
            )}
          </div>
          {name && name === 'Request MSRs' && (
            <a
              className="masrsLink"
              href={`https://dashboard.rpa.us-east-1.aws.sysco.net/projects/mint`}
              target="_blank" rel="noreferrer"
            >
              {`MSR BOT Link`}
            </a>
          )}

          {name && name === 'Request NIRs' && (
            <a
              className="masrsLink"
              href={SalesForceTaskUrl}
              target="_blank" rel="noreferrer"
            >
              {`NIR Business Event Link`}
            </a>
          )}
          {<CustomAcordion uploadedfiles={uploadedfiles} setuploadedfiles={setuploadedfiles} setResponseStatus={setResponseStatus}/>}
            
          <Divider sx={{ marginTop: 16 }} variant="fullWidth" />

          {!hideButtons &&
            doctype?.length > 0 &&
            name !== 'MsRs' &&
            name !== 'NIRs' &&
            !isPreReqDoc && (
              <div className="button-wrapper">
                {!customerPersona && (
                  <EnableSkip
                    isSkipActive={isSkipActive}
                    sectionSubmit={sectionSubmit}
                  />
                )}
                {!reviewcompflag && (
                  <Box sx={{ m: 1, position: 'relative' }}>
                    <Button
                      sx={{ '& .MuiButton-root': { fontSize: 14 } }}
                      id="secondary"
                      className="buttons-1"
                      onClick={closeHandler}
                    >
                      Cancel
                    </Button>
                  </Box>
                )}
                <Box sx={{ m: 1, position: 'relative' }}>
                  {!isMsRsOrNIRS && <Button
                    id="primary"
                    className="buttons-2"
                    onClick={submithandler}
                    data-testid="upload-btn"
                    disabled={uploading}
                  >
                    {uploading ? 'Uploading...' : 'Upload'}
                  </Button>}
                  {uploading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: 'white',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px'
                      }}
                    />
                  )}
                </Box>
                {(enableBosSubRule || showMarkBtn) && !customerPersona && (
                  <Box sx={{ m: 1, position: 'relative' }}>
                    <div>
                      <Button
                        // sx={{ marginTop: 1 }}
                        id="secondary"
                        className="markbutton"
                        data-testid="submit-btn"
                        onClick={handleSubmit}
                        // disabled={disableSubmit}
                      >
                        Mark this step as complete
                      </Button>
                    </div>
                  </Box>
                )}
              </div>
            )}
            {
              isPreReqDoc &&(
                <div className="button-wrapper">
                  {!customerPersona && (
                    <EnableSkip
                      isSkipActive={isSkipActive}
                      sectionSubmit={sectionSubmit}
                    />
                  )}
                  {!reviewcompflag && (
                    <Box sx={{ m: 1, position: 'relative' }}>
                      <Button
                        sx={{ '& .MuiButton-root': { fontSize: 14 } }}
                        id="secondary"
                        className="buttons-1"
                        onClick={closeHandler}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                  <Box sx={{ m: 1, position: 'relative' }}>
                    {!isMsRsOrNIRS && <Button
                      id="primary"
                      className="buttons-2"
                      onClick={submithandler}
                      data-testid="upload-btn"
                      disabled={uploading}
                    >
                      {uploading ? 'Uploading...' : 'Upload'}
                    </Button>}
                    {uploading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: 'white',
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px'
                        }}
                      />
                    )}
                  </Box>
                  {(enableBosSubRule || showMarkBtn) && !customerPersona && (
                    <Box sx={{ m: 1, position: 'relative' }}>
                      <div>
                        <Button
                          // sx={{ marginTop: 1 }}
                          id="secondary"
                          className="markbutton"
                          data-testid="submit-btn"
                          onClick={handleSubmit}
                          // disabled={disableSubmit}
                        >
                          Mark this step as complete
                        </Button>
                      </div>
                    </Box>
                  )}
                </div>
              )
            }
        </div>
      </div>
      {popupopen && (
        <InventoryReadinessTrackerPopup
          popupopen={popupopen}
          actionName={activesection?.actionName}
          setpopupopen={setpopupopen}
          confirmFunc = {IRTConfirmFunc}
        />
      )}
    </div>
  );
}

export default withSnackbar(Selectq);
