import { Avatar, Box, Typography } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import '../OfflineChatter/index.css';


export default function SubChat({ data}) {

  function dateformatter(dateString)
  {
    const [year, month, day, hours, minutes, seconds] = dateString.split(".");
   
    const date = new Date(year, month-1,day, hours, minutes, seconds);
   
    return date.toLocaleDateString().concat(" ",date.toLocaleTimeString())
  }
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  return (
    <Box className="subchatBox">
      { data?.length > 0
        ? data.slice(1)?.map((chat, index) => {
          
            return (
              <Box
                ref={messagesEndRef}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  fontFamily: 'MyriadPro'
                }}
                key={chat.message}
              >
                <Avatar
                  alt="Recepient Profile Pic"
                  sx={{
                    width: 40,
                    height: 40,
                    mt: 1.5,
                    color: '#000',
                    backgroundColor: '#bdbdbd7a'
                  }}
                 // src={chat.imageURL}
                />
                <Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      backgroundColor: '#fff',
                      borderRadius: '0.4rem 0.4rem 0 0',
                      fontFamily: 'MyriadPro',
                      border: '1px solid #ebebeb',
                      p: 2,
                      mt: 1,
                      ml: { sm: 2, xs: 2 },
                      mr: { sm: 2, xs: 2 },
                      display: 'inline-block',
                      wordBreak: 'break-word',
                      width:1000
                    }}
                  >
                    <div className="sendename">
                      {chat.modifiedBy}&nbsp;&nbsp;
                      <span className="sendertime"> {dateformatter(chat.modifiedDate)}</span>
                    </div>
                    <div className="senderMessage"> {chat.message}</div>

                  </Typography>
                 
                </Typography>
              </Box>
            );
          })
        : null}
      <Box
        sx={{
          bgcolor: 'white',
          width: '100%',
          position: 'fixed',
          bottom: 0,
          zIndex: 1,
          pb: 1,
          pt: 1,
          ml: '5vw'
        }}
      >
      </Box>
    </Box>
  );
}
