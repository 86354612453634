import axios from 'axios';
import {
  API_REQUEST_DASHBOARD,
  API_REQUEST_DOCUMENT,
  API_REQUEST_IMAGE,
  API_REQUEST_EMAIL,
  API_REQUEST_EMAIL_NON_BFF,
  API_REQUEST_EMAIL_NON_BFF_CHAT,
  API_REQUEST_EMAIL_ADMIN,
  API_ICT,
  API_REQUEST_DASHBOARD_NON_BFF
} from '../constant/api';
import { messages } from '../config';
import { Auth } from 'aws-amplify';
import { redirectUrl } from '../aws-config';


function redirectFunc(response){
  if(response && response.status === 401){
    localStorage.removeItem('userInfo');
    //remove role info
    localStorage.removeItem('userRole');
    window.location.assign(redirectUrl);
    return
  }
}
export const axiosInstanceDashboard = axios.create({
  baseURL: API_REQUEST_DASHBOARD,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstanceDashboard.interceptors.request.use(async (request) => {
  const res = await Auth.currentSession().then((data) => data.idToken.jwtToken);
  request.headers.Authorization = 'Bearer ' + res;
  return request;
});

axiosInstanceDashboard.interceptors.response.use(
  function (response) {
    // console.log('response',response)
    if (response.status === 401) {
      redirectFunc(response)
      return Promise.reject('Unauthorized access');
    } else if (response.status === 500) {
      return Promise.reject('Please try again later');
    }
    return Promise.resolve(response);
  },
  function (error) {
    if(error?.message?.includes('Please authenticate')){
      window.location.assign(redirectUrl);
      return;
    }
    redirectFunc(error.response)
    return Promise.reject(error);
  }
);

//dashboard non bff
export const axiosInstanceDashboardnonbff = axios.create({
  baseURL: API_REQUEST_DASHBOARD_NON_BFF,
  headers: {
    'Content-Type': 'application/json'
  }
});
axiosInstanceDashboardnonbff.interceptors.request.use(async (request) => {
  const res = await Auth.currentSession().then((data) => data.idToken.jwtToken);
  request.headers.Authorization = 'Bearer ' + res;
  return request;
});

axiosInstanceDashboardnonbff.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      redirectFunc(response)
      return Promise.reject('Unauthorized access');
    } else if (response.status === 500) {
      return Promise.reject('Please try again later');
    }
    return Promise.resolve(response);
  },
  function (error, _status) {
    if(error?.message?.includes('Please authenticate')){
      window.location.assign(redirectUrl);
      return;
    }
    redirectFunc(error.response)
    return Promise.reject(error);
  }
);
export const axiosInstanceDocument = axios.create({
  baseURL: API_REQUEST_DOCUMENT,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});

axiosInstanceDocument.interceptors.request.use(async (request) => {
  const res = await Auth.currentSession().then((data) => data.idToken.jwtToken);
  request.headers.Authorization = 'Bearer ' + res;
  return request;
});

axiosInstanceDocument.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      redirectFunc(response)
    } else if (response.status === 500) {
      return messages.internalError;
    }
    return response;
  },
  function (error) {
    if(error?.message?.includes('Please authenticate')){
      window.location.assign(redirectUrl);
      return;
    }
    redirectFunc(error.response)
    return error;
  }
);

export const axiosInstanceICT = axios.create({
  baseURL: API_ICT,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});




export const axiosInstanceImage = axios.create({
  baseURL: API_REQUEST_IMAGE,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  }
});

axiosInstanceImage.interceptors.request.use(async (request) => {
  const res = await Auth.currentSession().then((data) => data.idToken.jwtToken);
  request.headers.Authorization = 'Bearer ' + res;
  return request;
});
axiosInstanceImage.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      redirectFunc(response)
    } else if (response.status === 500) {
      return messages.internalError;
    }
    return response;
  },
  function (error) {
    if(error?.message?.includes('Please authenticate')){
      window.location.assign(redirectUrl);
      return;
    }
    redirectFunc(error.response)
    return error;
  }
);
export const axiosInstanceEmail = axios.create({
  baseURL: API_REQUEST_EMAIL,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstanceEmail.interceptors.request.use(async (request) => {
  const res = await Auth.currentSession().then((data) => data.idToken.jwtToken);
  request.headers.Authorization = 'Bearer ' + res;
  return request;
});

axiosInstanceEmail.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      redirectFunc(response)
    } else if (response.status === 500) {
      return messages.internalError;
    }
    return response;
  },
  function (error) {
    if(error?.message?.includes('Please authenticate')){
      window.location.assign(redirectUrl);
      return;
    }
    redirectFunc(error.response)
    return error;
  }
);

//email non bff
export const axiosInstanceEmailNonBff = axios.create({
  baseURL: API_REQUEST_EMAIL_NON_BFF,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstanceEmailNonBff.interceptors.request.use(async (request) => {
  const res = await Auth.currentSession().then((data) => data.idToken.jwtToken);
  request.headers.Authorization = 'Bearer ' + res;
  return request;
});

axiosInstanceEmailNonBff.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      redirectFunc(response)
    } else if (response.status === 500) {
      return messages.internalError;
    }
    return response;
  },
  function (error) {
    if(error?.message?.includes('Please authenticate')){
      window.location.assign(redirectUrl);
      return;
    }
    redirectFunc(error.response)
    return error;
  }
);

//email chat
export const axiosInstanceEmailNonBffchat = axios.create({
  baseURL: API_REQUEST_EMAIL_NON_BFF_CHAT,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstanceEmailNonBffchat.interceptors.request.use(async (request) => {
  const res = await Auth.currentSession().then((data) => data.idToken.jwtToken);
  request.headers.Authorization = 'Bearer ' + res;
  return request;
});

axiosInstanceEmailNonBffchat.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      redirectFunc(response)
    } else if (response.status === 500) {
      return messages.internalError;
    }
    return response;
  },
  function (error) {
    if(error?.message?.includes('Please authenticate')){
      window.location.assign(redirectUrl);
      return;
    }
    redirectFunc(error.response)
    return error;
  }
);

//email admin
export const axiosInstanceEmailadmin = axios.create({
  baseURL: API_REQUEST_EMAIL_ADMIN,
  headers: {
    'Content-Type': 'application/json'
  }
});

axiosInstanceEmailadmin.interceptors.request.use(async (request) => {
  const res = await Auth.currentSession().then((data) => data.idToken.jwtToken);
  request.headers.Authorization = 'Bearer ' + res;
  return request;
});

axiosInstanceEmailadmin.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      redirectFunc(response)
    } else if (response.status === 500) {
      return messages.internalError;
    }
    return response;
  },
  function (error) {
    if(error?.message?.includes('Please authenticate')){
      window.location.assign(redirectUrl);
      return;
    }
    redirectFunc(error.response)
    return error;
  }
);
