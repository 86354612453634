import React from 'react';
import Card from '@mui/material/Card';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CallIcon from '@mui/icons-material/Call';
import Grid from '@mui/material/Grid';

export default function ContactsInformation({ customerdetails }) {
  const syscoSalesContact = 'Sysco Sales Contact';
  const lead = 'Onboarding Lead';
  const partner = 'Onboarding Partner';
  const getContactByType = (type, customer = customerdetails) => {
    return (customer.customerContactResponses || []).find(
      (item) => item.contactType === type
    );
  };

  const Contacts = [
    {
      label: syscoSalesContact,
      key: syscoSalesContact,
      contact: getContactByType(syscoSalesContact) || {}
    },
    {
      label: lead,
      key: lead,
      contact: getContactByType(lead) || {}
    },
    {
      label: partner,
      key: partner,
      contact: getContactByType(partner) || {}
    }
  ];
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Grid container sx={{ color: 'text.primary' }}>
          <Grid item xs={1}>
            <CallIcon style={{ color: '#1976d2', width: '20px' }} />
          </Grid>
          <Grid item xs={11}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 'bold',
                fontFamily: 'MyriadPro',
                marginLeft: '10px'
              }}
              gutterBottom
            >
              Contact Informations
            </Typography>

            {Contacts.map((item) => {
              return (
                <React.Fragment>
                  <Typography
                    sx={{
                      fontSize: 12,
                      color: '#6a737b',
                      fontFamily: 'MyriadPro',
                      letterSpacing: '0.4px',
                      marginLeft: '16px'
                    }}
                    component="div"
                  >
                    {item.label}
                  </Typography>

                  <hr style={{ borderTop: '1px dotted #FDFEFE' }}></hr>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 'bold',
                      fontFamily: 'MyriadPro',
                      letterSpacing: '0.4px',
                      textTransform: 'capitalize',
                      marginLeft: '16px'
                    }}
                    component="div"
                  >
                    {item.contact?.firstName}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ fontFamily: 'MyriadPro', marginLeft: '16px' }}
                  >
                    {item.contact?.email}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      fontWeight: 'bold',
                      fontFamily: 'MyriadPro',
                      marginLeft: '16px'
                    }}
                  >
                    Call:
                    <Button size="small" sx={{ color: '#008CD2' }}>
                      {item.contact?.contactNo}
                    </Button>
                  </Typography>
                  <br></br>
                </React.Fragment>
              );
            })}

            <Grid container sx={{ color: 'text.primary' }}>
              <Grid item xs={2}>
                <InsertCommentOutlinedIcon
                  style={{ color: '#AAACAE', marginLeft: '16px' }}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography
                  sx={{ fontSize: 13 }}
                  color="text.primary"
                  gutterBottom
                >
                  <label
                    style={{
                      fontFamily: 'MyriadPro',
                      fontSize: 13,
                      fontWeight: 'bold',
                      marginLeft: '10px'
                    }}
                  >
                    NCOB Help
                  </label>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
