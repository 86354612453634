import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import '../DocumentUpload/index.css';
import Card from '@mui/material/Card';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import CloudDoneRoundedIcon from '@mui/icons-material/CloudDoneRounded';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { axiosInstanceDocument } from '../../../api/Axios';
import DangerousIcon from '@mui/icons-material/Dangerous';

const DocumentUpload = (props) => {
  const {
    documentID,
    doc,
    setuploadedfiles,
    uploadedfiles,
    fromTemplateAdminDashboard,
    enableBosSubRule,
    isMsRsOrNIRS,
    isOthers,
    tempIdMapperFromBackEnd,
    fromSalesDashboard,
  } = props;
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFilename] = useState([]);
  const [unsupportedfile, setunsupportedfile] = useState(false);
  const [unsupportedfilesize, setunsupportedfilesize] = useState(false);
  const [availTemplateFile, setavailTemplateFile] = useState(true);
  const activeId = useSelector((state) => state?.storeActiveStep?.actionId);
  const [documentId, setdocumentId] = useState();
  //bug fix:if click on  the folder that template list is more that 1, then switch t the folder that template list is one, the documentId will be set to undefined sometimes, if upload file will throw error
  useEffect(()=>{
    //COT-8889:NCOB -The Template name is updated incorrectly while uploading Prerequisites documents
    // if(!documentID){
      if(isOthers) setdocumentId(1000);
      else if(fromTemplateAdminDashboard || fromSalesDashboard) setdocumentId(documentID);
      else setdocumentId(getKeybyValue(tempIdMapperFromBackEnd, doc));
    // }
  },[tempIdMapperFromBackEnd,doc]);
  function getKeybyValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }
  useEffect(() => {
    setFileUploaded(false);
    setunsupportedfile(false);
    setunsupportedfilesize(false);
    // handleDownloadTemplate(documentId, true);
  }, [doc, documentId, activeId]);
  useEffect(() => {
    //bug fix: fixed calling handleDownloadTemplate twice if switch doc type
    if(documentId){
      handleDownloadTemplate(documentId, true);
    }
  },[documentId]);
  useEffect(() => {
    if (Object.keys(uploadedfiles).length === 0) setFileUploaded(false);
    if (Object.keys(uploadedfiles).length !== 0) {
      setFilename(uploadedfiles?.[doc]?.acceptedFiles)
       //COT-8819: defect- if remove all files for one doc type from UI, will show 'Drop your file here' card 
      if(uploadedfiles[doc] === undefined){
        setFileUploaded(false);
      }
    }
  }, [uploadedfiles]);

  const unsupportedfilehandler = (acceptedFiles) => {
      setunsupportedfile(true);
   
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
        if (acceptedFiles.filter(item => item === undefined).length > 0) {
          unsupportedfilehandler();
        } else {
          if (
            // acceptedFiles[i].size / (1024 * 1024) > 10 ||
            // acceptedFiles[i].size / (1024 * 1024) < 0
            acceptedFiles.filter(item => (item.size / (1024 * 1024) > 10 ||item.size / (1024 * 1024) < 0)).length > 0
          )
            setunsupportedfilesize(true);
          else {
            setFileUploaded(true);
            setFilename(acceptedFiles);
            setuploadedfiles((prevState) => {
              return { ...prevState, [doc]: { acceptedFiles, documentID:documentId } };
            });
       
          }
        }
    },
    [doc, documentId]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: doc === 'Spec Sheet' || doc === 'Vision Report' ? true: false,
    accept: enableBosSubRule
      ? {
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
          'application/vnd.ms-excel': ['.xlsx']
        }
      : {
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.csv',
            '.xlsx',
            '.xls',
            '.xlsm',
            '.zip',
            '.msg',
            '.html',
          ],
          'application/vnd.ms-excel': ['.csv', '.xlsx', '.xls', '.xlsm'],
          'application/pdf': ['.pdf', '.docx', '.doc']
        }
  });

  const handleDownloadTemplate = async (
    templateId,
    checkTemplateDownloaded
  ) => {
    axiosInstanceDocument
      .request({
        method: 'GET',
        url: `/downloadTemplate/${templateId}`,
        responseType: 'blob'
      })
      .then((response) => {
        if (checkTemplateDownloaded) {
          if (response?.data?.size < 150) {
            setavailTemplateFile(false);
            return false;
          } else {
            setavailTemplateFile(true);
            return false;
          }
        } else {
          const filename = response?.headers['content-disposition'];
          const contentType = response?.headers['content-type'];
          const url = window.URL.createObjectURL(
            new Blob([response?.data], { type: contentType })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            doc + filename.substring(filename.lastIndexOf('.'))
          );
          link.click();
        }
      });
  };

  return (
    <>
      <div>
        <div>
          <div className="documentUploadClass">
            {unsupportedfile || unsupportedfilesize ? (
              unsupportedfile ? (
                <>
                  <Card className="fileUpload">
                    <DangerousIcon
                      onClick={() => {
                        setunsupportedfile(false);
                      }}
                    />
                    <div className="unsupported">File Type Not Supported</div>
                    <div className="uploadFileDisplay"></div>
                  </Card>
                </>
              ) : (
                <>
                  <Card className="fileUpload">
                    <DangerousIcon
                      onClick={() => {
                        setunsupportedfilesize(false);
                      }}
                    />
                    <div className="unsupported">
                      File Size should be less than 10 MB
                    </div>
                    <div className="uploadFileDisplay"></div>
                  </Card>
                </>
              )
            ) : !fileUploaded ? (
              <>
              {!isMsRsOrNIRS && (<div {...getRootProps()} id={`upload-item-${documentId}`}>
                  <input {...getInputProps()} />
                  <Card className="notfileUpload">
                    <BackupOutlinedIcon className="BackupOutlinedIcon" />
                    <div data-testid='dropzone' className="uploadIcon">
                      Drop your file here, or <b>browse</b>
                    </div>
                  </Card>
                </div>
                )}
                {
                  <div className={isMsRsOrNIRS ? "helpertext isMSR" : "helpertext"}>
                    {fromTemplateAdminDashboard || isMsRsOrNIRS ? (
                      <div></div>
                    ) : (
                      <div className="item-list">{doc}</div>
                    )}
                    {availTemplateFile && !fromTemplateAdminDashboard && !isOthers && (
                      <div
                        data-testid="downloaddiv"
                        className="download"
                        onClick={() => {
                          handleDownloadTemplate(documentId);
                        }}
                      >
                        <FileDownloadOutlinedIcon
                          className="download-icon"
                          sx={{ color: '#AAACAE' }}
                        />
                        <p className="downloadtext">Download Template</p>
                      </div>
                    )}
                  </div>
                }
              </>
            ) : (
              <>
                <Card className="fileUpload">
                  <CloudDoneRoundedIcon className="CloudDoneRoundedIcon" />
                  <div className="uploadFilename">
                      {fileName && (fileName?.length > 2
                        ? `${fileName.length} files`
                        : fileName.map((file) => file.name).join(", "))}
                  </div>
                  <div className="uploadFileDisplay"></div>
                </Card>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DocumentUpload;
