import { axiosInstanceDashboard } from '../../../../api/Axios';

export default function requestTemplate(params) {
  return axiosInstanceDashboard.request({
    method: 'POST',
    url: `updateCustomerProgressStatus`,
    data: {
      customerId: `${params?.payload?.customerId}`,
      actionItem: `${params?.payload?.actionId}`,
      sectionItem: `${params?.payload?.sectionId}`,
      sectionStatus: `${params?.payload?.sectionStatus}`,
      comments:`${params?.payload?.comments}`
    }
  });
}
