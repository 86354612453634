
import {axiosInstanceDashboard } from '../api/Axios';

export async function triggerCustomerPortal(customerId) {
  try {
    const response = await axiosInstanceDashboard.request({
      method: 'GET',
      url: `/customerPortalIntegration?customerId=${customerId}`
    });
   
    return response;
  } catch (error) {
    console.error(error);
  }
}


