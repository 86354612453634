import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import {
  ItemCategorizationAPI,
  conciergeIntegrationAPI
} from '../../../utils/ItemCategorizationAPI';
import CircularProgress from '@mui/material/CircularProgress';
import './TextButton.css';
import { axiosInstanceDocument,axiosInstanceEmail } from '../../../api/Axios';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

const TextButton = (props) => {
  const {
    snackbarShowMessage,
    sectionSubmit,
    customerID,
    closeHandler,
    isCustomerApproval
  } = props;

  const [isbuttonclicked, setbuttonclicked] = useState(false);

  const activeSection = useSelector((state) => state?.storeActiveStep);
  const label = activeSection?.label;
  const categorizationflag = activeSection?.categorizationflag;
  const enableConciergeFlag = activeSection?.enableConciergeFlag;
  const justText = activeSection?.justText;
  const { customerID: customerId } = useParams();
  const [needApprovalFile, setNeedApprovalFile] = useState(null);
  const [loading, setLoading] = useState(false);
  function closehandler() {
    closeHandler();
  }
  useEffect(() => {
    if (isCustomerApproval) {
      axiosInstanceDocument
        .request({
          method: 'GET',
          url: `/downloadDocuments/${customerId}`
        })
        .then((response) => {
          if (response?.data?.errorCode === null) {
            const data = _.get(response, 'data.data.Document Downloaded');
            if(activeSection?.actionId === 67){
              setNeedApprovalFile(_.find(data, { templateId: 166 }));
            }else{
              setNeedApprovalFile(_.find(data, { templateId: 13 }));

            }
          }
        });
    }
  }, [customerId, isCustomerApproval,activeSection?.actionId]);
  const approveDocument = (CorrectAttachedFile) => {
    const { comment, templateId, docStatusId } = CorrectAttachedFile;
    return axiosInstanceDocument
      .request({
        method: 'POST',
        url: `/updateDocApproval`,
        data: [{
          customerId,
        templateId,
        comments: comment,
        isApproved: 'Approved',
        docStatusId: docStatusId
        }]
      })
   
  }
  const getApproveFileFromAttachment= async()=>{
    return await axiosInstanceEmail
    .request({
      method: 'GET',
      url: `/getEmailAttachment?customerId=${customerId}&actionId=${activeSection?.actionId}`
    })
  }
  async function onClickhandler() {
    setbuttonclicked(true);
    if (categorizationflag) {
      const res = await ItemCategorizationAPI(customerID);

      if (res?.data?.errorMessage)
        snackbarShowMessage({
          message: `${res.data.errorMessage}`,
          type: 'error'
        });
      if (res?.data?.errorMessage === null || res?.data?.errorMessage === '') {
        sectionSubmit(false, false, false, false, true);
        snackbarShowMessage({
          message: `${res.data.data.Status}`,
          type: 'success'
        });
      }
    } else if (isCustomerApproval) {
      if(!needApprovalFile){
        if(activeSection?.actionId === 67){
          snackbarShowMessage({
            message: `Please Upload Transfer List File`,
            type: 'error'
          });
        }else{
          snackbarShowMessage({
            message: `Please Upload Order Guide File`,
            type: 'error'
          });
        }
      }else{
        const res =await getApproveFileFromAttachment()
        if (res?.data?.errorMessage)
          snackbarShowMessage({
            message: `${res.data.errorMessage}`,
            type: 'error'
          });
        if (res?.data?.errorMessage === null || res?.data?.errorMessage === '') {
          approveDocument({..._.get(res,'data.data.EmailInfo'),templateId:_.get(res,'data.data.EmailInfo.masterTemplate.templateId')}).then((resp) => {
            if (resp?.data?.errorMessage === null || resp?.data?.errorMessage === '') {
              sectionSubmit(false, false, false, false, true);
            }else{
              snackbarShowMessage({
                message: `${resp.data.errorMessage}`,
                type: 'error'
              });
            }
          })
        }
       
      }
    }else if (enableConciergeFlag) {
      setLoading(true);
      const res = await conciergeIntegrationAPI(customerID);
      if (res?.data?.errorMessage)
        snackbarShowMessage({
          message: `${res.data.errorMessage}`,
          type: 'error'
        });
      if (res?.data?.errorMessage === null || res?.data?.errorMessage === '') {
        sectionSubmit(false, false, true, false, false);
        snackbarShowMessage({
          message: `${res?.data?.data?.['Concierge Response']}`,
          type: 'success'
        });
      }
      setLoading(false);
    } else sectionSubmit(false, false, false, false, true);
    setbuttonclicked(false);
  }
  return (
    <div className="Text_container">
      <div className="label">{label}</div>
      {!justText && (
        <div className="buttons-set">
          {enableConciergeFlag && (
            <Button id="secondary" data-testid="no" onClick={closehandler}>
              No
            </Button>
          )}
          {categorizationflag ? (
            <Button
              className="button"
              id="primary"
              disabled={isbuttonclicked}
              onClick={onClickhandler}
              data-testid="yes"
            >
              Push Categorization to Salesforce
            </Button>
          ) : (
            <Button
              className={`button ${loading?' disabled-button':''} `}
              id="primary"
              disabled={isbuttonclicked}
              onClick={onClickhandler}
              data-testid="yes"
            >
              Yes
            </Button>
          )}
          {isbuttonclicked && (
            <CircularProgress
              size={24}
              sx={{
                color: 'white',
                position: 'absolute',
                // top: '50%',
                left: '54%'
                // marginTop: '-12px',
                //  marginLeft: '-12px'
              }}
            />
          )}
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: 'white',
                position: 'absolute',
                left: '87%'
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default TextButton;
