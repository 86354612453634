
import {mapping} from '../Helper/mapping'

export function CardClickCheck(actionName,acctype,stepStatus,item,docstatus) {
// debugger
   if (item?.bundledWithDoc) 
   {

        const dependentdoc = docstatus?.find(
          (key) => key?.docName === item.bundledWithDoc
        );
       let value = dependentdoc?.isApproved === 'Approved';
        return value;       
 }
    
else
{ 
    const dependentitems =  mapping?.[acctype]?.find( (item) => item?.actionName === actionName)?.bundledWith
   
    if(!dependentitems)
    return true
   if(dependentitems) 
   {
    for(let value of dependentitems)
    {
        if(stepStatus[value] !== 'Completed' && stepStatus[value] !== 'Skipped' )
        return false
    }
     }
  
    return true;
    }
   
  }
  