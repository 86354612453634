import _ from 'lodash';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper
} from '@mui/material';
import React from 'react';


const TableBuilder = React.forwardRef(({ columns, dataSource }, ref) => {
  return (
      <TableContainer component={Paper} sx={{maxHeight: 400}}>
        <Table stickyHeader ref={ref}>
          <TableHead>
            <TableRow>
              {_.map(columns, (col) => {
                return <TableCell key={col.key}>{col.title}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(dataSource, (row) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {_.map(columns, ({ key, name, render }) => {
                  let content = _.get(row, name);
                  if (_.isFunction(render)) {
                    content =  render(_.get(row, name), row)
                  }
                  return (
                    <TableCell key={`${key || name}_${row.id}`}>
                      {content}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );
});

export default TableBuilder;
