import React, { useEffect, useState } from 'react';
import { axiosInstanceDashboard } from '../../../api/Axios';
import LocationProgressPopup from '../Popup/LocationProgressPopup';
import '../SalesCustomerInfo/index.css';
import { useParams } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import _ from 'lodash'
const LocationFields = () => {
  const [popupopen, setpopupopen] = useState(false);
  const [value, setvalue] = useState('');
  const [isCompleted,setIsCompleted] = useState(false);
  const handler = (type) => {
    setpopupopen(true);
    setvalue(type);
  };
  const [locationList, setlocation] = useState();
  const [conceptList, setConcept] = useState();
  const handleError = useErrorHandler();
  const { parentCustID } = useParams();
  const [customerDetails, setCustomerDetails] = useState({});
  const {customerID} = useParams();
    let isCustomerPortal = localStorage.getItem('customerPortalUser') ? true : false;
      const conceptId = isCustomerPortal && customerDetails?.conceptId ? customerDetails.conceptId : "";
  const fetchlocation = (value) => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getAllConceptOrLocationList?parentCustID=${parentCustID}&accountType=${value}&conceptId=`
      })
      .then(
        (response) => {
          if (response) {
            if (response.data) {
              if (value === 'LOCATION') {
                setlocation(response.data?.data?.Customer);
              }
              if (value === 'CONCEPT') {
                setConcept(response.data?.data?.Customer);
              }
            }
          }
        },
        (error) => handleError(error)
      );
  };

  
  const fetchlist = () => {
    const param = customerID ? customerID : parentCustID;
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getCustomerDetails?id=${param}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setCustomerDetails(_.get(response, 'data.data.Customer', {}));
          }
        }
      });
  };

  useEffect(() => {
    fetchlist();
    fetchlocation('LOCATION');
    fetchlocation('CONCEPT');
    
  }, [customerDetails?.conceptId]);

  const completedLocation = locationList?.filter(
    (e) => e.progressStatus === 'Steady State'
  )?.length;
  const activeLocation = locationList?.filter(
    (e) => e.progressStatus !== 'Steady State' 
  )?.length;
  const completedConcept = conceptList?.filter(
    (e) => e.progressStatus === 'Steady State'
  )?.length;
  const activeConcept = conceptList?.filter(
    (e) => e.progressStatus !== 'Steady State' 
  )?.length;
  return (
    <div>
      <div className="locationfields">
        
      { customerDetails?.accountType==='CMU' &&
      (<>
      <div className="fields">
          List of Locations{' '}
          <div className="values">
            {completedLocation || '0'}&nbsp;
            {completedLocation > 0 && (
              <span
                className="viewDocument"
                onClick={() => {handler('LOCATION');setIsCompleted(true)}}
                variant="text"
              >
                View all
              </span>
            )}
          </div>
        </div>

        
        <div className="fields">
          Locations In Progress{' '}
          <div className="values2">
            {activeLocation || '0'}&nbsp;
            {activeLocation > 0 && (
              <span
                className="viewDocument"
                onClick={() => {handler('LOCATION');setIsCompleted(false)}}
                variant="text"
              >
                View all
              </span>
            )}
          </div>
        </div>
        </>
        )}


        { customerDetails.accountType==='CMU' &&
        (<>
        <div className="fields">
          List of Concepts{' '}
          <div className="values">
            {completedConcept || '0'}&nbsp;
            {completedConcept > 0 && (
              <span
                className="viewDocument"
                onClick={() => {handler('CONCEPT');setIsCompleted(true)}}
                variant="text"
              >
                View all
              </span>
            )}
          
          </div>
        </div>
        <div className="fields">
          Concepts In Progress{' '}
          <div className="values2">
            {activeConcept || '0'}&nbsp;
            {activeConcept > 0 && (
              <span
                className="viewDocument"
                onClick={() => {handler('CONCEPT');setIsCompleted(false)}}
                variant="text"
              >
                View all
              </span>
            )}
          </div>
        </div>
        </>
        )}
      </div>
      {popupopen && (
        <LocationProgressPopup
          value={value}
          popupopen={popupopen}
          setpopupopen={setpopupopen}
          conceptId={conceptId}
          isCompleted={isCompleted}
        />
      )}
    </div>
  );
};

export default LocationFields;
