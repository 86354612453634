import React, { useCallback, useEffect,useState} from 'react';
import LinearProgress, {
  linearProgressClasses
} from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import moment from 'moment';
import { API_REQUEST_IMAGE, LOGO_PATH } from '../../../constant/api';
import { Auth } from 'aws-amplify';
import { axiosInstanceImage } from '../../../api/Axios';

const CustomerList = (props) => {
  const { customer } = props;
  const IMAGE_BASE_URL = `${API_REQUEST_IMAGE}`;
  const colorCodes = {
    ACTIVE: {
      color: '#56B146',
      bgColor: '#E6F4E4'
    },
    PENDING: {
      color: '#FF8A00',
      bgColor: '#FFEED9'
    },
    INCOMPLETED: {
      color: '#EC0A05',
      bgColor: '#FFE5E5'
    },
    FINISHED: {
      color: '#008CD2',
      bgColor: '#E5F3FA'
    },
    Archieved: {
      color: '#6A737B',
      bgColor: '#F1F2F2'
    }
  };

  const newColorCodes = {
    'On Track': {
      color: '#56B146',
      bgColor: '#E6F4E4'
    },
    'At Risk': {
      color: '#FF8A00',
      bgColor: '#FFEED9'
    },
    'Not Started': {
      color: 'white',
      bgColor: '#515050'
    },
    'Steady State': {
      color: '#008CD2',
      bgColor: '#E5F3FA'
    },
    'On Hold': {
      color: '#6A737B',
      bgColor: '#F1F2F2'
    },
    'Requires Attention': {
      color: 'red',
      bgColor: '#ead1d5'
    }
  };

  const BorderLinearProgress = styled(LinearProgress)(
    ({ theme, colorCode }) => ({
      height: 6,
      borderRadius: 5,
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[200]
      },
      [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: `${colorCode}`
      }
    })
  );
  const getHexCode = newColorCodes[customer?.progressStatus];
  const [userLogo, setuserLogo] = useState();
  let id = customer?.customerId

  //add by tinaTao
  async function viewImage() {
    const res = await Auth.currentSession().then(
      (data) => data.idToken.jwtToken
    );
    setuserLogo('');
    axiosInstanceImage
      .request({
        method: 'GET',
        url: `/getLogoFileName/${id}`
      })
      .then((response) => {
        if (response?.data?.data) {
          setuserLogo(`${LOGO_PATH}/${response?.data?.data.logoFileName}`);
        }
      });
  }
  const fetchImage = useCallback(async () => {
    viewImage();
  }, [id]);

  useEffect(() => {
    fetchImage();
  }, [fetchImage]);
  return (
    <Card className="custom_detail cards">
      <CardContent>
        {customer?.progressStatus && (
          <div className="status">
            <div
              className="status-text"
              style={{
                backgroundColor: `${getHexCode?.bgColor}`,
                color: `${getHexCode?.color}`
              }}
            >
              {customer?.progressStatus}
            </div>
            <div className="status-icon">
              <MoreVertIcon />
            </div>
          </div>
        )}

        <Link
          // to={
          //   customer?.totalCompletedSection >= 12
          //     ? `/Dashboard/${customer?.customerId}`
          //     : `/new-onboarding/${customer?.customerId}`
          // }
          to={
            customer?.progressStatus === 'Steady State'
              ? `/Dashboard/${customer?.customerId}`
              : `/new-onboarding/${customer?.customerId}`
          }
        >
          <div className="customer-info">
          {userLogo ? (
            <div className="customer-logo">
              <img
                className="logo"
                src={userLogo}
                alt="Logo"
              ></img>
            </div>
          ) : (
            <div className="customer-logo">Logo</div>
          )}
            <div>
              <div className="customer-detail">{customer?.customerName}</div>
              {customer?.email && (
                <div className="customer-onboardDate">{customer?.email}</div>
              )}
              {customer?.joinedDate && (
                <div className="customer-onboardDate">
                  {moment(customer?.modifiedDate).format('MMM DD, YYYY')}
                </div>
              )}
            </div>
          </div>
          
          {customer?.totalCompletedSection > 0 && (
            <BorderLinearProgress
              variant="determinate"
              value={((customer?.totalCompletedSection / 13) * 100).toFixed(2)}
              colorCode={getHexCode?.color}
            />
          )}
        </Link>
      </CardContent>
    </Card>
  );
};
export default CustomerList;
