import React, { useEffect, useState } from 'react';
import TableCell from '@mui/material/TableCell';
import StatusBox from '../StatusBox';
import { axiosInstanceDashboard } from '../../../api/Axios';
import { useParams, useNavigate } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import { format } from '../../../utils/dateFormatter';

export default function ListOfLocations({ conceptId }) {
  const [responseData, setResponseData] = useState([]);
  const { parentCustID } = useParams();
  let navigate = useNavigate();
  const onClickhandler = (customerID) => {
    navigate(
      `/Dashboard/${parentCustID}/new-onboarding/${customerID}?name=customerportaluser`
    );
    window.location.reload();
  };

  const fetchlist = () => {
    if (!conceptId) {
      return
    }
    axiosInstanceDashboard

      .request({
        method: 'GET',
        url: `/getAllConceptOrLocationList?parentCustID=${parentCustID}&accountType=LOCATION&conceptId=${conceptId}`
      })

      .then((response) => {
        if (response) {
          if (response.data) {
            setResponseData(response.data.data.Customer);
          }
        }
      });
  };
  useEffect(() => {
    fetchlist();
  }, []);

  return (
    <>
      <TableRow>
        <TableCell padding="checkbox" style={{ borderBottom: 'none' }} />
        <TableCell
          style={{
            borderBottom: 'none',

            paddingLeft: '1px',

            fontWeight: '600',
            fontSize: '14px',
            fontFamily: 'MyriadPro'
          }}
        >
          {responseData.length > 0
            ? 'List of Locations'
            : 'No locations to view'}
        </TableCell>
        <TableCell style={{ borderBottom: 'none' }}></TableCell>
        <TableCell style={{ borderBottom: 'none' }}></TableCell>
        <TableCell style={{ borderBottom: 'none' }}></TableCell>
        <TableCell style={{ borderBottom: 'none' }}></TableCell>
        <TableCell style={{ borderBottom: 'none' }}></TableCell>
        <TableCell style={{ borderBottom: 'none' }}></TableCell>
        <TableCell style={{ borderBottom: 'none' }}></TableCell>
      </TableRow>
      {responseData.length > 0 &&
        responseData.map((row, index) => (
          <>
            <TableRow>
              <TableCell padding="checkbox" />
              <TableCell
                onClick={() => {
                  onClickhandler(row.customerId);
                }}
                padding="none"
                style={{
                  fontSize: '12px',

                  color: '#6A737B',

                  fontFamily: 'MyriadPro',
                  letterSpacing: '0.2px'
                }}
                component="th"
                scope="row"
              >
                {row.accountName}
              </TableCell>
              <TableCell
                padding="none"
                style={{
                  fontSize: '12px',

                  color: '#6A737B',
                  fontFamily: 'MyriadPro',
                  letterSpacing: '0.2px'
                }}
                align="left"
              >
                {row.conceptId}
              </TableCell>
              <TableCell
                padding="none"
                style={{
                  fontSize: '12px',

                  color: '#6A737B',
                  fontFamily: 'MyriadPro',
                  letterSpacing: '0.2px'
                }}
                align="left"
              >
                {row?.addressList?.[0]?.address}
              </TableCell>
              <TableCell
                padding="normal"
                style={{
                  fontSize: '12px',

                  color: '#2A2F33',
                  fontFamily: 'MyriadPro',
                  letterSpacing: '0.2px'
                }}
                align="left"
              >
                <StatusBox status={row?.progressStatus} />
              </TableCell>
              <TableCell
                padding="normal"
                style={{
                  fontSize: '12px',

                  color: '#6A737B',
                  fontFamily: 'MyriadPro',
                  letterSpacing: '0.2px'
                }}
                align="left"
              >
                {/* {Math.floor((row.totalCompletedSection / 13) * 100)} % */}
                {row.totalActionCompletion}
                {/* {row?.status === 'FINISHED'
                  ? Math.floor((row.totalCompletedSection / 13) * 100)
                  : Math.floor((row.totalCompletedSection / 14) * 100)} */}
                %
              </TableCell>
              <TableCell
                padding="none"
                style={{
                  fontSize: '12px',

                  color: '#6A737B',
                  fontFamily: 'MyriadPro',
                  letterSpacing: '0.2px'
                }}
                align="left"
              >
                {row.customerName}
              </TableCell>
              <TableCell
                padding="none"
                style={{
                  fontSize: '12px',

                  color: '#6A737B',
                  fontFamily: 'MyriadPro',
                  letterSpacing: '0.2px'
                }}
                align="left"
              >
                {row.goLiveDate ? format(row.goLiveDate) : ''}
              </TableCell>
              <TableCell
                padding="normal"
                style={{
                  fontSize: '12px',

                  color: '#6A737B',
                  fontFamily: 'MyriadPro',
                  letterSpacing: '0.2px'
                }}
                align="left"
              >
                {row.intakeFormDueDate ? format(row.intakeFormDueDate) : ''}
              </TableCell>
            </TableRow>
          </>
        ))}
    </>
  );
}
