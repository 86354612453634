import { call, put } from 'redux-saga/effects';
import { getEmailListSuccess, getEmailListError } from './slice';
import requestTemplate from './request';

export function* handleEmailList() {
  try {
    const list = yield call(requestTemplate);
    // console.log(list)
    yield put(getEmailListSuccess(list?.data));
  } catch (error) {
    // console.log(error)
    yield put(getEmailListError(error));
  }
}
