import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogActions } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import '../Modal/index.css';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import styles from './index.module.css'
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { withSnackbar } from '../SnackBar/SnackBar';
import _ from 'lodash'
import {
    axiosInstanceDashboard
  } from '../../../api/Axios';

function Note(props) {
  const { snackbarShowMessage } = props;
  const actionId = useSelector((state) => {
    return state?.storeActiveStep?.actionId;
  });
  const { customerID: customerId } = useParams();
  const [submitting, setSubmitting] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [note, setNote] = useState({
    noteId: 0,
    noteBody: ''
  });
  const fetchNote = useCallback((activeStep) => {
    if (!actionId || !open) return
    setNote([])
    axiosInstanceDashboard.get(`/getCustomerNotes/${customerId}/${actionId}`).then((res) => {
      if (_.get(res, 'data.data')) {
        setNote(_.last(_.get(res, 'data.data.Customer')))
      }
    })
  }, [actionId, open, customerId])
  useEffect(() => {
    fetchNote()
  }, [fetchNote]);
  const handleNoteChange =(event) => {
    setNote({ ...note, noteBody: event.target.value});
  }

  const handleNoteClose=()=> {
    setOpen(false);
    setNote('');
  }

  const handleSubmitNote = () => {
    setSubmitting(true);
    const values = { noteId: _.get(note, 'noteId', 0), customerId, actionId, noteBody: _.get(note, 'noteBody') };
    axiosInstanceDashboard.post('/createCustomerNotes', values).then((res) => {
        if (_.get(res, 'data.hasError')) {
            snackbarShowMessage({
              message: _.get(res, 'data.errorMessage'),
              type: 'error'
            });
          } else {
            snackbarShowMessage({ message: 'Created successfully' });
            setOpen(false);
            fetchNote()
          }
    }).finally(() => {
        setSubmitting(false);
    })
  }

  return (
    <>
      <Box className={styles.box} sx={{ m: 1, position: 'relative' }}>
        <Button
          sx={{
            '& .MuiButton-root': {
              fontSize: 14
            }
          }}
          startIcon={<MarkUnreadChatAltOutlinedIcon />}
          variant="text"
          className={styles.showButton}
          onClick={() => setOpen(!open)}
        >
          Notes
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleNoteClose}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>
          <b>Note</b>
        </DialogTitle>
        <DialogContent>
          <TextField
            inputProps={{ 'data-testid': 'textbox' }}
            onChange={handleNoteChange}
            id="outlined-multiline-static"
            value={note?.noteBody}
            margin="dense"
            fullWidth
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              sx={{ '& .MuiButton-root': { fontSize: 14 } }}
              id="secondary"
              className="buttons-1"
              onClick={handleNoteClose}
            >
              Cancel
            </Button>
          </Box>
          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              disabled={submitting}
              id="primary"
              className="buttons-2"
              onClick={handleSubmitNote}
            >
              Submit
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default withSnackbar(Note);
