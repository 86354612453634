import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { watcherSaga } from './rootSaga';
import rootReducer from "./rootReducer";
import { persistReducer, persistStore, } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_ENV_ID !== 'production',
  middleware: [sagaMiddleware]
});

sagaMiddleware.run(watcherSaga);

export const persistor = persistStore(store);
export default store;
