import React, { useState, useEffect } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Grid from '@mui/material/Grid';
import ContactsInformation from '../../components/ContactsInformation';
import { getCustomerDetails } from '../../../utils/getCustomerDetails';
import { useNavigate, useParams } from 'react-router-dom';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import DoneIcon from '@mui/icons-material/Done';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import './Contact_Support.css';
const ContactSupport = () => {
  const [customerdetails, setcustomerdetails] = useState('');
  const { parentCustID } = useParams('');
  const [expanded, setExpanded] = React.useState('panel_0');
  const navigate = useNavigate()

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const accordionDetails = [
    {
      title: 'Customer Success can help when you need to :',
      description:
        'Officially become a new Sysco customer and place your first order'
    },
    {
      title: 'What we do',
      description: [
        'Set up your account in Sysco systems',
        'Work with our OpSite and your previous distributer to ensure availability of your items',
        'Highlight Sysco products that meet your needs',
        'Create your customized order guide',
        'Ensure accurate pricing,agreement and rebates are implemented'
      ],

      itemIcon: (
        <FormatListNumberedRtlIcon
          sx={{ paddingLeft: '45px', fontSize: '28px' }}
        />
      )
    },
    {
      title: 'What we need',
      description: [
        'Signed Master Supplier Agreement or Letter of intent',
        'Customer HQ contact info.',
        'Location listing with contact info.',
        'Full item list including :'
      ],
      miniDiscription: [
        'Proprietary items identified incl. MPC',
        'Forecasted weekly demand',
        'Vendor contact details',
        'Spec sheets'
      ],
      itemIcon: <DoneIcon sx={{ paddingLeft: '10px', fontSize: '32px' }} />
    }
  ];

  useEffect(() => {
    if(!parentCustID || parentCustID == 'undefined'){
      navigate(`/PageNotFound`);
    }
    getCustomerDetails(parentCustID).then((data) => {
      setcustomerdetails(data);
    });
  }, [parentCustID]);

  return (
    <>
      <div>
        <Typography
          variant="h5"
          sx={{
            fontFamily: 'MyriadPro',
            fontWeight: 600,
            fontSize: '18px',
            paddingTop: '18px',
            paddingLeft: '10px'
          }}
        >
          Support
        </Typography>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8} md={8} lg={8}>
          <div
            style={{ paddingTop: '5px', paddingLeft: '10px' }}
            className="accordion-container"
          >
            {accordionDetails.map((data, index) => (
              <div key={data.title}>
                <Accordion
                  expanded={expanded === `panel_${index}`}
                  onChange={handleChange(`panel_${index}`)}
                  className={
                    expanded === `panel_${index}`
                      ? 'accordion-Expanded'
                      : 'accordion-collapsed'
                  }
                  defaultExpanded={index === 0 ? true : false}
                  sx={{ margin: '0 !important' }}
                >
                  <AccordionSummary
                    sx={{
                      backgroundColor:
                        data.title === 'What we need'
                          ? '#8cc751'
                          : data.title === 'What we do'
                          ? 'grey'
                          : '#055C9D'
                    }}
                    expandIcon={
                      expanded === `panel_${index}` ? (
                        <AddIcon sx={{ fill: 'white' }} />
                      ) : (
                        <RemoveIcon sx={{ fill: 'white' }} />
                      )
                    }
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%'
                      }}
                    >
                      <Typography
                        className={
                          expanded === `panel_${index}`
                            ? 'header-1'
                            : 'header-2'
                        }
                        sx={{
                          fontFamily: 'MyriadPro',
                          fontWeight: 600,
                          fontSize: '18px',
                          color: 'white',
                          letterSpacing:'0.6px'
                        }}
                      >
                        {data.title}
                        <span>{data.itemIcon}</span>
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingLeft: '2%' }}>
                    <Typography
                      sx={{ fontFamily: 'MyriadPro', fontSize: '20px' }}
                    >
                      {index === 0 ? data.description : ''}

                      <div>
                        {data.title ===
                        'Customer Success can help when you need to :' ? (
                          <>
                            <AddBusinessIcon
                              sx={{
                                fontSize: '150px',
                                fill: 'grey',
                                paddingLeft: '20%'
                              }}
                            />
                            <div style={{ color: '#008CD2' }}>
                              Lead time :90 days prior to requested available
                              date
                            </div>
                          </>
                        ) : data.title === 'What we do' ? (
                          data.description.map((descriptions, index) => (
                            <div key={descriptions}>
                              <ul>
                                <li
                                  style={{
                                    fontSize: '20px',
                                    fontFamily: 'MyriadPro'
                                  }}
                                >
                                  {descriptions}
                                </li>
                                {/* <li>
                                work with our OpSite and your previous
                                distributer to ensure availability of your items
                              </li> */}
                              </ul>
                            </div>
                          ))
                        ) : (
                          data.description.map((datas, index) => (
                            <div key={datas}>
                              <ul>
                                <li
                                  style={{
                                    fontSize: '20px',
                                    fontFamily: 'Myriad Rgular',
                                    fontWeight: 'bolder',
                                    lineHeight: 1
                                  }}
                                >
                                  {datas}
                                </li>
                                {datas === 'Full item list including :' &&
                                  data?.miniDiscription.map(
                                    (miniData, index) => (
                                      <ul key={miniData}>
                                        <li
                                          style={{
                                            fontSize: '20px',
                                            fontFamily: 'MyriadPro',
                                            lineHeight: 1.5
                                          }}
                                        >
                                          {miniData}
                                        </li>
                                      </ul>
                                    )
                                  )}
                              </ul>
                            </div>
                          ))
                        )}
                      </div>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <br />
              </div>
            ))}
          </div>
        </Grid>
        {customerdetails?.customerContactResponses?.length > 0 && (
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            sx={{ paddingTop: '45px !important', maxWidth: '32% !important' }}
          >
            <ContactsInformation customerdetails={customerdetails} />
          </Grid>
        )}
        <br />
        <strong
          style={{
            fontSize: '20px',
            fontFamily: 'MyriadPro',
            paddingLeft: '40px',
            paddingTop: '35px'
          }}
        >
          Customer approvals on all prerequisites must be obtained with proper
          lead time to ensure Successfull business event execution
        </strong>
      </Grid>
    </>
  );
};
export default ContactSupport;
