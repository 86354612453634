import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Add from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StatusBox from '../StatusBox';
import { axiosInstanceDashboard } from '../../../api/Axios';
import { useParams, useNavigate } from 'react-router-dom';
import ListOfLocation from '../ListOfLocation/index';
import { format } from '../../../utils/dateFormatter';
import _ from 'lodash'

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto'
  },
  table: {
    minWidth: 650
  },
  fontFamily: {
    fontFamily: 'MyriadPro',
    letterSpacing: '0.4px'
  }
});

const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow {...otherProps}>
        <TableCell padding="checkbox">
          {isExpanded ? (
            <RemoveIcon
              fontSize="small"
              onClick={() => setIsExpanded(!isExpanded)}
            ></RemoveIcon>
          ) : (
            <Add
              fontSize="small"
              onClick={() => setIsExpanded(!isExpanded)}
            ></Add>
          )}
        </TableCell>
        {children}
      </TableRow>

      {isExpanded && <>{expandComponent}</>}
    </>
  );
};

export default function ListOfConepts() {
  const [responseData, setResponseData] = useState([]);
  const { parentCustID } = useParams();

  const fetchlist = () => {
    axiosInstanceDashboard

      .request({
        method: 'GET',
        url: `/getAllConceptOrLocationList?parentCustID=${parentCustID}&accountType=CONCEPT&conceptId=`
      })

      .then((response) => {
        if (response) {
          if (response.data) {
            getAllTotalCompletion( _.get(response,'data.data.Customer'))
          }
        }
      });
  };
  function getAllTotalCompletion(customerArr){
    let totalComArr = []
    let promiseArr = customerArr.map(res=>{
      return axiosInstanceDashboard
        .request({
          method: 'GET',
          url: `/getCustomerTotalCompletion?customerId=${res.customerId}`
        })
    })
    Promise.all(promiseArr).then(res=>{
      res.map(resp=>{
        customerArr.filter(cf=>{
          return cf.customerId == _.get(resp, 'data.data.Total Completion.customerId')
        })[0]['totalActionCompletion'] = _.get(resp, 'data.data.Total Completion.totalCompletion')
      })
      setResponseData(customerArr);
    })
  }
  useEffect(() => {
    fetchlist();
  }, []);

  let navigate = useNavigate();
  const onClickhandler = (customerID) => {
    navigate(
      `/Dashboard/${parentCustID}/new-onboarding/${customerID}?name=customerportaluser`
    );
    window.location.reload();
  };

  const classes = useStyles();

  return (
    <>
      {responseData.length > 0 ? (
        <div
          style={{ borderTop: '1px solid #D3D3D3' }}
          className={classes.root}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow style={{ backgroundColor: '#FAFAFA' }} padding="none">
                <TableCell padding="checkbox" />

                <TableCell
                  padding="none"
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    color: '#6A737B',
                    fontFamily: 'MyriadPro',
                    letterSpacing: '0.2px'
                  }}
                  align="left"
                >
                  Concept Name
                  <ExpandMoreIcon fontSize="small" />
                </TableCell>

                <TableCell
                  padding="none"
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    color: '#6A737B',
                    fontFamily: 'MyriadPro',
                    letterSpacing: '0.2px'
                  }}
                  align="left"
                >
                  Concept ID
                  <ExpandMoreIcon fontSize="small" />
                </TableCell>
                <TableCell
                  padding="none"
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    color: '#6A737B',
                    fontFamily: 'MyriadPro',
                    letterSpacing: '0.2px'
                  }}
                  align="left"
                >
                  Address
                  <ExpandMoreIcon fontSize="small" />
                </TableCell>
                <TableCell
                  padding="normal"
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    color: '#6A737B',
                    fontFamily: 'MyriadPro',
                    letterSpacing: '0.2px'
                  }}
                  align="left"
                >
                  Status
                  <ExpandMoreIcon fontSize="small" />
                </TableCell>
                <TableCell
                  padding="none"
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    color: '#6A737B',
                    fontFamily: 'MyriadPro',
                    letterSpacing: '0.2px'
                  }}
                  align="left"
                  data-testid="row-concept-name"
                >
                  Completion %
                  <ExpandMoreIcon fontSize="small" />
                </TableCell>
                <TableCell
                  padding="none"
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    color: '#6A737B',
                    fontFamily: 'MyriadPro',
                    letterSpacing: '0.2px'
                  }}
                  align="left"
                >
                  Customer Name
                  <ExpandMoreIcon fontSize="small" />
                </TableCell>
                <TableCell
                  padding="none"
                  style={{
                    fontSize: '12px',
                    fontWeight: '600',
                    color: '#6A737B',
                    fontFamily: 'MyriadPro',
                    letterSpacing: '0.2px'
                  }}
                  align="left"
                >
                  Go-Live Date
                  <ExpandMoreIcon fontSize="small" />
                </TableCell>
                
              </TableRow>
              :
            </TableHead>
            <TableBody>
              {responseData.length > 0 &&
                responseData.map((row) => (
                  <>
                    <ExpandableTableRow
                      expandComponent={
                        <ListOfLocation conceptId={row?.conceptId} />
                      }
                    >
                      <TableCell
                        padding="none"
                        style={{
                          fontSize: '13px',
                          fontWeight: '600',
                          color: '#2A2F33',
                          fontFamily: 'MyriadPro',
                          letterSpacing: '0.4px'
                        }}
                        component="th"
                        scope="row"
                        align="left"
                        onClick={() => {
                          onClickhandler(row.customerId);
                        }}
                      >
                        {row.conceptName}
                      </TableCell>
                      <TableCell
                        padding="none"
                        style={{
                          fontSize: '13px',
                          fontWeight: '600',
                          color: '#2A2F33',
                          fontFamily: 'MyriadPro',
                          letterSpacing: '0.4px'
                        }}
                        align="left"
                      >
                        {row.conceptId}
                      </TableCell>
                      <TableCell
                        padding="none"
                        style={{
                          fontSize: '13px',
                          fontWeight: '600',
                          color: '#2A2F33',
                          fontFamily: 'MyriadPro',
                          letterSpacing: '0.4px'
                        }}
                        align="left"
                      >
                        {row?.addressList?.[0]?.address}
                      </TableCell>
                      <TableCell
                        padding="normal"
                        style={{
                          fontSize: '13px',
                          fontWeight: '600',
                          color: '#2A2F33',
                          fontFamily: 'MyriadPro',
                          letterSpacing: '0.4px'
                        }}
                        align="left"
                      >
                        <StatusBox status={row?.progressStatus} />
                      </TableCell>
                      <TableCell
                        padding="normal"
                        style={{
                          fontSize: '13px',
                          fontWeight: '600',
                          color: '#2A2F33',
                          fontFamily: 'MyriadPro',
                          letterSpacing: '0.4px'
                        }}
                        align="left"
                      >
                        {/* {row?.status === 'FINISHED'
                          ? Math.floor((row.totalCompletedSection / 12) * 100)
                          : Math.floor((row.totalCompletedSection / 13) * 100)} */}
                          {row.totalActionCompletion}
                        %
                        {/* {Math.floor((row.totalCompletedSection / 13) * 100)} % */}
                      </TableCell>
                      <TableCell
                        padding="none"
                        style={{
                          fontSize: '13px',
                          fontWeight: '600',
                          color: '#2A2F33',
                          fontFamily: 'MyriadPro',
                          letterSpacing: '0.4px'
                        }}
                        align="left"
                      >
                        {row.customerName}
                      </TableCell>
                      <TableCell
                        padding="none"
                        style={{
                          fontSize: '13px',
                          fontWeight: '600',
                          color: '#2A2F33',
                          fontFamily: 'MyriadPro',
                          letterSpacing: '0.4px'
                        }}
                      >
                        {row.goLiveDate ? format(row.goLiveDate) : ''}
                      </TableCell>
                    </ExpandableTableRow>
                  </>
                ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        'No concepts available to view'
      )}
    </>
  );
}
