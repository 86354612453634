import * as React from 'react';
import { useState, useEffect,useRef } from 'react';
import '../DateOrTime/index.css';
import { useSelector } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {TimePicker} from '@mui/x-date-pickers/TimePicker';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import _ from 'lodash';
import moment from 'moment';
import dayjs from 'dayjs';
import { TextField } from '@mui/material';
import { axiosInstanceDashboard } from '../../../api/Axios';

export default function DateOrTime(props) {
  const {
    customerId,actionId,sectionSubmit,showTimeOr,snackbarShowMessage,actionName
  } = props;
  const [date, setDate] = useState('')
  const [time,setTime] = useState('')
  const dateRef = useRef();
  const [omccDate,setOmccDate] = useState('')
  useEffect(() =>{
    dateRef.current = date
  },[date])
  useEffect(() => {
      axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getDateOmcc?customerId=${customerId}&actionId=${actionId}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setOmccDate(response?.data?.data?.customerDateTime)
          }
        }
      });
  });
  

  const submitDateAndTime = () =>{
    let dateAndtimeValue = {}
    if(date && time){
      if(!checkTime(dayjs(time).format('HH:mm'))){
        snackbarShowMessage({
          message: 'Please enter the correct time format',
          type: 'error'
        });
      }else{
        dateAndtimeValue = {
          "customerId": customerId,
          "actionId": actionId,
          "dateTime": `${dayjs(date).format('YYYY-MM-DD')} ${dayjs(time).format('HH:mm')}`,
        }
  
        axiosInstanceDashboard.request({
          method: 'POST',
          url: '/getCustomerOMCC',
          data: dateAndtimeValue
        }).then((response) => {
          if (response.status === 200) {
            sectionSubmit(false, false, false,false,true);
          }
        });
      }
    }else{
      snackbarShowMessage({
        message: 'Please select date and time',
        type: 'error'
      });
    }
    
  }
  const cancelDateAndTime = () =>{
    setDate('')
    setTime('')
  }
  
  const checkTime = (time) =>{
    const reg = /^(([0-2][0-3])|([0-1][0-9])):[0-5][0-9]$/;
      const result = reg.test(time)
      if (!result) {
        return false;
      }
      return true;
  }
  return(
    
    <div className="Date-Time-template">
      <span className="omcc-tit" style={{ display: !showTimeOr ? 'block' : 'none' }}>Capture the Date and Time</span>
      
      <div className="date-time-sec" style={{ display: !showTimeOr ? 'block' : 'none' }}>
          <DatePicker
            views={['year', 'month', 'day']}
            label="Date"
            value={date}
            dateFormat="MM/dd/yyyy"
            minDate={dayjs().toDate()}
            maxDate={new Date('12/31/9999')}
            placeholderText={'MM/dd/yyyy'}
            onChange={(newValue) => {
                setDate(newValue);
            }}
            renderInput={(props) => {
              const inputProps = { ...props.inputProps, value: date ? dayjs(date).format('MM/DD/YYYY') : '' }
               return <TextField  {...props} onFocus={(el)=>{el.target.setAttribute('readonly','readonly')}} inputProps={inputProps} size='small' helperText={null} />
              }}
          />
          <TimePicker 
             label="Time" 
             views={['hours', 'minutes']}
             format="hh:mm"
             value={time}
             className="time-picker-sec"
              onChange={(newValue) => {
                  setTime(newValue);
              }}
            renderInput={(props) => {
              const inputProps = { ...props.inputProps, value: time ? dayjs(time).format('hh:mm') : '' }

            return<TextField  {...props} onFocus={(el)=>{el.target.setAttribute('readonly','readonly')}} size='small' inputProps={inputProps} helperText={null} />}}
          />
        </div>
      <div className="show-time" style={{ display: showTimeOr ? 'block' : 'none' }}>
          {`${actionName} was hosted on ${omccDate}`}
      </div>
      <div className="date-time-footer" style={{ display: !showTimeOr ? 'flex' : 'none' }}>
        <Stack spacing={2} direction="row">
          <Button variant="contained" onClick={() =>{cancelDateAndTime()}}>Cancel</Button>
          <Button variant="contained" onClick={submitDateAndTime}>Submit</Button>
        </Stack>
      </div>
    </div>
  );
}
