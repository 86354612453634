export const API_REQUEST_DASHBOARD =  process.env.REACT_APP_DASHBOARD;
export const API_REQUEST_DASHBOARD_NON_BFF =  process.env.REACT_APP_DASHBOARD_NON_BFF;
export const API_REQUEST_DOCUMENT =  process.env.REACT_APP_DOCUMENT;
export const API_REQUEST_EMAIL =  process.env.REACT_APP_EMAIL;
export const API_REQUEST_IMAGE =  process.env.REACT_APP_IMAGE
export const API_REQUEST_EMAIL_NON_BFF =  process.env.REACT_APP_EMAIL_NON_BFF;
export const API_REQUEST_EMAIL_NON_BFF_CHAT =  process.env.REACT_APP_EMAIL_NON_BFF_CHAT
export const API_REQUEST_EMAIL_ADMIN =  process.env.REACT_APP_EMAIL_ADMIN;
export const API_STEP_APP = process.env.REACT_APP_STEP;
export const API_PRICING_APP = process.env.REACT_APP_PRICING;
export const API_OGM_APP = process.env.REACT_APP_OGM;
export const API_ICT = process.env.REACT_APP_ICT;
export const LINK_SALESFORCE = process.env.REACT_APP_LINK_SALESFORCE
export const LINK_SUS = process.env.REACT_APP_LINK_SUS
export const API_LINK_PCI = process.env.REACT_APP_LINK_PCI; 
export const API_TOKEN = '';
export const LOGO_PATH = process.env.REACT_APP_LOGO_PATH;

