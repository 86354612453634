const CUSTOMER_DETAILS = 'Customer Details';
const PRE_REQ_DOCS = 'Pre-Req Docs';
const OGM_SETUP = 'OGM setup';

export const mapping_cust_pers = {
  CMU: [
    {
      actionId: 67,
      actionName: 'Obtain Customer Approval for Transfer List',
      template: 'Mail',
      mailTemplateID: 69,
      fallbackScreen: true,
      fallbackTemplate: 'Text',
      cardTitle: 'Approval for Inventory Transfer',
      cardSubtitle: 'Please select below for approval',
      label: 'Waiting for customer approval',
      justText: true,
      needConfirm: true,
      maildocmandatory: 'Yes',
      showUploadList: true,
      confirmConfig: {
        actionId: 67,
        actionName: 'Obtain Customer Approval For Transfer List',
        showReviewTemplate: [166],
        template: 'Review'
      }
    },
    {
      actionId: 64,
      actionName: 'Inventory Snapshot',
      cardTitle: 'Inventory Snapshot',
      cardSubtitle: 'upload all the documents for Inventory Snapshot',
      templateId: [135],
      template: 'File',
      fallbackScreen: false,
      mailattachflag: true,
      showUploadList: true,
      operations: ['download', 'delete'],
      maildocmandatory: 'Yes',
      confirmConfig: {
        template: 'File'
      }
    },
    {
      actionId: 57,
      actionName: 'Sign Off on Simulated Pricing',
      template: 'ack',
      labels: ['Signed Off on Simulated Pricing'],
      bundledWith: ['Simulated Pricing to VPNA'],
      display: 6,
      cardSubtitle: 'Sign Off on Simulating Pricing',
      cardTitle: 'Sign Off',
      confirmConfig: {
        template: 'A',
        labels: ['Signed Off on Simulated Pricing']
      }
    },
    {
      actionId: 39,
      actionName: 'Customer Approval',
      template: 'Mail',
      mailTemplateID: 19,
      bundledWith: ['OGM Setup', 'Item Coding', 'New Items'],
      fallbackScreen: true,
      fallbackTemplate: 'Text',
      label: 'Has the Customer approved all the Items in the Order Guide?',
      statuslinkage: true,
      linkeditems: [37, 38, 39],
      maildocmandatory: 'Yes',
      cardTitle: ' Approval for Order Guide',
      cardSubtitle: 'Please select below for approval',
      needConfirm: true,
      display: 4,
      confirmConfig: {
        actionId: 39,
        actionName: 'Customer Approval',
        showReviewTemplate: [13],
        template: 'Review'
      }
    },
    {
      actionId: 32,
      actionName: 'EDI Questionnaire',
      template: 'Mail',
      mailTemplateID: 29,
      enableSkip: true,
      cardTitle: 'Fill out the EDI Questionnaire',
      cardSubtitle: 'Start here to upload EDI Questionnaire',
      fallbackScreen: true,
      fallbackTemplate: 'File',
      templateId: [34],
      bundledWithDoc: 'Customer Accounts',
      needConfirm: true,
      display: 7,
      confirmConfig: {
        action: 'F',
        actionId: 32,
        sectionId: 3,
        actionName: 'EDI Questionnaire',
        template: 'File',
        templateId: [34]
      }
    },
    {
      actionId: 15,
      actionName: PRE_REQ_DOCS,
      templateId: [1, 2, 3, 26, 4, 5, 6, 7, 10, 13, 15, 29, 30, 25],
      template: 'File',
      bundledWith: [CUSTOMER_DETAILS],
      display: 1,
      cardTitle: 'Upload here Pre-Requisites documents',
      cardSubtitle: 'Start here to upload all the documents for Pre-Requisites',
      confirmConfig: {
        action: 'F',
        actionId: 15,
        sectionId: 1,
        actionName: 'Pre-Requisite',
        template: 'File',
        templateId: [1, 2, 3, 26, 4, 5, 6, 7, 10, 13, 15, 29, 30, 25]
      }
    }
  ],

  LOCATION: [
    {
      actionId: 67,
      actionName: 'Obtain Customer Approval for Transfer List',
      template: 'Mail',
      mailTemplateID: 69,
      fallbackScreen: true,
      fallbackTemplate: 'Text',
      cardTitle: 'Approval for Inventory Transfer',
      cardSubtitle: 'Please select below for approval',
      label: 'Waiting for customer approval',
      justText: true,
      needConfirm: true,
      maildocmandatory: 'Yes',
      showUploadList: true,
      confirmConfig: {
        actionId: 67,
        actionName: 'Obtain Customer Approval For Transfer List',
        showReviewTemplate: [166],
        template: 'Review'
      }
    },
    {
      actionId: 57,
      actionName: 'Sign Off on Simulated Pricing',
      template: 'ack',
      labels: ['Signed Off on Simulated Pricing'],
      bundledWith: ['Simulated Pricing to VPNA'],
      display: 6
    },
    {
      actionId: 64,
      actionName: 'Inventory Snapshot',
      templateId: [135],
      template: 'File',
      fallbackScreen: false,
      mailattachflag: true,
      showUploadList: true, cardTitle: 'Inventory Snapshot',
      cardSubtitle: 'upload all the documents for Inventory Snapshot',
      confirmConfig: {
        template: 'File'
      },
      operations: ['download', 'delete'],
      maildocmandatory: 'Yes'
    },
    {
      actionId: 39,
      actionName: 'Customer Approval',
      template: 'Mail',
      mailTemplateID: 19,
      bundledWith: ['OGM Setup', 'Item Coding', 'New Items'],
      fallbackScreen: true,
      fallbackTemplate: 'Text',
      label: 'Has the Customer approved all the Items in the Order Guide?',
      statuslinkage: true,
      linkeditems: [37, 38, 39],
      maildocmandatory: 'Yes',
      needConfirm: true,
      cardTitle: ' Approval for Order Guide',
      cardSubtitle: 'Please select below for approval',
      display: 4,
      confirmConfig: {
        actionId: 39,
        actionName: 'Customer Approval',
        showReviewTemplate: [13],
        template: 'Review'
      }
    },
    {
      actionId: 32,
      actionName: 'EDI Questionnaire',
      template: 'Mail',
      mailTemplateID: 29,
      enableSkip: true,
      fallbackScreen: true,
      fallbackTemplate: 'File',
      templateId: [34],
      bundledWithDoc: 'Customer Accounts',
      needConfirm: true,
      cardTitle: 'Fill out the EDI Questionnaire',
      cardSubtitle: 'Start here to upload EDI Questionnaire',
      display: 7,
      confirmConfig: {
        action: 'F',
        actionId: 32,
        sectionId: 3,
        actionName: 'EDI Questionnaire',
        template: 'File',
        templateId: [34]
      }
    },
    {
      actionId: 15,
      actionName: PRE_REQ_DOCS,
      templateId: [1, 2, 3, 26, 4, 5, 6, 7, 10, 13, 15, 29, 30, 25],
      template: 'File',
      bundledWith: [CUSTOMER_DETAILS],
      display: 1,
      cardTitle: 'Upload here Pre-Requisites documents',
      cardSubtitle: 'Start here to upload all the documents for Pre-Requisites',
      confirmConfig: {
        action: 'F',
        actionId: 15,
        sectionId: 1,
        actionName: 'Pre-Requisite',
        template: 'File',
        templateId: [1, 2, 3, 26, 4, 5, 6, 7, 10, 13, 15, 29, 30, 25]
      }
    }
  ],

  CONCEPT: [
    {
      actionId: 67,
      actionName: 'Obtain Customer Approval for Transfer List',
      template: 'Mail',
      mailTemplateID: 69,
      fallbackScreen: true,
      fallbackTemplate: 'Text',
      cardTitle: 'Approval for Inventory Transfer',
      cardSubtitle: 'Please select below for approval',
      label: 'Waiting for customer approval',
      justText: true,
      needConfirm: true,
      maildocmandatory: 'Yes',
      showUploadList: true,
      confirmConfig: {
        actionId: 67,
        actionName: 'Obtain Customer Approval For Transfer List',
        showReviewTemplate: [166],
        template: 'Review'
      }
    },
    {
      actionId: 57,
      actionName: 'Sign Off on Simulated Pricing',
      template: 'ack',
      labels: ['Signed Off on Simulated Pricing'],
      bundledWith: ['Simulated Pricing to VPNA'],
      display: 6
    },
    {
      actionId: 64,
      actionName: 'Inventory Snapshot',
      templateId: [135], cardTitle: 'Inventory Snapshot',
      cardSubtitle: 'upload all the documents for Inventory Snapshot',
      confirmConfig: {
        template: 'File'
      },
      template: 'File',
      fallbackScreen: false,
      mailattachflag: true,
      showUploadList: true,
      operations: ['download', 'delete'],
      maildocmandatory: 'Yes'
    },
    {
      actionId: 39,
      actionName: 'Customer Approval',
      template: 'Mail',
      mailTemplateID: 19,
      bundledWith: ['OGM Setup', 'Item Coding', 'New Items'],
      fallbackScreen: true,
      fallbackTemplate: 'Text',
      label: 'Has the Customer approved all the Items in the Order Guide?',
      statuslinkage: true,
      linkeditems: [37, 38, 39],
      maildocmandatory: 'Yes',
      needConfirm: true,
      cardTitle: ' Approval for Order Guide',
      cardSubtitle: 'Please select below for approval',
      display: 4,
      confirmConfig: {
        actionId: 39,
        actionName: 'Customer Approval',
        showReviewTemplate: [13],
        template: 'Review'
      }
    },
    {
      actionId: 32,
      actionName: 'EDI Questionnaire',
      template: 'Mail',
      mailTemplateID: 29,
      enableSkip: true,
      fallbackScreen: true,
      fallbackTemplate: 'File',
      templateId: [34],
      bundledWithDoc: 'Customer Accounts',
      needConfirm: true,
      cardTitle: 'Fill out the EDI Questionnaire',
      cardSubtitle: 'Start here to upload EDI Questionnaire',
      display: 7,
      confirmConfig: {
        action: 'F',
        actionId: 32,
        sectionId: 3,
        actionName: 'EDI Questionnaire',
        template: 'File',
        templateId: [34]
      }
    },

    {
      actionId: 15,
      actionName: PRE_REQ_DOCS,
      templateId: [1, 2, 3, 26, 4, 5, 6, 7, 10, 13, 15, 29, 30, 25],
      template: 'File',
      bundledWith: [CUSTOMER_DETAILS],
      display: 1,
      cardTitle: 'Upload here Pre-Requisites documents',
      cardSubtitle: 'Start here to upload all the documents for Pre-Requisites',
      confirmConfig: {
        action: 'F',
        actionId: 15,
        sectionId: 1,
        actionName: 'Pre-Requisite',
        template: 'File',
        templateId: [1, 2, 3, 26, 4, 5, 6, 7, 10, 13, 15, 29, 30, 25]
      }
    }
  ]
};
