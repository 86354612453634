import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import ContactsInformation from '../ContactsInformation';
import { axiosInstanceDashboard } from '../../../api/Axios';
import HelpDesks from '../../../../src/assets/HelpImg.PNG';
import completedview from '../../../../src/assets/completedCustomer.PNG';
import preReq from '../../../../src/assets/preReq.PNG';
import NoPending from '../../../../src/assets/NoPending.PNG';
import Card from '@mui/material/Card';
import { useParams } from 'react-router-dom';
import '../HelpDesk/index.css';
import _ from 'lodash'

const HelpDesk = () => {
  const [customerdetails, setcustomerdetails] = useState('');
  const { parentCustID } = useParams();

  const fetchlist = () => {
    axiosInstanceDashboard
      .request({
        method: 'GET',

        url: `/getCustomerDetails?id=${parentCustID}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setcustomerdetails(_.get(response, 'data.data.Customer', {}));
          }
        }
      });
  };
  useEffect(() => {
    fetchlist();
  });
  return (
    <div className='help-training'>
      <h3>Help & Training</h3>
      <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item xs={10}>
          {/* <div style={{ marginBottom: '10px', marginLeft: '10px' }}> */}
          <Card>
            <div style={{ margin: '40px' }}>
              <img src={HelpDesks} alt="help desk" />
            </div>
          </Card>
        </Grid>
        {customerdetails?.customerContactResponses?.length > 0 && (
          <Grid item xs={2}>
            <ContactsInformation customerdetails={customerdetails} />
          </Grid>
        )}
      </Grid>
      <Grid item xs={8.8} style={{ marginTop: '10px' }}>
        {/* <div style={{ marginBottom: '10px', marginLeft: '10px' }}> */}
        <Card>
          <div style={{ marginLeft: '110px', marginTop: '20px' }}>
            <img src={completedview} alt="help desk" />
          </div>
        </Card>
      </Grid>
      <Grid item xs={8.8} style={{ marginTop: '10px' }}>
        {/* <div style={{ marginBottom: '10px', marginLeft: '10px' }}> */}
        <Card>
          <div style={{ marginLeft: '110px', marginTop: '20px' }}>
            <img src={preReq} alt="help desk" />
          </div>
        </Card>
      </Grid>
      <Grid item xs={8.8} style={{ marginTop: '10px' }}>
        {/* <div style={{ marginBottom: '10px', marginLeft: '10px' }}> */}
        <Card>
          <div style={{ marginLeft: '110px', marginTop: '20px' }}>
            <img src={NoPending} alt="help desk" />
          </div>
        </Card>
      </Grid>
    </div>
  );
};

export default HelpDesk;
