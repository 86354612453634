import React, { useState, useEffect } from 'react';
import { axiosInstanceDashboard } from '../../../api/Axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  DialogActions,
  Button,
  TextField,
  InputAdornment
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import LocationProgressTable from '../Popup/LocationProgressTable';
import { useParams } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import '../Popup/index.css';

const LocationProgressPopup = (props) => {
  const { popupopen, setpopupopen, value,conceptId ,isCompleted} = props;
  const [list, setlist] = useState();
  const [searchList, setSearchList] = useState();
  const handleError = useErrorHandler();
  const { parentCustID } = useParams();
  const fetchlocation = () => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getAllConceptOrLocationList?parentCustID=${parentCustID}&accountType=${value}&conceptId=`
      })
      .then(
        (response) => {
          if (response) {
            if (response.data) {
              let res;
              if(isCompleted){
                res = response.data.data.Customer?.filter(
                  (e) => e.progressStatus === 'Steady State' 
                );
              }else{
                res = response.data.data.Customer?.filter(
                  (e) => e.progressStatus !== 'Steady State' 
                );
              }
              setlist(res);
              //log
              setSearchList(res);
            }
          }
        },
        (error) => handleError(error)
      );
  };

  useEffect(() => {
    fetchlocation();
  }, []);

  function closehandler() {
    setpopupopen(false);
  }

  return (
    <div>
      <Dialog
        sx={{
          '& .MuiTypography-root': { fontFamily: 'inherit', fontWeight: 700 }
        }}
        open={popupopen}
        fullWidth={true}
        maxWidth={`${isCompleted?'lg':'md'}`}
        onClose={closehandler}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {value === 'LOCATION'
            ? 'Locations In Progress'
            : 'Concepts In Progress'}
          <IconButton
            aria-label="close"
            onClick={closehandler}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <TextField
            placeholder={'Search...'}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '30px',
                width: '400px'
              },
              '& .MuiOutlinedInput-input': { padding: '10px 5px' }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            onChange={(e) => {
              const searchedVal = list?.filter((ele) =>
                value === 'LOCATION'
                  ? ele.accountName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
                  : ele.conceptName
                      .toLowerCase()
                      .includes(e.target.value.toLowerCase())
              );
              if (searchedVal) {
                setSearchList(searchedVal);
              } else {
                setSearchList(list);
              }
            }}
          />
          <br />
          <br />
          <LocationProgressTable
            value={value}
            searchList={searchList}
            parentCustID={parentCustID}
            isCompleted={isCompleted}
          />
        </DialogContent>

        <DialogActions sx={{ boxShadow: '0 0 10' }}>
          <Button id="primary" onClick={closehandler}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LocationProgressPopup;
