import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, DialogContent, DialogTitle } from '@mui/material';
import './EmailModal.css';
import VPNAEmail from '../Mail/VPNAEmail';
import { axiosInstanceEmail } from '../../../api/Axios';
import MailSend from '../MailSend';
import _ from 'lodash';
const EmailModal = (props) => {
  const { modalopen, setmodalopen, customerdetails,
    snackbarShowMessage, downloadeddocstatus, } = props;
  const actionId = 0;
  const customerId = customerdetails?.customerId;
  const [emailListData, setemailListData] = useState();
  const [openSendEmail, setopenSendEmail] = useState(false);
  const [openEmailDetails, setopenEmailDetails] = useState(false);
  function closehandler() {
    setopenSendEmail(false);
    setopenEmailDetails(false);
    setmodalopen(false);
  }
  useEffect(() => {
    if (customerId) {
      axiosInstanceEmail
        .request({
          method: 'GET',
          url: `/getEmailDetails?customerId=${customerId}&actionId=${actionId}`
        })
        .then((response) => {
          if (response) {
            if (response?.data?.errorCode || response?.data?.errorMessage) {
              // handleError(response?.data?.errorMessage);
              setemailListData([])
            } else {
              setemailListData(response?.data?.data?.EmailInfo);
            }
          }
        });
    }
  }, [modalopen,openSendEmail]);
  const toggleEmailModal = ()=>{
    setmodalopen(prevState => {
      return !prevState;
    });
  }
  const openSendView = ()=>{
    setopenSendEmail(prevState => {
      return !prevState;
    });
  }
  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          maxWidth: {sm:900,md:1000},
          height: { sm: 550, md: 550 },
        }
      }}
      open={modalopen}
      onClose={closehandler}
      aria-labelledby="responsive-dialog-title"
      fullWidth={true}
      // maxWidth={'xl'}
      height={400}
    >
      <DialogTitle
        className="email-modal-title"
      >
          <b>Send Alerts</b>
       
        <div data-testid="closeicon" className="closeDialog" onClick={closehandler}>
          {' '}
          <CloseIcon />{' '}
        </div>

      </DialogTitle>
      <DialogContent>
        <div className='vpna-email-list' style={{display: openSendEmail?'none':'block'}}>
          {emailListData && emailListData?.length !== 0 &&
        
            <MailSend isVPNA={true} vpnaEmailListData={emailListData} customerId={customerId} 
              snackbarShowMessage={snackbarShowMessage} setmodalopen={setmodalopen} 
              customerdetails={customerdetails}
              setopenEmailDetails={setopenEmailDetails}
              />
          }
          {(!emailListData ||(emailListData && emailListData?.length === 0)) &&  
          <div style={{textAlign:'center'}}>No Alerts</div>
          }
        </div>
        <div className="vpna-send-email" style={{display: !openEmailDetails && openSendEmail?'block':'none'}}>
          <VPNAEmail
              snackbarShowMessage={snackbarShowMessage}
              customerdetails={customerdetails}
              downloadeddocstatus={downloadeddocstatus}
              setmodalopen={()=>{toggleEmailModal();openSendView()}}
              goToInitView={()=>openSendView()}
            />
        </div>
        <Box sx={{ position: 'absolute', bottom: 0, right: 0, padding: 2, display: openEmailDetails || openSendEmail ? 'none' : 'block' }}>
          <Button
            id="primary"
            className="buttons-2"
            onClick={() => openSendView()}
            data-testid="upload-btn"
          >Send a new alert</Button>
          </Box>
       </DialogContent>
    </Dialog>
  );
};

export default EmailModal;
