import React from 'react';
import Grid from '@mui/material/Grid';
import { Field, ErrorMessage } from 'formik';

const ShippingForm = ({formEditClass, values}) => {
  const FORMCONTROL = 'form-control';
  const FORMTITLE = 'form-title';
  return (
    <div className="customer-form form-container">
        <div className='popup-title form2'>{values.AccountRecordType === 'CMU' ? 'Corporate Office' : 'Shipping Address' }</div>
    <div className="customer-form form-container">
    <Grid container rowSpacing={2} columnSpacing={3}>
         {/* <Grid item xs={6} sm={6}>
                   <div className="form-header">
                     <div className={FORMTITLE}>First Name</div>
                     <div className="form-subTitle fullWidth">
                       <Field
                         name="shippingFirstName"
                         type="text"
                         placeholder={!formEditClass ? 'Input text*' : ''}
                         className={
                          FORMCONTROL
                         }
                       />
                     </div>
                   </div>
                   </Grid>
                   <Grid item xs={6} sm={6}>
                   <div className="form-header">
                     <div className={FORMTITLE}>Last Name</div>
                     <div className="form-subTitle">
                       <Field
                         name="shippingLastName"
                         type="text"
                         placeholder={!formEditClass ? 'Input text*' : ''}
                         className={
                          FORMCONTROL
                         }
                       />

                     </div>
                   </div>
                   </Grid> */}
                   <Grid item xs={12} sm={12}>
                   <div className="form-header">
                     <div className={FORMTITLE}>Address</div>
                     <div className="form-subTitle">
                     <Field
                         name="shippingAddress"
                         type="text"
                         placeholder={!formEditClass ? 'Input text*' : ''}
                         className={
                           'form-control address'
                         }
                       />
                    </div>
                    </div>
                   </Grid>
                   <Grid item xs={6} sm={6}>
                   <div className="form-header">
                     <div className={FORMTITLE}>State</div>
                     <div className="form-subTitle">
                       <Field
                         name="shippingState"
                         type="text"
                         placeholder={!formEditClass ? 'Input text*' : ''}
                         className={
                          FORMCONTROL
                         }
                       />
    
                     </div>
                   </div>
                   </Grid>
                   <Grid item xs={6} sm={6}>
                   <div className="form-header">
                     <div className={FORMTITLE}>Zip Code</div>
                     <div className="form-subTitle">
                       <Field
                         name="shippingZipcode"
                         type="text"
                         placeholder={!formEditClass ? 'Input text*' : ''}
                         className={
                          FORMCONTROL
                         }
                       />

                     </div>
                     <ErrorMessage
                      name="shippingZipcode"
                      component="div"
                      className="invalid-feedback"
                    />
                   </div>
                   </Grid>
    
               </Grid>
 </div>
 </div>
  );
};

export default ShippingForm;
