const awsAuth = {
  domain: process.env.REACT_AUTH_DOMAIN,
  scope: [
    'phone',
    'email',
    'profile',
    'openid',
    'aws.cognito.signin.user.admin'
  ],
  redirectSignIn: process.env.REACT_APP_REDIRECT,
  redirectSignOut: process.env.REACT_APP_REDIRECT,
  responseType: 'token',
  options: {
    // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
    AdvancedSecurityDataCollectionFlag: true,
  },

  urlOpener: null,
};

export default awsAuth;
