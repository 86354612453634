import React from 'react';
import './index.css';

const StatusBox = ({ status }) => {
  let fontColor = 'black'
  
  switch (status)
  {
    case 'On Track':   
      fontColor = 'onTrackStatus'
      break;
    case 'At Risk':   
      fontColor = 'atRiskStatus' 
      break;
    case 'Not Started':   
      fontColor = 'notStartedStatus' 
      break;
    case 'Steady State':   
      fontColor = 'steadyStateStatus' 
      break;
    case 'On Hold':   
      fontColor = 'onHoldStatus' 
      break;
    case 'Requires Attention':   
      fontColor = 'requireAttentionsStatus' 
      break;
  }
 
  return (
    <div>
      <div className="statusbox">
        <span
          className={
            fontColor
          }
         
        >
          {status}
        </span>
      </div>
    </div>
  );
};

export default StatusBox;
