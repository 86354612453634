import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router
} from 'react-router-dom';
import RoutesUrl from './router/Routes';
import Grid from '@mui/material/Grid';
import HeaderComp from './modules/components/Header';
import Sidebar from './modules/components/Sidebar/Sidebar';
import { redirectUrl } from './aws-config';
import { Auth } from 'aws-amplify';
import { getCustomerID } from './utils/getCustomerDetails';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { axiosInstanceDashboard } from '../src/api/Axios';
import { getUserRole } from './modules/components/Sidebar/redux/slice';
import { useDispatch } from 'react-redux';
function App() {
  const [userInfo, setUserInfo] = useState('');
  const [networkId, setNetworkId] = useState('');
  const dispatch = useDispatch();
  async function getUserInfo() {
    try {
      return await Auth.currentSession().then((data) => data.idToken.payload);
    } catch (e) {
      localStorage.clear();
      window.location.assign(redirectUrl);
      return false;
    }
  }
  useEffect(() => {
    getUserInfo().then((session) => {
      const identities = session?.identities?.map(
        ({ userId, providerType, providerName }) => ({
          userId,
          providerType,
          providerName
        })
      );
      let userID;
      if (identities && identities[0] && identities[0]?.providerType === 'SAML'){
        if(session?.name){
          userID = identities[0]?.providerName == "AzureAD"? session?.name?.split(',')[1]+' '+session?.name?.split(',')[0] : session?.name
          // setNetworkId(identities[0]?.userId.split('@')[0]);
          dispatch(getUserRole(identities[0]?.userId.split('@')[0]));
        }else{
          userID = identities[0]?.userId?.split('@')[0];
        }
      }else{
        userID = session['cognito:username'];
      }
      setUserInfo(userID);
      if (
        identities &&
        identities[0] &&
        identities[0]?.providerName === 'Okta'
      ) {
        getCustomerID(identities[0]?.userId).then((data) => {
          localStorage.setItem('customerPortalUser', JSON.stringify(data));
          window.dispatchEvent(new Event('customerPortalUser'));
        });
      } else {
        localStorage.setItem('userInfo', JSON.stringify(userID));
        const isAdmin = session['cognito:groups']?.filter(
          (user) => user === 'admin'
        );
        const groups = isAdmin?.length > 0 ? true : false;
        localStorage.setItem('isAdmin', JSON.stringify(groups));
        window.dispatchEvent(new Event('storage'));
      }
    });
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
      <HeaderComp username={userInfo} />
      <Grid container className="top-container">
        <Grid item xs={0.5} sx={{ boxShadow: 'none' }}>
          <Sidebar />
        </Grid>
        <Grid item xs={11.5} sx={{ boxShadow: 'none' }}>
          <RoutesUrl />
        </Grid>
      </Grid>
    </Router>
    </LocalizationProvider>
  );
}

export default App;
