import { axiosInstanceDocument } from '../api/Axios'

export async function downloadAllDocuments(cusid) {
   
    try
    {
    const response =  await axiosInstanceDocument
    .request({
      method: 'GET',
      url: `/downloadDocuments/${cusid}`
    })
    const responseData = response?.data?.data?.['Document Downloaded']
    
  return responseData
  
     }
     catch (error) {
        console.error(error);
      }

  }
  