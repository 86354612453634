import { axiosInstanceICT } from '../api/Axios';

export const  downloadICTfile = (fileName) => {

  const response = axiosInstanceICT
    .request({
      method: 'POST',
      url: `/downloadBulkResult?fileName=${fileName}`,
      responseType: "blob"
    }).then((response) => {
      const filename = response?.headers['content-disposition']
      const contentType = response?.headers['content-type']
      const url = window.URL.createObjectURL(new Blob([response?.data],{type: contentType}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename.replace('attachment; filename=', ''));
      link.click();
    });
    // console.log(response)
};
