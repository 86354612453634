import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { withSnackbar } from '../SnackBar/SnackBar';
import style from './index.module.css';
import _ from 'lodash';

const Confirm = (props) => {
  const { snackbarShowMessage, sectionSubmit } = props;
  const { actionId, confirmText } = useSelector((state) => {
    return state?.storeActiveStep || {};
  });
  // const { customerID: customerId } = useParams();
  // const [submitting, setSubmitting] = useState(false);
  const [confirm, setConfirm] = React.useState(true);

  useEffect(() => {
    setConfirm(true);
  }, [actionId])

  return (
    <>
      <Box padding={'0 10px'} sx={{ m: 1, position: 'relative' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={confirm}
              onChange={(e) => {
                if (e.target) {
                  setConfirm(e.target.checked);
                }
              }}
            />
          }
          label={<span style={{ fontWeight: 'bold' }}>{confirmText}</span>}
        />
        <Box className={style.buttonGroup}>
          <Button onClick={() => {
            setConfirm(false);
          }} size="small" variant="outlined">
            Cancel
          </Button>
          <Button disabled={!confirm} onClick={() => {
            if (_.isFunction(sectionSubmit)) {
              sectionSubmit();
              snackbarShowMessage({
                message: 'Confirmed',
                type: 'success'
              });
            }
          }} size="small" variant="outlined">
            Update
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default withSnackbar(Confirm);
