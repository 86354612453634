import React from 'react';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Form from '../CustomerForm/';
import '../Modal/index.css';

const CustomModal = (props) => {
  const { modalopen, setmodalopen, customerList,acctype,customerdetails, snackbarShowMessage,manualNationalId } = props;
  
  
  function closehandler () {
    setmodalopen(false);
  }

  return (
    <div>
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            width: { sm: 467, md: 567 },
            height: { sm: 759, md: 759 }
          },
          '& .MuiDialogContent-root .content': {
            width: { sm: 467, md: 567 },
            height: { sm: 759, md: 759 }
          }
        }}
        open={modalopen}
        onClose={closehandler}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent
          className="create_customer"
          sx={{ p: 0, overflow: 'scroll', mb: '5%' }}
        >
          <div className="closeDialog" onClick={closehandler}>
            {' '}
            <CloseIcon />{' '}
          </div>
          <Form
            modalOpen = {modalopen}
            closehandler={closehandler}
            customerList={customerList}
            acctype={acctype}
            customerdetails={customerdetails}
            snackbarShowMessage={snackbarShowMessage}
            manualNationalId={manualNationalId}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomModal;
