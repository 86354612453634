import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogActions, Grid, IconButton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import DownloadIcon from '@mui/icons-material/Download';
import '../ViewDocuments/index.css';
import '../CustomerForm/index.css';
import '../Modal/index.css';
import Selectq from '../Select';
import '../Select/index.css';
import { downloadFile } from '../../../utils/downloadFile';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MarkUnreadChatAltOutlinedIcon from '@mui/icons-material/MarkUnreadChatAltOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { axiosInstanceDocument } from '../../../api/Axios';
import { withSnackbar } from '../../components/SnackBar/SnackBar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FolderIcon from '@mui/icons-material/Folder';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import _ from 'lodash';
import {ncobsectionList,cusSectionList, SALES_PRE_REQUISTES_DIR} from '../../../utils/SectionFolderByDocument'
import { AddOutlined } from '@mui/icons-material';
import { MinimizeOutlined } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
// let sectionList = {
//   'Sales':['Item list','MSA/LOI','Customer Accounts','Customer Vendor List'],
//   'Pre-Requisites':[],
//   'Account Setup':['EDI Questionnaire'],
//   'Order Guide Setup':['OGM Setup','ICT Template','Order Guide'],
//   'Vendor Setup':['Supplier Readiness Documents','Ship-Point Maintenance Request'],
//   'Pricing Setup':['Controlled Pricing setup','Simulate Pricing'],
//   'Service Level Readiness':['OMCC Intake Form','Special Orders','Inventory Readiness Tracker'],
//   'Inventory Transfer':['Transfer List','Inventory Snapshot'],
//   'Others':['Preliminary Customer Information Form','Market Basket','Spec Sheet','Customer contact information','Operating Site Alignment & Heat Map','Deep Dive Template']
// }

function Row(props) {
  const {
    fileattachdone,
    row,
    customerId,
    snackbarShowMessage,
    onClose,
    emailattachflag,
    handleAttach,
    expand,
    setExpand,
    isShowExpand,
    isStatic,
    rows,
  } = props;

  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = useState('');

  useEffect(() => {
    setComment(row.comments || '');
  }, [row]);

  const handleSubmitComment = () => {
    if ((comment !== '') || (row.comments !== null)) {
      if (row.comments !== comment) {
        if (comment === '') {
          setOpen(false);
          onClose(true);
        }
        let reqData = {
          customerId,
          templateId: row?.templateId,
          comments: comment,
          isApproved: row.isApproved === 'Approved' ? 'Approved' : 'Pending',
          docStatusId:row.docStatusId
        };
        axiosInstanceDocument
          .request({
            method: 'POST',
            url: `/updateDocApproval`,
            data: [reqData]
          })
          .then((response) => {
            if (response?.data?.errorMessage != null) {
              snackbarShowMessage({
                message: response?.data?.errorMessage,
                type: 'error'
              });
            } else {
              snackbarShowMessage({
                message: 'Commented Successfully',
                type: 'success'
              });
              setOpen(false);
              onClose(true);
            }
          });
      } else {
        snackbarShowMessage({ message: 'No Changes Found', type: 'warning' });
      }
    } else {
      snackbarShowMessage({ message: 'please enter comment', type: 'warning' });
    }
  };

  const [singleDownloading,setSingleDownloading] = useState(false);
  const downloadMainOperation= ({docName,templateId,fileName,docStatusId}) =>{
    // if(docName === "Inventory Readiness Tracker" || docName === "Inventory Snapshot"){
      try{
        setSingleDownloading(true);
        axiosInstanceDocument
        .request({
            method: 'GET',
            url: `/downloadTrackerDocument/${templateId}/${customerId}/${docName=='MSA/LOI'?'MSALOI':docName}/${docStatusId}`,
            responseType: "blob"
        })
        .then((response) => {
              const contentType = response?.headers['content-type'];
              const url = window.URL.createObjectURL(
                new Blob([response?.data], { type: contentType })
              );
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                fileName
              );
              
              link.click();
              setSingleDownloading(false);
        })
      }catch(error){
        snackbarShowMessage({
          message: `Something went wrong. Please try again later`,
          type: 'error'
        });
        setSingleDownloading(false);
      }
    // }else{
    //   downloadFile(templateId, customerId,fileName)
    // }
    
    
  }
  const [downloading,setDownloading]= useState(false);
  const downloadAllDocs = ({ templateId }) => {
    try{
      setDownloading(true);
      axiosInstanceDocument
        .request({
          method: 'GET',
          url: `/downloadAllSpecDocument/${templateId}/${customerId}`,
          responseType: "blob"
        })
        .then((response) => {
          const contentType = response?.headers['content-type'];
          const url = window.URL.createObjectURL(
            new Blob([response?.data], { type: contentType })
          );
          const filename = response?.headers['content-disposition'];
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            filename.replace('attachment; filename=', '')
          );

          link.click();
          setDownloading(false);
        })
      } catch(error){
        snackbarShowMessage({
          message: `Something went wrong. Please try again later`,
          type: 'error'
        });
        setDownloading(false);
      }
  }
  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  function getFormattedDate(date) {
    date = new Date(date);
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    return month + '/' + day + '/' + year;
  }
  return (
    <>
      {row && (
        <>
          <TableRow sx={{ '& .MuiTableCell-root': { pt: 0, pb: 0, } }} key={row?.docStatusId}>
            <TableCell width='4%'>{isShowExpand && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setExpand(!expand)}
              >
                {expand ? <MinimizeOutlined /> : <AddOutlined />}
              </IconButton>)}</TableCell>
              <TableCell width='15%' >
                {isStatic && <b > {rows.name}</b>}
              </TableCell>
            <TableCell width='27%' style={{maxWidth:200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title ={row?.fileName} >{row?.fileName}</TableCell>
            <TableCell width='10%' >
              {row?.fileName?.substring(row.fileName.lastIndexOf('.') + 1)}
            </TableCell>
            <TableCell width='10%' >
              {row?.versionId}
            </TableCell>
            <TableCell width='10%' >{getFormattedDate(row.modifiedDate)}</TableCell>
            <TableCell width='5%'>
              {row.isApproved === 'Approved' ? 'Approved' : 'Pending'}
            </TableCell>
            <TableCell width='10%'>
              <>
                {emailattachflag &&
                  ((fileattachdone.indexOf(row.docStatusId) <= -1) ? (
                    <IconButton edge="end" aria-label="delete">
                      <AddIcon
                        data-testid="add"
                        onClick={() => handleAttach(row)}
                      />
                    </IconButton>
                  ) : (
                    <IconButton edge="end" aria-label="delete">
                      <DoneIcon />
                    </IconButton>
                  ))}
                {!emailattachflag && (
                  <>
                    <IconButton
                      edge="end"
                      data-testid='downloadfile'
                      aria-label="comments"
                      title='Current version download'
                      onClick={() => {
                        downloadMainOperation(row);
                      }}
                      disabled={singleDownloading}
                    >
                      <DownloadIcon />
                      {singleDownloading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              // color: 'white',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px'
                            }}
                          />
                        )}
                    </IconButton>

                    <IconButton
                      edge="end"
                      aria-label="comments"
                      data-testid="commenticon"
                      disabled={row.isApproved === 'Approved'}
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      <MarkUnreadChatAltOutlinedIcon />
                    </IconButton>
                    {isShowExpand && (row.docName === 'Spec Sheet' || row.docName === 'Vision Report') && (
                      <IconButton
                        edge="end"
                        data-testid='downloadfile'
                        aria-label="comments"
                        onClick={() => {
                          downloadAllDocs(row);
                        }}
                        title='All versions download' 
                        disabled={downloading}
                      >
                        <DownloadIcon />
                        {downloading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              // color: 'white',
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px'
                            }}
                          />
                        )}
                      </IconButton>
                    )}
                  </>
                )}
              </>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 0 }}>
                  <TextField
                  inputProps={{'data-testid':'input'}}
                    onChange={handleCommentChange}
                    id="outlined-multiline-static"
                    value={comment}
                    margin="dense"
                    data-testid="text-field"
                    fullWidth
                    multiline
                    rows={3}
                  />
                  <div className="button-wrapper">
                    <Button
                      data-testid="cancel-btn"
                      sx={{ '& .MuiButton-root': { fontSize: 14 } }}
                      id="secondary"
                      className="buttons-1"
                      onClick={() => {
                        setOpen(false);
                        setComment(row.comments || '');
                      }}
                    >
                      {' '}
                      Cancel{' '}
                    </Button>
                    <Button
                      data-testid="submit-btn"
                      id="primary"
                      className="buttons-2"
                      onClick={handleSubmitComment}
                    >
                      {' '}
                      Submit{' '}
                    </Button>
                  </div>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}

function DocRows(props) {
  let{
    rows
  } = props
  let [opendetail, setOpendetail] = React.useState(true)
  const [expand,setExpand] = useState(false);
  let staticRow = {...rows};
  let expandRow = {...rows};
  if(rows.value?.length > 1){
    expandRow['value'] = rows.value.slice(1,rows.value?.length);
    staticRow['value'] = rows.value.slice(0,1);
  }else{
    staticRow = rows;
    expandRow = {};
  }
  return (
  <React.Fragment >
    {/* <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} key={rows.name}>
      <TableCell style={{maxWidth:200}} 
        // onClick={() => setOpendetail(!opendetail)}
      >
     
      <b > 
        {expandRow?.value?.length > 0 && <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setExpand(!expand)}
                >
                  {expand ? <MinimizeOutlined /> : <AddOutlined />}
                </IconButton>
              }
          {rows.name}</b>
      </TableCell>
    </TableRow> */}
      {staticRow.value?.map((row) => (
        <Row
          row={row}
          {...props}
          isStatic={true}
          setExpand={setExpand}
          expand={expand}
          isShowExpand={expandRow?.value?.length > 0}
        />
      ))}
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={12}>
        <Collapse in={expand} timeout="auto" unmountOnExit>
          <Box  sx={{ margin: 0 }}>
            <Table size="small" aria-label="purchases">
              <TableBody>
                  {/* {staticRow.value?.map((row) => (
                    <Row
                      row={row}
                      {...props}
                      isStatic = {true}
                    />
                  ))} */}
                  {expand && expandRow.value?.map((row) => (
                    <Row
                      row={row}
                      {...props}
                    />
                  ))}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  </React.Fragment>
  )
}
function ViewDocuments(props) {
  const {
    fileattachdone,
    onClose,
    open,
    handleAttach,
    customerFiles,
    customerId,
    snackbarShowMessage,
    emailattachflag,
    sectionNameForexecute,
    setsectionNameForexecute,
    isFromVPNA,
  } = props;
  const [data, setData] = useState([]);
  const [newdata, setnewData] = useState([]);
  const [step, setStep] = useState(1);
  const [filteredApproveDoc,setfilteredApproveDoc] = useState([])
  const [checkedItem,setchekcedItem] = useState(null) 
  let sectionList = {}
  let isCustomerPortal = localStorage.getItem('customerPortalUser')
  ? true
  : false;
  if(isCustomerPortal){
    sectionList = cusSectionList
  }else{
    // if(!sectionNameForexecute){
    //   sectionList = {...mergedDir,...ncobsectionList};
    //   delete sectionList['Sales'];
    //   delete sectionList['Pre-Requisites'];
    // }else{
      sectionList = ncobsectionList;
    // }
  }
  const names = [
    'Preliminary Customer Information Form',
    'Market Basket',
    'Item list',
    'Spec Sheet',
    'Customer contact information',
    'Operating Site Alignment & Heat Map',
    'Deep Dive Template',
    'MSA/LOI',
    'Customer Accounts',
    'Customer Vendor List',
    'Order Guide',
    'Supplier Readiness Documents',
    'Ship-Point Maintenance Request',
    'EDI Questionnaire',
    'Others',
    'OMCC Intake Form',
    'Special Orders',
    'Transfer List'
  ];
  const approveDoc = data?.filter(e => e.isApproved === "Approved")?.map(i=>i.docName);

  function handleClose() {
    onClose(false);
  }
  
  useEffect(() => {
    if(checkedItem){
        gotoNextStep(checkedItem)
    }
  }, [customerFiles]);

  useEffect(() =>{
    if(open && emailattachflag){
      if(document.getElementById('sectionName')?.innerText === 'Sales' || document.getElementById('sectionName')?.innerText === 'Pre-Requisites' || isFromVPNA)
      gotoNextStep(SALES_PRE_REQUISTES_DIR);
      else gotoNextStep(document.getElementById('sectionName')?.innerText)
    }
    if(open &&!emailattachflag){
        setStep(1)
    }
    if(open && sectionNameForexecute){
      if(document.getElementById('sectionName')?.innerText === 'Sales' || document.getElementById('sectionName')?.innerText === 'Pre-Requisites'){
        gotoNextStep(SALES_PRE_REQUISTES_DIR);
      }else{
        gotoNextStep(document.getElementById('sectionName')?.innerText)
      }
    }
  },[open])
  function sortClass(sortData){
    const groupBy = (array, f) => {
      let groups = {};
      array.forEach((o) => {
        let group = JSON.stringify(f(o));
        groups[group] = groups[group] || [];
        groups[group].push(o);
      });
      // return groups
      return Object.keys(groups).map((group) => {
        return {
          name:group,
          value:groups[group]
        };
      });
    };
    if(sortData.length>0){
      const sorted = groupBy(sortData, (item) => {
        return item.docName; // 返回需要分组的对象
      });
      return sorted;
    }else{
      return sortData
    }
   
  };
  function getfileNum(name){
    let increseNum = 0
    if(name === 'Others'){
      increseNum+=customerFiles.filter(fres=>fres.templateId == 1000).length;
    }else{
      sectionList[name].map(res=>{
        increseNum+=customerFiles.filter(fres=>fres.docName == res).length
      })
    }
    return increseNum
  }
  const [isOthers,setOthers] = useState(false);
  function gotoNextStep(res){
    setchekcedItem(res)
    let docList = [];
    if(res === 'Others'){
       let mappedSectionDoc = customerFiles.filter((res)=>{
          return res.templateId === 1000;
        })
        docList = docList.concat(mappedSectionDoc)
      setOthers(true);
    }else{
      sectionList[res].map((sectionItem)=>{
        let mappedSectionDoc = customerFiles.filter((res)=>{
          return res.docName === sectionItem
        })
        docList = docList.concat(mappedSectionDoc)
      })
      setOthers(false);
    }
      setStep(2)
      //show all documents when attach a file in email, not only show current section's documents
      setnewData(open && emailattachflag ? sortClass(customerFiles): sortClass(docList));
      setfilteredApproveDoc( sectionList[res].filter(element => !(approveDoc.includes(element))))

  }

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'xl'}>
      <Grid item xs={12} sm={12} className="View-document-popup customer-form">
        <DialogTitle>
          Document Management
          <IconButton
            aria-label="close"
            data-testid="close-btn"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {((step == 2 && !emailattachflag && !sectionNameForexecute)) && (
            <ArrowBackIcon onClick={()=>{setStep(1); setchekcedItem(null)}}/>
          )}
          <br />
          {step == 1 && (
            <List>
              {Object.keys(sectionList).map(res=>
                <ListItem disablePadding >
                  <ListItemButton onClick={()=>{
                    gotoNextStep(res)
                  }}>
                    <ListItemIcon>
                      <FolderIcon />
                    </ListItemIcon>
                    <ListItemText primary={`${res} (${getfileNum(res)})`} />
                  </ListItemButton>
                </ListItem>
              )}
             
            </List>
          )}
          {step == 2 && (
          <React.Fragment>
          {newdata?.length == 0 && (
            <p>No File Uploaded...</p>
          )}
          {newdata?.length !== 0 && (
          <TableContainer component={Paper} sx={{ maxHeight: 440,marginBottom:2 }}>
            <Table aria-label="collapsible table" size="small" stickyHeader sx={{'& .MuiTableCell-root':{padding: '6px'}}}>
              <TableHead>
                <TableRow colSpan={12}>
                    <TableCell width='4%'></TableCell>
                    <TableCell width='15%' >
                      <b>Documents Name</b>
                    </TableCell>
                    <TableCell width='25%'>
                      <b>File Name</b>
                    </TableCell>
                    <TableCell width='10%'>
                      <b>File Type</b>
                    </TableCell >
                    <TableCell width='10%'>
                      <b>Version Id</b>
                    </TableCell>
                    <TableCell width='10%'>
                      <b>Upload Date</b>
                    </TableCell>
                    <TableCell width='5%'>
                      <b>Status</b>
                    </TableCell>
                    <TableCell  width='10%'>
                      <b>Actions</b>
                    </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {newdata.map((rows) =>
                  <DocRows
                    key={rows.name}
                    fileattachdone={fileattachdone}
                    emailattachflag={emailattachflag}
                    handleAttach={handleAttach}
                    rows={rows}
                    customerId={customerId}
                    snackbarShowMessage={snackbarShowMessage}
                    onClose={onClose}
                  ></DocRows>
                )}
                </TableBody>
              </Table>
            </TableContainer>  
          )}
          {!emailattachflag && <b>Upload Documents</b>}
          {!emailattachflag && (
            <Selectq
              reviewcompflag={true}
              nameList={filteredApproveDoc}
              hideButtons={false}
              customerID={customerId}
              sectionSubmit={() => {
                onClose(true);
              }}
              closeHandler={() => {}}
              isOthers={isOthers}
            />
          )}
          </React.Fragment>
          )}
        </DialogContent>
        <DialogActions className="form-buttons">
          {' '}
          <Button
            data-testid="close-button"
            onClick={handleClose}
            id="primary"
            className="button"
          >
            Close
          </Button>{' '}
        </DialogActions>
      </Grid>
    </Dialog>
  );
}

export default withSnackbar(ViewDocuments);
