import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { Field, ErrorMessage } from 'formik';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material'

export const FieldTemp = ({
  layout = { xs: 12, sm: 12 },
  label,
  name,
  formEditClass,
  errors,
  touched,
  required = true,
  placeholder = 'Input text*'
}) => {
  function className(FORMTITLE, param1, param2) {
    return FORMTITLE + (param1 && param2 ? ISINVALID : '');
  }
  const FORMCONTROL = 'form-control';
  const ISINVALID = ' is-invalid';
  const FORMTITLE = ' form-title';
  return (
    <Grid item {...layout}>
      <div className="form-header">
        <div className={className(FORMTITLE, errors[name], touched[name])}>
          {label}
        </div>
        <div className="form-subTitle fullWidth">
          <Field
            name={name}
            type="text"
            placeholder={!formEditClass ? placeholder : ''}
            className={className(FORMCONTROL, errors[name], touched[name])}
          />
          {!formEditClass && (
            <div className={className('required', errors[name], touched[name])}>
              {errors[name] && touched[name] ? (
                <ErrorMessage
                  name={name}
                  component="div"
                  className="invalid-feedback"
                />
              ) : required ? (
                '*Required'
              ) : (
                ''
              )}
            </div>
          )}
        </div>
      </div>
    </Grid>
  );
};

const LeadAndPartnerFields = ({ errors, touched, formEditClass }) => {
  const leadFormConfigs = [
    {
      name: 'leadName',
      label: 'Name',
      required: false
    },
    {
      name: 'leadEmail',
      required: false,
      label: 'Email'
    },
    {
      name: 'leadContcatNo',
      required: false,
      label: 'Phone Number',
      placeholder: 'Format in (xxx)xxx-xxxx/xxx-xxx-xxxx'
    }
  ];
  const partnerFormConfigs = [
    {
      name: 'partnerName',
      label: 'Name',
      required: false
    },
    {
      name: 'partnerEmail',
      required: false,
      label: 'Email'
    },
    {
      name: 'partnerContcatNo',
      required: false,
      label: 'Phone Number',
      placeholder: 'Format in (xxx)xxx-xxxx/xxx-xxx-xxxx'
    }
  ];
  const configs = [
    {
      label: 'Onboarding Lead',
      type: 'Onboarding Lead',
      configs: leadFormConfigs
    },
    {
      label: 'Onboarding Partner',
      type: 'Onboarding Partner',
      configs: partnerFormConfigs
    }
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [selectedText, setSelectedText] = useState(configs[0].type);
  const [showItem, setShowItem] = useState(configs[0].configs);
  const handleMenuItemClick = (e, config) => {
    setSelectedText(config.type);
    const target = configs.find((item) => item.type === config.type);
    setShowItem(target.configs);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  if (formEditClass) {
    return (
      <div className="customer-form onbording-form form-container">
        <div className="popup-title form2">
          <div
            className='form-title-width-onboarding'
            aria-haspopup="listbox"
            onClick={handleOpenMenu}
            aria-controls="lock-menu"
            aria-label={selectedText}
            aria-expanded={open ? 'true' : undefined}
          >
            {selectedText} {!open ? <ArrowDropDown /> : <ArrowDropUp />}
          </div>

          <Menu
            id="lock-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              role: 'listbox'
            }}
          >
            {configs.map((config) => (
              <MenuItem
                key={config.type}
                selected={selectedText === config.type}
                onClick={(event) => handleMenuItemClick(event, config)}
              >
                {config.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <div className="customer-form form-container">
          <Grid className={formEditClass} container rowSpacing={2} columnSpacing={3}>
            {showItem&&showItem.map((config) => {
              return (
                <FieldTemp
                  key={config.name}
                  {...config}
                  formEditClass={formEditClass}
                  errors={errors}
                  touched={touched}
                />
              );
            })}
          </Grid>
        </div>
      </div>
    );
  }

  return (
    <Grid container  rowSpacing={2} >
      {
        configs.map((config) => {
          return (<Grid item sm={12} xs={12} key={config.type}>
          <div className="popup-title form2">{config.label}</div>
          <div className="customer-form form-container onbording-form">
            <Grid container rowSpacing={2} columnSpacing={3}>
              {config.configs.map((subConfig) => {
                return (
                  <FieldTemp
                    key={subConfig.name}
                    {...subConfig}
                    formEditClass={formEditClass}
                    errors={errors}
                    touched={touched}
                  />
                );
              })}
            </Grid>
          </div>
        </Grid>)
        })
      }
    </Grid>
  );
};

export default LeadAndPartnerFields;
