import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  Navigate,
  useLocation,
  useNavigate
} from 'react-router-dom';
import ErrorFallback from '../modules/components/ErrorBoundary';
import { ErrorBoundary } from 'react-error-boundary';
import Home from '../modules/containers/Home';
import TemplateComponent from '../modules/containers/templateComponent';
import { Amplify } from 'aws-amplify';
import { awsconfig } from '../aws-config';
import AdminDashboard from '../modules/components/AdminDashboard/AdminDashboard';
import Dashboard from '../modules/containers/Location_Concept_Dashboard/index';
import PageNotFound from '../modules/components/PageNotFound';
import ConfettiPage from '../modules/components/ConfettiPage';
import CustomerPortal from '../modules/containers/CustomerPortal/index';
import ContactSupport from '../modules/containers/Contact_Support/index';
import HelpDesk from '../modules/components/HelpDesk';
import OfflineChatter from '../modules/components/OfflineChatter';
Amplify.configure(awsconfig);

export default function RoutesUrl() {
  const { pathname } = useLocation();
  let isCustomerPortal = localStorage.getItem('customerPortalUser')
    ? true
    : false;
  let navigate = useNavigate();
  useEffect(() => {
    window.addEventListener('customerPortalUser', () => {
      if (pathname === '/') {
        navigate(`/Dashboard/${localStorage.getItem('customerPortalUser')}`);
        setTimeout(()=>{
           window.location.reload()
        },[2000])
       
        return;
      }
    });
  }, []);

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Routes>
          {isCustomerPortal ? (
            <>
              <Route
                path="/Dashboard/:parentCustID"
                element={<CustomerPortal />}
              />
              <Route
                path="/Dashboard/:parentCustID/new-onboarding/:customerID"
                element={<CustomerPortal />}
              />
              <Route path="/help-desk" element={<HelpDesk />} />
              <Route
                path="/contact-support/:parentCustID"
                element={<ContactSupport />}
              />
               <Route
                path="/chat/:customerID"
                element={<OfflineChatter />}
              />
               <Route path="/PageNotFound" element={<PageNotFound />} />
            </>
          ) : (
            <>
              <Route path="/ConfettiPage" element={<ConfettiPage />} />
              <Route
                path="/:url*(/+#)"
                element={<Navigate to={pathname.slice(0, -1)} />}
              />
              <Route path="/" element={<Home />} />
              <Route path="/help-desk" element={<HelpDesk />} />
              <Route
                path="/new-onboarding/:customerID"
                element={<TemplateComponent />}
              />
              <Route path="*" element={<PageNotFound />} />
              <Route path="/admin-dashboard" element={<AdminDashboard />} />
              <Route
                path="/chat/:customerID"
                element={<OfflineChatter />}
              />
              <Route path="/Template-dashboard" element={<AdminDashboard />} />
              <Route path="/Dashboard/:parentCustID" element={<Dashboard />} />
              <Route
                path="/Dashboard/:parentCustID/new-onboarding/:customerID"
                element={<TemplateComponent />}
              />
            </>
          )}
        </Routes>
      </ErrorBoundary>
    </>
  );
}
