import { createSlice } from '@reduxjs/toolkit';
import { redirectUrl } from '../../../../aws-config';

//Initializing state
const initialState = {};

//Creating Reducers functions
export const templateSlice = createSlice({
  name: 'userRole',
  initialState,
  reducers: {
    getUserRole(state, action) {
      return { ...state, ...action.payload };
    },
    getUserRoleSuccess(state, action) {
      return { ...state, ...action.payload };
    },
    getUserRoleError(state, action) {
      if(action?.payload?.response?.status  === 401){
        localStorage.removeItem('userInfo');
        window.location.assign(redirectUrl);
        return false;
      }
      return { ...state, ...action.payload };
    }
  }
});

//Action creators
export const { getUserRole,getUserRoleSuccess,getUserRoleError } =
  templateSlice.actions;

export default templateSlice.reducer;
