import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import {postNewchat} from '../../../utils/postNewchat'
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import { DialogActions, Button, IconButton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
const useStyles = makeStyles(theme => ({
  text: {
    '& .MuiInputBase-root': {
     height:150,
     width:400
    }
  }
}));

const RequestPopup = (props) => {
  const classes = useStyles();
  const {
    popupopen,
    headingtitle,
    setpopupopen,
    sectionSubmit,
    triggerPopupDesc,
    triggerPopupSuccess,
    cancelHandler,
    Textfield,
    message,
    setmessage,
    loading
  } = props;

  const [isbuttonclicked, setbuttonclicked] = useState(false);
 
  const { customerID } = useParams();
  function closehandler  () {
    Textfield && setmessage('')
    setpopupopen(false);
    cancelHandler();
  };
  function postnewChathandler  () {
   postNewchat(customerID,message)
   setpopupopen(false);
  };

  return (
    <div>
      <Dialog
        sx={{
          '& .MuiPaper-root': {
            width: { sm: 467, md: 467 },
            height: { sm: 300, md: 300 }
          },
          '& .MuiDialogContent-root .content': {
            width: { sm: 467, md: 467 },
            height: { sm: 300, md: 300 }
          },
          '& .MuiTypography-root': { fontFamily: 'inherit', fontWeight: 700 },
          '& .MuiDialogActions-root': {
            boxShadow: '0px 0px 10px rgb(0 0 0 /0.25)'
          }
        }}
        open={popupopen}
        onClose={closehandler}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ borderBottom: '1px solid #00000017' }}
        >
          {headingtitle}
          <IconButton
            aria-label="close"
            onClick={closehandler}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            p: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="ABC Food National ID Request"
              name="radio-buttons-group"
              sx={{
                m: 1,
                '& .MuiTypography-root': {
                  fontFamily: 'inherit',
                  fontSize: 20,
                  fontWeight: 400
                }
              }}
            >
              {triggerPopupDesc && (
                <div style={{ fontSize: '18px' }}>{triggerPopupDesc}</div>
              )}
              {Textfield && (   <TextField
          id="outlined-textarea"
        
          inputProps={{ 'data-testid': 'textbox' }}
          className={`${classes.text}`}
          value={message}
          placeholder="Placeholder"
          multiline
          onChange={(e)=>{setmessage(e.target.value)}}
        />)
                
              }
              {/* <FormControlLabel
              value="ABC Food National ID Request"
               control={<Radio />}
               label="ABC Food National ID Request"
               /> */}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ boxShadow: '0 0 10' }}>
          <Button id="secondary" onClick={closehandler}>
            No
          </Button>
          <Button
            className={`button ${loading?' disabled-button':''} `}
            id="primary"
            data-testid="yesbutton"
            disabled={isbuttonclicked}
            onClick={(e) => {
              {Textfield && postnewChathandler()}
              {Textfield && setmessage('')}
              setbuttonclicked(true);
              sectionSubmit(triggerPopupSuccess, false);
            }}
          >
            Yes
          </Button>
            {( loading && 
                <CircularProgress
                  size={24}
                  sx={{
                    color: 'white',
                    position: 'absolute',
                    left: '87%',
                  }}
                />
            )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RequestPopup;
