export const SALES_PRE_REQUISTES_DIR = "Sales/Pre-Requisites";
export const ncobsectionList = {
  // 'Sales':['Item list','MSA/LOI','Customer Accounts','Customer Vendor List'],
  // 'Pre-Requisites':['Preliminary Customer Information Form','Market Basket','Spec Sheet','Customer contact information','Operating Site Alignment & Heat Map','Deep Dive Template'],
  [SALES_PRE_REQUISTES_DIR]:['Item list','MSA/LOI','Customer Accounts','Customer Vendor List','Market Basket','Spec Sheet','Customer contact information','Operating Site Alignment & Heat Map','Deep Dive Template'],
  'Account Setup':['EDI Questionnaire'],
  'Order Guide Setup':['OGM Setup','ICT Template','Order Guide'],
  'Vendor Setup':['Supplier Readiness Documents','Ship-Point Maintenance Request'],
  'Pricing Setup':['Controlled Pricing setup','Simulate Pricing'],
  'Service Level Readiness':['OMCC Intake Form','Special Orders','Inventory Readiness Tracker'],
  'Inventory Transfer':['Transfer List','Inventory Snapshot'],
  'Validation':['Vision Report'],
  'Others':[]
}
export const cusSectionList = {
  'Sales':['Item list','MSA/LOI','Customer Accounts','Customer Vendor List'],
  'Account Setup':['EDI Questionnaire'],
  'Order Guide Setup':['OGM Setup','ICT Template','Order Guide'],
  'Inventory Transfer':['Transfer List','Inventory Snapshot'],
}

