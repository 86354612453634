import React, { useState, useEffect } from 'react';
import { axiosInstanceDashboard } from '../../../api/Axios';
import '../SalesDashboard/index.css';
import Grid from '@mui/material/Grid';
import usePagination from '../Home/Pagination';
import SalesCustomerInfo from '../../components/SalesCustomerInfo';
import CustomModal from '../../components/Modal/index';
import { Popover, Card, CardContent, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch } from 'react-redux';
import { updateAccounttype } from '../templateComponent/redux/accounttypeSlice';
import { withSnackbar } from '../../components/SnackBar/SnackBar';
import { useParams, useNavigate } from 'react-router-dom';
import ContactsInformation from '../../components/ContactsInformation/index';
import ThingsToDo from '../../components/ThingsToDo/index';
import ProgressBar from '../../components/ProgressBar/index';
import Paper from '@mui/material/Paper';
import ListOfConcepts from '../../components/ListOfConcepts/index';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RequestToCreate from '../../components/RequestToCreate/index';
import { triggerCustomerPortal } from '../../../utils/triggerCustomerPortal';
import _ from 'lodash'
const Dashboard = (props) => {
  const {
    customerportal,
    snackbarShowMessage,
    clickedflag,
    setconfettipage,
    cust_acctype
  } = props;
  const dispatch = useDispatch();
  const { parentCustID, customerID } = useParams();
  const custID = customerID ? customerID : parentCustID;
  const [modalopen, setmodalopen] = useState(false);
  const [steadystate, setsteadystate] = useState(false);
  const [customerdetails, setcustomerdetails] = useState({});
  let list;
  const modalhandler = () => {
    setmodalopen(true);
  };
  const PER_PAGE = 8;
  const _DATA = usePagination(
    list?.sort((a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate)),
    PER_PAGE
  );
  const [anchorEl, setAnchorEl] = useState(null);
  let navigate = useNavigate();
  const [acctype, setacctype] = useState();
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const fetchlist = () => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getCustomerDetails?id=${custID}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            // console.log(response.data.data.Customer)
            if(_.get(response, 'data.data.Customer.progressStatus') !== "Steady State" && !customerportal){
              navigate(`/new-onboarding/${custID}`,{replace: true});
            }
            setcustomerdetails(_.get(response, 'data.data.Customer', {}));
          }
        }
      });
  };

  useEffect(() => {
    if (acctype === 'LOCATION') dispatch(updateAccounttype('LOCATION'));
    else if (acctype === 'CONCEPT') dispatch(updateAccounttype('CONCEPT'));
    fetchlist();
  }, [acctype, custID]);

  const locclickhandle = () => {
    setacctype('LOCATION');
    modalhandler();
    setAnchorEl(null);
  };
  const conclickhandle = () => {
    setacctype('CONCEPT');
    modalhandler();
    setAnchorEl(null);
  };

  const handlePopperClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Dashboardhandler = () => {
    customerportal &&
      navigate(`/Dashboard/${parentCustID}?name=customerportaluser`);
    setTimeout(() => {
      window.location.reload();
    }, [1000]);
  };
  const CustomerPortalTrigger = async () => {
    const res = await triggerCustomerPortal(custID);

    if (res?.data?.data?.['Customer Portal']) {
      snackbarShowMessage({
        message: res?.data?.data?.['Customer Portal'],
        type: 'success'
      });
    } else if (res?.data?.errorMessage) {
      snackbarShowMessage({
        message: res?.data?.errorMessage,
        type: 'error'
      });
    }
    setTimeout(() => {
      setAnchorEl(null);
    }, [1000]);
  };
  return (
    <Grid spacing={4} sx={{ padding: 0, mt: 0 }}>
      <Grid xs={12} sx={{ p: 4 }}>
        <div className="header-title">
          <div
            className="Header"
            onClick={() => {
              Dashboardhandler();
            }}
            style={{ fontSize: '24px' }}
          >
            {customerportal && customerID && <ArrowBackIcon />}Dashboard{' '}
          </div>
          {!customerportal && (
            <div className="Header-action">
              Action Items <MoreVertIcon onClick={handlePopperClick} />
            </div>
          )}
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Typography sx={{ p: 2 }}>
            <div onClick={CustomerPortalTrigger}>Create Customer Login</div>
            {customerdetails?.accountType !== 'LOCATION' && (<div
              onClick={() => {
                locclickhandle();
              }}
            >
              Create New Locations{' '}
            </div>
            )}
            {(customerdetails?.accountType == 'CMU' || customerdetails?.accountType == 'LCC') &&(<div
              onClick={() => {
                conclickhandle();
              }}
            >
              Create New Concepts{' '}
            </div>
            )}
          </Typography>
        </Popover>
        <Grid container spacing={4} sx={{ padding: 0, mt: 0 }}>
          {customerportal ? (
            <Grid item xs={9} sx={{ boxShadow: 'none' }}>
              <SalesCustomerInfo
                customerdetails={customerdetails}
                customerportal={customerportal}
                parentCustID={parentCustID}
                snackbarShowMessage={snackbarShowMessage}
                location_concept_flag={true}
              />
            </Grid>
          ) : (
            <Grid xs={12} sx={{ boxShadow: 'none', paddingLeft: '32px' }}>
              <SalesCustomerInfo
                customerportal={customerportal}
                parentCustID={parentCustID}
                snackbarShowMessage={snackbarShowMessage}
                location_concept_flag={true}
                customerdetails={customerdetails}
              />
            </Grid>
          )}

          {customerdetails?.customerContactResponses?.length > 0 && customerportal ? (
            <Grid item xs={3} sx={{ marginTop: '10px' }}>
              <ContactsInformation customerdetails={customerdetails} />
            </Grid>
          ) : (
            ''
          )}

          {customerportal &&
            (steadystate ? (
              <>
                <Grid item xs={6} sx={{ marginTop: '-17px' }}>
                  <div style={{ paddingBottom: '10px' }}>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <RequestToCreate
                          heading="Request to Create New Concept"
                          subheading="Start here to request a new concept"
                          iconType="concept"
                        />
                      </div>
                      <div style={{ marginLeft: '10px' }}>
                        <RequestToCreate
                          heading="Request to Add New Location"
                          subheading="Start here to request a new location"
                          iconType="location"
                        />
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} style={{ marginTop: '-11px' }}>
                  <div className="table_card">
                    <Paper
                      style={{
                        width: 'auto',
                        height: 'auto',
                        padding: '18px'
                      }}
                    >
                      <div>
                        <span
                          style={{
                            fontSize: '20px',
                            fontWeight: '600',
                            fontFamily: 'MyriadPro',
                            letterSpacing: '0.2px'
                          }}
                        >
                          List Of Concepts
                        </span>
                      </div>
                      <div style={{ marginTop: '20px', paddingBottom: '30px' }}>
                        <ListOfConcepts />
                      </div>
                    </Paper>
                  </div>
                </Grid>
              </>
            ) : (
              <Grid item xs={9} sx={{ marginTop: '0px' }}>
                <ThingsToDo
                  setsteadystate={setsteadystate}
                  snackbarShowMessage={snackbarShowMessage}
                  CustomerId={custID}
                  setconfettipage={setconfettipage}
                  clickedflag={clickedflag}
                />
              </Grid>
            ))}
          {customerportal &&
            (steadystate ? (
              ''
            ) : (
              <Grid item xs={3} sx={{ marginTop: '0px' }}>
                <Card>
                  <CardContent sx={{ paddingLeft: '40px' }}>
                    <p
                      className="progressbar-header"
                      style={{ paddingLeft: '10px' }}
                    >
                      In Progress
                    </p>
                    <ProgressBar customerId={custID} acctype={cust_acctype} />
                  </CardContent>
                </Card>
              </Grid>
            ))}
          <CustomModal
            modalopen={modalopen}
            setmodalopen={setmodalopen}
            customerList={_DATA.currentData()}
            acctype={acctype}
            customerdetails={customerdetails}
            snackbarShowMessage={snackbarShowMessage}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default withSnackbar(Dashboard);
