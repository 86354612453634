import { takeLatest } from 'redux-saga/effects';


import { getCustomerProgress } from './components/ProgressBar/redux/slice';
import { getAllActions } from './containers/SalesDashboard/redux/slice';


import { handlegetAllActions } from './containers/SalesDashboard/redux/saga-handler';
import { handleGetCustomerProgressRequest } from './components/ProgressBar/redux/saga-handler';
import { handleCustomerList } from './components/Header/redux/saga-handler';
import { handleupdateCustomerProgressStatus } from './containers/templateComponent/redux/saga-handler';
import { updateCustomerProgress } from './containers/templateComponent/redux/slice';
import { getCustomerList} from './components/Header/redux/slice';
import { getEmailList } from './components/CustomerForm/redux/slice'
import { handleEmailList } from './components/CustomerForm/redux/saga-handler';
import { getUserRole } from './components/Sidebar/redux/slice';
import { handleUserRole } from './components/Sidebar/redux/saga-handler';

export function* watcherSaga() {

  yield takeLatest(getCustomerList.type, handleCustomerList);
  yield takeLatest(getCustomerProgress.type, handleGetCustomerProgressRequest);
  yield takeLatest(getAllActions.type, handlegetAllActions)
  yield takeLatest(updateCustomerProgress.type, handleupdateCustomerProgressStatus)
  yield takeLatest(getEmailList.type, handleEmailList)
  yield takeLatest(getUserRole.type, handleUserRole);
}
