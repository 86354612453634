import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import '../MailSend/index.css';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TurnLeftIcon from '@mui/icons-material/TurnLeft';
import { downloadFile } from '../../../utils/downloadFile';
import Mail from '../../components/Mail';
import _ from 'lodash'
import { axiosInstanceEmail } from '../../../api/Axios';
import Tooltip,{  tooltipClasses } from '@mui/material/Tooltip';
import VPNAEmail from '../Mail/VPNAEmail';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#1b4f72',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#1b4f72',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

export default function MailSend(props) {
  const {customerId, sectionSubmit, snackbarShowMessage,isVPNA,vpnaEmailListData,setmodalopen,customerdetails,setopenEmailDetails} = props;
  const [emailListData,setemailListData] = useState([]);
  const actionId = useSelector((state) => state?.storeActiveStep?.actionId);
  useEffect(() => {
    if(isVPNA){
      setemailListData(vpnaEmailListData);
      return;
    }
      axiosInstanceEmail
      .request({
        method: 'GET',
        url: `/getEmailDetails?customerId=${customerId}&actionId=${actionId}`
      })
      .then((response) => {
        if (response) {
          if (response?.data?.errorCode || response?.data?.errorMessage) {
            // handleError(response?.data?.errorMessage);
            setemailListData([])
          } else {
            setemailListData(response?.data?.data?.EmailInfo);
          }
        }
      });
  }, [actionId, customerId]);
  const formatEmailList = useMemo(() => {
    return (emailListData || []).map((email) => {
      return {
        to: email.toName.split(','),
        cc: email.ccName.split(','),
        subject: email.emailSubject,
        content: email.emailBody,
        ...email
      };
    });
  }, [emailListData]);
  const [mailResend, setMailResend] = useState(false);
  const [emailChain, setEmailChain] = useState([]);
  const [moreEmails, setMoreEmails] = useState(false);
  const [email, setEmail] = useState({})
  const fetchEmailChain = (id) => {
    axiosInstanceEmail.request({
      method: 'GET',
      url: `/getEmailMoreList?emailId=${id}`
    }).then((response) => {
      if (response) {
        if (response?.data?.errorCode || response?.data?.errorMessage) {
          // do some error handle 
        } else {
          setEmailChain(response?.data?.data?.EmailInfo);
        }
      }
    })
  }
  const [emaiTable, setEmaiTable] = useState(true);
  const [emailDetails, setEmailDetails] = useState(false);

  const openEmailDetails = (email) => {
    setEmaiTable(false);
    setEmailDetails(true);
    setEmailChain([email]);
    setEmail(email)
    fetchEmailChain(email.emailId)
    setopenEmailDetails && setopenEmailDetails(true)
  };
  const openEmailList = () => {
    setEmaiTable(true);
    setEmailDetails(false);
    setMailResend(false);
    setopenEmailDetails && setopenEmailDetails(false);
  };



  const openReSend = () => {
    setMailResend(true);
  };

  const showMoreEmails = () => {
    setMoreEmails(true);
  };

  const closeReSend = () => {
    setMailResend(false);
  };
  return (
    <div className="mail-send-template">
      {/* mail-list */}
      {!!_.get(emailListData, 'length') && (
        <div
          className="mail-list"
          style={{ display: emaiTable ? 'block' : 'none' }}
        >
          {!isVPNA && 
          <div className="mail-send-tit">
            <h4>Email Sent</h4>
          </div>}
          <div className="mail-send-Table">
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>To:</StyledTableCell>
                      <StyledTableCell align="left">Subject</StyledTableCell>
                      <StyledTableCell align="left">Date</StyledTableCell>
                      <StyledTableCell align="left">Time</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {formatEmailList.map((row) => {
                      const showAttacmentList = [];
                      return (
                      <TableRow
                        key={row.emailId}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                        onClick={openEmailDetails.bind(null, row)}
                      >
                        <TableCell component="th" scope="row">
                          {row.toName?.split(',').map(item => item.split('@')[0]).join(';')}
                        </TableCell>
                        <TableCell align="left">
                          <div className="subject-cell">
                            <span className="sub01">{row.emailSubject}</span> -{' '}
                            <span>{row.emailBody}</span>
                          </div>
                          <div
                            className="file-lists-mailSend"
                            style={{
                              display:
                                row?.documentList?.length > 0
                                  ? 'block'
                                  : 'none',
                              height: moreEmails ? 'auto' : '35px'
                            }}
                          >
                            {row?.documentList?.map((item) => (
                              <a
                                className="files-down-list"
                                style={{
                                  display:
                                    item.fileName != null ? 'block' : 'none'
                                }}
                                onClick={() => {
                                  downloadFile(
                                    item.masterTemplate.templateId,
                                    customerId,
                                    item.masterTemplate.templateName,
                                    item.docStatusId,
                                    item.fileName
                                  );
                                }}
                              >
                                {item.fileName}
                              </a>
                            ))}
                            <a
                              className="more-email-attacment"
                              style={{
                                display:
                                  row?.documentList?.length - 3 > 0 &&
                                  !moreEmails
                                    ? 'block'
                                    : 'none'
                              }}
                              onClick={showMoreEmails}
                            >
                              +{row?.documentList?.length - 3}
                            </a>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className="send-date-cell">
                            {row.createdDate}
                          </div>
                        </TableCell>
                        <TableCell align="left">
                          <div className="send-time-cell">
                            {row.createdTime}
                          </div>
                        </TableCell>
                      </TableRow>
                    )})}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        </div>
      )}

      {/* mail-detail */}
      {
        _.map(emailChain, (oneEmailData, index) => {
          const isFrist = 0 === index
          return <div
          className="mail-details"
          style={{ display: emailDetails ? 'block' : 'none' }}
        >
         { isFrist &&  <div className="mail-top">
          <BootstrapTooltip placement={`${isVPNA?"bottom":"top"}`} title="back">
            <ArrowBackIcon className="arrbackIcon" onClick={openEmailList}/>
           </BootstrapTooltip>
           <BootstrapTooltip placement={`${isVPNA?"bottom":"top"}`} title="Retrigger">
            <ContentPasteGoIcon onClick={openReSend} className="reSendIcon"/>
           </BootstrapTooltip>
          </div> }
          <div className="mail-body">
            <div className="send-email-peo">
              <div className="mail-tit">
                <div className="mail-tit-left">
                  <h4 className="mail-title">{oneEmailData.emailSubject}</h4>
                </div>
              </div>
              <div className="send-mail-peo">
                <div className="smp-left">
                  <div className="send-peo">
                    to:{' '}
                    {_.map(_.split(oneEmailData.toName, ','), (item) => {
                      if (!item) return null
                      return <>
                       <span className="sub01">
                      {item.split('@')[0]}
                    </span>
                    <span className="sub02">&lt; {item} &gt;</span>{';'}
                      </>
                    })}
                  </div>
                  <div className="send-peo">
                    cc:{' '}
                    {_.map(_.split(oneEmailData.ccName, ','), (item) => {
                      return <>
                       <span className="sub01">
                      {item.split('@')[0]}
                    </span>
                    <span className="sub02">&lt; {item} &gt;</span>{';'}
                      </>
                    })}
                  </div>
                </div>
                <div className="mail-tit-right">
                  <div className="mail-tit-time">
                    {oneEmailData.createdDate}, {oneEmailData.createdTime}
                  </div>
                </div>
              </div>
              <div className="attchment-send-mail">
                {oneEmailData?.documentList?.map((item) => (
                  <a
                    className="files-down-list"
                    style={{ display: item.fileName != null ? 'block' : 'none' }}
                    onClick={() => {
                      downloadFile(
                        item.masterTemplate.templateId,
                        customerId,
                        item.masterTemplate.templateName,
                        item.docStatusId,
                        item.fileName
                      );
                    }}
                  >
                    {item.fileName}
                  </a>
                ))}
              </div>
              <div className="send-mail-body">
                <div>{oneEmailData.emailBody}</div>
              </div>
            </div>
          </div>
        </div>
        })
      }

      {/* mail reSend */}
      <div
        className="mail-reSend"
        style={{ display: mailResend ? 'block' : 'none' }}
      >
        <div className="mail-reSend-tit">
          <TurnLeftIcon onClick={closeReSend} />
        </div>
        {isVPNA &&
          <VPNAEmail
            snackbarShowMessage={snackbarShowMessage}
            mode="resend" mailValues={email}
            setmodalopen={setmodalopen}
            customerdetails={customerdetails}
            setopenEmailDetails={setopenEmailDetails}
            isFromTrigger={true}
          />
        }
        {!isVPNA &&
          <Mail snackbarShowMessage={snackbarShowMessage} sectionSubmit={sectionSubmit} mode="resend" mailValues={email} />
        }
        </div>
    </div>
  );
}
