import { axiosInstanceEmailNonBff } from '../../../../api/Axios';


export function getEmailTemplateRequest(params) {
    return axiosInstanceEmailNonBff.request({
        method: 'GET',
        url: `/getEmailTemplate/${params}`
    });
}

export function sendEmailRequest(data) {


    return axiosInstanceEmailNonBff.request({
        method: 'POST',
        url: `/sendNCOBEmail`,
        data,
        // params:data
    });
}
export function sendVPNAEmailRequest(data) {
    return axiosInstanceEmailNonBff.request({
        method: 'POST',
        url: `/sendVPNAEmail`,
        data,
    });
}