import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomModal from '../../components/Modal/index';
import Customerlistdata from '../../../Helper/CustomerListHeader.json';
import CustomerList from '../../components/CustomerList/index';
import ChartsDashboard from '../../components/ChartsDashboard';
import CustomerTable from '../../components/CustomerList/CustomerTable';
import '../../containers/Home/styles/index.css';
import { Pagination } from '@mui/material';
import { updateAccounttype } from '../templateComponent/redux/accounttypeSlice';
import { updatePaginationPage } from './redux/slice';
import usePagination from './Pagination';
import { useDispatch, useSelector } from 'react-redux';
import { withSnackbar } from '../../components/SnackBar/SnackBar';
import  ListIcon from '@mui/icons-material/List';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import _ from 'lodash';
import { axiosInstanceDashboard } from '../../../api/Axios';
const Home = ({ snackbarShowMessage }) => {
  const dispatch = useDispatch();
  dispatch(updateAccounttype('CMU'));
  const [modalopen, setmodalopen] = useState(false);
  const [localenv, setlocalenv] = useState(false)
  const [viewType, setviewType] = useState('card')
  const modalhandler = () => {
    setmodalopen(true);
  };
  const list = useSelector((state) => state?.customerList?.data?.Customer);
  
  // const initPage = useSelector((state) => state?.paginationPage?.page);
  
// COT-8671:NCOB - UI - Adding pagination in dashboard page
  let [page, setPage] = useState(localStorage.getItem('pageNum')?parseInt(localStorage.getItem('pageNum')): 1);
  const PER_PAGE = 8;
  const [customerPageData,setCustomerPageData] = useState([]);
  const total = (customerPageData && Math.ceil(customerPageData?.totalNum / PER_PAGE)) || 0;
  const userRole = useSelector((state) => state?.userRole?.data?.userRole);
  useEffect(()=>{
      if(userRole === 'Sysco VPNA'){
        setviewType('card');
      }else{
        setviewType('list');
      }
  },[userRole]);
  useEffect(() => {
    fetchList();
}, [page]);
const fetchList = () => {
  try {
    axiosInstanceDashboard({
      method: 'get',
      url: `/getCustomerPageLists?pageSize=${PER_PAGE}&pageNum=${page}`,
    }).then((response) => {
      if (response.status === 200) {
        setCustomerPageData(response?.data?.data?.Customer ? response?.data?.data?.Customer : []);
      }
    })
  } catch (error) {
    snackbarShowMessage({
      message: error,
      type: 'error'
    })
  }
};
  // const count = list && Math.ceil(list?.length / PER_PAGE);
  // const sortList =
  //   list &&
  //   [...list].sort(
  //     (a, b) => new Date(b.modifiedDate) - new Date(a.modifiedDate)
  //   );
  // const _DATA = usePagination(sortList, PER_PAGE,initPage);
  useEffect(() => {
    dispatch(updateAccounttype('CMU'));
    setlocalenv(window.location.href.includes('localhost'))
  }, []);

  const handleChange = (e, p) => {
    // dispatch(updatePaginationPage({page:p}))
    // _DATA.jump(p);
    localStorage.setItem("pageNum",p);
    setPage(p);
    window.scrollTo(0, 0);
  };

  const changeView = (type) => {
    setviewType(type)
  }
  
  return (
    <div className="home" style={{ padding: '15px' }}>
      <div className="container">
        <div style={{display:'flex',justifyContent:'space-between'}}>
          <h1 className="Header_1">{viewType === 'list'?Customerlistdata.listViewTitle:Customerlistdata.title}</h1>
          <div >
            <LeaderboardIcon style={{fontSize:'xx-large'}} onClick={()=>{changeView('chart')}} className={viewType === 'chart'?'viewClickedStatus':''}></LeaderboardIcon>
            <ListIcon style={{margin:'0 40px',fontSize:'xx-large'}}  onClick={()=>{changeView('list')}}  className={viewType === 'list'?'viewClickedStatus':''}></ListIcon>
            <DashboardIcon style={{fontSize:'xx-large'}} onClick={()=>{changeView('card')}}  className={viewType === 'card'?'viewClickedStatus':''}> </DashboardIcon>
          </div>
        </div>
        
        {viewType === 'card' && (
          <div className="cardcontainer">
            <div className="cardLayout">
              <div className="firstcard" onClick={modalhandler}>
                <Card
                  className="default "
                  sx={{ boxShadow: '1px 1px 4px 0px #6a737b26' }}
                >
                  <AddCircleOutlineIcon className="add_circle_outline" />
                  <div className="createCustomer">Create New Customer</div>
                </Card>
              </div>
              {customerPageData?.customerList &&
               customerPageData?.customerList.map((customer, index) => {
                  return (
                    <CustomerList customer={customer} key={customer?.customerId} />
                  );
                })}
            </div>
            <Pagination
            style={{ marginTop: '16px' }}
            className="customPagination"
            count={total}
            size="large"
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{ padding: '20px', display: 'flex', justifyContent: 'center' }}
          />
          </div>
        )} 
         {viewType === 'chart' && (
          <ChartsDashboard />
        )}
        {viewType === 'list' && (
          <div className="listcontainer" > <CustomerTable snackbarShowMessage={snackbarShowMessage}/></div>
        )}
        
      </div>
      <CustomModal
        modalopen={modalopen}
        setmodalopen={setmodalopen}
        customerList={customerPageData?.customerList}
        snackbarShowMessage={snackbarShowMessage}
        manualNationalId={true}
      />
    </div>
  );
};
export default withSnackbar(Home);
