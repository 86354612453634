import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardContent, Button } from '@mui/material';

import { mapping } from '../../../Helper/mapping';
import './index.css';
import EnableSkip from '../EnableSkip';
import _ from 'lodash';
import { axiosInstanceDashboard } from '../../../api/Axios';
import { useParams } from 'react-router-dom';

const URLredirect = (props) => {
  const { acctype, sectionSubmit } = props;

  const currentstep = useSelector((state) => state?.storestep?.actionId);
  const { customerID } = useParams();
  const currobj = mapping[acctype]?.find((obj) => obj.actionId === currentstep);

  const [url, setUrl] = useState('https://sysco--staging.sandbox.lightning.force.com');
  const urltext = currobj?.URLtext;
  const urltextSecond = currobj?.urltextSecond;
  const hyperlinktext = currobj?.hyperlinktext;
  const hyperlinktextSecond = currobj?.hyperlinktextSecond;
  const isSkipActive = currobj?.enableSkip;
  useEffect(() => {
    if (currobj?.needComplition) {
      const complited = _.template(currobj?.hyperlinkURL);
      axiosInstanceDashboard
        .request({
          method: 'GET',
          url: `/getSalesForceTaskId?customerId=${customerID}&actionId=18`
        })
        .then((response) => {
          if (response) {
            if (_.get(response, 'data.data.SalesForceTask.salesforceTaskId')) {
              setUrl(complited({ [currobj?.complitionField]: _.get(response, 'data.data.SalesForceTask.salesforceTaskId') }))
            } else {
              setUrl('https://sysco--staging.sandbox.lightning.force.com')
            }
          }
        });
    }else{
      setUrl(currobj?.hyperlinkURL)
    }
  }, [currentstep, currobj?.complitionField, currobj?.hyperlinkURL, currobj?.needComplition, customerID])
  const clickHandler = (type) => {
    if(type == 'second' && currobj?.hyperlinkSecondURL){
      window.open(currobj?.hyperlinkSecondURL, '_blank');
    }else{
      window.open(url, '_blank');
    }
  };
  const handleSubmit = () => {
    sectionSubmit();
  };
  return (
    <div>
      <Card>
        <CardContent>
          {urltext}
          <span
            className="highlight"
            onClick={() => {
              clickHandler('first');
            }}
          >
            {' '}
            {hyperlinktext}
          </span>
        </CardContent>
        <CardContent>
          {urltextSecond}
          <span
            className="highlight"
            onClick={() => {
              clickHandler('second');
            }}
          >
            {' '}
            {hyperlinktextSecond}
          </span>
        </CardContent>
        <div className="button-wrapper">
          <EnableSkip
            isSkipActive={isSkipActive}
            sectionSubmit={sectionSubmit}
          />
          <Button
            sx={{ marginTop: 1 }}
            id="secondary"
            className="markbutton"
            data-testid="submit-btn"
            onClick={handleSubmit}
            // disabled={disableSubmit}
          >
            Mark this step as complete
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default URLredirect;
