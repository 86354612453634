import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined';
import AssistantIcon from '@mui/icons-material/Assistant';
import '../Sidebar/Sidebar.css';

export const SidebarData = [
  {
    title: 'Dashboard',
    icon: <DashboardIcon sx={{ fontSize: 32 }} className="dashboard-icon" />,
    link: '/'
  },
  {
    title: 'Admin Console',
    checkIsAdmin: true,
    icon: (
      <ManageAccountsIcon sx={{ fontSize: 32 }} className="ManageAccounts" />
    ),

    link: '/admin-dashboard'
  },
  {
    title: 'Contact Support',
    isCustomerPortal: true,
    icon: <ContactSupportIcon sx={{ fontSize: 32 }} className="support-icon" />,
    link: `/contact-support/${localStorage.getItem('customerPortalUser')}`
  },
  {
    title: 'Offline Chat',
    isCustomerPortal: true,
    icon: <InsertCommentOutlinedIcon sx={{ fontSize: 32 }} className="support-icon" />,
    link: `/chat/${localStorage.getItem('customerPortalUser')}`
  },
  {
    title: 'Help & Training',
    icon: (
      <AssistantIcon
        sx={{ fontSize: 32 }}
        className="support-icon"
      />
    ),
    link: '/help-desk'
  },
];
