import React, { useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

export const withSnackbar = WrappedComponent => {
  return props => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [duration, setDuration] = useState(2000);
    const [severity, setSeverity] =
      useState('success'); /** error | warning | info */

    function showMessage ({ message, type, duration = 3000 }) {
      setMessage(message);
      setSeverity(type);
      setDuration(duration);
      setOpen(true);
    };

    function handleClose (event, reason)  {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    return (
      <>
        <WrappedComponent {...props} snackbarShowMessage={showMessage} />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          autoHideDuration={duration}
          open={open}
          onClose={handleClose}
        >
          <Alert variant="filled" onClose={handleClose} severity={severity}>
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  };
};
