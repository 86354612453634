import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { axiosInstanceEmailadmin } from '../../../../api/Axios';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import usePagination from '../../../containers/Home/Pagination';
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// import MatPaginationTable from '../MatPaginationTable'
export function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = () => [
  {
    id: 'templateName',
    numeric: false,
    disablePadding: true,
    label: 'Action Name'
  },
  {
    id: 'toName',
    numeric: true,
    disablePadding: false,
    label: 'toName'
  },
  {
    id: 'ccName',
    numeric: true,
    disablePadding: false,
    label: 'ccName'
  },
  {
    id: 'subject',
    numeric: true,
    disablePadding: false,
    label: 'Email Subject'
  },
  {
    id: 'body',
    numeric: true,
    disablePadding: false,
    label: 'Email Body'
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions'
  }
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells()?.map((headCell) => (
          <TableCell
            key={headCell.id}
            //align={headCell.numeric ? 'left' : 'left'}
            align="left"
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={KeyboardArrowDownIcon}
              size="large"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EmailAdminDashboard({ searchList, updateHandler, filterList }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('templateName');
  const [selected, setSelected] = useState([]);
  const [editable, seteditable] = useState(false);
  const [value, setValue] = useState(false);

  useEffect(() => {
    handleChange(null, 1);
  }, [searchList]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = searchList?.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const PER_PAGE = 10;

  let [paginationPage, setPaginationPage] = useState(1);
  const count = searchList && Math.ceil(searchList?.length / PER_PAGE);
  const _DATA = usePagination(
    stableSort(searchList, getComparator(order, orderBy)),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPaginationPage(p);
    _DATA.jump(p);
  };

  const editCell = (val) => {
    seteditable(true);
    setValue(val);
    const selectedVal = _DATA
      .currentData()
      .find((ele) => ele.emailTemplateId === val);
    setFormData({
      emailTemplateId: val,
      toName: selectedVal?.toName,
      ccName: selectedVal?.ccName,
      subject: selectedVal?.subject || selectedVal?.emailSubject,
      body: selectedVal?.body || selectedVal?.emailBody
    });
  };
  const [formData, setFormData] = useState({});
  const updateEmail = (params) => {
    axiosInstanceEmailadmin
      .request({
        method: 'POST',
        url: `/updateEmailTemplate`,
        data: params
      })
      .then((response) => {
        if (response.status === 200) updateHandler(params);
      });
  };
  const updateFormData = (event) =>
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });

  const submitHandler = (val) => {
    seteditable(false);
    setValue('');
    updateEmail(formData);
  };
  return (
    <Box sx={{ width: '100%', mb: '50px' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            className="admin-table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={searchList?.length}
              value={''}
              searchList={searchList}
              filterList={filterList}
            />
            <TableBody>
              {_DATA &&
                _DATA.currentData()?.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow key={row.emailTemplateId}>
                      <TableCell component="th" id={labelId} scope="row">
                        {row.templateName}
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        {editable && value === row.emailTemplateId ? (
                          <input
                            onChange={(e) => updateFormData(e)}
                            placeholder="toName"
                            type="email"
                            name="toName"
                            required
                            defaultValue={row.toName}
                          />
                        ) : (
                          row.toName
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        {editable && value === row.emailTemplateId ? (
                          <input
                            onChange={(e) => updateFormData(e)}
                            placeholder="ccName"
                            type="email"
                            name="ccName"
                            required
                            defaultValue={row.ccName}
                          />
                        ) : (
                          row.ccName
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        {editable && value === row.emailTemplateId ? (
                          <input
                            onChange={(e) => updateFormData(e)}
                            placeholder="Email Subject"
                            type="text"
                            name="subject"
                            required
                            defaultValue={row.emailSubject || row.subject}
                          />
                        ) : (
                          row.emailSubject || row.subject
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {' '}
                        {editable && value === row.emailTemplateId ? (
                          <input
                            onChange={(e) => updateFormData(e)}
                            placeholder="Email Body"
                            type="text"
                            name="body"
                            required
                            defaultValue={row.emailBody || row.body}
                          />
                        ) : (
                          row.emailBody || row.body
                        )}
                      </TableCell>
                      <TableCell align="center" scope="row">
                        {editable && value === row.emailTemplateId ? (
                          <Button
                            variant="contained"
                            aria-label="submitButton"
                            color="info"
                            onClick={() => submitHandler(row.emailTemplateId)}
                            data-testid="submit-btn"
                          >
                            Submit
                          </Button>
                        ) : (
                          <>
                            <Button aria-label="editButton">
                              <EditOutlinedIcon
                                aria-label="editButton"
                                onClick={() => editCell(row.emailTemplateId)}
                                sx={{ '&:hover': { transition: '0.7s' } }}
                              />
                            </Button>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {searchList?.length === 0 && (
            <p style={{ display: 'flex', justifyContent: 'center' }}>
              No Results Found
            </p>
          )}
        </TableContainer>
        <Stack spacing={2}>
          <Pagination
            className="customPagination"
            count={count}
            size="large"
            page={paginationPage}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{ padding: '20px', display: 'flex', justifyContent: 'center' }}
          />
        </Stack>
      </Paper>
    </Box>
  );
}

export default EmailAdminDashboard;
