import { axiosInstanceDashboard } from '../api/Axios';

export const createServiceAPI = (values) => {
  
  return axiosInstanceDashboard.request({
    method: 'POST',
    url: `createServiceRequest`,
    data: values
  });
};
