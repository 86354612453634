import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllActions } from '../SalesDashboard/redux/slice';
import Dashboard from '../Location_Concept_Dashboard/index';

import { useNavigate, useParams } from 'react-router-dom';
import { getCustomerProgress } from '../../components/ProgressBar/redux/slice';
import ConfettiPage from '../../components/ConfettiPage';
import { axiosInstanceDashboard } from '../../../api/Axios';
import _ from 'lodash';
const Index = () => {
  const dispatch = useDispatch();
  const { parentCustID, customerID } = useParams();
  const [list, setlist] = useState({});
  const [confettipage, setconfettipage] = useState(false);
  const [clickedflag, setclickedflag] = useState(false);
  const navigate = useNavigate()
  const fetchlist = () => {
    axiosInstanceDashboard

      .request({
        method: 'GET',

        url: `/getCustomerDetails?id=${customerID ? customerID : parentCustID}`
      })

      .then((response) => {
        if (response) {
          if (response.data) {
            setlist(_.get(response, 'data.data.Customer', {}));
          }
        }
      });
  };
  useEffect(() => {
    // console.log('customerID',customerID)
    // console.log('parentCustID',parentCustID)
    if((!customerID || customerID == 'undefined') && (!parentCustID || parentCustID == 'undefined') ){
      navigate(`/PageNotFound`);
    }
    fetchlist();
  }, [parentCustID, customerID]);

  useEffect(() => {
    const storeCustomerId = localStorage.getItem('customerPortalUser');
    if (!customerID && parentCustID !== storeCustomerId) {
      navigate(`/Dashboard/${storeCustomerId}`);
      setTimeout(() => {
        window.location.reload()
      }, 0);
    }
  }, [parentCustID, customerID, navigate])

  useEffect(() => {
    dispatch(getAllActions(list.accountType));
    dispatch(
      getCustomerProgress({
        customerId: customerID ? customerID : parentCustID
      })
    );
  }, [dispatch, list.accountType, customerID, parentCustID]);

  return (
    <>
      {confettipage ? (
        <ConfettiPage
          setclickedflag={setclickedflag}
          customerpersona={true}
          setconfettipage={setconfettipage}
        />
      ) : (
        <Dashboard
          customerportal={true}
          cust_acctype={list.accountType}
          clickedflag={clickedflag}
          confettipage={confettipage}
          setconfettipage={setconfettipage}
        />
      )}
    </>
  );
};

export default Index;
