import React, { useState, useEffect } from 'react';
import './index.css';
import { TextField, Button, Typography, Stack } from '@mui/material';
import Grid from '@mui/material/Grid';
import { withSnackbar } from '../../components/SnackBar/SnackBar';
import { useParams } from 'react-router-dom';
import { updatecustomerdetailsAPI } from '../../../utils/updatecustomerdetailsAPI';
import { axiosInstanceDashboard } from '../../../api/Axios';
import { NumericFormat } from 'react-number-format';
import BN from 'bignumber.js';
import _ from 'lodash';

const CustomerDetails = (props) => {
  const { snackbarShowMessage, sectionSubmit, detailsFilled, closeHandler,customerdetails } =
    props;

  const custObj = {
    customerRequest: {},
    customerAddressRequests: [],
    contactRequests: []
  };

  const fieldRules = {
    totalOpSite: [
      {
        required: true,
        message: 'Please enter number of Opsites'
      }
    ],
    totalNumberOfLocation: [
      {
        required: true,
        message: 'Please Enter Number of Location'
      }
    ],
    propItems: [
      {
        required: true,
        message: 'Please Enter Number of Prop items'
      }
    ],
    salesVolume: [
      {
        required: true,
        message: 'Please Enter yearly sales'
      },
      {
        validator: (value) => {
          const MAX_LIMIT = 16;
          const [interNumber] = `${value}`.split('.');
          return interNumber.length > MAX_LIMIT;
        },
        message: 'Invalid Value'
      }
    ],
    firstName: [
      {
        required: true,
        message: 'Please Enter Name'
      }
    ],
    contactNo: [
      {
        required: true,
        message: 'Please enter Phone number'
      },
      {
        pattern: /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/,
        message: 'Phone number is not Correct'
      }
    ],
    email: [
      {
        required: true,
        message: 'Please enter Email'
      },
      {
        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i,
        message: 'Email ID is not Correct'
      }
    ]
  };
  const [formData, setFormData] = useState({
    website: '',
    totalOpSite: '',
    totalNumberOfLocation: '',
    propItems: '',
    salesVolume: '',
    firstName: '',
    email: '',
    contactNo: ''
  });
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const fieldsValidation = (name, value) => {
    const newErrors = { ...errors };
    if (!fieldRules[name]) {
      return errors;
    }
    const rules = fieldRules[name];
    newErrors[name] = '';
    _.each(rules, (rule) => {
      if (rule.required && (value === '' || undefined === value)) {
        newErrors[name] = rule.message;
      }
      if (value !== '' && rule.pattern && !rule.pattern.test(value)) {
        newErrors[name] = rule.message;
      }
      if (rule.validator && rule.validator(value)) {
        newErrors[name] = rule.message;
      }
    });
    setErrors(newErrors);
    return newErrors;
  };
  const allFieldsValidation = (values) => {
    let errors = {};
    _.forIn(values, (value, name) => {
      errors = { ...errors, ...fieldsValidation(name, value) };
    });
    return errors;
  };
  const handleKeyDown = (event) => {
    if (event.key === 'e') {
      event.preventDefault();
    }
  };

  const handleKeyDownPositive = (event) => {
    if (event.key === 'e' || event.key === '.' || event.key === '-') {
      event.preventDefault();
    }
  };

  const { customerID } = useParams();

  async function submitHandler(event) {
    event.preventDefault();
    setSubmitted(true);
    const contactKeys = ['firstName', 'email', 'contactNo'];
    const contactParams = _.pick(formData, contactKeys);
    const customerRequest = _.omit(formData, contactKeys);
    custObj.customerRequest = customerRequest;
    if(customerDetails){ //COT-9015:NCOB - Disable "Create National ID" ---should save national id and concept id when update customer detail in customer detail action
      custObj.customerRequest['nationalId'] = customerDetails?.nationalId;
      custObj.customerRequest['conceptId'] = customerDetails?.conceptId;
      custObj.customerRequest['manuallyCreateNationalId'] = customerDetails?.manuallyCreateNationalId;
    }
    custObj.contactRequests = [
      { ...contactParams, contactType: 'Sysco Sales Contact', contactId: 0 }
    ];

    custObj.contactRequests[0].contactNo = custObj.contactRequests[0].contactNo
      ?.replace('(', '')
      ?.replace(')', '-');
    const submitValidation = allFieldsValidation(formData);
    setErrors(submitValidation);
    if (
      _.isEmpty(submitValidation) ||
      _.every(submitValidation, (item) => !item)
    ) {
      try {
        const res = await updatecustomerdetailsAPI(custObj, customerID);
        if (res?.status === 200) {
          if (res?.data?.errorCode || res?.data?.errorMessage) {
            snackbarShowMessage({
              message: `Unable to update the details. Please try again later`,
              type: 'error'
            });
            return false;
          }
          snackbarShowMessage({
            message: `Details Updated for `,
            type: 'success'
          });
          sectionSubmit();
        }
      } catch (err) {
        snackbarShowMessage({
          message: `Something went wrong. Please try again later`,
          type: 'error'
        });
      }
    }
  }

  const [customerDetails, setCustomerDetails] = useState({});
  const fetchlist = () => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getCustomerDetails?id=${customerID}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setCustomerDetails(_.get(response, 'data.data.Customer', {}));
          }
        }
      });
  };

  useEffect(() => {
    fetchlist();
  }, []);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  function changeHandlers(event) {
    const { name, value } = event.target;
    fieldsValidation(name, value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  }
  const [toucheds, setToucheds] = useState({
    totalOpSite: false,
    totalNumberOfLocation: false,
    propItems: false,
    salesVolume: false,
    firstName: false,
    email: false,
    contactNo: false
  })
  const handleBlur = (event) => {
    const { name, value } = event.target;
    setToucheds((prevState) => ({ ...prevState, [name]: true }));
    let newValue = value;
    if (name === 'salesVolume' && value !== '' && value !== undefined) {
      newValue = value.split(',').join('')
    }
    fieldsValidation(name, newValue);
  }
  const commonEditProps = {
    disabled: detailsFilled,
    InputProps: {
      style: {
        fontFamily: 'MyriadPro',
        height: '30px',
        fontSize: '14px'
      }
    },
    className: 'number-field',
    size: 'small',
    onBlur: handleBlur

  };
  const comonEditLabel = {
    className: 'input-text',
    style: {
      fontWeight: 'bold',
      fontFamily: 'MyriadPro',
      fontSize: '14px'
    },
  };
  const getCommonEditPropsByField = (field) => {
    const specailOnchange = ['salesVolume'];
    const noErrorFields = ['website'];
    const showErrorExtraCondition = toucheds[field] || submitted;
    const restProps = {
      value: formData[field],
      name: field
    };

    if (_.includes(specailOnchange, field)) {
      restProps.onValueChange = ({ value }) => {
        changeHandlers({ target: { name: 'salesVolume', value } });
      }
    } else {
      restProps.onChange = changeHandlers
    }

    if (!_.includes(noErrorFields, field)) {
      restProps.helperText = showErrorExtraCondition ? errors[field] : '';
      restProps.error = showErrorExtraCondition && !!errors[field];
    }
    return {...commonEditProps, ...restProps}
  }
  const commonDetailLabelProps = {
    className: 'input-text',
    style: {
      fontWeight: 'none',
      color: '#6A737B',
      fontFamily: 'MyriadPro',
      fontSize: '14px'
    }
  };
  const commonDetailStyle = {
    fontWeight: 'bold',
    fontFamily: 'MyriadPro',
    fontSize: '14px'
  };
  const yealyValueTextFieldProps = {
    inputProps: { 'data-testid': 'yearly-values' },
    className: 'number-field',
    onKeyDown: handleKeyDown,
    ...getCommonEditPropsByField('salesVolume')
  };
  const syscoSalesContact = 'Sysco Sales Contact';
  const syscoSalesContactTarget = (
    customerDetails.customerContactResponses || []
  ).find((item) => item.contactType === syscoSalesContact);

  return (
    <>
      <div className="customer-container">
        <h2>Customer Details</h2>
        {detailsFilled ? (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {' '}
                <Typography {...commonDetailLabelProps}>Website</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography {...commonDetailLabelProps}>
                  Number of Opsites
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {' '}
                <Typography {...commonDetailLabelProps}>
                  Number of Locations
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                {' '}
                <label
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'MyriadPro',

                    fontSize: '14px'
                  }}
                >
                  {detailsFilled ? customerDetails.website : formData.website}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'MyriadPro',

                    fontSize: '14px'
                  }}
                >
                  {detailsFilled
                    ? customerDetails.totalOpSite
                    : formData.totalOpSite}
                </label>
              </Grid>
              <Grid item xs={4}>
                {' '}
                <label
                  style={{
                    fontWeight: 'bold',
                    fontFamily: 'MyriadPro',
                    fontSize: '14px'
                  }}
                >
                  {detailsFilled
                    ? customerDetails.totalNumberOfLocation
                    : formData.totalNumberOfLocation}
                </label>
              </Grid>
            </Grid>
            <br></br>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography {...commonDetailLabelProps}>
                  Number of prop items
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography {...commonDetailLabelProps}>
                  Estimated yearly values
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <label style={commonDetailStyle}>
                  {customerDetails.propItems}
                </label>
              </Grid>
              <Grid item xs={4}>
                <label style={commonDetailStyle}>
                  {BN(customerDetails.salesVolume).toFormat(2)}
                </label>
              </Grid>
            </Grid>
            <br></br>

            <h2>Contact Information</h2>
            <p>Sysco Sales Contact</p>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography {...commonDetailLabelProps}>Name</Typography>
              </Grid>
              <Grid item xs={4}>
                {' '}
                <Typography {...commonDetailLabelProps}>Email</Typography>
              </Grid>
              <Grid item xs={4}>
                {' '}
                <Typography {...commonDetailLabelProps}>
                  Phone Number
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <label style={commonDetailStyle}>
                  {syscoSalesContactTarget?.firstName}
                </label>
              </Grid>
              <Grid item xs={4}>
                {' '}
                <label style={commonDetailStyle}>
                  {syscoSalesContactTarget?.email}
                </label>
              </Grid>
              <Grid item xs={4}>
                {' '}
                <label style={commonDetailStyle}>
                  {syscoSalesContactTarget?.contactNo}
                </label>
              </Grid>
            </Grid>
          </div>
        ) : (
          <form onSubmit={submitHandler}>
            <Stack direction="row" spacing={4} my={1}>
              <Stack direction="column" spacing={1}>
                <Typography {...comonEditLabel}>Website</Typography>
                <TextField
                  placeholder="Enter Website URL"
                  {...getCommonEditPropsByField('website')}
                />
              </Stack>
              <Stack direction="column" spacing={1}>
                <Typography {...comonEditLabel}>Number of Opsites</Typography>
                <TextField
                  placeholder="Enter number of Opsites"
                  inputProps={{ 'data-testid': 'no-of-opsites' }}
                  onKeyDown={handleKeyDownPositive}
                  type="number"
                  {...getCommonEditPropsByField('totalOpSite')}
                />
              </Stack>
              <Stack direction="column" spacing={1}>
                <Typography {...comonEditLabel}>Number of Locations</Typography>
                <TextField
                  placeholder="Enter number of Locations"
                  inputProps={{ 'data-testid': 'no-of-locations' }}
                  onKeyDown={handleKeyDownPositive}
                  type="number"
                  {...getCommonEditPropsByField('totalNumberOfLocation')}
                />
              </Stack>
            </Stack>

            <Stack direction="row" spacing={4} my={3}>
              <Stack direction="column" spacing={1}>
                <Typography {...comonEditLabel}>
                  Number of prop items
                </Typography>
                <TextField
                  placeholder="Enter number of prop items"
                  inputProps={{ 'data-testid': 'no-of-props' }}
                  onKeyDown={handleKeyDownPositive}
                  type="number"
                  {...getCommonEditPropsByField('propItems')}
                />
              </Stack>
              <Stack direction="column" spacing={1}>
                <Typography {...comonEditLabel}>
                  Estimated yearly values
                </Typography>
                <NumericFormat
                  allowLeadingZeros
                  thousandSeparator=","
                  decimalScale={2}
                  customInput={TextField}
                  {...yealyValueTextFieldProps}
                  placeholder="Enter estimated yearly sales"
                />
              </Stack>
            </Stack>
            <h2>Contact Information</h2>
            <p>Sysco Sales Contact</p>
            <Stack direction="row" spacing={4} my={1}>
              <Stack direction="column" spacing={1}>
                <Typography {...comonEditLabel}>Name</Typography>
                <TextField
                  placeholder="Enter Name"
                  inputProps={{ 'data-testid': 'firstNames' }}
                  {...getCommonEditPropsByField('firstName')}
                />
              </Stack>
              <Stack direction="column" spacing={1}>
                <Typography {...comonEditLabel}>Email</Typography>
                <TextField
                  placeholder="Enter Email"
                  {...getCommonEditPropsByField('email')}
                />
              </Stack>
              <Stack direction="column" spacing={1}>
                <Typography {...comonEditLabel}>Phone Number</Typography>
                <TextField
                  placeholder="Format in (xxx)xxx-xxxx/xxx-xxx-xxxx"
                  {...getCommonEditPropsByField('contactNo')}
                />
              </Stack>
            </Stack>

            <hr className="faded-line" />

            {!detailsFilled && (
              <>
                <Button
                  style={{
                    float: 'right',
                    marginRight: '3vw',
                    borderRadius: '10px',
                    fontFamily: 'MyriadPro'
                  }}
                  variant="contained"
                  type="submit"
                  data-testid="submit-btn"
                >
                  Submit
                </Button>

                <Button
                  style={{
                    float: 'right',
                    marginRight: '1vw',
                    borderRadius: '10px',
                    fontFamily: 'MyriadPro'
                  }}
                  variant="outlined"
                  onClick={closeHandler}
                >
                  Cancel
                </Button>
              </>
            )}
          </form>
        )}
      </div>
    </>
  );
};

export default withSnackbar(CustomerDetails);
