import { axiosInstanceDashboard } from '../api/Axios';

export const createCustomerAPI = (values, cusid, acctype) => {
  const addressResponses = [];
  const shipping = {
    customerAddressId: values.shippingCustID ? values.shippingCustID : 0,
    addressType: 'SHIPPING_ADDRESS',
    firstName: values.shippingFirstName,
    lastName: values.shippingLastName,
    address: values.shippingAddress,
    state: values.shippingState,
    zipCode: values.shippingZipcode
  };
  const billing = {
    customerAddressId: values.billingCustID ? values.billingCustID : 0,
    addressType: 'BILLING_ADDRESS',
    firstName: values.toggleAddress
      ? values.shippingFirstName
      : values.billingFirstName,
    lastName: values.toggleAddress
      ? values.shippingLastName
      : values.billingLastName,
    address: values.toggleAddress
      ? values.shippingAddress
      : values.billingAddress,
    state: values.toggleAddress ? values.shippingState : values.billingState,
    zipCode: values.toggleAddress
      ? values.shippingZipcode
      : values.billingZipcode
  };
  addressResponses.push(shipping, billing);

  const formatValues = {
    customerId: values.customerId ? values.customerId : 0,
    parentCustomerId:
      acctype === 'LOCATION' || acctype === 'CONCEPT' ? cusid.parentCustID : 0,
    customerAddressRequests: addressResponses,
    accountType: values.AccountRecordType,
    accountName: values.accountName,
    channel: values.channel,
    contactNumber: values?.contact?.replace('(', '')?.replace(')', '-'),
    goLiveDate: values.liveDate !== 'Invalid date' ? values.liveDate : '',
    segment: values.segment,
    accountOwner: values.accountOwner ? values.accountOwner : '',
    customerName: values.name,
    email: values.email,
    nationalId: values.nationalId,
    modelType: '',
    startDate: '',
    deadLine: '',
    totalOpSite: '',
    totalNumberOfLocation: '',
    opcoSite: values.opcoSite ? values.opcoSite: '',
    propItems: '',
    caseVolume: '',
    salesVolume: '',
    currentDistribution: '',
    currentSyscoCustomer: '',
    gpoAffiliation: 'N',
    name: values.name ? values.name : '',
    creditAnalyst: '',
    dpmAnalyst: '',
    totalCompletedSection: '',
    conceptId: values.conceptId,
    conceptName: values.conceptName,
    customerFirstName: values.customerFirstName,
    customerLastName: values.customerLastName,
    customerTitle: values.customerTitle,
    contactRequests: [],
    parentAccountType: values.parentAccountType,
    manuallyCreateNationalId: values?.manuallyCreateNationalId,
  };

  return axiosInstanceDashboard.request({
    method: 'POST',
    url: '/createCustomer',
    data: formatValues
  });
};
function valuesolver(param1, alternatevalue) {
  if (param1) return param1;
  else return alternatevalue;
}
function valuesolver1(param1, value1, value2, value3) {
  if (param1) return value1;
  else if (value2) return value2;
  else return value3;
}
export const updateCustomerAPI = (values) => {
  const addressResponses = [];
  const shipping = {
    customerAddressId: valuesolver(values.shippingCustID, 0),
    addressType: 'SHIPPING_ADDRESS',
    firstName: valuesolver(values.shippingFirstName, ' '),
    lastName: valuesolver(values.shippingLastName, ' '),
    address: valuesolver(values.shippingAddress, ' '),
    state: valuesolver(values.shippingState, ' '),
    zipCode: valuesolver(values.shippingZipcode, '00000')
  };
  const {lead, leadName, leadEmail, leadContcatNo, partner,partnerName, partnerEmail, partnerContcatNo } =  values
  const contactRequests = [
    {contactId: 0, ...(lead || {}),contactType: 'Onboarding Lead', firstName: leadName, email: leadEmail, contactNo: leadContcatNo },
    {contactId: 0, ...(partner || {}),contactType: 'Onboarding Partner', firstName: partnerName, email: partnerEmail, contactNo: partnerContcatNo },

  ]
  const billing = {
    customerAddressId: valuesolver(values.billingCustID, 0),
    addressType: 'BILLING_ADDRESS',
    firstName: valuesolver1(
      values.toggleAddress,
      values.shippingFirstName,
      values.billingFirstName,
      ' '
    ),
    lastName: valuesolver1(
      values.toggleAddress,
      values.shippingLastName,
      values.billingLastName,
      ' '
    ),
    address: valuesolver1(
      values.toggleAddress,
      values.shippingAddress,
      values.billingAddress,
      ' '
    ),
    state: valuesolver1(
      values.toggleAddress,
      values.shippingState,
      values.billingState,
      ' '
    ),
    zipCode: valuesolver1(
      values.toggleAddress,
      values.shippingZipcode,
      values.billingZipcode,
      '00000'
    )
  };
  addressResponses.push(shipping, billing);
  const formatValues = {
    // "customerId" : values.customerId ? values.customerId : 0,
    // "parentCustomerId": 0,
    // "accountType":values.AccountRecordType,
    accountName: values.accountName,
    channel: values.channel,
    contactNumber: values?.contact?.replace('(', '')?.replace(')', '-'),
    goLiveDate: values.liveDate !== 'Invalid date' ? values.liveDate : '',
    segment: values.segment,
    accountOwner: values.accountOwner ? values.accountOwner : '',
    customerName: values.name,
    email: values.email,
    nationalId: values.nationalId,
    modelType: '',
    startDate: '',
    deadLine: '',
    totalOpSite: '',
    totalNumberOfLocation: '',
    opcoSite: values.opcoSite ? values.opcoSite : '',
    propItems: '',
    caseVolume: '',
    salesVolume: '',
    currentDistribution: '',
    currentSyscoCustomer: '',
    gpoAffiliation: 'N',
    name: values.name,
    creditAnalyst: '',
    dpmAnalyst: '',
    totalCompletedSection: '',
    conceptId: values.conceptId,
    conceptName: values.conceptName,
    customerFirstName: values.customerFirstName,
    customerLastName: values.customerLastName,
    customerTitle: values.customerTitle,
    parentAccountType: values.parentAccountType,
    manuallyCreateNationalId: values?.manuallyCreateNationalId,//COT-9015: Disable "Create National ID"--- set manuallyCreateNationalId is true, if national id and concept id are not empty
  };
  return axiosInstanceDashboard.request({
    method: 'PUT',
    url: `/updateDetails?customerId=${values.customerId}`,
    data: {
      customerRequest: formatValues,
      customerAddressRequests: addressResponses,
      contactRequests,
    }
  });
};
