import { Box, Button } from '@mui/material';
import FormBuilder from '../FormBuilder';
import TableBuilder from '../TableBuilder';
import * as Yup from 'yup';
import { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import ExcellentExport from 'excellentexport';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  axiosInstanceDashboard,
  axiosInstanceDocument
} from '../../../api/Axios';
import LoginIcon from '@mui/icons-material/Login';
import { withSnackbar } from '../../components/SnackBar/SnackBar';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
const validationSchema = Yup.object().shape({
  siteNumber: Yup.string().required('DC Number is Required'),
  siteName: Yup.string().required('DC Name is Required'),
  accountManagerName: Yup.string().required('Account Manager Name is Required'),
  accountManagerEmail: Yup.string()
    .required('Account Manager Email is Required')
    .email(),
  accountManagerPhone: Yup.string().matches(
    /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/,
    {
      message: 'Invalid Account Manager Phone',
      excludeEmptyString: false
    }
  ),
  managerOfCustomerExperiencePhone: Yup.string().matches(
    /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/,
    {
      message: 'Invalid Manager of Customer Experience Phone',
      excludeEmptyString: false
    }
  )
});

const AccountOwnerAlignment = ({
  status = 'Awaiting',
  snackbarShowMessage,
  sectionSubmit
}) => {
  const [options, setOptions] = useState([]);
  const configs = [
    {
      label: 'DC Number',
      name: 'siteNumber',
      type: 'select',
      required: true,
      options,
      onChange: (e, setFieldValue) => {
        const { value } = e.target;
        const target = _.find(options, { value });
        setFieldValue('siteNumber', value);
        setFieldValue('siteName', _.get(target, 'dcName'));
      }
    },
    {
      label: 'DC Name',
      name: 'siteName',
      disabled: true
    },
    {
      label: 'AM Territory',
      name: 'amTerritory'
    },
    {
      label: 'Account Manager Name',
      name: 'accountManagerName',
      required: true
    },
    {
      label: 'Account Manager Email',
      required: true,
      name: 'accountManagerEmail'
    },
    {
      label: 'Account Manager Phone',
      name: 'accountManagerPhone'
    },
    {
      label: 'Manager of Customer Experience',
      name: 'managerOfCustomerExperience'
    },
    {
      label: 'Manager of Customer Experience Phone',
      name: 'managerOfCustomerExperiencePhone'
    }
  ];
  const { customerID } = useParams();
  const actionId = useSelector((state) => {
    return state?.storeActiveStep?.actionId;
  });
  const fetchDataSource = () => {
    axiosInstanceDashboard
      .get(`/getAcOwnerAlignment/${customerID}`)
      .then((res) => {
        setDataSource(_.get(res, 'data.data.accountOwnerAlignment'));
      });
  };

  const fetchOptions = () => {
    axiosInstanceDocument
      .get(`/getCustomerOpcoDetails/${customerID}`)
      .then((res) => {
        setOptions(
          _.map(_.get(res, 'data.data.OpcoDetails'), (item) => {
            return {
              ...item,
              label: item.dcNumber,
              value: item.dcNumber
            };
          })
        );
      });
  };

  const initialValues = {
    siteNumber: '',
    siteName: '',
    amTerritory: '',
    accountManagerName: '',
    accountManagerEmail: '',
    accountManagerPhone: '',
    managerOfCustomerExperience: '',
    managerOfCustomerExperiencePhone: ''
  };
  const [values, setInitialValues] = useState(_.cloneDeep(initialValues));

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const payload = {
      ...values
    };
    if (!values.accountOwnerAlignmentId) {
      payload.accountOwnerAlignmentId = 0;
      payload.customerId = Number(customerID);
      payload.actionId = Number(actionId);
    }
    const message = `${
      !values.accountOwnerAlignmentId ? 'Created' : 'Updated'
    } Successfully`;

    axiosInstanceDashboard
      .post('/createUpdateAccountOwnerAlignment', payload)
      .then((res) => {
        if (_.get(res, 'data.hasError')) {
          snackbarShowMessage({
            message: _.get(res, 'data.errorMessage'),
            type: 'error'
          });
        } else {
          snackbarShowMessage({ message });
          fetchDataSource();
          resetForm();
          setInitialValues(_.cloneDeep(initialValues));
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const handleEdit = (row) => {
    setInitialValues({ ...row });
  };
  const handleDelete = ({ accountOwnerAlignmentId }) => {
    axiosInstanceDashboard
      .delete(`/deleteAcOwnerAlignment`, {
        params: {
          acOwnerAlignmentId: accountOwnerAlignmentId,
          customerId: customerID
        }
      })
      .then((res) => {
        snackbarShowMessage({ message: 'Deleted Successfully' });
        fetchDataSource();
      });
  };

  const columns = [
    ..._.map(configs, ({ name, label }) => ({ name, title: label })),
    {
      name: 'action',
      title: 'Action',
      render: (value, row) => {
        return (
          <Box display="flex" justifyContent="space-between">
            <EditIcon onClick={handleEdit.bind(null, row)} />
            <DeleteIcon onClick={handleDelete.bind(null, row)} />
          </Box>
        );
      }
    }
  ];
  const [dataSource, setDataSource] = useState([]);
  const tableRef = useRef(null);

  const exportExcel = () => {
    if (tableRef.current) {
      ExcellentExport.convert(
        {
          openAsDownload: true,
          filename: 'Account Owner Alignment',
          format: 'xlsx'
        },
        [
          {
            from: { table: tableRef.current },
            name: 'Sheet number 1',
            removeColumns: [8]
          }
        ]
      );
    }
  };
  useEffect(() => {
    fetchDataSource();
    fetchOptions();
  }, []);

  return (
    <Box padding="20px">
      {status !== 'Completed' && (
        <Box width={'80%'} marginBottom={'10px'}>
          <FormBuilder
            configs={configs}
            initialValues={values}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          />
        </Box>
      )}
      {!!dataSource?.length && (
        <TableBuilder
          ref={tableRef}
          columns={columns}
          dataSource={dataSource}
        />
      )}
      <Box display={'flex'} style={{ marginTop: 20 }} justifyContent="flex-end">
        {status === 'Awaiting' && (
          <Button
            // sx={{ marginTop: 1 }}
            style={{
              borderRadius: '20px',
              height: 30
            }}
            size="small"
            variant="outlined"
            data-testid="submit-btn"
            onClick={() => {
              sectionSubmit();
            }}
          >
            Mark this step as complete
          </Button>
        )}
        {!!dataSource?.length && (
          <Button
            onClick={exportExcel}
            size="small"
            style={{
              borderRadius: '20px',
              height: 30,
              marginLeft: 10
            }}
            startIcon={<LoginIcon />}
            variant="outlined"
          >
            Export
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default withSnackbar(AccountOwnerAlignment);
