import React, { useCallback, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import '../SalesCustomerInfo/index.css';
import FullscreenOutlinedIcon from '@mui/icons-material/FullscreenOutlined';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Formik, Form } from 'formik';
import { IconButton } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import {
  axiosInstanceDashboard,
  axiosInstanceDocument,
  axiosInstanceImage
} from '../../../api/Axios';
import CustomerForm from '../CustomerForm/CustomerForm';
import ShippingForm from '../CustomerForm/ShippingForm';
import BillingForm from '../CustomerForm/BillingForm';
import ViewDocuments from '../ViewDocuments';
import UpdateCustomerModal from '../UpdateCustomerModal';
import LocationFields from './LocationFields';
import CustomerPortalfields from './CustomerPortalfields';
import _ from 'lodash'
import CustomerPortalfields2 from './CustomerPortalfields2';
import moment from 'moment';
import LocationCustomerForm from '../CustomerForm/LocationCustomerForm';
import ContactInfo from '../CustomerForm/ContactInfo';
import UploadPicture from '../UploadPicture';
import { API_REQUEST_IMAGE, LOGO_PATH } from '../../../constant/api';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import { Auth } from 'aws-amplify';
import LeadAndPartnerFields from './LeadAndPartnerFields';
import { Email } from '@mui/icons-material';
import EmailModal from '../Modal/EmailModal';

const SalesCustomerInfo = (props) => {
  const CLASS_GRID_ROOT = '& .MuiGrid-root';
  const IMAGE_BASE_URL = `${API_REQUEST_IMAGE}`;
  const { snackbarShowMessage, parentCustID, customerportal,sectionNameForexecute,resetViewFunc,
    sectionSubmit,activeSection,customerdetails,downloadeddocstatus} = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [userLogo, setuserLogo] = useState();
  const [list, setlist] = useState({});
  const [customerFiles, setCustomerFiles] = useState([]);
  const shippingAddress = list?.customerAddressResponses?.find(
    (ele) => ele.addressType === 'SHIPPING_ADDRESS'
  );
  const billingAddress = list?.customerAddressResponses?.find(
    (ele) => ele.addressType === 'BILLING_ADDRESS'
  );
  const lead = list?.customerContactResponses?.find((item) => item.contactType === 'Onboarding Lead') || {};
  const partner = list?.customerContactResponses?.find((item) => item.contactType === 'Onboarding Partner') || {};

  const [openDocuments, setOpenDocuments] = useState(false);
  const [PictureDocuments, setPictureDocuments] = useState(false);
  const [checkCustomerPortal, setCheckCustomerPortal] = useState(false);
  const [emailModalOpen,setEmailModalOpen] = useState(false);
  const initialValues = {
    shippingCustID: shippingAddress?.customerAddressId,
    shippingFirstName: shippingAddress?.firstName,
    shippingLastName: shippingAddress?.lastName,
    shippingAddress: shippingAddress?.address,
    shippingState: shippingAddress?.state,
    shippingZipcode: shippingAddress?.zipCode,
    lead,
    leadName: lead.firstName,
    leadEmail: lead.email,
    leadContcatNo: lead.contactNo,
    partner,
    partnerName: partner.firstName,
    partnerEmail: partner.email,
    partnerContcatNo: partner.contactNo,
    toggleAddress:
      billingAddress?.firstName === shippingAddress?.firstName &&
      shippingAddress?.lastName === billingAddress?.lastName &&
      shippingAddress?.zipCode === billingAddress?.zipCode &&
      shippingAddress?.state === billingAddress?.state &&
      shippingAddress?.address === billingAddress?.address,
    billingCustID: billingAddress?.customerAddressId,
    billingFirstName: billingAddress?.firstName,
    billingLastName: billingAddress?.lastName,
    billingAddress: billingAddress?.address,
    billingState: billingAddress?.state,
    billingZipcode: billingAddress?.zipCode,
    customerId: list?.customerId,
    segment: list?.segment,
    accountName: list?.accountName,
    name: list?.name,
    opcoSite: list?.opcoSite,
    accountOwner: list.accountOwner,
    customerName: list.customerName,
    conceptId: list.conceptId,
    conceptName: list.conceptName,
    channel: list.channel,
    AccountRecordType: list.accountType,
    contact: list.contactNumber,
    goLiveDate: list?.goLiveDate ? moment(list?.goLiveDate).toDate() : '',
    email: list.email,
    customerfullName: `${list.customerFirstName} ${list.customerLastName}`,
    customerFirstName: list.customerFirstName,
    customerLastName: list.customerLastName,
    customerTitle: list.customerTitle,
    nationalId: list.nationalId,
    'No. of Locations': '',
    'No of Opsites': '',
    'No of Prop Items': '',
    'Tota Completion': '',
    Status: '',
    parentAccountType: list?.parentAccountType,
    manuallyCreateNationalId:list?.manuallyCreateNationalId, //COT-9015: Disable "Create National ID"--- set manuallyCreateNationalId for update function
  };

  const { customerID } = useParams();
  let id = parentCustID ? parentCustID : customerID;
  let isCustomerPortal = localStorage.getItem('customerPortalUser')
    ? true
    : false;
  if (isCustomerPortal) {
    id = customerID ? customerID : parentCustID;
  }
  const fetchlist = useCallback(() => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getCustomerDetails?id=${id}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setlist(_.get(response, 'data.data.Customer', {}));
          }
        }
      });
  }, [id]);

  async function viewImage() {
    const res = await Auth.currentSession().then(
      (data) => data.idToken.jwtToken
    );
    setuserLogo('');
    axiosInstanceImage
      .request({
        method: 'GET',
        url: `/getLogoFileName/${id}`
      })
      .then((response) => {
        if (response?.data?.data) {
          setuserLogo(`${LOGO_PATH}/${response?.data?.data.logoFileName}`);
        }
      });
    // const response = fetch(`${IMAGE_BASE_URL}/viewCustomerLogo/${id}`, {
    //   method: 'GET',
    //   headers: new Headers({
    //     Authorization: 'Bearer ' + res
    //   })
    // });

    // response.then(async (response) => {
    //   const reader = response.body.getReader();
    //   reader.read().then(({ done, value }) => {
    //     if (value.length == 32) setuserLogo('');
    //     else {
    //       const blob = new Blob([value.buffer], { type: 'image/jpeg' });
    //       const imgUrl = URL.createObjectURL(blob);

    //       setuserLogo(imgUrl);
    //     }
    //   });
    // });
  }

  const fetchImage = useCallback(async () => {
    viewImage();
  }, [id]);

  useEffect(()=>{
    if(sectionNameForexecute){
      handleViewFiles(true)
    }
  },[sectionNameForexecute])

  useEffect(() => {
    fetchImage();
  }, [fetchImage]);

  useEffect(() => {
    fetchlist();
  }, [fetchlist]);
  useEffect(() => {
    setCheckCustomerPortal(isCustomerPortal);
  }, [isCustomerPortal]);

  function handleViewFiles(flag) {
    if (flag) {
      axiosInstanceDocument
        .request({
          method: 'GET',
          url: `/downloadDocuments/${list?.customerId}`
        })
        .then((response) => {
          if (response?.data?.errorMessage) {
            snackbarShowMessage({
              message: response?.data?.errorMessage,
              type: 'error'
            });
            setOpenDocuments(true);
          }
          if (response?.data?.errorCode === null) {
            setCustomerFiles(response?.data?.data?.['Document Downloaded']);
            setOpenDocuments(true);
          }
        });
    } else {
      setOpenDocuments(false);
      if(!isCustomerPortal){
        resetViewFunc(false)
      }
    }
  }
  function UploadPictureFiles(flag1) {
    setPictureDocuments(flag1);
  }

  const renderCustTitle = (values) => {
    if (values.AccountRecordType === 'CMU') {
      return values?.customerName;
    } else if (values.AccountRecordType === 'LOCATION') {
      return values?.accountName;
    } else {
      return values?.conceptName;
    }
  };
  let navigate = useNavigate();
  const [isExcuteConcierge, setExcuteConcierge] = useState(false);
  const fetchcustomerprogress = async () => {
    const res = await axiosInstanceDashboard.request({
      method: 'GET',
      url: `getCustomerProgressStatus?custId=${initialValues.customerId}`
    });
    if (res?.data?.data?.['Customer Progress']?.['actionItems']?.filter(item => item.actionId === 30)?.length > 0) {
      setExcuteConcierge(true);
    }
  };
  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        // onSubmit={onSubmit}
      >
        {({ errors, touched, values, setValues, setFieldValue }) => {
          return (
            <Form>
              <div
                className="sales-customer-info"
                style={{ marginTop: '-15px' }}
              >
                <Card
                  className="top"
                  sx={{ boxShadow: '1px 1px 4px rgba(106, 115, 123, 0.15)' }}
                >
                  <CardContent>
                    <Grid container>
                      <Grid
                        item
                        style={{ display: 'flex', alignContent: 'center' }}
                        xs={6}
                        onClick={() => UploadPictureFiles(true)}
                      >
                        {userLogo ? (
                          <div className="cuslogo">
                            <img
                              className="logo"
                              src={userLogo}
                              alt="Logo"
                            ></img>
                          </div>
                        ) : (
                          <div className="cuslogo">Logo</div>
                        )}
                        <div
                          className="custitle"
                          style={{ lineHeight: '100px', marginLeft: 8 }}
                        >
                          {renderCustTitle(values)}
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div className="custitle-right">
                          <Grid style={{ justifyContent: 'flex-end' }} container>
                            {!checkCustomerPortal && (
                              <Grid item xs={1}>
                                <IconButton
                                  className="chat-screen-btn"
                                  size="small"
                                  aria-label="view files"
                                  data-testId="edit-btn"
                                  onClick={() =>
                                    navigate(
                                      `/chat/${id}?redirect=${window.location.href}`
                                    )
                                  }
                                >
                                  <MarkUnreadChatAltIcon
                                    style={{ fontSize: '18px' }}
                                  />
                                </IconButton>
                              </Grid>
                            )}
                            {!checkCustomerPortal && (
                              <Grid item xs={1}>
                                <IconButton
                                  className="full-screen-btn"
                                  size="small"
                                  aria-label="view files"
                                  data-testId="edit-btn"
                                  onClick={() => {setModalOpen(true);fetchcustomerprogress()}}
                                >
                                  <FullscreenOutlinedIcon
                                    style={{ fontSize: '24px' }}
                                  />
                                </IconButton>
                              </Grid>
                            )}
                            {!checkCustomerPortal && (
                              <Grid item xs={1}>
                                <IconButton
                                  className="full-screen-btn"
                                  size="small"
                                  aria-label="view files"
                                  data-testId="edit-btn"
                                  onClick={ ()=>setEmailModalOpen(true)}
                                >
                                  <Email
                                    style={{ fontSize: '24px' }}
                                  />
                                </IconButton>
                              </Grid>
                            )}
                            <Grid item className="viewAll">
                              <IconButton
                                className="button-root"
                                size="small"
                                aria-label="view files"
                                data-testid="view-files-btn"
                                onClick={() => handleViewFiles(true)}
                              >
                                <FileCopyIcon style={{ fontSize: '18px' }} />
                                <span className="viewDocument">
                                  View all Documents
                                </span>
                              </IconButton>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container sx={{ mt: '30px', mb: '10px' }}>
                      {customerportal && <Grid item xs={1}></Grid>}
                      {!customerportal && (
                        <Grid
                          item
                          xs={12}
                          sx={{ display: 'flex' }}
                          className={`info-container`}
                        >
                          <Grid
                            xs={3}
                            sx={{
                              [CLASS_GRID_ROOT]: { maxWidth: '100%' },
                              display: 'flex',
                              maxWidth: '100%'
                            }}
                            className={`info-container div-separator editClass`}
                          >
                            {values.AccountRecordType === 'LOCATION' ? (
                              <LocationCustomerForm
                                values={values}
                                setFieldValue={setFieldValue}
                                errors={errors}
                                touched={touched}
                                setValues={setValues}
                                formEditClass={'editClass'}
                                showAccountOwner={true}
                                hideFields={parentCustID ? true : false}
                              />
                            ) : (
                              <CustomerForm
                                formEditClass={'editClass'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                setFieldValue={setFieldValue}
                                showAccountOwner={true}
                                hideFields={parentCustID ? true : false}
                              />
                            )}
                          </Grid>

                          <Grid
                            xs={3}
                            sx={{
                              '& .MuiGrid-root': {
                                maxWidth: '100%',
                                pl: '20px'
                              },
                              display: 'flex',
                              maxWidth: '100%'
                            }}
                            className={`info-container div-separator  editClass`}
                          >
                            {
                              <ContactInfo
                                formEditClass={'editClass'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            }
                          </Grid>
                          <Grid
                            xs={3}
                            sx={{
                              '& .MuiGrid-root': {
                                maxWidth: '100%',
                                pl: '20px'
                              },
                              display: 'flex',
                              maxWidth: '100%'
                            }}
                            className={`info-container div-separator  editClass`}
                          >
                            {
                              <ShippingForm
                                formEditClass={'editClass'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            }
                          </Grid>
                          {values.AccountRecordType !== 'CMU' && (
                            <Grid
                              xs={4}
                              sx={{
                                [CLASS_GRID_ROOT]: {
                                  maxWidth: '100%',
                                  pl: '20px'
                                },
                                display: 'flex',
                                maxWidth: '100%'
                              }}
                              className={`info-container div-separator  editClass`}
                            >
                              <BillingForm
                                formEditClass={'editClass'}
                                modalOpen = {modalOpen}
                                errors={errors}
                                touched={touched}
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            </Grid>
                          )}
                          <Grid
                            xs={3}
                            sx={{
                              [CLASS_GRID_ROOT]: { maxWidth: '100%' },
                              display: 'flex',
                              maxWidth: '100%'
                            }}
                            className={`info-container div-separator`}
                          >
                           <LeadAndPartnerFields formEditClass={'editClass'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                setFieldValue={setFieldValue} />
                          </Grid>
                          {parentCustID && (
                            <Grid
                              xs={3}
                              sx={{
                                [CLASS_GRID_ROOT]: { maxWidth: '100%' },
                                display: 'flex',
                                maxWidth: '100%'
                              }}
                              className={`info-container div-separator editClass`}
                            >
                              <LocationFields />
                            </Grid>
                          )}
                        </Grid>
                      )}
                      {customerportal && (
                        <Grid
                          item
                          xs={11}
                          sx={{ display: 'flex' }}
                          className={`info-container editClass`}
                        >
                          <Grid
                            xs={4}
                            sx={{
                              [CLASS_GRID_ROOT]: { maxWidth: '100%' },
                              display: 'flex',
                              maxWidth: '100%'
                            }}
                            className={`info-container div-separator editClass`}
                          >
                            {
                              <CustomerPortalfields
                                formEditClass={'editClass'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            }
                          </Grid>
                          <Grid
                            xs={4}
                            sx={{
                              [CLASS_GRID_ROOT]: { maxWidth: '100%' },
                              display: 'flex',
                              maxWidth: '100%'
                            }}
                            className={`info-container div-separator editClass`}
                          >
                            {
                              <CustomerPortalfields2
                                formEditClass={'editClass'}
                                errors={errors}
                                touched={touched}
                                values={values}
                                setFieldValue={setFieldValue}
                              />
                            }
                          </Grid>

                          {parentCustID && (
                            <Grid
                              xs={4}
                              sx={{
                                [CLASS_GRID_ROOT]: { maxWidth: '100%' },
                                display: 'flex',
                                maxWidth: '100%'
                              }}
                              className={`info-container div-separator editClass`}
                            >
                              <LocationFields />
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </CardContent>
                </Card>
              </div>
              <ViewDocuments
                customerId={list?.customerId}
                customerFiles={customerFiles}
                open={openDocuments}
                onClose={handleViewFiles}
                mode="download"
                sectionNameForexecute={sectionNameForexecute}
                
              />
              <UploadPicture
                viewImage={viewImage}
                open={PictureDocuments}
                onClose={UploadPictureFiles}
                mode="download"
              />
            </Form>
          );
        }}
      </Formik>
      <UpdateCustomerModal
        modalopen={modalOpen}
        setmodalopen={setModalOpen}
        initialValues={initialValues}
        fetchlist={fetchlist}
        snackbarShowMessage={snackbarShowMessage}
        parentCustID={parentCustID}
        isExcuteConcierge={isExcuteConcierge}
      />
      <EmailModal
         modalopen={emailModalOpen}
         setmodalopen={setEmailModalOpen}
         snackbarShowMessage={snackbarShowMessage}
         sectionSubmit={sectionSubmit}
         activeSection={activeSection}
         customerdetails={customerdetails}
         downloadeddocstatus={downloadeddocstatus}
        //  emailListData={emailListData}
      />
      
    </>
  );
};

export default SalesCustomerInfo;
