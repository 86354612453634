import React, { useState, useEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import '../SalesDashboard/index.css';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CachedIcon from '@mui/icons-material/Cached';
import { downloadAllDocuments } from '../../../utils/downloadallDocuments';
import ProgressBar from '../../components/ProgressBar';
import Divider from '@mui/material/Divider';
import RequestPopup from '../../components/Popup';
import Selectq from '../../components/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import ConfettiPage from '../../components/ConfettiPage/index';
import URLredirect from '../../components/URLredirect';
import TextButton from '../../components/TextButton/TextButton';
import { CardClickCheck } from '../../../utils/CardClickcheck';
import {CardClickCheckShowError} from '../../../utils/CardClickCheckShowError'
import { createServiceAPI } from '../../../utils/createServiceAPI';
import { axiosInstanceDashboard } from '../../../api/Axios';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  updateCustomerProgress,
  updateCustomerProgressReset
} from './redux/slice';
import { getAllActions } from '../SalesDashboard/redux/slice';
import Mail from '../../components/Mail';
import SalesCustomerInfo from '../../components/SalesCustomerInfo';
import { mapping } from '../../../Helper/mapping';
import { Popover, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerProgressmapping } from '../../../utils/CustomerProgressmapping';
import tempcustomer from '../../../Helper/customerpreogressdatastatus';
import { updateAccounttype } from './redux/accounttypeSlice';
import '../templateComponent/index.css';
import { storeStepData, storeActiveStep } from './redux/slice';
import { withSnackbar } from '../../components/SnackBar/SnackBar';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import Acknowledge from '../../components/Acknowledge';
import DynamicInput from '../../components/DynamicInput';
import DateOrTime from '../../components/DateOrTime';
import CustomerDetails from '../../components/CustomerDetails';
import { triggerCustomerPortal } from '../../../utils/triggerCustomerPortal';
import { getCustomerProgress } from '../../components/ProgressBar/redux/slice';
import { updateCustomerStatusAPI } from '../../../utils/updateCustomerStatusAPI';

import Carousel from 'react-elastic-carousel';
import CompletedView from '../../components/CompletedView';
import Review from '../../components/Review';
import { useErrorHandler } from 'react-error-boundary';
import BosOnboarding from '../../components/BosOnboarding';
import Note from '../../components/Note';
import ICTAccordion from '../../components/Accordion/ICTAccordion';
import _ from 'lodash';

import MailSend from '../../components/MailSend';
import AccountOwnerAlignment from '../../components/AccountOwnerAlignment';
import Confirm from '../../components/Confirm';
import { SALES_PRE_REQUISTES_DIR, ncobsectionList } from '../../../utils/SectionFolderByDocument';
import { sendVPNAEmailRequest } from '../../components/Mail/redux/request';
const Customerlistdata = require('../../../Helper/CustomerListHeader.json');

const TemplateComponent = ({ snackbarShowMessage }) => {
  const CUSTOMER_PROGRESS = 'Customer Progress';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { parentCustID, customerID } = useParams();
  const [customerdetails, setcustomerdetails] = useState('');
  const [downloadeddocstatus, setdownloadeddocstatus] = useState();
  const [stepStatus, setStepStatus] = useState();
  const [popupopen, setpopupopen] = useState(false);
  const [stepId, setStep] = useState('');
  const [stepData, setStepData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeSection, setActiveSection] = useState();
  const [clickedcard, setclickedcard] = useState(false);
  const [clickedcardawaiting, setclickedcardawaiting] = useState(false);
  const [cardData, setData] = useState();
  const [sectionClick, setSectionClick] = useState(false)

  const [confettiflag, setconfettiflag] = useState(false);
  const [awaitingState, setAwitingState] = useState(false);
  const [showOmccTime, setShowOmccTime] = useState(false);
  const [closeStateHandler, setCloseHandler] = useState(true);
  const [sectionNameForexecute,setsectionNameForexecute] = useState(false);
  const [fileNum,setFileNum] = useState(0)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [isPreReqDoc,setisPreReqDoc] = useState(false);
  const [isNcobReview,setisNcobReview] = useState(false);
  const handleError = useErrorHandler();

  const cRef = useRef(null);
  
  const fetchdhownloaddocstatus = () => {
    downloadAllDocuments(customerID).then((data) => {
      setdownloadeddocstatus(data);
      calFileNum(data)
    });
  };
  const fetchlist = () => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getCustomerDetails?id=${customerID}`
      })
      .then((response) => {
        if (response) {
          if (response?.data?.errorCode || response?.data?.errorMessage) {
            handleError(response?.data?.errorMessage);
          } else {
            const customerDetail = _.get(response, 'data.data.Customer',{})
            if(_.get(customerDetail, 'progressStatus','') === 'Steady State'){
              navigate(`/Dashboard/${_.get(customerDetail, 'customerId','')}`,{replace: true});
              window.scrollTo(0, 0); 
            }
            setcustomerdetails(_.get(response, 'data.data.Customer',{}));
            // console.log('customerdetails',customerdetails)
            dispatch(updateAccounttype(_.get(response,'data.data.Customer.accountType')));
          }
        }
      });
  };

  
  const newsteps = useSelector((state) => state?.getAllActions);

  function closeHandlerCustomerDetails() {
    setActiveSection(false);
  }
  const customerProgressData = useSelector(
    (state) =>
      state?.getCustomerProgress?.data?.[CUSTOMER_PROGRESS]?.actionItems
  );

  const getCustomerProgressResponse = useSelector(
    (state) => state?.getCustomerProgress
  );

  const activeId = useSelector((state) => state?.storeActiveStep?.actionId);
  const activeitem = useSelector((state) => state?.storeActiveStep);
  const activeactionname = useSelector(
    (state) => state?.storeActiveStep?.actionName
  );
  const activeactionpopup = useSelector(
    (state) => state?.storeActiveStep?.createServiceRequest
  );
  const updateProgressResponse = useSelector(
    (state) => state?.updateprogressstatus
  );

  const custlist = useSelector((state) => state?.customerList?.data?.Customer);
  const labels = useSelector((state) => state?.storeActiveStep?.labels);

  const acctype = customerdetails.accountType;
  const isManualNationalId = customerdetails?.manuallyCreateNationalId;
  const cusname = custlist?.find(
    (ele) => ele.customerId.toString() === parentCustID
  )?.customerName;

  useEffect(() => {
    if (getCustomerProgressResponse) {
      if (
        localStorage.getItem(`currentstep_${customerID}`) >
        getCustomerProgressResponse?.data?.[CUSTOMER_PROGRESS]
          ?.completedSections
      ) {
        setStep(JSON.parse(localStorage.getItem(`currentstep_${customerID}`)));
      } else if (
        getCustomerProgressResponse?.data?.[CUSTOMER_PROGRESS]
          ?.completedSections
      )
        setStep(
          getCustomerProgressResponse?.data?.[CUSTOMER_PROGRESS]
            ?.completedSections + 1
        );
      else setStep(1);
    }
  }, [customerID, getCustomerProgressResponse]);

  useEffect(() => {
    if ((stepId > 13 || customerdetails?.progressStatus === 'Steady State') && acctype === 'CMU') {
      navigate(`/Dashboard/${customerID}`,{replace: true});
      window.scrollTo(0, 0);
    } else if (
      (stepId > 13 || customerdetails?.progressStatus === 'Steady State') &&
      (acctype === 'LOCATION' || acctype === 'CONCEPT')
    ) {
      if(parentCustID != undefined){
        navigate(`/Dashboard/${parentCustID}`,{replace: true});
      }else{
        navigate(`/Dashboard/${customerID}`,{replace: true});
      }
      
      window.scrollTo(0, 0);
    }
  }, [stepId, customerdetails]);

  useEffect(() => {
    if (customerProgressData) {
      setStepStatus(CustomerProgressmapping(customerProgressData));
    } else
      setStepStatus(
        CustomerProgressmapping(
          tempcustomer?.data[CUSTOMER_PROGRESS].actionItems
        )
      );
  }, [customerID, customerProgressData]);
  useEffect(() => {
    if (updateProgressResponse[CUSTOMER_PROGRESS]) {
      fetchdhownloaddocstatus();
      dispatch(getAllActions(acctype));

      setclickedcard(false);
      setActiveSection();
      dispatch(getCustomerProgress({ customerId: customerID }));
      dispatch(updateCustomerProgressReset());
    }
  }, [updateProgressResponse]);

  useEffect(() => {
    fetchlist();
    dispatch(getCustomerProgress({ customerId: customerID }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getAllActions(acctype));
  }, [acctype]);
  useEffect(() => {
    fetchdhownloaddocstatus();
  }, []);
  useEffect(() => {
    if (stepId && Object.keys(newsteps).length) {
      if (newsteps) {
        dispatch(storeActiveStep({ actionName: '' }));
        const arr = newsteps['Action Details'] || [];
        for (let i of arr) {
          if (i?.sectionId === stepId) {
            setStepData(i);
            dispatch(storeStepData(i));
            const mappedDataVal = i?.sectionResponse
              ?.map((ele) =>
                mapping?.[acctype]?.find(
                  (item) => item?.actionName === ele?.actionName
                )
              )
              .filter(function (element) {
                return element !== undefined;
              });

            setData(mappedDataVal);
            setDialogMes(false)
          }
        }
      }
    }
  }, [dispatch, newsteps, stepId, acctype]);

  const createservice = {};

  const completedSections = useSelector(
    (state) =>
      state?.getCustomerProgress?.data?.['Customer Progress']?.completedSections
  );
  const currAllsteps = useSelector(
    (state) =>
      state?.getAllActions?.['Action Details']?.[completedSections]
        ?.totalNumberOfSection
  );

  const currcomsteps = useSelector(
    (state) =>
      state?.getCustomerProgress?.data?.['Customer Progress']
        ?.actionCompletedForCurrentSection
  );
  const currSkipsteps = useSelector(
    (state) =>
      state?.getCustomerProgress?.data?.['Customer Progress']
        ?.actionSkippedForCurrentSection
  );

  useEffect(() => {
    
    if (
      currcomsteps &&
      currAllsteps &&
      currcomsteps + currSkipsteps === currAllsteps &&
      stepData?.sectionId < 9 && 
      customerdetails &&
      customerdetails?.progressStatus !== 'Steady State'
    ) {
      dispatch(
        updateCustomerProgress({
          customerId: customerID,
          actionId: stepData?.sectionId,
          sectionId: stepData?.sectionId,
          sectionStatus: stepData?.sectionId !== 12 ? 'Completed' : 'COMPLETED'
        })
      );
      cRef.current.goTo(0);
    }
    
  }, [
    currcomsteps,
    currSkipsteps,
    currAllsteps,
    stepData?.sectionId,
    customerID,
    dispatch,
    customerdetails
  ]);


  const valueSolver = (active, Upload, fromSkip, awaiting) => {
    if (active) {
      return 'Active';
    } else if (Upload) {
      return 'Upload';
    } else if (fromSkip?.enableSkip) {
      return 'Skipped';
    } else if (awaiting) {
      return 'Awaiting';
    } else if (stepData?.sectionId !== 13) {
      return 'Completed';
    } else {
      return 'COMPLETED';
    }
  };
  
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMes,setDialogMes] = useState('');

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClickClose = () => {
    setOpenDialog(false);
  };
  const [loading,setLoading] = useState(false);
  function sendVPNAEmail(){
    let emailContent = `These doc are required to upload : Customer contact information,Operating Site Alignment & Heat Map,Deep Dive Template,Item list,MSA/LOI,Customer Accounts,Customer Vendor List. chlick link below to upload. ${window.location.origin}${window.location.pathname}`
      let temp = {
        emailTemplateID: 0,
        customerId: customerID,
        emailSubject:"",
        emailBody: emailContent,
        toName: "",
        ccName: "",
        documentTemplateIds: [],
        emailInfoRequests: [
          {
            emailId: 0,
            toName: "",
            ccName: "",
            emailSubject:"",
            emailBody: emailContent,
            customerId: customerID,
            actionId: 0,
            attachmentIds: ""
          }
        ]
      };
      let json_data = JSON.stringify(temp);
      sendVPNAEmailRequest(json_data);
  }
  async function sectionSubmit(
    triggerPopup,
    fromSkip,
    awaiting,
    Upload,
    CustomerApproval,
    active
  ) {
    if (activeactionpopup) {
      setLoading(true);
      createservice.customerId = customerID;
      createservice.actionName = activeactionname;
      let response =await createServiceAPI(createservice)
      if(_.get(response,'data.errorCode')){
        snackbarShowMessage({
          message: _.get(response,'data.errorMessage'),
          type: 'error'
        });
        setpopupopen(false);
        setLoading(false);
        return
      }
      setpopupopen(false);
      setLoading(false);
      // sendVPNAEmail();
    }
    if (triggerPopup) {
      snackbarShowMessage({
        message: triggerPopup,
        type: 'success'
      });
    }
    if (activeitem.statuslinkage && !CustomerApproval && !awaiting) {
      for (const element of activeitem.linkeditems) {
        dispatch(
          updateCustomerProgress({
            customerId: customerID,
            actionId: element,
            sectionId: stepData?.sectionId,
            sectionStatus: 'Active'
          })
        );
      }
      cRef.current.goTo(1);
    } else {
      dispatch(
        updateCustomerProgress({
          customerId: customerID,
          actionId: activeId,
          sectionId: stepData?.sectionId,
          sectionStatus: valueSolver(active, Upload, fromSkip, awaiting),
          comments: fromSkip?.enableSkip ? fromSkip?.comment : ''
        })
      );
    }
    fetchlist();
  }

  const handleActionClick = (id,actionName,acctype,stepStatus,item,downloaddocstatus) => {
    let res = CardClickCheckShowError(actionName,acctype,stepStatus,item,downloaddocstatus)
    //add tips for ncob review action
    if(item?.actionId === 19){
      setisNcobReview(true);
      setOpenDialog(true);
      setDialogMes('Info:  Review each document before approving it.')
    }else if(!res.passValidation){
      setOpenDialog(true)
      setDialogMes(res?.validationMsg)
    }else{
      setOpenDialog(false)
    }
    setActiveSection({ action: '' });
    setSectionClick(false)
    setShowOmccTime(false);
    setclickedcard(id);
    setTimeout(() => {
      for (let i of mapping[acctype]) {
        if (i?.actionId === id) {
          if (i.triggerPopup === true) setpopupopen(true);
          dispatch(updateCustomerProgressReset());
          dispatch(storeActiveStep(i));
          setActiveSection({
            ...i,
            ...(i.triggerPopup ? { action: 'P' } : {}),
            ...(i.template === 'File' ? { action: 'F' } : {}),
            ...(i.template === 'URLredirect' ? { action: 'U' } : {}),
            ...(i.template === 'Review' ? { action: 'R' } : {}),
            ...(i.template === 'ack' ? { action: 'A' } : {}),
            ...(i.template === 'Mail' ? { action: 'M' } : {}),
            ...(i.template === 'BOS_Onboarding' ? { action: 'B' } : {}),
            ...(i.template === 'Text' ? { action: 'Text' } : {}),
            ...(i.template === 'Form' ? { action: 'Form' } : {}),
            ...(i.template === 'DateOrTime' ? { action: 'DateOrTime' } : {}),
            ...(i.template === 'Confirm' ? { action: 'Confirm' } : {}),
          });
          
        }
      }
    }, 0);
    setCloseHandler(true);
    setisPreReqDoc(item?.actionId === 15 && item?.actionName === 'Pre-Req Docs')
  };

  const handleawaitingActionClick = (id) => {
    setclickedcardawaiting(id);
    setAwitingState(true);
    setSectionClick(false)
    for (let i of mapping[acctype]) {
      if (i?.actionId === id) {
        dispatch(updateCustomerProgressReset());
        dispatch(storeActiveStep(i));
        

        if (i.fallbackScreen) {
          if (i.fallbackTemplate === 'ack') setActiveSection({ action: 'A' });
          if (i.fallbackTemplate === 'dynamicInput')
            setActiveSection({ action: 'D' });
          if (i.fallbackTemplate === 'Mail') setActiveSection({ action: 'M' });
          if (i.fallbackTemplate === 'File_Accordion')
            setActiveSection({ action: 'FA' });
          if (i.fallbackTemplate === 'Text')
            setActiveSection({ action: 'Text' });
          if (i.fallbackTemplate === 'File')
            setActiveSection({ ...i, action: 'F' });

          if (i.fallbackTemplate === 'Alignment') {
            setActiveSection({ action: 'Alignment' });
          }
        }
      }
    }
    setCloseHandler(true);
  };

  const handlecompletedActionClick = (id) => {
    setDialogMes(false)
    dispatch(storeStepData(id));
    setSectionClick(false)
    const targetStep = _.find(mapping[acctype], { actionId: id });
    if (targetStep) {
      dispatch(storeActiveStep(targetStep));
    }
    setActiveSection({ action: '' });
    setTimeout(() => {
      const AC_OWNER_ALIGNMENT_ID = 29;
      if (targetStep?.actionName === 'Concierge Integration') {
        setActiveSection({ action: 'FA-COMPLETEDCARD' });
      } else if (targetStep?.template === 'DateOrTime') {
        setShowOmccTime(true);
        setActiveSection({ action: 'DateOrTime' });
      } else {
        setActiveSection({ action: 'COMPLETEDCARD' });
      }
      if (id === AC_OWNER_ALIGNMENT_ID) {
        setActiveSection({ action: 'Alignment' });
      }
    }, 0);
  };

  const handlePopperClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 4, itemsToScroll: 2 },
    { width: 768, itemsToShow: 6, itemsToScroll: 3 },
    { width: 1200, itemsToShow: 8, itemsToScroll: 4 }
  ];

  const useStyles = makeStyles({
    root: {
      '& .rec.rec-arrow': {
        color: '#1B4F72',
        boxShadow: 'none',
        fontSize: '20px',
        border: 'none',
        width: '20px',
        height: '20px',
        minWidth: '20px',
        lineHeight: '20px',
        backgroundColor: '#fff'
      },
      '& .rec.rec-arrow:hover': {
        backgroundColor: '#1B4F72',
        color: 'white'
      },
      '& .rec.rec-arrow:focus': {
        backgroundColor: '#1B4F72',
        color: 'white'
      },
      '& .rec.rec-arrow:disabled': {
        color: 'white',
        backgroundColor: 'white'
      }
    }
  });
  const classes = useStyles();
  const CustomerPortalTrigger = async () => {
    const res = await triggerCustomerPortal(customerID);

    if (res?.data?.data?.['Customer Portal']) {
      snackbarShowMessage({
        message: res?.data?.data?.['Customer Portal'],
        type: 'success'
      });
    } else if (res?.data?.errorMessage) {
      snackbarShowMessage({
        message: res?.data?.errorMessage,
        type: 'error'
      });
    }
    setTimeout(() => {
      setAnchorEl(null);
    }, [1000]);
  };
  function classnamecalc(comparator1, comparator2, output1, output2) {
    if (comparator1 === comparator2) return output1;
    else return output2;
  }
  function closeHandler() {
    setCloseHandler(false);
    setclickedcard(false);
  }

  function awaitingcloseHandler() {
    setCloseHandler(false);
    setclickedcardawaiting(false);
  }
  async function changeCusStatus(){
    // console.log('customerdetails',customerdetails)
    if(_.get(customerdetails,'progressStatus')){
      const res = await updateCustomerStatusAPI(_.get(customerdetails,'progressStatus')!=='On Hold'?'On Hold':'On Track', customerID);
        if (res?.status === 200) {
          if (res?.data?.errorCode || res?.data?.errorMessage) {
            snackbarShowMessage({
              message: `Unable to update the status. Please try again later`,
              type: 'error'
            });
            return false;
          }
          snackbarShowMessage({
            message: `Status Change Successful `,
            type: 'success'
          });
          setTimeout(() => {
            window.location.reload()
          }, 1000);
        }
    }

    
  }
  function calFileNum(data){
    let increseNum = 0
    if(stepData?.sectionName === 'Sales' || stepData?.sectionName === 'Pre-Requisites')
    {
      if(stepData?.sectionName && ncobsectionList[SALES_PRE_REQUISTES_DIR]){
        ncobsectionList[SALES_PRE_REQUISTES_DIR].map(res=>{
          increseNum += _.filter(data,(o)=>{
            return o.docName === res
           }).length
        })
      }
    }else if(stepData?.sectionName && ncobsectionList[stepData?.sectionName]){
      ncobsectionList[stepData?.sectionName].map(res=>{
        increseNum += _.filter(data,(o)=>{
          return o.docName === res
         }).length
      })
    }
    setFileNum(increseNum)
  }
  useEffect(()=>{
   
    fetchdhownloaddocstatus()
    
  },[stepData?.sectionName,sectionNameForexecute])

  function openTheViewDocument(){
    setsectionNameForexecute(true)
  }
  function resetViewFunc(){
    setsectionNameForexecute(false)
  }
  return (
    <>
      {/* <Dialog
          open={openDialog}
          onClose={handleClickClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Error"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{dialogMes}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClickClose}>Cancle</Button>
            <Button onClick={handleClickClose} autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog> */}
      {stepId === 12 && !confettiflag ? (
        <ConfettiPage
          sectionSubmit={sectionSubmit}
          setconfettiflag={setconfettiflag}
        />
      ) : (
        <Grid sx={{ display: 'flex' }}>
          <Grid
            item
            xs={9}
            sx={{ boxShadow: 'none', padding: '25px 10px 20px 20px' }}
          >
            <div className="header-title">
              {acctype === 'LOCATION' || acctype === 'CONCEPT' ? (
                <div
                  className="Header"
                  onClick={() => {
                    if(parentCustID){
                      navigate(`/Dashboard/${parentCustID}`,{replace: true});
                    }else{
                      navigate(`/`,{replace: true});
                    }
                    
                    window.scrollTo(0, 0);
                  }}
                >
                  <ArrowBackIcon />
                  {cusname ? cusname : 'Customer'}
                </div>
              ) : (
                <div className="Header">{Customerlistdata.salestitle}</div>
              )}

              <div className="Header-action">
                Action Items <MoreVertIcon onClick={handlePopperClick} />
              </div>
            </div>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <Typography sx={{ p: 2 }}>
                {_.get(customerdetails,'progressStatus')!=='On Hold' ? 
                  (
                    <div onClick={CustomerPortalTrigger}>Create Customer Login</div>
                  ):(
                    <div className="disable-class">Create Customer Login</div>
                  )
                }
                <div className="disable-class">Create New Locations </div>
                <div className="disable-class">Create New Concepts </div>
                <div onClick={changeCusStatus}>Change Status to {_.get(customerdetails,'progressStatus')!=='On Hold'?'On Hold':'On Track'}</div>
              </Typography>
            </Popover>
            <SalesCustomerInfo snackbarShowMessage={snackbarShowMessage} sectionNameForexecute={sectionNameForexecute} resetViewFunc={resetViewFunc} 
            sectionSubmit={sectionSubmit} activeSection={activeSection} customerdetails={customerdetails} downloadeddocstatus={downloadeddocstatus}/>
            <Card style={{ display:_.get(customerdetails,'progressStatus')==='On Hold'?'none':'' }}
              className="bottom"
              sx={{ boxShadow: '1px 1px 4px #6a737b26' }}
            >
              <CardContent sx={{ padding: '32px 16px' }}>
                <div className="bottom-heading">
                  <div style={{display:'flex',alignItems:'center'}}>
                  <span id="sectionName">{stepData?.sectionName}</span>
                  <span style={{ display: openDialog ? 'block' : 'none' }} className={`${isPreReqDoc || isNcobReview?'restrictionMes mandatoryFileMsg':'restrictionMes'}`}>{dialogMes}</span>
                  </div>
                  {stepData?.sectionId < 10 && (
                    <div style={{fontSize:'small',fontWeight:'normal',display:'flex',alignItems:'flex-end',minWidth:'153px'}}>
                      <FolderOutlinedIcon style={{fontSize:'large'}}/> Document({fileNum})  <span style={{cursor:'pointer',color:'blue'}} onClick={openTheViewDocument}>&nbsp;&nbsp;  View All</span>
                    </div>
                  )} 
                </div>
                <div className="goal-stages">
                  <Carousel
                    className={classes.root}
                    pagination={false}
                    showArrows={true}
                    breakPoints={breakPoints}
                    showEmptySlots={true}
                    initialActiveIndex={5}
                    ref={cRef}
                    focusOnSelect={true}
                  >
                    {cardData &&
                      cardData.map((item, index) => {
                        return (
                          <div key={item.actionName} className={`${item.actionId === 23 && isManualNationalId ? 'disable-class':''}`}>
                          {/* <div key={item.actionName} className={`${item.actionId === 30?'disable-class':''}`}> */}
                            {stepStatus &&
                              ['completed', 'Completed', 'Skipped'].includes(
                                stepStatus[item.actionName]
                              ) && (
                                <Card
                                  className={`customerdetails completed ${item.slowBlueBG ? 'wathetBG': ''} ${item.actionId === 23 && isManualNationalId ?'disable-completed-card':''}`}
                                  // className={`customerdetails completed ${item.slowBlueBG ? 'wathetBG': ''} ${item.actionId === 30?'disable-completed-card':''}`}
                                  onClick={() => {
                                    handlecompletedActionClick(item.actionId);
                                  }}
                                >
                                  <div className="checkCircle-icon">
                                    <CheckCircleIcon className="checkCircle" />
                                  </div>
                                  <div className="checkCircle-text">
                                    <CardContent>{item.actionName}</CardContent>
                                  </div>
                                </Card>
                              )}

                            {stepStatus &&
                              stepStatus[`${item.actionName}`] === 'Active' &&
                              stepData &&
                              (CardClickCheck(
                                item.actionName,
                                acctype,
                                stepStatus,
                                item,
                                downloadeddocstatus
                              ) ? (
                                <Card
                                  className={classnamecalc(
                                    clickedcard,
                                    item.actionId,
                                    `customerdetailsclicked ${item.actionId === 23 && isManualNationalId?'disable-card':''}`,
                                    `customerdetails ${item.actionId === 23 && isManualNationalId?'disable-card':''}`
                                    // `customerdetailsclicked ${item.actionId === 30?'disable-card':''}`,
                                    // `customerdetails ${item.actionId === 30?'disable-card':''}`
                                  )}
                                  onClick={() => {
                                    handleActionClick(item.actionId,item.actionName,
                                      acctype,
                                      stepStatus,
                                      item,
                                      downloadeddocstatus);
                                  }}
                                >
                                  <div className="checkCircle-text">
                                    <CardContent>{item.actionName}</CardContent>
                                  </div>
                                </Card>
                              ) : (
                                <Card className="customerdetails-inactive">
                                  <div className="checkCircle-text">
                                    <CardContent>{item.actionName}</CardContent>
                                  </div>
                                </Card>
                              ))}
                            {stepStatus &&
                              (stepStatus[`${item.actionName}`] ===
                                'Awaiting' ||
                                stepStatus[`${item.actionName}`] === 'Upload' ||
                                stepStatus[`${item.actionName}`] === 'Ready' ||
                                stepStatus[`${item.actionName}`] ===
                                  'Failed') &&
                              stepData && (
                                <Card
                                  className={classnamecalc(
                                    clickedcardawaiting,
                                    item.actionId,
                                    `customerdetailsclicked awaiting ${item.actionId === 23 && isManualNationalId?'disable-card':''}`,
                                    `customerdetails awaiting ${item.actionId === 23 && isManualNationalId?'disable-card':''}`
                                    // `customerdetailsclicked awaiting ${item.actionId === 30?'disable-card':''}`,
                                    // `customerdetails awaiting ${item.actionId === 30?'disable-card':''}`
                                  )}
                                  onClick={() => {
                                    handleawaitingActionClick(item.actionId);
                                  }}
                                >
                                  <div className="pending-icon">
                                    <CachedIcon className="pending-icon-icon" />
                                  </div>
                                  <div className="checkCircle-text">
                                    <CardContent>{item.actionName}</CardContent>
                                  </div>
                                </Card>
                              )}
                            {stepStatus &&
                              !stepStatus[`${item.actionName}`] &&
                              stepData &&
                              (CardClickCheck(
                                item.actionName,
                                acctype,
                                stepStatus,
                                item,
                                downloadeddocstatus
                              ) ? (
                                <Card
                                  className={`${
                                    clickedcard === item.actionId
                                      ? `customerdetailsclicked ${item.actionId === 23 && isManualNationalId?'disable-card':''}`
                                      : 'customerdetails'} ${item.slowBlueBG ? 'wathetBG': ''} ${item.actionId === 23 && isManualNationalId?'disable-card':''}`
                                      // : 'customerdetails'} ${item.slowBlueBG ? 'wathetBG': ''} ${item.actionId === 30?'disable-card':''}`
                                  }
                                  onClick={() => {
                                    handleActionClick(item.actionId,item.actionName,
                                      acctype,
                                      stepStatus,
                                      item,
                                      downloadeddocstatus);
                                  }}
                                >
                                  <div className="checkCircle-text">
                                    <CardContent>{item.actionName}</CardContent>
                                  </div>
                                </Card>
                              ) : (
                                <Card className="customerdetails-inactive">
                                  <div className="checkCircle-text">
                                    <CardContent>{item.actionName}</CardContent>
                                  </div>
                                </Card>
                              ))}
                          </div>
                        );
                      })}
                  </Carousel>
                </div>
              </CardContent>
              <Divider variant="fullWidth" />

              <div style={{ position: 'relative' }}>
                {!!activeSection?.action  && (
                  <div style={{ display:"flex",justifyContent:"flex-end" }}>
                    <Note />
                  </div>
                  )
                }
                {!sectionClick && (activeitem?.template === 'Mail' || activeitem?.fallbackTemplate === 'Mail') &&
                  activeSection?.action !== 'M' && 
                  (activeSection?.action === 'COMPLETEDCARD' ||
                    awaitingState || activeSection?.action === 'Alignment') && (
                    <div className="mailSend-sec">
                      <MailSend
                        snackbarShowMessage={snackbarShowMessage}
                        activesection={activeSection}
                        sectionSubmit={sectionSubmit}
                        customerId={customerID}
                      />
                    </div>
                  )}

                {activeSection?.action === 'Alignment' && (
                  <AccountOwnerAlignment
                    sectionSubmit={sectionSubmit}
                    status={_.get(stepStatus, 'Account Owner Alignment')}
                  />
                )}

                { activeSection?.action === 'Confirm' && <Confirm sectionSubmit={sectionSubmit} />}

                {activeSection?.action === 'R' && closeStateHandler && (
                  <Review
                    customerId={customerID}
                    actionId={activeId}
                    closeHandler={closeHandler}
                    sectionSubmit={sectionSubmit}
                    snackbarShowMessage={snackbarShowMessage}
                    showReviewTemplate={activeSection?.showReviewTemplate}
                  />
                )}

                {activeSection?.action === 'Form' && (
                  <CustomerDetails
                    snackbarShowMessage={snackbarShowMessage}
                    closeHandler={closeHandlerCustomerDetails}
                    activesection={activeSection}
                    sectionSubmit={sectionSubmit}
                    customerdetails={customerdetails}
                  />
                )}

                {activeSection?.action === 'DateOrTime' && (
                  <DateOrTime
                    customerId={customerID}
                    activesection={activeSection}
                    sectionSubmit={sectionSubmit}
                    snackbarShowMessage={snackbarShowMessage}
                    actionId={activeId}
                    actionName={activeactionname}
                    showTimeOr={showOmccTime}
                  />
                )}

                {activeSection?.action === 'M' && (
                  <Mail
                    // sectionSubmitmail={sectionSubmitmail}
                    snackbarShowMessage={snackbarShowMessage}
                    activesection={activeSection}
                    sectionSubmit={sectionSubmit}
                  />
                )}
                {activeSection?.action === 'B' && (
                  <BosOnboarding
                    snackbarShowMessage={snackbarShowMessage}
                    activesection={activeSection}
                    sectionSubmit={sectionSubmit}
                  />
                )}

                {activeSection?.action === 'FA' && (
                  <ICTAccordion
                    customerdetails={customerdetails}
                    itemcodingstatus={stepStatus?.['Item Coding']}
                    stepStatus={stepStatus}
                    customerID={customerID}
                    action={activeSection?.action}
                    sectionSubmit={sectionSubmit}
                    snackbarShowMessage={snackbarShowMessage}
                  />
                )}
                {activeSection?.action === 'FA-COMPLETEDCARD' && (
                  <ICTAccordion
                    customerdetails={customerdetails}
                    itemcodingstatus={false}
                    stepStatus={stepStatus}
                    customerID={customerID}
                    action={activeSection?.action}
                    snackbarShowMessage={snackbarShowMessage}
                  />
                )}
                {activeSection?.action === 'P' && (
                  <RequestPopup
                    popupopen={popupopen}
                    headingtitle={activeSection?.triggerPopupTitle}
                    triggerPopupDesc={activeSection?.triggerPopupDesc}
                    setpopupopen={setpopupopen}
                    sectionSubmit={sectionSubmit}
                    triggerPopupSuccess={activeSection?.triggerPopupSuccess}
                    cancelHandler={closeHandler}
                    loading={loading && (activeSection?.actionId === 18 || activeSection?.actionId === 23)}
                  />
                )}
                {activeSection?.action === 'Text' && closeStateHandler && (
                  <TextButton
                    customerID={customerID}
                    sectionSubmit={sectionSubmit}
                    isCustomerApproval={_.get(activeitem, 'actionId') === 39 || _.get(activeitem, 'actionId') === 67}
                    snackbarShowMessage={snackbarShowMessage}
                    closeHandler={closeHandler}
                  />
                )}
                {activeSection?.action === 'F' && closeStateHandler && (
                  <Selectq
                    clickedcard={clickedcard}
                    customerID={customerID}
                    activesection={activeSection}
                    hideButtons={false}
                    sectionSubmit={sectionSubmit}
                    closeHandler={closeHandler}
                    setActiveSection={setActiveSection}
                    setDialogMes={setDialogMes}
                  />
                )}

                {activeSection?.action === 'U' && closeStateHandler && (
                  <URLredirect
                    acctype={acctype}
                    customerID={customerID}
                    activesection={activeSection}
                    hideButtons={false}
                    sectionSubmit={sectionSubmit}
                    closeHandler={closeHandler}
                  />
                )}
                {activeSection?.action === 'A' && closeStateHandler && (
                  <Acknowledge
                    labels={labels}
                    sectionSubmit={sectionSubmit}
                    setfallbackcompleted={false}
                    closeHandler={awaitingcloseHandler}
                    snackbarShowMessage={snackbarShowMessage}
                  />
                )}

                {activeSection?.action === 'D' && closeStateHandler && (
                  <DynamicInput
                    fields={labels}
                    sectionSubmit={sectionSubmit}
                    setfallbackcompleted={false}
                    closeHandler={closeHandler}
                  />
                )}
                {activeSection?.action === 'COMPLETEDCARD' && (
                  <CompletedView
                    acctype={acctype}
                    customerdetails={customerdetails}
                    stepStatus={stepStatus}
                    customerID={customerID}
                    activeSection={activeSection}
                    snackbarShowMessage={snackbarShowMessage}
                  />
                )}
              </div>
            </Card>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{ boxShadow: 'none', padding: '25px 20px 20px 10px' }}
          >
            <Card
              className="progressbar-card"
              sx={{ boxShadow: '1px 1px 4px #6a737b26' }}
              style={{position:'relative'}}
            >
               {_.get(customerdetails,'progressStatus')==='On Hold' && (<div className="onHoldArea" >
                 <span><b>Customer Status is On Hold</b></span>
                 <br></br>
                 <span><b>only have view permission</b></span>
                 
               </div>
               )}
              <CardContent sx={{ p: '30px' }}>
             
                <p className="progressbar-header">In Progress</p>
                <ProgressBar
                  cRef={cRef}
                  setActiveSection={setActiveSection}
                  customerId={customerID}
                  setStep={setStep}
                  acctype={acctype}
                  setSectionClick={setSectionClick}
                  downloadeddocstatus={downloadeddocstatus}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default withSnackbar(TemplateComponent);
