import {axiosInstanceDashboard} from '../api/Axios';

export async function getCustomerDetails(parentCustID) {
  try {
    const response = await axiosInstanceDashboard.request({
      method: 'GET',
      url: `/getCustomerDetails?id=${parentCustID}`
    });
    const responseData = response.data.data.Customer;
    return responseData;
  } catch (error) {
    console.error(error);
  }
}


export async function getCustomerID(userID) {
  try {
    const response = await axiosInstanceDashboard.request({
      method: 'GET',
      url: `/getCustomerID?emailID=${userID}`
    });
    // console.log(response,'response');
    const responseData = response.data.data.Customer;
    return responseData;
  } catch (error) {
    console.error(error);
  }
}