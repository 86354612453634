import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import AddTaskIcon from '@mui/icons-material/AddTask';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Selectq from '../Select/index';
import Grid from '@mui/material/Grid';
import Review from '../Review/index';
import Acknowledge from '../Acknowledge/index';
import { axiosInstanceDashboard } from '../../../api/Axios';
import { CustomerProgressmapping } from '../../../utils/CustomerProgressmapping';
import tempcustomer from '../../../Helper/customerpreogressdatastatus';
import { downloadAllDocuments } from '../../../utils/downloadallDocuments';
import { CardClickCheck } from '../../../utils/CardClickcheck';
import { getCustomerProgress } from '../ProgressBar/redux/slice';
import CompleteUploadDocTable from '../CompleteUploadDocTable';

import ConfettiPage from '../ConfettiPage';

import '../../containers/templateComponent/index.css';
import { mapping_cust_pers } from '../../../Helper/mapping_cust_pers';
import _ from 'lodash';

const ColoredLine = ({ color }) => (
  <hr
    style={{
      color: '#FDFEFE',
      backgroundColor: '#FDFEFE',
      height: 0.15,
      borderColor: '#FDFEFE'
    }}
  />
);
export default function ThingsToDo(props) {
  const [list, setlist] = useState({});
  const [display, setDisplay] = useState(5);
  const [stepStatus, setStepStatus] = useState();
  const [downloadeddocstatus, setdownloadeddocstatus] = useState();
  const [completedSections, setcompletedSections] = useState(0);
  const [options, setOptions] = useState([]);
  const inventorySnapshotAction = _.find(
    _.get(mapping_cust_pers, `${list.accountType}`),
    { actionName: 'Inventory Snapshot' }
  );
  const {
    CustomerId,
    setconfettipage,
    clickedflag,
    snackbarShowMessage,
    setsteadystate
  } = props;

  

  const fetchcustomerprogress = async () => {
    const res = await axiosInstanceDashboard.request({
      method: 'GET',
      url: `getCustomerProgressStatus?custId=${CustomerId}`
    });
    if (res?.data?.data?.['Customer Progress']?.completedSections == 12)
      setsteadystate(true);
    setcompletedSections(
      res?.data?.data?.['Customer Progress']?.completedSections
    );
  };

  const fetchdhownloaddocstatus = () => {
    downloadAllDocuments(CustomerId).then((data) => {
      setdownloadeddocstatus(data);
    });
  };
  const dispatch = useDispatch();
  useEffect(() => {
    fetchdhownloaddocstatus();
    fetchcustomerprogress();
  }, []);

  useEffect(() => {
    dispatch(getCustomerProgress({ customerId: CustomerId }));
  }, [options, display]);

  const customerProgressData = useSelector(
    (state) =>
      state?.getCustomerProgress?.data?.['Customer Progress']?.actionItems
  );
  useEffect(() => {
    if (customerProgressData) {
      setStepStatus(CustomerProgressmapping(customerProgressData));
    } else
      setStepStatus(
        CustomerProgressmapping(
          tempcustomer?.data['Customer Progress'].actionItems
        )
      );
  }, [customerProgressData]);

  const fetchlist = () => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getCustomerDetails?id=${CustomerId}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setlist(_.get(response, 'data.data.Customer', {}));
          }
        }
      });
  };
  useEffect(() => {
    fetchlist();
  }, []);

  useEffect(() => {
    if (mapping_cust_pers?.[list.accountType]) {
      if (completedSections === 10 && clickedflag === false) {
        setconfettipage(true);
      } else if (completedSections === 12) setsteadystate(true);
   
      let cards = [];
      for (let i in mapping_cust_pers?.[list.accountType]) {
        const action = mapping_cust_pers?.[list.accountType][i] || {};
        const { actionName, needConfirm } = action;
        const cardClickCheck = CardClickCheck(
          actionName,
          list.accountType,
          stepStatus,
          action,
          downloadeddocstatus
        );
        const isAwaiting = _.get(stepStatus, actionName) === 'Awaiting';
        const uncompleted = _.get(stepStatus, actionName) !== 'Completed';
        if (
          ((cardClickCheck && needConfirm && isAwaiting) ||
          (cardClickCheck && !needConfirm && uncompleted)) && 'Inventory Snapshot' !== actionName
        ) {
          cards.push(action);
        }
      }
      cards.push(inventorySnapshotAction);
      setOptions(cards);
    }
  }, [list]);


  const [showType, setShowType] = useState(''); // showType: action

  return (
    <>
      <Card sx={{ Width: 275, height: 'auto' }}>
        <CardContent>
          <Grid item xs={8}>
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 'bolds',
                fontFamily: 'MyriadPro'
              }}
              gutterBottom
            >
              Things To Do
            </Typography>
          </Grid>
          <ColoredLine color="grey" />
          {display === 12 && (
            <>
              <ConfettiPage customerpersona={true} setDisplay={setDisplay} />
            </>
          )}
          {display === 0 && (
            <Box
              style={{
                borderRadius: '2px',
                //boxShadow: '1px 1px 1px #e4e5e6',
                background: '#FFFFFF',
                boxShadow: '1px 1px 4px rgb(106 115 123 / 15%)',
                display: 'flex',

                margin: '5px'
              }}
            >
              <div>
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: 'bold',
                    marginTop: '20px',
                    marginBottom: '20px'
                  }}
                  gutterBottom
                >
                  No pending task available
                </Typography>

                {/* <Typography sx={{ fontSize: 11 }} gutterBottom>
                  No pending task available content Start here to upload all the
                  documents for pre-requites
                </Typography> */}
              </div>
            </Box>
          )}
          <Box style={{ display: 'flex', overflowX: 'auto' }}>
            {_.map(options, (option) => {
              return (
                <Box
                  style={{
                    borderRadius: '2px',
                    background: '#FFFFFF',
                    boxShadow: '1px 1px 4px rgb(106 115 123 / 15%)',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '50%',
                    margin: '5px',
                    marginTop: '30px'
                  }}
                >
                  {_.get(option, 'confirmConfig.template') === 'File' ? (
                    <CloudUploadOutlinedIcon
                      fontSize="large"
                      style={{
                        color: '#008CD2',
                        padding: '20px',
                        fontSize: 40
                      }}
                    />
                  ) : (
                    <AddTaskIcon
                      fontSize="large"
                      style={{
                        color: '#008CD2',
                        padding: '20px',
                        fontSize: 40
                      }}
                    />
                  )}
                  <div>
                    <Typography
                      sx={{
                        fontSize: 15,
                        fontWeight: 'bold',
                        marginTop: '20px',
                        fontFamily: 'MyriadPro'
                      }}
                      gutterBottom
                    >
                      {option.cardTitle}
                    </Typography>

                    <Typography
                      sx={{ fontSize: 11, fontFamily: 'MyriadPro' }}
                      gutterBottom
                    >
                      {option.cardSubtitle}
                    </Typography>
                  </div>
                  <Button
                    onClick={setShowType.bind(null, option.actionName)}
                    variant="outlined"
                    style={{
                      borderRadius: '18px',
                      margin: '23px',
                      marginTop: '30px',
                      height: '2vw',
                      padding: '13px'
                    }}
                  >
                    Go
                  </Button>
                </Box>
              );
            })}
          </Box>
          <div>
            {showType === 'Inventory Snapshot' &&
              _.get(stepStatus, 'Inventory Snapshot') !== 'Completed' && (
                <Selectq
                  customerID={CustomerId}
                  customerPersona={true}
                  closeHandler={setShowType.bind(null, '')}
                  activesection={inventorySnapshotAction}
                />
              )}
            {showType === 'Inventory Snapshot' &&
              _.get(stepStatus, 'Inventory Snapshot') === 'Completed' && (
                <div>
                  <CompleteUploadDocTable
                    customerID={CustomerId}
                    currobj={inventorySnapshotAction}
                  />
                  <Button onClick={setShowType.bind(null, '')}>Cancel</Button>
                </div>
              )}
            {_.map(options, (option) => {
              const { confirmConfig = {}, actionName } = option;
              if ('Inventory Snapshot' === actionName) return
              if (
                confirmConfig.template === 'Review' &&
                actionName === showType
              ) {
                return (
                  <Review
                    snackbarShowMessage={snackbarShowMessage}
                    customerId={CustomerId}
                    actionId={confirmConfig.actionId}
                    showReviewTemplate={confirmConfig?.showReviewTemplate}
                    customerpersona={true}
                    setDisplay={setDisplay}
                    closeHandler={setShowType.bind(null, '')}
                  />
                );
              }

              if (
                confirmConfig.template === 'File' &&
                actionName === showType
              ) {
                return (
                  <Selectq
                    activesection={confirmConfig}
                    customerID={CustomerId}
                    setDisplay={setDisplay}
                    customerPersona={true}
                    closeHandler={setShowType.bind(null, '')}
                  />
                );
              }

              if (confirmConfig.template === 'A' && showType === actionName) {
                return (
                  <Acknowledge
                    snackbarShowMessage={snackbarShowMessage}
                    cuspid={CustomerId}
                    customerpersona={true}
                    setDisplay={setDisplay}
                    labels={confirmConfig.labels}
                    closeHandler={setShowType.bind(null, '')}
                  />
                );
              }
            })}
          </div>
        </CardContent>
      </Card>
    </>
  );
}
