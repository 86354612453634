import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Field, ErrorMessage } from 'formik';
import { FieldTemp } from '../SalesCustomerInfo/LeadAndPartnerFields';
import { getEmailList } from './redux/slice';
import { getCustomerList } from '../Header/redux/slice';
import { useDispatch, useSelector } from 'react-redux';

const ContactInfo = ({ errors, touched, formEditClass }) => {
  function className(FORMTITLE, param1, param2) {
    return FORMTITLE + (param1 && param2 ? ISINVALID : '');
  }
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getEmailList());
    dispatch(getCustomerList());
  }, [dispatch]);
  const FORMCONTROL = 'form-control';
  const ISINVALID = ' is-invalid';
  const FORMTITLE = ' form-title';
  const justView = !!formEditClass;
  const showFullName = justView;
  const gridByShowFullName = showFullName ? 6 : 12;
  const titleLayout = { xs: gridByShowFullName, sm: gridByShowFullName };
  const topFieldsConfig = [
    {
      name: 'customerFirstName',
      touched,
      errors,
      formEditClass,
      layout: {
        xs: 6,
        sm: 6
      },
      label: 'First Name'
    },
    {
      name: 'customerLastName',
      touched,
      errors,
      formEditClass,
      layout: {
        xs: 6,
        sm: 6
      },
      label: 'Last Name'
    }
  ];
  const fullNameFieldConfig = {
    name: 'customerfullName',
    touched,
    errors,
    formEditClass,
    layout: {
      xs: 6,
      sm: 6
    },
    label: 'Name',
    require: false
  };
  const topView = showFullName ? (
    <FieldTemp {...fullNameFieldConfig} />
  ) : (
    <>
      {topFieldsConfig.map((config) => (
        <FieldTemp key={config.name} {...config} />
      ))}
    </>
  );
  return (
    <div className="customer-form form-container">
      <div className="popup-title form2">Contact Information</div>
      <div className="customer-form form-container">
        <Grid container rowSpacing={2} columnSpacing={3}>
          {topView}
          <Grid item {...titleLayout}>
            <div className="form-header">
              <div className={FORMTITLE}>Title</div>
              <div className="form-subTitle">
                <Field
                  name="customerTitle"
                  type="text"
                  placeholder={!formEditClass ? 'Input text*' : ''}
                  className={'form-control address'}
                />
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div
                className={className(
                  FORMTITLE,
                  errors.contact,
                  touched.contact
                )}
              >
                Contact Phone
              </div>
              <div className="form-subTitle">
                <Field
                  name="contact"
                  type="text"
                  placeholder="Format in (xxx)xxx-xxxx/xxx-xxx-xxxx"
                  className={className(
                    FORMCONTROL,
                    errors.contact,
                    touched.contact
                  )}
                />
                {!formEditClass && (
                  <div
                    className={className(
                      'required',
                      errors.contact,
                      touched.contact
                    )}
                  >
                    {errors.contact && touched.contact ? (
                      <ErrorMessage
                        name="contact"
                        component="div"
                        className="invalid-feedback"
                      />
                    ) : (
                      '*Required'
                    )}
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div
                className={className(FORMTITLE, errors.email, touched.email)}
              >
                Email ID
              </div>
              <div className="form-subTitle">
                <Field
                  name="email"
                  type="text"
                  placeholder={!formEditClass ? 'Input text*' : ''}
                  className={className(
                    FORMCONTROL,
                    errors.email,
                    touched.email
                  )}
                />
                {!formEditClass && (
                  <div
                    className={className(
                      'required',
                      errors.email,
                      touched.email
                    )}
                  >
                    {errors.email} {!errors.email && <span>*Required</span>}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ContactInfo;
