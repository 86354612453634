import React, { useEffect, useState } from 'react';

function usePagination(data, itemsPerPage,initPage) {
  
  const [currentPage, setCurrentPage] = useState(initPage);
  const maxPage = Math.ceil(data?.length / itemsPerPage);
  useEffect(() =>{
    setCurrentPage(initPage)
  },[initPage]);
  function currentData() {
    const begin = (currentPage - 1) * itemsPerPage;
    const end = begin + itemsPerPage;
    return data?.slice(begin, end);
  }

   function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }
  function resetCurrentPage(page){
    setCurrentPage(page);
  }

  return { next, prev, jump, currentData, currentPage, maxPage,resetCurrentPage };
}

export default usePagination;