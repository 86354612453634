import { axiosInstanceDocument } from '../api/Axios';

export const uploadFile = async (customerID, acceptedFiles, templateID) => {
  let formData = new FormData();
  formData.append('document', acceptedFiles);
  const response = axiosInstanceDocument
    .request({
      method: 'POST',
      url: `/uploadDocument/${templateID}/${customerID}`,
      data: formData
    });

  return response;
};
export const uploadOtherDoc = async (customerID, acceptedFiles, documentName) => {
  let formData = new FormData();
  formData.append('document', acceptedFiles);
  const response = axiosInstanceDocument
    .request({
      method: 'POST',
      url: `uploadCustomizedDocument/${customerID}/${documentName}`,
      data: formData
    });

  return response;
};



export const uploadBOSSubRuleFile = async (customerID, acceptedFiles) => {
  let formData = new FormData();
  formData.append('file', acceptedFiles);
  const response = axiosInstanceDocument
    .request({
      method: 'POST',
      url: `/BOS2_0/uploadSubs/${customerID}`,
      data: formData
    });

  return response;
};

export const uploadTrackerFile = async (customerID,templateName,acceptedFiles) => {
  let formData = new FormData();
  formData.append('document', acceptedFiles);
  const response = axiosInstanceDocument
    .request({
      method: 'POST',
      url: `/uploadTrackerDocument/${customerID}/${templateName}`,
      data: formData
    });

  return response;
};

