import React, { useEffect, useState } from 'react';
import { axiosInstanceDashboard } from '../../../api/Axios';
import { useParams } from 'react-router-dom';
import { updatecustomerdetailsAPI } from '../../../utils/updatecustomerdetailsAPI';
import { useSelector, useDispatch } from 'react-redux';
import { updateCustomerProgress } from '../../containers/templateComponent/redux/slice';
import { getCustomerProgress } from '../ProgressBar/redux/slice';
import { mapping } from '../../../Helper/mapping';
import './index.css';
import _ from 'lodash'
import {
  Checkbox,
  Divider,
  Button,
  FormGroup,
  FormControlLabel
} from '@mui/material';

export default function Acknowledge(props) {
 
  const {
    labels,
    sectionSubmit,
    closeHandler,
    snackbarShowMessage,
    customerpersona,
    setDisplay,
    cuspid,
  } = props;
  const [checked, setChecked] = React.useState({});
  const [list, setlist] = useState({});
  const [flag, setflag] = useState(false);
  const { customerID } = useParams();
 
  const currentstep = useSelector((state) => state?.storestep?.actionId);
  const completedSections = useSelector(
    (state) =>
      state?.getCustomerProgress?.data?.['Customer Progress']?.completedSections
  );
  const dispatch = useDispatch();
  const custobj = {
    customerRequest: {},
    customerAddressRequests: [],
    contactRequests: []
  };
  const addfieldstoObject = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (currentstep === 16)
      custobj.customerRequest['financialApprover'] = userInfo;
    if (currentstep === 46)
      custobj.customerRequest['currentDistribution'] = userInfo;
    if (currentstep === 17)
      custobj.customerRequest['opSiteAlignment'] = userInfo;
  };
  const fetchlist = () => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getCustomerDetails?id=${customerID}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setlist(_.get(response, 'data.data.Customer', {}));
          }
        }
      });
  };
  useEffect(() => {
    if (list.customerId) {
      addfieldstoObject();
      updatecustomerdetailsAPI(custobj, list.customerId);
    }
  }, [list]);
  useEffect(() => {
    if (flag === true) {
      fetchlist();
      setTimeout(() => {
        sectionSubmit(false, false);
      }, [2000]);
    }
  }, [flag]);

  const handleChange = (index, event) => {
    setChecked((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  function handleSubmit() {
    if (customerpersona) {
      if (checked['0'] === false)
        snackbarShowMessage({
          message: 'Please select checkbox',
          type: 'error'
        });
      if (checked['0'] === true) {
        let sign_off =  mapping['CMU']?.find(
          (action) => action?.actionName === 'Sign Off on Simulated Pricing'
        );
        dispatch(
          updateCustomerProgress({
            customerId: cuspid,
            actionId: sign_off.actionId,
            sectionId: 6,
            sectionStatus: 'Completed',
            comments: ''
          })
        );
         if(completedSections===5)
         {
        dispatch(
          updateCustomerProgress({
            customerId: cuspid,
            actionId: 6,
            sectionId: 6,
            sectionStatus: 'Completed',
            comments: ''
          })
        );}
        snackbarShowMessage({
          message: 'Sign Off triggered',
          type: 'success'
        });
       
          setDisplay(0);
          dispatch(getCustomerProgress({ cuspid }));
        setTimeout(() => {
          window.location.reload()
        }, [3000]);
      }
    } else {
      if (checked['0'] === true) {
        setflag(true);
      } else {
        snackbarShowMessage({
          message: `Please check the checkbox before proceeding`,
          type: 'error'
        });
      }
    }
  }

  useEffect(() => {
    if (labels) {
      const obj = {};
      for (let i = 0; i < labels.length; i++) obj[i] = false;
      setChecked(obj);
    }
  }, [labels]);

  return (
    <div className="acknowledge">
      <FormGroup>
        {labels &&
          labels.map((label, index) => {
            return (
              <div key={label}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={checked[index]}
                      data-testid={index}
                      onChange={(e) => {
                        handleChange(index, e);
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={<b>{label}</b>}
                />
              </div>
            );
          })}
      </FormGroup>
      {/* <Divider sx={{ marginTop: 16 }} variant="fullWidth" /> */}
      <div className="button-wrapper">
        <Button
          sx={{ '& .MuiButton-root': { fontSize: 14 } }}
          id="secondary"
          className="buttons-1"
          onClick={closeHandler}
          style={customerpersona ? { marginLeft: '45vw' } : {}}
        >
          Cancel
        </Button>
        <Button
          id="secondary"
          className="buttons-2"
          data-testid="submit-btn"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
