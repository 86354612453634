import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import '../AdminDashboard/AdminDashboard.css';
import SearchIcon from '@mui/icons-material/Search';
import { axiosInstanceEmailNonBff, axiosInstanceDocument } from '../../../api/Axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormControl, InputAdornment, Grid, Input, MenuItem, Select, InputLabel } from '@mui/material';
import EmailAdminDashboard from './EmailAdminDashboard';
import TemplateAdminDashboard from './TemplateAdminDashboard'
function AdminDashboard() {
  const [emaillist, setemaillist] = useState([]);
  const [searchList, setSearchList] = useState([]);
  const [filterList, setFilterList] = useState('Emails');
  const [templateList, settemplateList] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const handleFilterChange = event => {
    setFilterList(event.target.value)
    navigate(`${event.target.value === 'Emails' ? "/admin-dashboard" : "/Template-dashboard"}`)
  }
  const fetchemailtemplate = () => {
    axiosInstanceEmailNonBff
      .request({
        method: 'GET',
        url: `/getAllEmailTemplate`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setemaillist(response.data.data.Email);
            setSearchList(response.data.data.Email);
          }
        }
      });
  };
  useEffect(() => {
    fetchemailtemplate();
  }, []);
  useEffect(() => {
    if(filterList === 'Template'){
      axiosInstanceDocument
            .request({
                method: 'GET',
                url: `/getAllMasterTemplate`,
            })
            .then((response) => {
                if (response.data) {
                    setSearchList(response.data.data.DOCUMENT);
                    settemplateList(response.data.data.DOCUMENT);
                }
            })
    }
    if(filterList === 'Emails'){
      fetchemailtemplate();
    }
  }, [filterList]);
  useEffect(() => {
    location.pathname.toLowerCase() === '/admin-dashboard' ? setFilterList('Emails') : setFilterList('Template')
  }, [])
  function updateHandler(response) {
    const res = [];
    searchList.forEach((ele) => {
      if (ele.emailTemplateId === response.emailTemplateId) {
        res.push({ ...response, templateName: ele.templateName });
      } else {
        res.push(ele);
      }
    });
    setemaillist(res)
    setSearchList(res);
  };
  return (
    <div className='admin-dashboard-wrapper'>
      <h1 className="title-heading">{filterList === 'Emails' ? "Email Admin Dashboard" : "Template Admin Dashboard"}</h1>
      <div className="main-container">
        <div className="firstcard-div">
          <div className="top-header">
            <Grid container>
              <Grid item xs={3}>
                <Typography variant="h5" component="h2" className="1">
                  {filterList}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <div className="EmailSearch">
                  
                    <FormControl
                      className="form-control"
                      fullWidth
                      label="fullWidth"
                      id="fullWidth"
                      placeholder="Search"
                      border="none"
                    >
                      <Input
                        fullWidth
                        label="fullWidth"
                        id="fullWidth"
                        startAdornment={
                          <InputAdornment position="start">
                            <SearchIcon className="search-color" />
                          </InputAdornment>
                        }
                        onChange={(e) => {
                          let data = [];
                          if (filterList === 'Emails') {
                            data = emaillist;
                          } else if (filterList === 'Template') {
                            data = templateList;
                          }
                          const searchedVal = data?.filter((ele) =>
                            ele.templateName
                              .toLowerCase()
                              .includes(e.target.value.toLowerCase())
                          );
                          if (searchedVal) {
                            setSearchList(searchedVal);
                          } else {
                            setSearchList(data);
                          }
                        }}
                      />
                    </FormControl>
                 
                </div>
              </Grid>
              <Grid item xs={3}>
                <div class="email-filters">
                  <InputLabel id="demo-simple-select-label">
                    Filters:
                  </InputLabel>
                  <div class="email-filter-left">
                    <Select
                      fullWidth
                      sx={{}}
                      size="small"
                      placeholder="Search"
                      value={filterList}
                      onChange={handleFilterChange}
                    >
                      {/* <MenuItem disabled value="">
                        <em>Placeholder</em>
                      </MenuItem> */}
                      <MenuItem value='Emails'>Emails</MenuItem>
                      <MenuItem value='Template'>Template</MenuItem>

                    </Select>
                  </div>
                  {/* <div class="email-filter-right">
                    <Select
                      fullWidth
                      className="email-select"
                      sx={{}}
                      size="small"
                      placeholder="Search"
                    >
                      <MenuItem value={10}>Ten</MenuItem>
                      <MenuItem value={20}>Twenty</MenuItem>
                      <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {filterList === 'Emails' ?
        <EmailAdminDashboard
          searchList={searchList}
          updateHandler={updateHandler}
          filterList={filterList}
        /> :
        <TemplateAdminDashboard
          filterList={filterList}
          searchList={searchList} />
      }

    </div>
  )
}
export default AdminDashboard;
