import React from 'react';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import '../PageNotFound/index.css';
import Error from '../PageNotFound/Error.png';


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

export default function PageNotFound() {
  return (
    <Paper className='ErrorMsg'
      sx={{
        margin: '10% auto',
        flexGrow: 1,
      }} >
      <Grid container >
        <Grid item xs={12}>
          <img src={Error}/>
          <h2>Page Not Found!</h2>
          <p>The link you followed probably broken or the page has been removed!</p>
        </Grid>
      </Grid> 
    </Paper>
    
  );
  
}

