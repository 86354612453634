const templateidmapper = {
  1: 'Preliminary Customer Information Form',
  2: 'Market Basket',
  3: 'Item list',
  4: 'Customer contact information',
  5: 'Operating Site Alignment & Heat Map',
  6: 'Deep Dive Template',
  7: 'MSA/LOI',
  8: 'Concept Alignment sheet',
  9: 'New Supplier Docs for Corporate Billed Services',
  10: 'Customer Accounts',
  11: 'Credit Application',
  12: ' Tax document',
  13: 'Order Guide',
  14: 'Spec Sheets for all Hard and Prop items',
  15: 'Customer Vendor List',
  16: 'Op Site Vendor List',
  17: 'Special Order Quantities',
  18: 'Active Stock Items',
  19: 'Targeted Inventory (TI) Report',
  20: 'Inventory Transfer Items',
  21: 'Depletion (DSO) Analysis',
  22: 'A/P Cost Variance Analysis',
  23: 'Inventory Transfer Plan',
  24: 'Phase Out Items',
  25: 'Others',
  26:'Spec Sheet',
  27:'ICT Template',
  28:'Bos Sub-Rule Setup',
  29:'Supplier Readiness Documents',
  30:'Ship-Point Maintenance Request',
  31:'OGM Setup',
  34:'EDI Questionnaire',
  67:'OMCC Intake Form',
  68:'Special Orders',
  72:'Inventory Readiness Tracker',
  73:'Request MSRs',
  74:'Request NIRs',
  135: 'Inventory Snapshot',
  166: 'Transfer List',
  167: 'Controlled Pricing setup',
  168: 'Simulate Pricing',
  169: 'Kick Off Template',
  170: 'Vision Report',
};
export default templateidmapper;
// export const PRE_REQ_DOC_TEMPLATE_ID_MAPPER ={
//   // 1: 'Preliminary Customer Information Form',
//   2: 'Market Basket',
//   3: 'Item list',
//   4: 'Customer contact information',
//   5: 'Operating Site Alignment & Heat Map',
//   6: 'Deep Dive Template',
//   7: 'MSA/LOI',
//   10: 'Customer Accounts',
//   15: 'Customer Vendor List',
//   26:'Spec Sheet',
// }
