import React from 'react';
import './index.css';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
} from 'recharts';
export default function Charts(props) {
  const { chartsObj: { disposition = {}, data: chartData = [] } } = props;
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const renderCharts = ({
    type = 'BarChart',
    height,
    width,
    layout,
    title,
    margin,
    legend = true,
    legendProps,
    COLORS,
    element = []
  }, data) => {
    let chartsHtml = null;
    if (type === 'BarChart') {
      chartsHtml = (
        <div className="charts-itme-content">
          <div className="charts-title">{title}</div>
          <BarChart
            width={width}
            height={height}
            layout={layout}
            data={data}
            margin={margin}
          >
            <CartesianGrid strokeDasharray="3 3" />
            {layout === 'horizontal' && (
              <>
                <XAxis dataKey="name" />
                <YAxis />
              </>
            )}
            {layout === 'vertical' && (
              <>
                <XAxis type="number" />
                <YAxis
                  type="category"
                  dataKey="name"
                  interval={0}
                />
              </>
            )}

            <Tooltip />
            {legend && <Legend {...legendProps || {}} />}
            {element.map((item) => (
              <Bar dataKey={item?.name} fill={item?.color} />
            ))}
          </BarChart>
        </div>
      );
    } else if (type === 'PieChart') {
      chartsHtml = (
        <div className="charts-itme-content">
          <div className="charts-title">{title}</div>
          <PieChart width={220} height={280}>
            <Pie
              data={data}
              cx="60%"
              cy="40%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            {legend && <Legend {...legendProps || {}} iconSize={8} iconType={'circle'} layout='vertical' verticalAlign='bottom' align="middle"/>}
          </PieChart>
        </div>
      );
    }

    return <>{chartsHtml}</>;
  };
  return (
    <div className="Chart-dashboard">
      {renderCharts({...disposition, legend: disposition.legend === undefined ? true : disposition.legend}, chartData)}
    </div>
  );
}
