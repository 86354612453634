import { axiosInstanceDocument } from '../api/Axios';

export const ItemCategorizationAPI = async (CustomerId) => {
  const response = await axiosInstanceDocument.request({
    method: 'GET',
    url: `categorization/${CustomerId}`
  });
  return response;
};


export const conciergeIntegrationAPI =  async (CustomerId) => {
  const response = await axiosInstanceDocument.request({
    method: 'GET',
    url: `CreateConcierge/${CustomerId}`
  });
  return response;
};

export const getConciergeIntegrationAPI =  async (CustomerId) => {
  const response = await axiosInstanceDocument.request({
    method: 'GET',
    url: `getConciergeSupportRequestStatus/${CustomerId}`
  });
  return response;
};