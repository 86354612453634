import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Header/index.css';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import sysco_white from '../../../assets/sysco_white.png';
import { Autocomplete, Paper, TextField } from '@mui/material';
import { getCustomerList } from './redux/slice';
import { useDispatch, useSelector } from 'react-redux';

const styles = makeStyles({
  customizeToolbar: {
    minHeight: '6.3%',
    height: '6.3%'
  }
});

const HeaderComp = ({ username }) => {
  let isCustomerPortal =
    localStorage.getItem('customerPortalUser') ? true : false;
  const custlist = useSelector((state) => state?.customerList?.data?.Customer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCustomerList());
  }, [dispatch]);

  const defaultProps = {
    options: custlist,
    getOptionLabel: (option) => option.title
  };
  const [value, setValue] = React.useState(null);
  const [checkCustomerPortal, setCheckCustomerPortal] = React.useState(false);

  useEffect(() => {
    setCheckCustomerPortal(isCustomerPortal);
  }, [isCustomerPortal]);
  const classes = styles();
  const navigate = useNavigate();
  return (
    <div>
      <AppBar
        position="static"
        className="appbar-custom"
        sx={{ height: '6.3%', backgroundColor: '#008CD2' }}
      >
        <Toolbar className={classes.customizeToolbar}>
          <img
            src={sysco_white}
            alt="LOGO"
            width="62.39"
            height="24.17"
            style={{ marginLeft: '6%' }}
          />
          <Divider
            sx={{
              height: 20,
              mr: '0.5%',
              ml: '0.5%',
              border: '1px solid white'
            }}
            orientation="vertical"
          />
          <Typography
            className="product-name"
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            NCOB
          </Typography>
          {checkCustomerPortal === false && (
            <Paper
              component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 330,
                height: 32,
                borderRadius: '16px'
              }}
            >
              <IconButton type="button" sx={{ p: '10px', color: '#1B4F72' }} aria-label="search">
                <SearchIcon />
              </IconButton>
              <Autocomplete
                className="search-name"
                {...defaultProps}
                id="auto-complete"
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                  navigate(`/new-onboarding/${newValue?.customerId}`);
                }}
                autoComplete
                includeInputInList
                sx={{ width: 300 }}
                options={custlist}
                getOptionLabel={(option) => option?.customerName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Search Customer"
                  />
                )}
              />
            </Paper>
          )}

          <IconButton
            sx={{ ml: '1.25%', mr: '1.25%', background: '#007EBD' }}
            className="notification-icon-box"
          >
            <NotificationsNoneIcon className="notification-icon" />
          </IconButton>
          <IconButton sx={{ mr: '1.25%' }} className="user-box">
            {/* <AccountCircleIcon className="user-icon" /> */}
            <span>Welcome, {username}</span>
          </IconButton>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default HeaderComp;
