import './index.css';
import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
  Button,
  Typography,
  Select,
  Checkbox
} from '@mui/material';
import {
  axiosInstanceDocument,
  axiosInstanceDashboard
} from '../../../api/Axios';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useParams } from 'react-router-dom';
import { withSnackbar } from '../../components/SnackBar/SnackBar';
import _ from 'lodash';
const BosOnboarding = (props) => {
  const { snackbarShowMessage, sectionSubmit } = props;
  const initialState = {
    site: [],
    includeExcludeFlag: 'Y',
    requiredApprovalToSub: 'N',
    subOutOfCatalog: 'N',
    subMaintenanceCutOff: '23:30',
    managedBy: 'LOCAL',
    sendStartOfDayEmail: 'Y'
  };

  const [formData, setFormData] = useState(initialState);

  const [siteIdData, setSiteData] = React.useState([]);

  const [customerDetails, setCustomerDetails] = useState({});

  const { customerID } = useParams();

  function handleChange(event) {
    const { name, value, type, checked } = event.target;
    let newData = {};

    if (type === 'checkbox') {
      if (name === 'managedBy') {
        newData = { ...newData, [name]: checked ? 'CENTRAL' : 'LOCAL' };
      } else {
        newData = { ...newData, [name]: checked ? 'Y' : 'N' };
      }
    } else {
      newData = { ...newData, [name]: value };
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...newData
      // [name]: type === "checkbox" ? name === "managedBy" ? checked ? "C" : "L" : checked ? "Y" : "N" : value
    }));
  }

  async function getSiteOptions() {
    await axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getOpcoDetails`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setSiteData(response?.data?.data?.['Opco Details']);
          }
        }
      });
  }

  const fetchlist = () => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getCustomerDetails?id=${customerID}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setCustomerDetails(_.get(response, 'data.data.Customer', {}));
          }
        }
      });
  };

  useEffect(() => {
    getSiteOptions();
    fetchlist();
  }, []);

  siteIdData?.sort((a, b) => parseInt(a.siteNum, 10) - parseInt(b.siteNum, 10));

  const siteIDs = siteIdData?.map((ele) => (
    <MenuItem key={ele.siteNum} value={ele.siteNum}>
      <Checkbox checked={formData.site?.indexOf(ele.siteNum) > -1} />
      <ListItemText primary={ele.siteNum + ' - ' + ele.siteDescription} />
    </MenuItem>
  ));

  async function handleSubmit(event) {
    event.preventDefault();
    if (formData.site?.length > 0) {
      await axiosInstanceDocument
        .request({
          method: 'POST',
          url: `/BOS2_0/createEditAccount/${customerID}`,
          data: formData
        })
        .then((response) => {
          if (response?.data?.errorMessage !== null) {
            snackbarShowMessage({
              message: 'Bos onboarding is not Completed',
              type: 'error'
            });
          } else {
            snackbarShowMessage({
              message: 'Bos onboarding Completed Successfully',
              type: 'success'
            });
            sectionSubmit();
          }
        });
    } else {
      snackbarShowMessage({ message: 'Site ID is required', type: 'error' });
    }
  }

  const handleReset = () => {
    setFormData(initialState);
  };
  const timeOptions = [];
  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes += 30) {
      const formattedHours = hours.toString().padStart(2, '0');
      const formattedMinutes = minutes.toString().padStart(2, '0');
      const time = `${formattedHours}:${formattedMinutes}`;
      timeOptions.push(time);
    }
  }

  return (
    <div className="bos-container">
      <h2 className="heading">BOS Onboarding</h2>
      <form onSubmit={handleSubmit}>
        <div className="select-ids">
          <Typography
            className="field-text"
            style={{ fontWeight: 'bold', fontFamily: 'MyriadPro' }}
          >
            Site ID:
          </Typography>
          <div className="concept-id">
            <Typography
              className="field-text"
              style={{ fontWeight: 'bold', fontFamily: 'MyriadPro' }}
            >
              Concept ID:
            </Typography>
            <Typography style={{ fontFamily: 'MyriadPro' }}>
              {customerDetails?.conceptId}
            </Typography>
          </div>
        </div>

        <FormControl sx={{ m: 2, width: 300 }}>
          <InputLabel>Select Site IDs here</InputLabel>
          <Select
            multiple
            className="select-box"
            data-testid="site-select-box"
            input={<OutlinedInput label="Select Site IDs here" />}
            name="site"
            type="text"
            value={formData.site}
            onChange={handleChange}
            renderValue={(selected) =>
              selected
                .map((value) => {
                  const site = siteIdData.find((ele) => ele.siteNum === value);
                  return site
                    ? site.siteNum + ' - ' + site.siteDescription
                    : '';
                })
                .join(', ')
            }
          >
            {siteIDs}
          </Select>
        </FormControl>

        <div className="switch-line1">
          <div className="switch">
            <Typography
              className="field-text"
              style={{ fontWeight: 'bold', fontFamily: 'MyriadPro' }}
            >
              Include/Exclude
            </Typography>

            <Typography
              variant="body1"
              className="no-text"
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                fontFamily: 'MyriadPro'
              }}
            >
              No
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  name="includeExcludeFlag"
                  data-testid="inc-exc-switch"
                  type="checkbox"
                  checked={formData.includeExcludeFlag === 'Y'}
                  onChange={handleChange}
                />
              }
            />
            <Typography variant="body1" style={{ marginLeft: '-19px' }}>
              Yes
            </Typography>
          </div>

          <div className="switch">
            <Typography
              className="field-text"
              style={{ fontWeight: 'bold', fontFamily: 'MyriadPro' }}
            >
              Required approval to sub
            </Typography>
            <Typography
              variant="body1"
              className="no-text"
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                fontFamily: 'MyriadPro'
              }}
            >
              No
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  name="requiredApprovalToSub"
                  data-testid="req-appoval-switch"
                  type="checkbox"
                  checked={formData.requiredApprovalToSub === 'Y'}
                  onChange={handleChange}
                />
              }
            />

            <Typography variant="body1" style={{ marginLeft: '-19px' }}>
              Yes
            </Typography>
          </div>

          <div className="switch">
            <Typography
              className="field-text"
              style={{ fontWeight: 'bold', fontFamily: 'MyriadPro' }}
            >
              Sub out of Catalog
            </Typography>
            <Typography
              variant="body1"
              className="no-text"
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                fontFamily: 'MyriadPro'
              }}
            >
              No
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  name="subOutOfCatalog"
                  data-testid="sub-out-switch"
                  type="checkbox"
                  checked={formData.subOutOfCatalog === 'Y'}
                  onChange={handleChange}
                />
              }
            />
            <Typography variant="body1" style={{ marginLeft: '-19px' }}>
              Yes
            </Typography>
          </div>
        </div>

        <div className="switch-line2">
          <div className="submaintenance-cutoff">
            <Typography
              className="field-text"
              style={{ fontWeight: 'bold', fontFamily: 'MyriadPro' }}
            >
              Sub maintenance cut-off
            </Typography>

            <Box width="100px" height="20px" className="select-site-ids">
              <TextField
                select
                name="subMaintenanceCutOff"
                type="text"
                data-testid="sub-main-dropdown"
                value={formData.subMaintenanceCutOff}
                onChange={handleChange}
                fullWidth
              >
                {timeOptions.map((timeOption) => (
                  <MenuItem key={timeOption} value={timeOption}>
                    {timeOption}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </div>

          <div className="switch">
            <Typography
              className="field-text"
              style={{ fontWeight: 'bold', fontFamily: 'MyriadPro' }}
            >
              Managed by
            </Typography>
            <Typography
              variant="body1"
              className="no-text"
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                fontFamily: 'MyriadPro'
              }}
            >
              Local
            </Typography>

            <FormControlLabel
              control={
                <Switch
                  name="managedBy"
                  type="checkbox"
                  checked={formData.managedBy === 'CENTRAL'}
                  onChange={handleChange}
                />
              }
            />

            <Typography variant="body1" style={{ marginLeft: '-19px' }}>
              Central
            </Typography>
          </div>

          <div className="switch">
            <Typography
              className="field-text"
              style={{ fontWeight: 'bold', fontFamily: 'MyriadPro' }}
            >
              Send start of day email
            </Typography>
            <Typography
              variant="body1"
              className="no-text"
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                fontFamily: 'MyriadPro'
              }}
            >
              No
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  name="sendStartOfDayEmail"
                  type="checkbox"
                  checked={formData.sendStartOfDayEmail === 'Y'}
                  onChange={handleChange}
                />
              }
            />
            <Typography variant="body1" style={{ marginLeft: '-19px' }}>
              Yes
            </Typography>
          </div>
        </div>
        <hr className="faded-line" />

        <div className="buttons">
          <Button
            variant="outlined"
            className="button"
            style={{
              marginRight: '20px',
              borderRadius: '20px',
              textTransform: 'none',
              width: '100px'
            }}
            data-testid="reset-btn"
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            className="button"
            style={{
              marginRight: '10px',
              borderRadius: '20px',
              textTransform: 'none',
              width: '100px'
            }}
            type="submit"
            data-testid="save-btn"
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};

export default withSnackbar(BosOnboarding);
