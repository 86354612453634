import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Button } from '@mui/material';
import { downloadICTfile } from '../../../utils/downloadICTfile';
import DownloadIcon from '@mui/icons-material/Download';
import { getCustomerProgress } from '../ProgressBar/redux/slice';
import { getICTfileName } from '../../../utils/getICTfileName';
import { downloadConciergeFilestatus } from '../../../utils/downloadConciergeFilestatus';
import './ICTAccordion.css';
import ConciergeTable from './ConciergeTable';

const ICTAccordion = ({
  customerdetails,
  customerID,
  sectionSubmit,
  itemcodingstatus,
  action
}) => {
  const currentstep = useSelector((state) => {
    return state?.storeActiveStep;
  });
  const dispatch = useDispatch();

  const onClickhandler = async () => {
    const res = await getICTfileName(customerdetails.email);
    const fileName = res?.data?.content[0].fileName;
    downloadICTfile(fileName);
  };

  const CONCIERGEFLAG = currentstep?.actionName==='Concierge Integration';
  const handleSubmit = () => {
    sectionSubmit();
  };
  const retry = () => {
    sectionSubmit(false, false, false, false, false, true);
  };
  useEffect(() => {
    const id = setInterval(() => {
      dispatch(getCustomerProgress({ customerId: customerID }));
    }, 120000);
    return () => {
      if (itemcodingstatus === 'Ready') clearInterval(id);
    };
  }, []);
  return (
    <div className="accordion-container">
      <Card>
        {CONCIERGEFLAG && <ConciergeTable status={action === 'FA' ? 'Initiated' : 'Completed'} />}
        {!CONCIERGEFLAG && itemcodingstatus === 'Upload' && (
          <div className="heading">
            Please wait for atleast 5 minutes after Successful File Upload.
          </div>
        )}
        <CardContent>
          <div className="contain">
            <div className="dataconcierge">
              {!CONCIERGEFLAG && itemcodingstatus === 'Ready' && (
                <span data-testid="download" onClick={onClickhandler}>
                  <DownloadIcon /> Available for download
                </span>
              )}
              {!CONCIERGEFLAG && itemcodingstatus === 'Upload' && (
                <span>
                  File Not Ready for Download.Please check back after sometime.
                </span>
              )}
              {!CONCIERGEFLAG && itemcodingstatus === 'Failed' && (
                <span data-testid="retry" onClick={retry}>
                  File upload Failed. Click here to reupload
                </span>
              )}
            </div>
          </div>
        </CardContent>
        {sectionSubmit && (
          <div className="button-wrapper">
            <Button
              sx={{ marginTop: 1 }}
              id="secondary"
              className="markbutton"
              data-testid="submit-btn"
              onClick={handleSubmit}
              // disabled={disableSubmit}
            >
              Mark this step as complete
            </Button>
          </div>
        )}
      </Card>
    </div>
  );
};

export default ICTAccordion;
