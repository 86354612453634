import { axiosInstanceICT } from '../api/Axios';

export const  getICTfileName = async (email) => {
 let values ={
    "date":"",
    "page":1,
    "pageSize":5,
    "sortDirection":"",
    "sortField":"",
    "userId":`${email}`
    }
  const response = await axiosInstanceICT
    .request({
      method: 'POST',
      url: `/item/batchRequest`,
      data: values
    })
   return response
};
