import { createSlice } from '@reduxjs/toolkit';
import { redirectUrl } from '../../../../aws-config';

//Initializing state
const initialState = {};

//Creating Reducers functions
export const templateSlice = createSlice({
  name: 'CustomerList',
  initialState,
  reducers: {
    getCustomerList(state, action) {
      return { ...state, ...action.payload };
    },
    getCustomerListSuccess(state, action) {
      return { ...state, ...action.payload };
    },
    getCustomerListError(state, action) {
      if(action?.payload?.response?.status  === 401){
        localStorage.removeItem('userInfo');
        //remove role info
        localStorage.removeItem('userRole');
        window.location.assign(redirectUrl);
        return false;
      }
      return { ...state, ...action.payload };
    }
  }
});

//Action creators
export const { getCustomerList, getCustomerListSuccess, getCustomerListError } =
  templateSlice.actions;

export default templateSlice.reducer;
