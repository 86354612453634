import { createGlobalStyle } from 'styled-components';
import regular from './MyriadPro/MyriadPro-Regular.otf';
import bold from './MyriadPro/MyriadPro-Bold.otf';


const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: 'MyriadPro';
  font-style: normal;
  src: url(${regular}) format('woff'), /* chrome、firefox */
       url(${regular}) format('opentype');
}
@font-face {
  font-family: 'MyriadPro-Bold';
  font-style: normal;
  src: url(${bold}) format('woff'), /* chrome、firefox */
       url(${bold}) format('opentype');
}

body{ 
  font-family: MyriadPro,Serif;
  font-size: 14px;
  letter-spacing: 0.2px;
 }
`;

export default GlobalStyles;
