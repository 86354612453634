import { put, call } from 'redux-saga/effects';
import {
  updateCustomerProgressSuccess,
  updateCustomerProgressError
} from './slice';
import requestTemplate from './requestTemplate';

export function* handleupdateCustomerProgressStatus(action) {
  try {
    const response = yield call(requestTemplate, action);
    yield put(updateCustomerProgressSuccess(response.data.data));
  } catch (error) {
    yield put(updateCustomerProgressError(error));
  }
}
