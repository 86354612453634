import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, Chip } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { useNavigate } from 'react-router-dom';
import usePagination from '../../containers/Home/Pagination';
import { format } from '../../../utils/dateFormatter';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

const headCells = (value) => [
  {
    id: value === 'LOCATION' ? 'accountName' : 'conceptName',
    numeric: false,
    disablePadding: true,
    label: value === 'LOCATION' ? 'Account Name' : 'Concept Name'
  },
  {
    id: 'status',
    numeric: true,
    disablePadding: false,
    label: 'Status'
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email'
  },
  {
    id: 'joinedDate',
    numeric: true,
    disablePadding: false,
    label: 'Joined Date'
  },
  {
    id: 'modifiedDate',
    numeric: true,
    disablePadding: false,
    label: 'Modified Date'
  },
  {
    id: 'actions',
    numeric: true,
    disablePadding: false,
    label: 'Actions'
  }
];
const ACCOUNT_ID_COL = {
    id: 'accountId',
    numeric: false,
    disablePadding: false,
    label: 'Account Id'
    };
const BILL_TYPE_COL = {
      id: 'billType',
      numeric: false,
      disablePadding: false,
      label: 'Bill Type'
    };
const CONECEPT_ID = {
  id: 'conceptId',
  numeric: false,
  disablePadding: false,
  label: 'Concept Id'
}
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, value,isCompleted } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  let columns = headCells(value); 
  if(value === 'LOCATION' && isCompleted){
    columns.splice(1,0,ACCOUNT_ID_COL,BILL_TYPE_COL);
  }
  if(value === 'CONCEPT' && isCompleted){
    columns.splice(1,0,CONECEPT_ID);
  }
  return (
    <TableHead>
      <TableRow>
        {columns?.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'eft' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              IconComponent={KeyboardArrowDownIcon}
              size="large"
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function EnhancedTable({ value, searchList, parentCustID,isCompleted }) {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(
    value === 'LOCATION' ? 'accountName' : 'conceptName'
  );

  const [selected, setSelected] = useState([]);
  const [isCustomerPortalUser, setIsCustomerPortalUser] = useState(false);
  const navigate = useNavigate();
  function handleRequestSort(event, property) {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }
  let isCustomerPortal =
    localStorage.getItem('customerPortalUser') ? true : false;

  useEffect(() => {
    setIsCustomerPortalUser(isCustomerPortal);
  }, [isCustomerPortal]);

  function handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelected = searchList?.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  }

  const PER_PAGE = 5;

  let [paginationPage, setPaginationPage] = useState(1);
  const count = searchList && Math.ceil(searchList?.length / PER_PAGE);
  const _DATA = usePagination(
    stableSort(searchList, getComparator(order, orderBy)),
    PER_PAGE
  );
  const handleChange = (e, p) => {
    setPaginationPage(p);
    _DATA.jump(p);
  };
  useEffect(() => {
    if (_DATA.currentData() <= 5) {
      setPaginationPage(count);
      _DATA.jump(count);
    }
  }, [count]);
  function formatStyle(status){
    const newColorCodes = {
      'On Track': {
        color: '#56B146',
        bgColor: '#E6F4E4'
      },
      'At Risk': {
        color: '#FF8A00',
        bgColor: '#FFEED9'
      },
      'Not Started': {
        color: 'white',
        bgColor: '#515050'
      },
      'Steady State': {
        color: '#008CD2',
        bgColor: '#E5F3FA'
      },
      'On Hold': {
        color: '#6A737B',
        bgColor: '#F1F2F2'
      },
      'Requires Attention': {
        color: 'red',
        bgColor: '#ead1d5'
      }
    };
    const getHexCode = newColorCodes[status];
    return (
          <div
            className="status-text"
            style={{
              backgroundColor: getHexCode?.bgColor,
              color: getHexCode?.color,
              width:'max-content'
            }}
          >
            {status}
          </div>)

  }
  return (
    <Box sx={{ width: '100%', mb: '50px' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            className="progress-table"
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={searchList?.length}
              value={value}
              searchList={searchList}
              isCompleted={isCompleted}
            />
            <TableBody>
              {_DATA &&
                _DATA.currentData()?.map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow key={row.customerId}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.accountName || row.conceptName}
                      </TableCell>
                      {isCompleted && value === 'LOCATION' &&
                        <TableCell align="center">{row?.accountId}</TableCell>
                      }
                      {isCompleted && value === 'LOCATION' &&
                        <TableCell align="center">{row?.billType}</TableCell>
                      }
                      {isCompleted && value === 'CONCEPT' &&
                        <TableCell align="center">{row?.conceptId}</TableCell>
                      }
                      <TableCell align="center">
                        {formatStyle(row.progressStatus)}
                      </TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">
                        {format(row.joinedDate)}
                      </TableCell>
                      <TableCell align="center">
                        {format(row.modifiedDate)}
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          variant="text"
                          startIcon={<RemoveRedEyeIcon />}
                          onClick={() => {
                            navigate(
                              `${
                                isCustomerPortalUser
                                  ? `/Dashboard/${parentCustID}/new-onboarding/${row.customerId}?name=customerportaluser`
                                  : `new-onboarding/${row.customerId}`
                              }`
                            );
                            setTimeout(()=>{window.location.reload();},[1000])
                          }}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
          {searchList?.length === 0 && (
            <p style={{ display: 'flex', justifyContent: 'center' }}>
              No Results Found
            </p>
          )}
        </TableContainer>
        <Stack spacing={2}>
          <Pagination
            className="customPagination"
            count={count}
            size="large"
            page={paginationPage}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
            sx={{ padding: '20px', display: 'flex', justifyContent: 'center' }}
          />
        </Stack>
      </Paper>
    </Box>
  );
}
