import { combineReducers } from '@reduxjs/toolkit';
import customerlistreducer from './components/Header/redux/slice';
import getCustomerProgressReducer from './components/ProgressBar/redux/slice';
import getAllActionsReducer from './containers/SalesDashboard/redux/slice';
import updateCustomerProgressReducer from './containers/templateComponent/redux/slice'
import accounttypereducer from './containers/templateComponent/redux/accounttypeSlice'
import emailListReducer from './components/CustomerForm/redux/slice'
import updatePaginationPage from './containers/Home/redux/slice'
import userRoleReducer from './components/Sidebar/redux/slice';

const appReducer = combineReducers({
  customerList: customerlistreducer,
  getCustomerProgress: getCustomerProgressReducer,
  getAllActions :getAllActionsReducer,
  updateprogressstatus: updateCustomerProgressReducer,
  storestep: updateCustomerProgressReducer,
  storeActiveStep: updateCustomerProgressReducer,
  AccountType: accounttypereducer,
  emailList:emailListReducer,
  paginationPage:updatePaginationPage,
  userRole: userRoleReducer,
  storeManualCreateNationalId: updateCustomerProgressReducer,
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'Logout/logout') {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
