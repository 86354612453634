import { createSlice } from '@reduxjs/toolkit';
import { redirectUrl } from '../../../../aws-config';

//Initializing state
const initialState = {};

//Creating Reducers functions
export const templateSlice = createSlice({
  name: 'EmailList',
  initialState,
  reducers: {
    getEmailList(state, action) {
      return { ...state, ...action.payload };
    },
    getEmailListSuccess(state, action) {
      return { ...state, ...action.payload };
    },
    getEmailListError(state, action) {
      if(action?.payload?.response?.status  === 401){
        localStorage.removeItem('userInfo');
        //remove role info
        localStorage.removeItem('userRole');
        window.location.assign(redirectUrl);
        return false;
      }
      return { ...state, ...action.payload };
    }
  }
});

//Action creators
export const { getEmailList, getEmailListSuccess, getEmailListError } =
  templateSlice.actions;

export default templateSlice.reducer;
