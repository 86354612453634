
export function format(inputDate) {
  let date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
    return (
      ('0' + (date.getMonth() + 1)).slice(-2)+
      '/' +
      ('0' + date.getDate()).slice(-2) +
      '/' +
      date.getFullYear()
    );
  }
}
