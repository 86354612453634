import React, { useEffect } from 'react';
import '../ProgressBar/index.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import tempcustomer from '../../../Helper/customerpreogressdatastatus';
import { styled } from '@mui/material/styles';
import { CardClickCheck } from '../../../utils/CardClickcheck';
import { getCustomerProgress, resetGetCustomerProgress } from './redux/slice';
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector';
import { CustomerProgressmapping } from '../../../utils/CustomerProgressmapping';
import { useDispatch, useSelector } from 'react-redux';
import { Chip } from '@mui/material';
import { mapping } from '../../../Helper/mapping';
import _ from 'lodash';

const FONT_MYRIAD = 'MyriadPro';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#D9EEF9',
      marginTop: '0',
      marginBottom: '0',
      height: '50%'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#D9EEF9',
      marginTop: '0',
      marginBottom: '0',
      height: '50%'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderTopWidth: 3,
    borderRadius: 1,
    borderColor: '#D9EEF9',
    marginTop: '0',
    marginBottom: '0',
    height: '50%'
  }
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#008CD2',
    fontFamily: FONT_MYRIAD
  }),
  '& .QontoStepIcon-completedIcon .QontoStepIcon-checkcircle': {
    color: '#008CD2',
    marginLeft: '-2px',
    width: 20
  },
  '& .QontoStepIcon-circle': {
    width: 5,
    height: 5,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    border: '4px solid #E5F3FA;',
    color: 'white',
    fontFamily: FONT_MYRIAD,
    marginLeft: '1px'
  },
  '& .QontoStepIcon-activeIcon': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    border: '4px solid #E5F3FA;',
    color: '#008CD2',
    fontFamily: FONT_MYRIAD
  }
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {active && <div className="QontoStepIcon-activeIcon" />}
      {completed && (
        <div className="QontoStepIcon-completedIcon">
          <CheckCircleIcon className="QontoStepIcon-checkcircle" />
        </div>
      )}
      {!completed && !active && <div className="QontoStepIcon-circle" />}
    </QontoStepIconRoot>
  );
}

const ProgressBar = (props) => {
  let stepstatus = null;
  const dispatch = useDispatch();
  const { setStep, setActiveSection, cRef, acctype, downloadeddocstatus,setSectionClick } =
    props;

  useEffect(() => {
    if (props.customerId) {
      dispatch(resetGetCustomerProgress());
      dispatch(getCustomerProgress({ customerId: props.customerId }));
    }
  }, [dispatch, props.customerId]);

  const docmappings = {
    'Vendor Setup': 'Item list',
    'Account Setup': 'Customer Accounts',
    'Order Guide Setup': 'Item list',
    'Pricing Setup': 'MSA/LOI'
  };

  const newsteps = useSelector(
    (state) => state?.getAllActions['Action Details']
  );
  const customerProgressData = useSelector(
    (state) => state?.getCustomerProgress?.data?.['Customer Progress']
  );
  // console.log(customerProgressData)
  function SectionClickCheck(sectionid, step) {
    for (const element of step.sectionResponse) {
      let item = mapping?.[acctype]?.find(
        (key) => key?.actionName === element.actionName
      );

      if (
        CardClickCheck(
          element.actionName,
          acctype,
          stepstatus,
          item,
          downloadeddocstatus
        )
      ) {
        return true;
      }
    }
    return false;
  }
  if (customerProgressData) {
    stepstatus = CustomerProgressmapping(customerProgressData?.actionItems);
  } else {
    stepstatus = CustomerProgressmapping(
      tempcustomer?.data['Customer Progress'].actionItems
    );
  }
  return (
    <Box>
      <Stepper
        activeStep={customerProgressData?.completedSections}
        orientation="vertical"
        connector={<QontoConnector />}
      >
        {newsteps &&
          newsteps?.map((step) => {
            const pendingCount = customerProgressData?.actionItems?.filter(
              (ele) =>
                !(ele?.sectionName == ele?.actionName && ele?.actionId < 10) &&
                ele?.sectionName === step['sectionName'] &&
                (ele.sectionStatus === 'Completed' ||
                  ele.sectionStatus === 'Skipped')
            )?.length;
            const ifBusniessEvent = customerProgressData?.actionItems?.filter(
              (item) => item?.actionName === 'Business Event' && item.sectionStatus === 'Completed'
            )
            
            const mappedData = step?.sectionResponse
              ?.map((ele) =>
                mapping?.[acctype]?.find(
                  (item) => item?.actionName === ele?.actionName
                )
              )
              .filter(function (element) {
                return element !== undefined;
              });


           let status = 'pending';
           if (pendingCount == mappedData?.length) {
            status = 'completed'
          //  } else if (_.get(stepstatus, step.sectionName === 'ACTIVE')) {
           } else if(ifBusniessEvent?.length > 0){
            status = 'active';
          //  } else if (_.get(_.find(downloadeddocstatus, (item) => item.docName === _.get(docmappings, step.sectionName)), 'isApproved') === 'Approved') {
          //   status = 'otherActive'
          //  }
          //   else if (_.get(stepstatus, step.sectionName) !== 'Completed' && (step.sectionName === 'Service Level Readiness' || step.sectionName === 'Inventory Transfer')) {
          //   status = 'serviceLevelReadiness'
           } 
           else {
            status = 'pending';
           }
           const statusMapping = {
            pending: {
              className: 'step-label-pending',
              suffixNum: pendingCount || 0,
              style: {
                background: '#F1F2F2',
                color: '#6A737B'
              }
            },
            completed: {
              className: 'step-label-completed',
              suffixNum: mappedData?.length,
            },
            active: {
              className: 'step-label-active',
              suffixNum: pendingCount || 0
            },
            otherActive: {
              className: 'step-label-active',
              suffixNum: pendingCount || 0,
              style: {
                background: '#F1F2F2',
                color: '#6A737B'
              }
            },
            // serviceLevelReadiness: {
            //   className: 'step-label-active',
            //   suffixNum: pendingCount || 0,
            // },
           }
            return (
              <Step key={step.actionName}>
                <StepLabel
                  sx={{ padding: '0', marginLeft: '5px' }}
                  StepIconComponent={QontoStepIcon}
                >
                  <div
                    data-testid="step"
                    className="step-label"
                    onClick={() => {
                        (SectionClickCheck(step.sectionId, step) &&
                        (setStep(step.sectionId) ||
                          localStorage.setItem(
                            `currentstep_${props.customerId}`,
                            step.sectionId
                          ))) ||
                        setActiveSection({ action: '' }) || setSectionClick(true) ||
                        cRef.current.goTo(0);
                     
                    }}
                  >
                     <span className={_.get(statusMapping, `${status}.className`)}>
                          {step.sectionName}&nbsp;
                          <Chip
                            label={`${_.get(statusMapping, `${status}.suffixNum`)} out of ${mappedData?.length}`}
                            variant="outlined"
                            size="small"
                            sx={{
                              border: 'none',
                              fontWeight: 400,
                              background: '#E5F3FA',
                              color: '#008CD2',
                              ..._.get(statusMapping, `${status}.style`, {})
                            }}
                          />
                        </span>
                  </div>
                </StepLabel>
              </Step>
            );
          })}
      </Stepper>
    </Box>
  );
};

export default ProgressBar;
