import {
  API_STEP_APP,
  API_PRICING_APP,
  API_OGM_APP,
  LINK_SALESFORCE,
  LINK_SUS,
  API_LINK_PCI
} from '../constant/api';
const CUSTOMER_DETAILS = 'Customer Details';
const BUSINESS_EVENT = 'Business Event';
const BUSINESS_EVENT_ACKNOWLEDGE =
  'Are you sure you want to trigger the business event?';
const PRE_REQ_DOCS = 'Pre-Req Docs';
const OPSITE_ALIGNMENT = 'Opsite Alignment';
const NCOB_REVIEW = 'NCOB Review';
const TRIGGER_BUSINESS_EVENT = 'Trigger Business Event';
const CREATE_NATIONAL_ID = 'Create National ID';
const CREATE_NATIONAL_ID_ACKNOWLEDGE =
  'Are you sure you want to create the national ID?';
const AE_ALIGNMENT = 'Account Owner Alignment';
const CREDIT_APPLICATIONS = 'Credit Applications';
const CREATE_ACCOUNT = 'Create Account';
const ROUTING_SETUP = 'Routing Setup';
const BUSINESS_TRIGGER = 'Business Event Triggered!';
const NATIONAL_ID_TRIGGER = 'Create national id request has been submitted';

export const mapping = {
  CMU: [
    {
      actionId: 14,
      actionName: CUSTOMER_DETAILS,
      template: 'Form',
      fallbackScreen: false,
      completedInformation: true,
      viewinformation: [
        'website :  ',
        'Total number of OpSite: ',
        'Total number of Locations: ',
        'Number of prop items: ',
        'Yearly estimated values: ',
        'Intake form due date: '
      ]
    },

    {
      actionId: 15,
      actionName: PRE_REQ_DOCS,
      templateId: [2, 3, 26, 4, 5, 6, 7, 10, 15],
      template: 'File',
      // bundledWith: [CUSTOMER_DETAILS],
      fallbackScreen: false,
      showUploadList: true,
      showMarkBtn: true,
      operations: ['download'],
      bundledWithDocUsedByClickCard: ['Customer contact information','Operating Site Alignment & Heat Map','Deep Dive Template',
      'Item list','MSA/LOI','Customer Accounts','Customer Vendor List'],
    },
    {
      actionId: 16,
      actionName: 'Finance Approval',
      template: 'Mail',
      mailTemplateID: 1,
      fallbackScreen: true,
      fallbackTemplate: 'ack',
      labels: [
        "I provide the financial approval for this customer's onboarding"
      ],
      completedInformation: true,
      viewinformation: ['Finance Approval was given by : '],
      // bundledWith: [CUSTOMER_DETAILS]
    },
    {
      actionId: 17,
      actionName: OPSITE_ALIGNMENT,
      template: 'Mail',
      mailTemplateID: 2,
      fallbackScreen: true,
      fallbackTemplate: 'ack',
      labels: ['I confirm that the Opsite is aligned to service this Customer'],
      completedInformation: true,
      viewinformation: ['Opsite Aligment approved by :  '],
      // bundledWith: [CUSTOMER_DETAILS]
    },
    {
      actionId: 18,
      templateId: [],
      actionName: BUSINESS_EVENT,
      template: '',
      triggerPopup: true,
      createServiceRequest: true,
      triggerPopupTitle: TRIGGER_BUSINESS_EVENT,
      triggerPopupDesc: BUSINESS_EVENT_ACKNOWLEDGE,
      triggerPopupSuccess: BUSINESS_TRIGGER,
      isBusinessEvent: true,
      bundledWith: [CUSTOMER_DETAILS],
      fallbackScreen: false
    },
    {
      actionId: 19,
      actionName: 'NCOB Review',
      showReviewTemplate: [1, 2, 3, 26, 4, 5, 6, 7, 10, 15],
      template: 'Review',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 20,
      actionName: 'Kick Off',
      template: 'Mail',
      mailTemplateID: 3,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 21,
      actionName: 'CST Alignment',
      template: 'Mail',
      mailTemplateID: 4,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 22,
      actionName: 'Supplier OMCC',
      template: 'Mail',
      mailTemplateID: 5,
      //bundledWithDoc: 'Customer Vendor List',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 23,
      actionName: CREATE_NATIONAL_ID,
      template: '',
      triggerPopup: true,
      createServiceRequest: true,
      triggerPopupTitle: CREATE_NATIONAL_ID,
      triggerPopupDesc: CREATE_NATIONAL_ID_ACKNOWLEDGE,
      triggerPopupSuccess: NATIONAL_ID_TRIGGER,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 24,
      actionName: 'Credit & DPM Analysts',
      template: 'Mail',
      mailTemplateID: 6,
      fallbackScreen: true,
      fallbackTemplate: 'dynamicInput',
      labels: ['Credit Analyst', 'DPM Analyst'],
      completedInformation: true,
      viewinformation: ['Credit Analyst :  ', 'DPM Analyst : '],
      bundledWith: [BUSINESS_EVENT]
    },

    {
      actionId: 29,
      actionName: 'Account Owner Alignment',
      template: 'Mail',
      mailTemplateID: 8,
      fallbackScreen: true,
      fallbackTemplate: 'Alignment',
      completedInformation: true,
      viewinformation: ['Account Owner Alignment Approval was given by : '],
      //bundledWithDoc: 'Customer Accounts',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Customer Accounts',
    },
    {
      actionId: 30,
      actionName: 'Concierge Integration',
      template: 'Text',
      label:
        'The document of type ‘Customer Approved Customer Accounts’ will be used to push the Concierge data to Salesforce. Please make sure to upload the document with Customer Accounts',
      fallbackScreen: true,
      fallbackTemplate: 'File_Accordion',
      enableConciergeFlag: true,
      bundledWith: [BUSINESS_EVENT],
      categorizationflag: false,
      bundledWithDocUsedByClickCard: 'Customer Accounts'
    },
    {
      actionId: 31,
      actionName: ROUTING_SETUP,
      template: 'Mail',
      mailTemplateID: 10,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false,
      bundledWithDocUsedByClickCard: 'Customer Accounts'
    },
    {
      actionId: 32,
      actionName: 'EDI Questionnaire',
      template: 'Mail',
      mailTemplateID: 29,
      enableSkip: true,
      fallbackScreen: true,
      fallbackTemplate: 'File',
      templateId: [34],
      bundledWith: [BUSINESS_EVENT],
      //bundledWithDoc: 'Customer Accounts',
      bundledWithDocUsedByClickCard: 'Customer Accounts',
    },
    {
      actionId: 33,
      actionName: 'EDI Setup',
      template: 'Mail',
      mailTemplateID: 11,
      enableSkip: true,
      //bundledWithDoc: 'Customer Accounts',
      maildocmandatory: 'Yes',
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Customer Accounts',
      attachment: true
    },
    {
      actionId: 34,
      actionName: 'Sysco Pay Request',
      template: 'Mail',
      mailTemplateID: 12,
      // bundledWith: ['Concierge Integration'],
      bundledWithDocUsedByClickCard: 'Customer Accounts',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 35,
      actionName: 'Shop Request',
      template: 'Mail',
      mailTemplateID: 13,
      enableSkip: true,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Customer Accounts',
      fallbackScreen: false
    },
    {
      actionId: 36,
      actionName: 'OGM Setup',
      templateId: [31],
      template: 'File',
      mailTemplateID: 27,
      fallbackScreen: true,
      fallbackTemplate: 'Mail',
      fallbackScreenactivated: false,
      mailattachflag: true,
      //bundledWithDoc: 'Item list',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      maildocmandatory: 'Yes'
    },
    {
      actionId: 37,
      actionName: 'Item Coding',
      template: 'File',
      templateId: [27],
      //bundledWithDoc: 'Item list',
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: true,
      fallbackTemplate: 'File_Accordion',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      enableICTcoding: true
    },
    {
      actionId: 38,
      actionName: 'New Items',
      template: 'URLredirect',
      URLtext: 'Go to the STEP App from here ',
      hyperlinktext: 'STEP App Enter',
      hyperlinkURL: `${API_STEP_APP}`,
      //bundledWithDoc: 'Item list',
      bundledWithDocUsedByClickCard: 'Item list',
      enableSkip: true,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 39,
      actionName: 'Customer Approval',
      template: 'Mail',
      mailTemplateID: 19,
      // bundledWith: ['OGM Setup', 'Item Coding', 'New Items'],
      fallbackScreen: true,
      fallbackTemplate: 'Text',
      label: 'Has the Customer approved all the Items in the Order Guide?',
      statuslinkage: true,
      linkeditems: [37, 38, 39],
      maildocmandatory: 'Yes',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      attachment: true
    },
    {
      actionId: 40,
      actionName: 'Costing',
      fallbackScreen: false,
      template: 'Mail',
      mailTemplateID: 20,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list'
      // bundledWith: ['OGM Setup', 'Item Coding', 'New Items']
    },
    {
      actionId: 41,
      actionName: 'Order Guide Creation',
      template: 'URLredirect',
      URLtext: 'Go to the OGM App from here ',
      hyperlinktext: 'OGM App Enter',
      hyperlinkURL: `${API_OGM_APP}`,
      bundledWith: [BUSINESS_EVENT],
      // bundledWith: ['Customer Approval'],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 42,
      actionName: 'BOS Onboarding',
      template: 'BOS_Onboarding',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      // bundledWith: ['OGM Setup', 'Item Coding', 'New Items'],
      fallbackScreen: false
    },
    {
      actionId: 43,
      actionName: 'BOS Sub-Rule Setup',
      templateId: [28],
      template: 'File',
      bosSubRule: true,
      bundledWith: [BUSINESS_EVENT,'BOS Onboarding'],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 44,
      actionName: 'Item Categorization',
      template: 'Text',
      // bundledWith: ['Customer Approval'],
      label:
        'The document of type ‘Customer Approved Order Guide’ will be used to push the Item Categorization data to Salesforce. Please make sure to upload the document with Customer Approved Item Categories',
      categorizationflag: true,
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Order Guide',
      enableConciergeFlag: false
    },
    {
      actionId: 45,
      actionName: 'Distributor Change Notification',
      template: 'ack',
      labels: [
        'Confirm if the suppliers are notified about the distributor change'
      ],
      //bundledWithDoc: 'Item list',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 46,
      actionName: 'Supplier Questionnaire',
      template: 'Mail',
      mailTemplateID: 21,
      bundledWith: [BUSINESS_EVENT],
      // bundledWith: ['Distributor Change Notification'],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 47,
      actionName: 'Supplier Readiness Documents',
      templateId: [29],
      template: 'File',
      bundledWith: [BUSINESS_EVENT],
      // bundledWith: ['Supplier Questionnaire'],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 48,
      actionName: 'New Vendor Request',
      template: 'URLredirect',
      URLtext: 'Go to the STEP App from here ',
      hyperlinktext: 'STEP App Enter',
      hyperlinkURL: `${API_STEP_APP}`,
      bundledWith: [BUSINESS_EVENT],
      // bundledWith: ['Supplier Questionnaire'],
      bundledWithDocUsedByClickCard: 'Item list',
      enableSkip: true,
      fallbackScreen: false
    },
    {
      actionId: 49,
      actionName: 'Supplier Doc to Risk Management',
      template: 'Mail',
      mailTemplateID: 22,
      bundledWith: [BUSINESS_EVENT],
      // bundledWith: ['Supplier Questionnaire'],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 50,
      actionName: 'Ship-Point Maintenance Request',
      templateId: [30],
      template: 'File',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      // bundledWith: ['Supplier Questionnaire'],
      fallbackScreen: false
    },
    {
      actionId: 51,
      actionName: 'Ship-Point Maintenance',
      template: 'URLredirect',
      URLtext: 'Go to the STEP App from here ',
      hyperlinktext: 'STEP App Enter',
      hyperlinkURL: `${API_STEP_APP}`,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      // bundledWith: ['Supplier Questionnaire'],
      enableSkip: true,
      fallbackScreen: false
    },
    {
      actionId: 52,
      actionName: 'Supplier Aggreements',
      template: 'Mail',
      mailTemplateID: 23,
      //bundledWithDoc: 'MSA/LOI',
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      enableSkip: true,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 53,
      actionName: 'DPM Request',
      template: 'Mail',
      mailTemplateID: 18,
      // bundledWith: ['Supplier Aggreements'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 54,
      actionName: 'Controlled Pricing setup',
      templateId: [167],
      template: 'File',
      fallbackScreen: true,
      fallbackTemplate: 'Mail',
      fallbackScreenactivated: false,
      mailattachflag: true,
      mailTemplateID: 24,
      // bundledWith: ['Supplier Aggreements'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI'
    },
    {
      actionId: 55,
      actionName: 'Simulate Pricing',
      templateId: [168],
      template: 'File',
      fallbackScreen: true,
      fallbackTemplate: 'URLredirect',
      fallbackScreenactivated: false,
      // template: 'URLredirect',
      urltextSecond: 'Go to the PCI Cloud App from here',
      hyperlinktextSecond: ' Enter',
      URLtext: 'Go to the Simulate Pricing App from here ',
      hyperlinktext: ' Enter',
      hyperlinkURL: `${API_PRICING_APP}`,
      hyperlinkSecondURL: `${API_LINK_PCI}`,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      // bundledWith: ['Controlled Pricing setup', 'DPM Request'],
    },
    {
      actionId: 56,
      actionName: 'Simulated Pricing to VPNA',
      template: 'Mail',
      mailTemplateID: 25,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      // bundledWith: ['Simulate Pricing'],
      fallbackScreen: false
    },
    {
      actionId: 57,
      actionName: 'Sign Off on Simulated Pricing',
      template: 'ack',
      labels: ['I confirm that the Signed Off on Simulated Pricing'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      // bundledWith: ['Simulated Pricing to VPNA'],
      fallbackScreen: false
    },
    {
      actionId: 58,
      actionName: 'OMCC Intake Form',
      templateId: [67],
      template: 'File',
      mailTemplateID: 34,
      fallbackScreen: true,
      fallbackTemplate: 'Mail',
      fallbackScreenactivated: false,
      mailattachflag: true,
      bundledWith: [BUSINESS_EVENT],
      maildocmandatory: 'Yes'
    },
    {
      actionId: 62,
      actionName: 'Special Orders',
      templateId: [68],
      template: 'File',
      mailTemplateID: 35,
      fallbackScreen: true,
      fallbackTemplate: 'Mail',
      fallbackScreenactivated: false,
      mailattachflag: true,
      bundledWith: [BUSINESS_EVENT],
      // bundledWithDoc: 'Item list',
      maildocmandatory: 'Yes'
    },
    {
      actionId: 70,
      actionName: 'Inventory Management COE',
      template: 'Mail',
      bundledWith: [BUSINESS_EVENT],
      mailTemplateID: 70
    },
    {
      // template: 'Mail',
      // mailTemplateID: 72,
      template: 'URLredirect',
      actionId: 77,
      bundledWith: [BUSINESS_EVENT],
      actionName: 'Monitor PO Status'
    },
    {
      actionId: 79,
      actionName: 'Inventory Transfer Complete',
      template: 'Mail',
      bundledWith: [BUSINESS_EVENT],
      mailTemplateID: 74
    },
    {
      actionId: 71,
      actionName: 'Confirm Sysco Account Setup with Exiting Distributor',
      template: 'Confirm',
      bundledWith: [BUSINESS_EVENT],
      confirmText: 'Confirm Account Setup with exiting Distributor.'
    },
    {
      actionId: 72,
      actionName: 'Confirm Distributor Setup as a Supplier',
      template: 'Confirm',
      bundledWith: [BUSINESS_EVENT],
      confirmText: 'Confirm that the Distributor is setup as a Supplier.'
    },
    {
      actionId: 7,
      actionName: 'Inventory Management',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, inventory management is completed?',
      triggerPopupSuccess: 'Inventory Management marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 8,
      actionName: 'Inventory Transfer',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, inventory transfer is completed?',
      triggerPopupSuccess: 'Inventory Transfer marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 9,
      actionName: 'Validation',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, validation is completed?',
      triggerPopupSuccess: 'Validation marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 10,
      actionName: 'Training',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, training is completed?',
      triggerPopupSuccess: 'Training marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 11,
      actionName: 'Go-Live',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, go-live is completed?',
      triggerPopupSuccess: 'Go-Live marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 12,
      actionName: 'Hyper Care',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, hypercare is completed?',
      triggerPopupSuccess: 'HyperCare marked as completed',
      fallbackScreen: false
    },
    {
      actionId: 13,
      actionName: 'Steady State',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, steady state is completed?',
      triggerPopupSuccess: 'Steady State marked as completed',
      fallbackScreen: false
    },
    {
      actionId: 59,
      actionName: 'OMCC Inventory Call',
      template: 'DateOrTime',
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      slowBlueBG: true
    },
    {
      actionId: 60,
      actionName: 'Request NIRs/MSRs',
      templateId: [73, 74],
      template: 'File',
      fallbackScreen: false,
      isMsRsOrNIRS: true,
      bundledWith: [BUSINESS_EVENT],
      bosSubRule: true
    },
    {
      actionId: 61,
      actionName: 'Host Field Sales Readiness',
      template: 'DateOrTime',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 63,
      actionName: 'Inventory Readiness Tracker',
      templateId: [72],
      template: 'File',
      fallbackScreen: false,
      mailattachflag: true,
      bosSubRule: false,
      showUploadList: true,
      operations: ['download', 'delete'],
      showMarkBtn: true,
      maildocmandatory: 'Yes',
      bundledWith: [BUSINESS_EVENT],
      slowBlueBG: true
    },
    {
      actionId: 68,
      actionName: 'Host Inventory Transfer Kickoff Call',
      template: 'DateOrTime',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 69,
      actionName: 'Obtain Internal Sign off for Transfer List',
      template: 'DateOrTime',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 66,
      enableSkip: true,
      actionName: 'Create New SUPCs',
      template: 'URLredirect',
      URLtext: 'Go to the STEP App from here ',
      hyperlinktext: 'STEP App Enter',
      hyperlinkURL: `${API_STEP_APP}`,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 73,
      actionName: 'Confirm Pickup Date Schedule',
      template: 'URLredirect',
      URLtext: 'Go to the link ',
      hyperlinktext: 'Salesforce',
      hyperlinkURL: `${LINK_SALESFORCE}`,
      bundledWith: [BUSINESS_EVENT],
      needComplition: true,
      complitionField: 'SalesForceTaskId',
      fallbackScreen: false
    },
    {
      actionId: 75,
      actionName: 'Submit MSR Request',
      template: 'URLredirect',
      URLtext: 'Go to the link ',
      hyperlinktext: 'Salesforce',
      hyperlinkURL: `${LINK_SALESFORCE}`,
      needComplition: true,
      complitionField: 'SalesForceTaskId',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 76,
      actionName: 'Setup Phase Out/Phase in',
      template: 'URLredirect',
      enableSkip: true,
      bundledWith: [BUSINESS_EVENT],
      // URLtext: 'Go to the link ',
      // hyperlinktext: 'SUS',
      // hyperlinkURL: `${LINK_SUS}`,
      fallbackScreen: false
    },
    {
      actionId: 80,
      actionName: 'Exiting Distributor - Corporate Controlled',
      template: 'Confirm',
      confirmText:
        'Exiting Distributor is setup as Sysco supplier (identify if corporate controlled)',
        bundledWith: [BUSINESS_EVENT],
      slowBlueBG: true
    },
    {
      actionId: 64,
      actionName: 'Inventory Snapshot',
      templateId: [135],
      template: 'File',
      fallbackScreen: false,
      mailattachflag: true,
      bosSubRule: false,
      operations: ['download', 'delete'],
      showMarkBtn: true,
      showUploadList: true,
      bundledWith: [BUSINESS_EVENT],
      maildocmandatory: 'Yes'
    },
    {
      actionId: 74,
      actionName: 'Final Internal Sign Off',
      template: 'Mail',
      bundledWith: [BUSINESS_EVENT],
      mailTemplateID: 71
    },
    {
      template: 'Mail',
      mailTemplateID: 73,
      actionId: 78,
      bundledWith: [BUSINESS_EVENT],
      actionName: 'Inventory Disposition Plan'
    },
    {
      actionId: 67,
      actionName: 'Obtain Customer Approval for Transfer List',
      template: 'Mail',
      mailTemplateID: 69,
      fallbackScreen: true,
      fallbackTemplate: 'Text',
      label: 'Has the Customer approved all the Items in the Transfer List?',
      // justText: true,
      maildocmandatory: 'Yes',
      bundledWith: [BUSINESS_EVENT],
      showUploadList: true
    },
    {
      actionId: 65,
      actionName: 'Review/Analyze Exiting Distributor Item Lists',
      showReviewTemplate: [3, 13, 135],
      template: 'Review',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 81,
      actionName: 'Naming Convention',
      template: 'ack',
      labels: ['Confirm that the Naming Convention has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 82,
      actionName: 'Price Rule & Parent',
      template: 'ack',
      labels: ['Confirm that the price rule and parent have been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 83,
      actionName: 'Chart Ties',
      template: 'ack',
      labels: ['Confirm that the chart ties have been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 84,
      actionName: 'Tax Status',
      template: 'ack',
      labels: ['Confirm that the tax status has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 85,
      actionName: 'Delivery Days',
      template: 'ack',
      labels: ['Confirm that the delivery days have been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 86,
      actionName: 'Credit Terms',
      template: 'ack',
      labels: ['Confirm that the credit terms have been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    }, {
      actionId: 87,
      actionName: 'Account Type',
      template: 'ack',
      labels: ['Confirm that the account type has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    }, {
      actionId: 88,
      actionName: 'Order Guide',
      template: 'ack',
      labels: ['Confirm that the order guide has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 89,
      actionName: 'NAID',
      template: 'ack',
      labels: ['Confirm that the NAID has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
  ],

  LOCATION: [
    {
      actionId: 14,
      actionName: CUSTOMER_DETAILS,
      template: 'Form',
      completedInformation: true,
      fallbackScreen: false,
      viewinformation: [
        'website :  ',
        'Total number of OpSite: ',
        'Total number of Locations: ',
        'Number of prop items: ',
        'Yearly estimated values: ',
        'Intake form due date: '
      ]
    },
    {
      actionId: 15,
      actionName: PRE_REQ_DOCS,
      templateId: [2, 3, 26, 4, 5, 6, 7, 10, 15],
      template: 'File',
      fallbackScreen: false,
      // bundledWith: [CUSTOMER_DETAILS]
      showUploadList: true,
      showMarkBtn: true,
      operations: ['download'],
      bundledWithDocUsedByClickCard: ['Customer contact information','Operating Site Alignment & Heat Map','Deep Dive Template',
      'Item list','MSA/LOI','Customer Accounts','Customer Vendor List'],
    },
    {
      actionId: 17,
      actionName: OPSITE_ALIGNMENT,
      template: 'Mail',
      mailTemplateID: 2,
      fallbackScreen: true,
      fallbackTemplate: 'ack',
      labels: ['I confirm that the Opsite is aligned to service this Customer'],
      completedInformation: true,
      viewinformation: ['Opsite Aligment approved by :  '],
      // bundledWith: [CUSTOMER_DETAILS]
    },
    {
      actionId: 18,
      templateId: [],
      actionName: BUSINESS_EVENT,
      template: '',
      triggerPopup: true,
      createServiceRequest: true,
      triggerPopupTitle: TRIGGER_BUSINESS_EVENT,
      triggerPopupDesc: BUSINESS_EVENT_ACKNOWLEDGE,
      triggerPopupSuccess: BUSINESS_TRIGGER,
      isBusinessEvent: true,
      // bundledWith: [PRE_REQ_DOCS, OPSITE_ALIGNMENT],
      bundledWith: [CUSTOMER_DETAILS],
      fallbackScreen: false
    },

    {
      actionId: 19,
      actionName: NCOB_REVIEW,
      showReviewTemplate: [1, 2, 3, 26, 4, 5, 6, 7, 10, 15],
      template: 'Review',
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT]
    },
    {
      actionId: 20,
      actionName: 'Kick Off',
      template: 'Mail',
      mailTemplateID: 3,
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT]
    },
    {
      actionId: 25,
      actionName: 'Proprietary Items',
      template: 'Mail',
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      mailTemplateID: 14
    },
    {
      actionId: 26,
      actionName: 'Opening Order Items',
      template: 'Mail',
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      mailTemplateID: 15
    },
    {
      actionId: 27,
      actionName: 'MSA Stocking Requirements',
      template: 'Mail',
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      mailTemplateID: 16
    },
    {
      actionId: 28,
      actionName: 'Open National ID',
      template: '',
      triggerPopup: true,
      fallbackScreen: false,
      createServiceRequest: true,
      bundledWith: [BUSINESS_EVENT],
      triggerPopupTitle: CREATE_NATIONAL_ID,
      triggerPopupDesc: CREATE_NATIONAL_ID_ACKNOWLEDGE
    },
    {
      actionId: 29,
      actionName: 'Account Owner Alignment',
      template: 'Mail',
      mailTemplateID: 8,
      fallbackScreen: true,
      fallbackTemplate: 'Alignment',
      completedInformation: true,
      viewinformation: ['Account Owner Alignment Approval was given by : '],
      bundledWith: [BUSINESS_EVENT],
      //bundledWithDoc: 'Customer Accounts',
      bundledWithDocUsedByClickCard: 'Customer Accounts',
    },
    {
      actionId: 31,
      actionName: ROUTING_SETUP,
      template: 'Mail',
      mailTemplateID: 10,
      bundledWith: [BUSINESS_EVENT],
      //bundledWithDoc: 'Customer Accounts',
      bundledWithDocUsedByClickCard: 'Customer Accounts',
    },
    {
      actionId: 32,
      actionName: 'EDI Questionnaire',
      template: 'Mail',
      mailTemplateID: 29,
      enableSkip: true,
      fallbackScreen: true,
      fallbackTemplate: 'File',
      templateId: [34],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Customer Accounts',
      //bundledWithDoc: 'Customer Accounts',
    },
    {
      actionId: 33,
      actionName: 'EDI Setup',
      template: 'Mail',
      mailTemplateID: 11,
      enableSkip: true,
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      //bundledWithDoc: 'Customer Accounts',
      bundledWithDocUsedByClickCard: 'Customer Accounts',
      maildocmandatory: 'Yes',
      attachment: true
    },
    {
      actionId: 36,
      actionName: 'OGM Setup',
      templateId: [31],
      template: 'File',
      mailTemplateID: 27,
      fallbackScreen: true,
      fallbackTemplate: 'Mail',
      fallbackScreenactivated: false,
      mailattachflag: true,
      bundledWith: [BUSINESS_EVENT],
      //bundledWithDoc: 'Item list',
      bundledWithDocUsedByClickCard: 'Item list',
      maildocmandatory: 'Yes'
    },
    {
      actionId: 37,
      actionName: 'Item Coding',
      template: 'File',
      templateId: [27],
      //bundledWithDoc: 'Item list',
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: true,
      fallbackTemplate: 'File_Accordion',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      enableICTcoding: true
    },
    {
      actionId: 38,
      actionName: 'New Items',
      template: 'URLredirect',
      URLtext: 'Go to the STEP App from here ',
      hyperlinktext: 'STEP App Enter',
      hyperlinkURL: `${API_STEP_APP}`,
      //bundledWithDoc: 'Item list',
      bundledWithDocUsedByClickCard: 'Item list',
      enableSkip: true,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 39,
      actionName: 'Customer Approval',
      template: 'Mail',
      mailTemplateID: 19,
      // bundledWith: ['OGM Setup', 'Item Coding', 'New Items'],
      fallbackScreen: true,
      fallbackTemplate: 'Text',
      label: 'Has the Customer approved all the Items in the Order Guide?',
      statuslinkage: true,
      linkeditems: [37, 38, 39],
      maildocmandatory: 'Yes',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      attachment: true
    },
    {
      actionId: 40,
      actionName: 'Costing',
      template: 'Mail',
      mailTemplateID: 20,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      // bundledWith: ['OGM Setup', 'Item Coding', 'New Items'],
      fallbackScreen: false
    },
    {
      actionId: 41,
      actionName: 'Order Guide Creation',
      template: 'URLredirect',
      URLtext: 'Go to the OGM App from here ',
      hyperlinktext: 'OGM App Enter',
      hyperlinkURL: `${API_OGM_APP}`,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false,
      bundledWithDocUsedByClickCard: 'Item list'
      // bundledWith: ['Customer Approval']
    },

    {
      actionId: 44,
      actionName: 'Item Categorization',
      template: 'Text',
      // bundledWith: ['Customer Approval'],
      label:
        'The document of type ‘Customer Approved Order Guide’ will be used to push the Item Categorization data to Salesforce. Please make sure to upload the document with Customer Approved Item Categories',
      categorizationflag: true,
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Order Guide',
      enableConciergeFlag: false
    },
    {
      actionId: 45,
      actionName: 'Distributor Change Notification',
      template: 'ack',
      labels: [
        'Confirm if the suppliers are notified about the distributor change'
      ],
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      //bundledWithDoc: 'Item list',
      bundledWithDocUsedByClickCard: 'Item list',
    },
    {
      actionId: 46,
      actionName: 'Supplier Questionnaire',
      template: 'Mail',
      mailTemplateID: 21,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false,
      bundledWithDocUsedByClickCard: 'Item list'
      // bundledWith: ['Distributor Change Notification']
    },
    {
      actionId: 47,
      actionName: 'Supplier Readiness Documents',
      templateId: [29],
      template: 'File',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false,
      // bundledWith: ['Supplier Questionnaire']
    },
    {
      actionId: 48,
      actionName: 'New Vendor Request',
      template: 'URLredirect',
      URLtext: 'Go to the STEP App from here ',
      hyperlinktext: 'STEP App Enter',
      hyperlinkURL: `${API_STEP_APP}`,
      // bundledWith: ['Supplier Questionnaire'],
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      enableSkip: true
    },
    {
      actionId: 49,
      actionName: 'Supplier Doc to Risk Management',
      template: 'Mail',
      mailTemplateID: 22,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false,
      // bundledWith: ['Supplier Questionnaire']
    },
    {
      actionId: 50,
      actionName: 'Ship-Point Maintenance Request',
      templateId: [30],
      template: 'File',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false,
      // bundledWith: ['Supplier Questionnaire']
    },
    {
      actionId: 51,
      actionName: 'Ship-Point Maintenance',
      template: 'URLredirect',
      URLtext: 'Go to the STEP App from here ',
      hyperlinktext: 'STEP App Enter',
      hyperlinkURL: `${API_STEP_APP}`,
      // bundledWith: ['Supplier Questionnaire'],
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      enableSkip: true
    },
    {
      actionId: 52,
      actionName: 'Supplier Aggreements',
      template: 'Mail',
      mailTemplateID: 23,
      // bundledWithDoc: 'MSA/LOI',
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      enableSkip: true
    },
    {
      actionId: 53,
      actionName: 'DPM Request',
      template: 'Mail',
      mailTemplateID: 18,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false,
      // bundledWith: ['Supplier Aggreements']
    },
    {
      actionId: 54,
      actionName: 'Controlled Pricing setup',
      templateId: [167],
      template: 'File',
      fallbackScreen: true,
      fallbackTemplate: 'Mail',
      fallbackScreenactivated: false,
      mailattachflag: true,
      mailTemplateID: 24,
      // bundledWith: ['Supplier Aggreements'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI'
    },
    {
      actionId: 55,
      actionName: 'Simulate Pricing',
      templateId: [168],
      template: 'File',
      fallbackScreen: true,
      fallbackTemplate: 'URLredirect',
      fallbackScreenactivated: false,
      urltextSecond: 'Go to the PCI Cloud App from here',
      hyperlinktextSecond: ' Enter',
      // template: 'URLredirect',
      URLtext: 'Go to the Simulate Pricing App from here ',
      hyperlinktext: ' Enter',
      hyperlinkURL: `${API_PRICING_APP}`,
      hyperlinkSecondURL: `${API_LINK_PCI}`,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      // bundledWith: ['Controlled Pricing setup', 'DPM Request'],
    },
    {
      actionId: 56,
      actionName: 'Simulated Pricing to VPNA',
      template: 'Mail',
      mailTemplateID: 25,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false,
      // bundledWith: ['Simulate Pricing']
    },
    {
      actionId: 57,
      actionName: 'Sign Off on Simulated Pricing',
      template: 'ack',
      labels: ['I confirm that the Signed Off on Simulated Pricing'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      // bundledWith: ['Simulated Pricing to VPNA'],
      fallbackScreen: false
    },
    {
      actionId: 58,
      actionName: 'OMCC Intake Form',
      templateId: [67],
      template: 'File',
      mailTemplateID: 34,
      fallbackScreen: true,
      fallbackTemplate: 'Mail',
      fallbackScreenactivated: false,
      bundledWith: [BUSINESS_EVENT],
      mailattachflag: true,
      // bundledWithDoc: 'Item list',
      maildocmandatory: 'Yes'
    },
    {
      actionId: 62,
      actionName: 'Special Orders',
      templateId: [68],
      template: 'File',
      mailTemplateID: 35,
      fallbackScreen: true,
      fallbackTemplate: 'Mail',
      fallbackScreenactivated: false,
      mailattachflag: true,
      bundledWith: [BUSINESS_EVENT],
      // bundledWithDoc: 'Item list',
      maildocmandatory: 'Yes'
    },
    {
      actionId: 7,
      actionName: 'Inventory Management',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      triggerPopupDesc: 'Are you sure, inventory management is completed?',
      triggerPopupSuccess: 'Inventory Management marked as completed'
    },
    {
      actionId: 8,
      actionName: 'Inventory Transfer',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, inventory transfer is completed?',
      triggerPopupSuccess: 'Inventory Transfer marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 9,
      actionName: 'Validation',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, validation is completed?',
      triggerPopupSuccess: 'Validation marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 10,
      actionName: 'Training',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, training is completed?',
      triggerPopupSuccess: 'Training marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 11,
      actionName: 'Go-Live',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, go-live is completed?',
      triggerPopupSuccess: 'Go-Live marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 12,
      actionName: 'Hyper Care',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, hypercare is completed?',
      triggerPopupSuccess: 'HyperCare marked as completed',
      fallbackScreen: false
    },
    {
      actionId: 13,
      actionName: 'Steady State',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, steady state is completed?',
      triggerPopupSuccess: 'Steady State marked as completed',
      fallbackScreen: false
    },
    {
      actionId: 59,
      actionName: 'OMCC Inventory Call',
      template: 'DateOrTime',
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      slowBlueBG: true
    },
    {
      actionId: 60,
      actionName: 'Request NIRs/MSRs',
      templateId: [73, 74],
      template: 'File',
      fallbackScreen: false,
      isMsRsOrNIRS: true,
      bundledWith: [BUSINESS_EVENT],
      bosSubRule: true
    },
    {
      actionId: 61,
      actionName: 'Host Field Sales Readiness',
      template: 'DateOrTime',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 63,
      actionName: 'Inventory Readiness Tracker',
      templateId: [72],
      template: 'File',
      fallbackScreen: false,
      mailattachflag: true,
      bosSubRule: false,
      showMarkBtn: true,
      operations: ['download', 'delete'],
      showUploadList: true,
      maildocmandatory: 'Yes',
      bundledWith: [BUSINESS_EVENT],
      slowBlueBG: true
    },
    {
      actionId: 64,
      actionName: 'Inventory Snapshot',
      templateId: [135],
      template: 'File',
      fallbackScreen: false,
      mailattachflag: true,
      bosSubRule: false,
      operations: ['download', 'delete'],
      showMarkBtn: true,
      maildocmandatory: 'Yes',
      bundledWith: [BUSINESS_EVENT],
      showUploadList: true
    },
    {
      actionId: 65,
      actionName: 'Review/Analyze Exiting Distributor Item Lists',
      showReviewTemplate: [3, 13, 135],
      template: 'Review',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 66,
      enableSkip: true,
      actionName: 'Create New SUPCs',
      template: 'URLredirect',
      URLtext: 'Go to the STEP App from here ',
      hyperlinktext: 'STEP App Enter',
      hyperlinkURL: `${API_STEP_APP}`,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 68,
      actionName: 'Host Inventory Transfer Kickoff Call',
      template: 'DateOrTime',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 69,
      actionName: 'Obtain Internal Sign off for Transfer List',
      template: 'DateOrTime',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 70,
      actionName: 'Inventory Management COE',
      template: 'Mail',
      bundledWith: [BUSINESS_EVENT],
      mailTemplateID: 70
    },
    {
      // template: 'Mail',
      // mailTemplateID: 72,
      template: 'URLredirect',
      actionId: 77,
      bundledWith: [BUSINESS_EVENT],
      actionName: 'Monitor PO Status'
    },
    {
      actionId: 79,
      actionName: 'Inventory Transfer Complete',
      template: 'Mail',
      bundledWith: [BUSINESS_EVENT],
      mailTemplateID: 74
    },
    {
      actionId: 71,
      actionName: 'Confirm Sysco Account Setup with Exiting Distributor',
      template: 'Confirm',
      bundledWith: [BUSINESS_EVENT],
      confirmText: 'Confirm Account Setup with exiting Distributor.'
    },
    {
      actionId: 72,
      actionName: 'Confirm Distributor Setup as a Supplier',
      template: 'Confirm',
      bundledWith: [BUSINESS_EVENT],
      confirmText: 'Confirm that the Distributor is setup as a Supplier.'
    },
    {
      actionId: 73,
      actionName: 'Confirm Pickup Date Schedule',
      template: 'URLredirect',
      URLtext: 'Go to the link ',
      hyperlinktext: 'Salesforce',
      hyperlinkURL: `${LINK_SALESFORCE}`,
      bundledWith: [BUSINESS_EVENT],
      needComplition: true,
      complitionField: 'SalesForceTaskId',
      fallbackScreen: false
    },
    {
      actionId: 75,
      actionName: 'Submit MSR Request',
      template: 'URLredirect',
      URLtext: 'Go to the link ',
      hyperlinktext: 'Salesforce',
      hyperlinkURL: `${LINK_SALESFORCE}`,
      bundledWith: [BUSINESS_EVENT],
      needComplition: true,
      complitionField: 'SalesForceTaskId',
      fallbackScreen: false
    },
    {
      actionId: 76,
      actionName: 'Setup Phase Out/Phase in',
      enableSkip: true,
      template: 'URLredirect',
      // URLtext: 'Go to the link ',
      // hyperlinktext: 'SUS',
      // hyperlinkURL: `${LINK_SUS}`,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 80,
      actionName: 'Exiting Distributor - Corporate Controlled',
      slowBlueBG: true,
      template: 'Confirm',
      bundledWith: [BUSINESS_EVENT],
      confirmText:
        'Exiting Distributor is setup as Sysco supplier (identify if corporate controlled)'
    },
    {
      actionId: 74,
      actionName: 'Final Internal Sign Off',
      template: 'Mail',
      bundledWith: [BUSINESS_EVENT],
      mailTemplateID: 71
    },
    {
      template: 'Mail',
      mailTemplateID: 73,
      actionId: 78,
      bundledWith: [BUSINESS_EVENT],
      actionName: 'Inventory Disposition Plan'
    },
    {
      actionId: 67,
      actionName: 'Obtain Customer Approval for Transfer List',
      template: 'Mail',
      mailTemplateID: 69,
      fallbackScreen: true,
      fallbackTemplate: 'Text',
      label: 'Has the Customer approved all the Items in the Transfer List?',
      // justText: true,
      maildocmandatory: 'Yes',
      bundledWith: [BUSINESS_EVENT],
      showUploadList: true
    },
    {
      actionId: 65,
      actionName: 'Review/Analyze Exiting Distributor Item Lists',
      showReviewTemplate: [3, 13, 135],
      template: 'Review',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 81,
      actionName: 'Naming Convention',
      template: 'ack',
      labels: ['Confirm that the Naming Convention has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 82,
      actionName: 'Price Rule & Parent',
      template: 'ack',
      labels: ['Confirm that the price rule and parent have been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 83,
      actionName: 'Chart Ties',
      template: 'ack',
      labels: ['Confirm that the chart ties have been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 84,
      actionName: 'Tax Status',
      template: 'ack',
      labels: ['Confirm that the tax status has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 85,
      actionName: 'Delivery Days',
      template: 'ack',
      labels: ['Confirm that the delivery days have been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 86,
      actionName: 'Credit Terms',
      template: 'ack',
      labels: ['Confirm that the credit terms have been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    }, {
      actionId: 87,
      actionName: 'Account Type',
      template: 'ack',
      labels: ['Confirm that the account type has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    }, {
      actionId: 88,
      actionName: 'Order Guide',
      template: 'ack',
      labels: ['Confirm that the order guide has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 89,
      actionName: 'NAID',
      template: 'ack',
      labels: ['Confirm that the NAID has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
  ],

  CONCEPT: [
    {
      actionId: 14,
      actionName: CUSTOMER_DETAILS,
      template: 'Form',
      completedInformation: true,
      fallbackScreen: false,
      viewinformation: [
        'website :  ',
        'Total number of OpSite: ',
        'Total number of Locations: ',
        'Number of prop items: ',
        'Yearly estimated values: ',
        'Intake form due date: '
      ]
    },

    {
      actionId: 15,
      actionName: PRE_REQ_DOCS,
      templateId: [2, 3, 26, 4, 5, 6, 7, 10, 15],
      template: 'File',
      fallbackScreen: false,
      // bundledWith: [CUSTOMER_DETAILS]
      showUploadList: true,
      showMarkBtn: true,
      operations: ['download'],
      bundledWithDocUsedByClickCard: ['Customer contact information','Operating Site Alignment & Heat Map','Deep Dive Template',
      'Item list','MSA/LOI','Customer Accounts','Customer Vendor List'],
    },
    {
      actionId: 16,
      actionName: 'Finance Approval',
      template: 'Mail',
      mailTemplateID: 1,
      fallbackScreen: true,
      fallbackTemplate: 'ack',
      labels: [
        "I provide the financial approval for this customer's onboarding"
      ],
      completedInformation: true,
      viewinformation: ['Finance Approval was given by : '],
      // bundledWith: [CUSTOMER_DETAILS]
    },
    {
      actionId: 17,
      actionName: OPSITE_ALIGNMENT,
      template: 'Mail',
      mailTemplateID: 2,
      fallbackScreen: true,
      fallbackTemplate: 'ack',
      labels: ['I confirm that the Opsite is aligned to service this Customer'],
      completedInformation: true,
      viewinformation: ['Opsite Aligment approved by :  '],
      // bundledWith: [CUSTOMER_DETAILS]
    },
    {
      actionId: 18,
      templateId: [],
      actionName: BUSINESS_EVENT,
      template: '',
      triggerPopup: true,
      createServiceRequest: true,
      triggerPopupTitle: TRIGGER_BUSINESS_EVENT,
      triggerPopupDesc: BUSINESS_EVENT_ACKNOWLEDGE,
      triggerPopupSuccess: BUSINESS_TRIGGER,
      isBusinessEvent: true,
      fallbackScreen: false,
      // bundledWith: [PRE_REQ_DOCS, 'Finance Approval', OPSITE_ALIGNMENT]
      bundledWith: [CUSTOMER_DETAILS]
    },
    {
      actionId: 19,
      actionName: 'NCOB Review',
      showReviewTemplate: [1, 2, 3, 26, 4, 5, 6, 7, 10, 15],
      template: 'Review',
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT]
    },
    {
      actionId: 20,
      actionName: 'Kick Off',
      template: 'Mail',
      mailTemplateID: 3,
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT]
    },
    {
      actionId: 21,
      actionName: 'CST Alignment',
      template: 'Mail',
      mailTemplateID: 4,
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT]
    },
    {
      actionId: 22,
      actionName: 'Supplier OMCC',
      template: 'Mail',
      mailTemplateID: 5,
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      //bundledWithDoc: 'Customer Vendor List',
    },
    {
      actionId: 23,
      actionName: CREATE_NATIONAL_ID,
      template: '',
      triggerPopup: true,
      createServiceRequest: true,
      triggerPopupTitle: CREATE_NATIONAL_ID,
      triggerPopupDesc: CREATE_NATIONAL_ID_ACKNOWLEDGE,
      triggerPopupSuccess: NATIONAL_ID_TRIGGER,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 24,
      actionName: 'Credit & DPM Analysts',
      template: 'Mail',
      mailTemplateID: 6,
      fallbackScreen: true,
      fallbackTemplate: 'dynamicInput',
      labels: ['Credit Analyst', 'DPM Analyst'],
      completedInformation: true,
      viewinformation: ['Credit Analyst :  ', 'DPM Analyst : '],
      bundledWith: [BUSINESS_EVENT]
    },

    {
      actionId: 29,
      actionName: 'Account Owner Alignment',
      template: 'Mail',
      mailTemplateID: 8,
      fallbackScreen: true,
      fallbackTemplate: 'Alignment',
      completedInformation: true,
      viewinformation: ['Account Owner Alignment Approval was given by : '],
      //bundledWithDoc: 'Customer Accounts',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Customer Accounts',
    },
    {
      actionId: 30,
      actionName: 'Concierge Integration',
      template: 'Text',
      label:
        'The document of type ‘Customer Approved Customer Accounts’ will be used to push the Concierge data to Salesforce. Please make sure to upload the document with Customer Accounts',
      fallbackScreen: true,
      fallbackTemplate: 'File_Accordion',
      enableConciergeFlag: true,
      bundledWith: [BUSINESS_EVENT],
      categorizationflag: false,
      bundledWithDocUsedByClickCard: 'Customer Accounts'
    },
    {
      actionId: 31,
      actionName: ROUTING_SETUP,
      template: 'Mail',
      mailTemplateID: 10,
      //bundledWithDoc: 'Customer Accounts',
      bundledWithDocUsedByClickCard: 'Customer Accounts',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 32,
      actionName: 'EDI Questionnaire',
      template: 'Mail',
      mailTemplateID: 29,
      enableSkip: true,
      fallbackScreen: true,
      fallbackTemplate: 'File',
      templateId: [34],
      //bundledWithDoc: 'Customer Accounts',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Customer Accounts',
    },
    {
      actionId: 33,
      actionName: 'EDI Setup',
      template: 'Mail',
      mailTemplateID: 11,
      enableSkip: true,
      //bundledWithDoc: 'Customer Accounts',
      maildocmandatory: 'Yes',
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Customer Accounts',
      attachment: true
    },
    {
      actionId: 34,
      actionName: 'Sysco Pay Request',
      template: 'Mail',
      mailTemplateID: 12,
      //bundledWithDoc: 'Customer Accounts',
      bundledWithDocUsedByClickCard: 'Customer Accounts',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 35,
      actionName: 'Shop Request',
      template: 'Mail',
      mailTemplateID: 13,
      enableSkip: true,
      //bundledWithDoc: 'Customer Accounts',
      bundledWithDocUsedByClickCard: 'Customer Accounts',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 36,
      actionName: 'OGM Setup',
      templateId: [31],
      template: 'File',
      mailTemplateID: 27,
      fallbackScreen: true,
      fallbackTemplate: 'Mail',
      fallbackScreenactivated: false,
      mailattachflag: true,
      //bundledWithDoc: 'Item list',
      bundledWithDocUsedByClickCard: 'Item list',
      bundledWith: [BUSINESS_EVENT],
      maildocmandatory: 'Yes'
    },
    {
      actionId: 37,
      actionName: 'Item Coding',
      template: 'File',
      templateId: [27],
      //bundledWithDoc: 'Item list',
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: true,
      fallbackTemplate: 'File_Accordion',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      enableICTcoding: true
    },
    {
      actionId: 38,
      actionName: 'New Items',
      template: 'URLredirect',
      URLtext: 'Go to the STEP App from here ',
      hyperlinktext: 'STEP App Enter',
      hyperlinkURL: `${API_STEP_APP}`,
      //bundledWithDoc: 'Item list',
      bundledWithDocUsedByClickCard: 'Item list',
      enableSkip: true,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 39,
      actionName: 'Customer Approval',
      template: 'Mail',
      mailTemplateID: 19,
      // bundledWith: ['OGM Setup', 'Item Coding', 'New Items'],
      fallbackScreen: true,
      fallbackTemplate: 'Text',
      label: 'Has the Customer approved all the Items in the Order Guide?',
      statuslinkage: true,
      linkeditems: [37, 38, 39],
      maildocmandatory: 'Yes',
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      attachment: true
    },
    {
      actionId: 40,
      actionName: 'Costing',
      template: 'Mail',
      mailTemplateID: 20,
      // bundledWith: ['OGM Setup', 'Item Coding', 'New Items'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 41,
      actionName: 'Order Guide Creation',
      template: 'URLredirect',
      URLtext: 'Go to the OGM App from here ',
      hyperlinktext: 'OGM App Enter',
      hyperlinkURL: `${API_OGM_APP}`,
      // bundledWith: ['Customer Approval'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 42,
      actionName: 'BOS Onboarding',
      template: 'BOS_Onboarding',
      // bundledWith: ['OGM Setup', 'Item Coding', 'New Items'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    // {
    //   actionId:58,
    //   actionName: 'OMCC Intake Form',
    //   templateId: [67],
    //   template: 'File',
    //   mailTemplateID: 34,
    //   fallbackScreen: true,
    //   fallbackTemplate: 'Mail',
    //   fallbackScreenactivated: false,
    //   mailattachflag: true,
    //   // bundledWithDoc: 'Item list',
    //   maildocmandatory:"Yes"
    {
      actionId: 43,
      actionName: 'BOS Sub-Rule Setup',
      templateId: [28],
      template: 'File',
      bosSubRule: true,
      bundledWith: [BUSINESS_EVENT,'BOS Onboarding'],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 44,
      actionName: 'Item Categorization',
      template: 'Text',
      // bundledWith: ['Customer Approval'],
      fallbackScreen: false,
      label:
        'The document of type ‘Customer Approved Order Guide’ will be used to push the Item Categorization data to Salesforce. Please make sure to upload the document with Customer Approved Item Categories',
      categorizationflag: true,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Order Guide',
      enableConciergeFlag: false
    },
    {
      actionId: 45,
      actionName: 'Distributor Change Notification',
      template: 'ack',
      labels: [
        'Confirm if the suppliers are notified about the distributor change'
      ],
      //bundledWithDoc: 'Item list',
      bundledWithDocUsedByClickCard: 'Item list',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 46,
      actionName: 'Supplier Questionnaire',
      template: 'Mail',
      mailTemplateID: 21,
      // bundledWith: ['Distributor Change Notification'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 47,
      actionName: 'Supplier Readiness Documents',
      templateId: [29],
      template: 'File',
      // bundledWith: ['Supplier Questionnaire'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 48,
      actionName: 'New Vendor Request',
      template: 'URLredirect',
      URLtext: 'Go to the STEP App from here ',
      hyperlinktext: 'STEP App Enter',
      hyperlinkURL: `${API_STEP_APP}`,
      // bundledWith: ['Supplier Questionnaire'],
      enableSkip: true,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 49,
      actionName: 'Supplier Doc to Risk Management',
      template: 'Mail',
      mailTemplateID: 22,
      // bundledWith: ['Supplier Questionnaire'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 50,
      actionName: 'Ship-Point Maintenance Request',
      templateId: [30],
      template: 'File',
      // bundledWith: ['Supplier Questionnaire'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 51,
      actionName: 'Ship-Point Maintenance',
      template: 'URLredirect',
      URLtext: 'Go to the STEP App from here ',
      hyperlinktext: 'STEP App Enter',
      hyperlinkURL: `${API_STEP_APP}`,
      // bundledWith: ['Supplier Questionnaire'],
      enableSkip: true,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'Item list',
      fallbackScreen: false
    },
    {
      actionId: 52,
      actionName: 'Supplier Aggreements',
      template: 'Mail',
      mailTemplateID: 23,
      //bundledWithDoc: 'MSA/LOI',
      bundledWithDocUsedByClickCard:'MSA/LOI',
      enableSkip: true,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 53,
      actionName: 'DPM Request',
      template: 'Mail',
      mailTemplateID: 18,
      // bundledWith: ['Supplier Aggreements'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 54,
      actionName: 'Controlled Pricing setup',
      templateId: [167],
      template: 'File',
      fallbackScreen: true,
      fallbackTemplate: 'Mail',
      fallbackScreenactivated: false,
      mailattachflag: true,
      mailTemplateID: 24,
      // bundledWith: ['Supplier Aggreements'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI'
    },

    {
      actionId: 55,
      actionName: 'Simulate Pricing',
      template: 'File',
      templateId: [168],
      fallbackScreen: true,
      fallbackTemplate: 'URLredirect',
      fallbackScreenactivated: false,
      // template: 'URLredirect',
      URLtext: 'Go to the Simulate Pricing App from here ',
      urltextSecond: 'Go to the PCI Cloud App from here',
      hyperlinktextSecond: ' Enter',
      hyperlinktext: ' Enter',
      hyperlinkURL: `${API_PRICING_APP}`,
      hyperlinkSecondURL: `${API_LINK_PCI}`,
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      // bundledWith: ['Controlled Pricing setup', 'DPM Request'],
    },
    {
      actionId: 56,
      actionName: 'Simulated Pricing to VPNA',
      template: 'Mail',
      mailTemplateID: 25,
      // bundledWith: ['Simulate Pricing'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 57,
      actionName: 'Sign Off on Simulated Pricing',
      template: 'ack',
      labels: ['I confirm that the Signed Off on Simulated Pricing'],
      // bundledWith: ['Simulated Pricing to VPNA'],
      bundledWith: [BUSINESS_EVENT],
      bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 58,
      actionName: 'OMCC Intake Form',
      // bosSubRule: true,
      templateId: [67],
      template: 'File',
      mailTemplateID: 34,
      fallbackScreen: true,
      fallbackTemplate: 'Mail',
      fallbackScreenactivated: false,
      mailattachflag: true,
      // bundledWithDoc: 'Item list',
      bundledWith: [BUSINESS_EVENT],
      maildocmandatory: 'Yes'
    },
    {
      actionId: 62,
      actionName: 'Special Orders',
      templateId: [68],
      template: 'File',
      mailTemplateID: 35,
      fallbackScreen: true,
      fallbackTemplate: 'Mail',
      fallbackScreenactivated: false,
      mailattachflag: true,
      // bundledWithDoc: 'Item list',
      bundledWith: [BUSINESS_EVENT],
      maildocmandatory: 'Yes'
    },
    {
      actionId: 7,
      actionName: 'Inventory Management',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, inventory management is completed?',
      triggerPopupSuccess: 'Inventory Management marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 8,
      actionName: 'Inventory Transfer',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, inventory transfer is completed?',
      triggerPopupSuccess: 'Inventory Transfer marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 9,
      actionName: 'Validation',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, validation is completed?',
      triggerPopupSuccess: 'Validation marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 10,
      actionName: 'Training',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      bundledWith: [BUSINESS_EVENT],
      triggerPopupDesc: 'Are you sure, training is completed?',
      triggerPopupSuccess: 'Training marked as completed'
    },
    {
      actionId: 11,
      actionName: 'Go-Live',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, go-live is completed?',
      triggerPopupSuccess: 'Go-Live marked as completed',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 12,
      actionName: 'Hyper Care',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, hypercare is completed?',
      triggerPopupSuccess: 'HyperCare marked as completed',
      fallbackScreen: false
    },
    {
      actionId: 13,
      actionName: 'Steady State',
      template: '',
      triggerPopup: true,
      triggerPopupTitle: '',
      triggerPopupDesc: 'Are you sure, steady state is completed?',
      triggerPopupSuccess: 'Steady State marked as completed',
      fallbackScreen: false
    },
    {
      actionId: 59,
      actionName: 'OMCC Inventory Call',
      template: 'DateOrTime',
      fallbackScreen: false,
      bundledWith: [BUSINESS_EVENT],
      slowBlueBG: true
    },
    {
      actionId: 60,
      actionName: 'Request NIRs/MSRs',
      templateId: [73, 74],
      template: 'File',
      fallbackScreen: false,
      isMsRsOrNIRS: true,
      bundledWith: [BUSINESS_EVENT],
      bosSubRule: true
    },
    {
      actionId: 61,
      actionName: 'Host Field Sales Readiness',
      template: 'DateOrTime',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 63,
      actionName: 'Inventory Readiness Tracker',
      templateId: [72],
      template: 'File',
      fallbackScreen: false,
      mailattachflag: true,
      bosSubRule: false,
      showMarkBtn: true,
      operations: ['download', 'delete'],
      showUploadList: true,
      maildocmandatory: 'Yes',
      bundledWith: [BUSINESS_EVENT],
      slowBlueBG: true
    },
    {
      actionId: 64,
      actionName: 'Inventory Snapshot',
      templateId: [135],
      template: 'File',
      fallbackScreen: false,
      mailattachflag: true,
      bosSubRule: false,
      operations: ['download', 'delete'],
      showMarkBtn: true,
      maildocmandatory: 'Yes',
      bundledWith: [BUSINESS_EVENT],
      showUploadList: true
    },
    {
      actionId: 65,
      actionName: 'Review/Analyze Exiting Distributor Item Lists',
      showReviewTemplate: [3, 13, 135],
      template: 'Review',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 66,
      actionName: 'Create New SUPCs',
      enableSkip: true,
      template: 'URLredirect',
      URLtext: 'Go to the STEP App from here ',
      hyperlinktext: 'STEP App Enter',
      hyperlinkURL: `${API_STEP_APP}`,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 68,
      actionName: 'Host Inventory Transfer Kickoff Call',
      template: 'DateOrTime',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 69,
      actionName: 'Obtain Internal Sign off for Transfer List',
      template: 'DateOrTime',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 70,
      actionName: 'Inventory Management COE',
      template: 'Mail',
      bundledWith: [BUSINESS_EVENT],
      mailTemplateID: 70
    },
    {
      // template: 'Mail',
      // mailTemplateID: 72,
      template: 'URLredirect',
      actionId: 77,
      bundledWith: [BUSINESS_EVENT],
      actionName: 'Monitor PO Status'
    },
    {
      actionId: 79,
      actionName: 'Inventory Transfer Complete',
      template: 'Mail',
      bundledWith: [BUSINESS_EVENT],
      mailTemplateID: 74
    },
    {
      actionId: 71,
      actionName: 'Confirm Sysco Account Setup with Exiting Distributor',
      template: 'Confirm',
      bundledWith: [BUSINESS_EVENT],
      confirmText: 'Confirm Account Setup with exiting Distributor.'
    },
    {
      actionId: 72,
      actionName: 'Confirm Distributor Setup as a Supplier',
      template: 'Confirm',
      bundledWith: [BUSINESS_EVENT],
      confirmText: 'Confirm that the Distributor is setup as a Supplier.'
    },
    {
      actionId: 73,
      actionName: 'Confirm Pickup Date Schedule',
      template: 'URLredirect',
      URLtext: 'Go to the link ',
      hyperlinktext: 'Salesforce',
      hyperlinkURL: `${LINK_SALESFORCE}`,
      bundledWith: [BUSINESS_EVENT],
      needComplition: true,
      complitionField: 'SalesForceTaskId',
      fallbackScreen: false
    },
    {
      actionId: 75,
      actionName: 'Submit MSR Request',
      template: 'URLredirect',
      URLtext: 'Go to the link ',
      hyperlinktext: 'Salesforce',
      hyperlinkURL: `${LINK_SALESFORCE}`,
      bundledWith: [BUSINESS_EVENT],
      needComplition: true,
      complitionField: 'SalesForceTaskId',
      fallbackScreen: false
    },
    {
      actionId: 76,
      actionName: 'Setup Phase Out/Phase in',
      enableSkip: true,
      template: 'URLredirect',
      // URLtext: 'Go to the link ',
      // hyperlinktext: 'SUS',
      // hyperlinkURL: `${LINK_SUS}`,
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 80,
      actionName: 'Exiting Distributor - Corporate Controlled',
      template: 'Confirm',
      confirmText:
        'Exiting Distributor is setup as Sysco supplier (identify if corporate controlled)',
        bundledWith: [BUSINESS_EVENT],
      slowBlueBG: true
    },
    {
      actionId: 74,
      actionName: 'Final Internal Sign Off',
      template: 'Mail',
      bundledWith: [BUSINESS_EVENT],
      mailTemplateID: 71
    },
    {
      template: 'Mail',
      mailTemplateID: 73,
      actionId: 78,
      bundledWith: [BUSINESS_EVENT],
      actionName: 'Inventory Disposition Plan'
    },
    {
      actionId: 67,
      actionName: 'Obtain Customer Approval for Transfer List',
      template: 'Mail',
      mailTemplateID: 69,
      fallbackScreen: true,
      fallbackTemplate: 'Text',
      label: 'Has the Customer approved all the Items in the Transfer List?',
      // justText: true,
      maildocmandatory: 'Yes',
      bundledWith: [BUSINESS_EVENT],
      showUploadList: true
    },
    {
      actionId: 65,
      actionName: 'Review/Analyze Exiting Distributor Item Lists',
      showReviewTemplate: [3, 13, 135],
      template: 'Review',
      bundledWith: [BUSINESS_EVENT],
      fallbackScreen: false
    },
    {
      actionId: 81,
      actionName: 'Naming Convention',
      template: 'ack',
      labels: ['Confirm that the Naming Convention has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 82,
      actionName: 'Price Rule & Parent',
      template: 'ack',
      labels: ['Confirm that the price rule and parent have been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 83,
      actionName: 'Chart Ties',
      template: 'ack',
      labels: ['Confirm that the chart ties have been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 84,
      actionName: 'Tax Status',
      template: 'ack',
      labels: ['Confirm that the tax status has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 85,
      actionName: 'Delivery Days',
      template: 'ack',
      labels: ['Confirm that the delivery days have been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 86,
      actionName: 'Credit Terms',
      template: 'ack',
      labels: ['Confirm that the credit terms have been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    }, {
      actionId: 87,
      actionName: 'Account Type',
      template: 'ack',
      labels: ['Confirm that the account type has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    }, {
      actionId: 88,
      actionName: 'Order Guide',
      template: 'ack',
      labels: ['Confirm that the order guide has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
    {
      actionId: 89,
      actionName: 'NAID',
      template: 'ack',
      labels: ['Confirm that the NAID has been verified.'],
      //bundledWith: [BUSINESS_EVENT],
      //bundledWithDocUsedByClickCard: 'MSA/LOI',
      fallbackScreen: false
    },
  ]
};
