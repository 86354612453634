import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as Yup from 'yup';

export function Validate() {
  const customerList = useSelector(
    (state) => state?.customerList?.data?.Customer
  );
  const realEmailList = useSelector(
    (state) => state?.emailList?.data?.Customer
  );
  const manualCreate = useSelector((state) => state?.storeManualCreateNationalId?.manualCreate);
  const emailList = customerList?.map((ele) => ele?.email);
  const totalEmailList = realEmailList
  const validationSchema = [
    Yup.object().shape({
      name: Yup.string().required('National Account Name is required'),
      segment: Yup.string().required('Segment is required'),
      channel: Yup.string().required('Channel is required'),
      parentAccountType: Yup.string().required('Account is required'),
      // goLiveDate: Yup.string().notRequired()
      conceptId:manualCreate?Yup.string().required('Concept ID is required'):Yup.string()
      .notRequired(),
    }),
    Yup.object().shape({
      customerFirstName: Yup.string().required('First Name is required'),
      customerLastName: Yup.string().required('Last Name is required'),
      contact: Yup.string()
        .required('Contact Phone is required')
        .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
          message: 'Invalid Phone Number',
          excludeEmptyString: false
        }),
      email: Yup.string()
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, {
          message: 'Email is invalid',
          excludeEmptyString: false
        })
        .email('Email is invalid')
        .required('Email is required')
        .test(
          'email check',
          'Email already exists',
          // (val) => !totalEmailList?.includes(val)
          (val) => !totalEmailList.map((item) => item.toLowerCase()).includes(val && val.toLowerCase())
        )
    }),
    Yup.object().shape({
      shippingZipcode: Yup.string()
        .notRequired()
        .matches(/^\d+$/, 'Zipcode must be a number')
        .min(5, 'Zipcode is not Valid')
        .max(5, 'Zipcode is not Valid')
    }),
    Yup.object().shape({
      billingZipcode: Yup.string().when('toggleAddress', {
        is: false,
        then: Yup.string()
          .notRequired()
          .matches(/^\d+$/, 'Zipcode must be a number')
          .min(5, 'Zipcode is not Valid')
          .max(5, 'Zipcode is not Valid'),
        otherwise: Yup.string()
      })
    })
  ];
  const validationSchema_concept = [
    Yup.object().shape({
      name: Yup.string().required('National Account Name is required'),
      segment: Yup.string().required('Segment is required'),
      channel: Yup.string().required('Channel is required'),
      conceptName: Yup.string().required('Concept Name is required')
    }),
    Yup.object().shape({
      customerFirstName: Yup.string().required('First Name is required'),
      customerLastName: Yup.string().required('Last Name is required'),
      contact: Yup.string()
        .required('Contact is required')
        .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
          message: 'Invalid Phone Number',
          excludeEmptyString: false
        }),
      email: Yup.string()
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, {
          message: 'Email is invalid',
          excludeEmptyString: false
        })
        .required('Email is required')
        .test(
          'email check',
          'Email already exists',
          // (val) => !emailList?.includes(val)
          (val) =>!emailList.map((item) => item.toLowerCase()).includes(val && val.toLowerCase())
        )
    }),
    Yup.object().shape({
      shippingZipcode: Yup.string()
        .notRequired()
        .matches(/^\d+$/, 'Zipcode must be a number')
        .min(5, 'Zipcode is not Valid')
        .max(5, 'Zipcode is not Valid')
    }),
    Yup.object().shape({
      billingZipcode: Yup.string().when('toggleAddress', {
        is: false,
        then: Yup.string()
          .notRequired()
          .matches(/^\d+$/, 'Zipcode must be a number')
          .min(5, 'Zipcode is not Valid')
          .max(5, 'Zipcode is not Valid'),
        otherwise: Yup.string()
      })
    })
  ];
  const validationSchema_loc = [
    Yup.object().shape({
      conceptId: Yup.string().required(' Concept Id is required'),
      accountName: Yup.string().required('Account Name is required'),
      opcoSite: Yup.string().required('Site is required')
    }),
    Yup.object().shape({
      customerFirstName: Yup.string().required('First Name is required'),
      customerLastName: Yup.string().required('Last Name is required'),
      contact: Yup.string()
        .required('Contact is required')
        .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
          message: 'Invalid Phone Number',
          excludeEmptyString: false
        }),
      email: Yup.string()
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, {
          message: 'Email is invalid',
          excludeEmptyString: false
        })
        .required('Email is required')
        .test(
          'email check',
          'Email already exists',
          // (val) => !emailList?.includes(val)
          (val) =>!emailList.map((item) => item.toLowerCase()).includes(val && val.toLowerCase())
        )
    }),
    Yup.object().shape({
      shippingZipcode: Yup.string()
        .notRequired()
        .matches(/^\d+$/, 'Zipcode must be a number')
        .min(5, 'Zipcode is not Valid')
        .max(5, 'Zipcode is not Valid')
    }),
    Yup.object().shape({
      billingZipcode: Yup.string().when('toggleAddress', {
        is: false,
        then: Yup.string()
          .notRequired()
          .matches(/^\d+$/, 'Zipcode must be a number')
          .min(5, 'Zipcode is not Valid')
          .max(5, 'Zipcode is not Valid'),
        otherwise: Yup.string()
      })
    })
  ];

  return { validationSchema, validationSchema_loc, validationSchema_concept };
}

export function UpdateValidation(email) {
  const customerList = useSelector(
    (state) => state?.customerList?.data?.Customer
  );
  const realEmailList = useSelector(
    (state) => state?.emailList?.data?.Customer
  );
 
  const emailList = _.pull(customerList?.map((ele) => ele?.email), email);
  const totalEmailList = _.pull(realEmailList?.map((ele) => ele), email);

  const updateValidationSchema = Yup.object().shape({
    name: Yup.string().required('National Account Name is required'),
    segment: Yup.string().required('Segment is required'),
    channel: Yup.string().required('Channel is required'),
    customerFirstName: Yup.string().required('First Name is required'),
    customerLastName: Yup.string().required('Last Name is required'),
    contact: Yup.string()
      .required('Contact is required')
      .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
        message: 'Invalid Phone Number',
        excludeEmptyString: false
      }),
    leadContcatNo: Yup.string()
      .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
        message: 'Invalid Phone Number',
        excludeEmptyString: false
      })
      .nullable(),
    partnerContcatNo: Yup.string()
      .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
        message: 'Invalid Phone Number',
        excludeEmptyString: false
      })
      .nullable(),
    leadEmail: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, {
        message: 'Email is invalid',
        excludeEmptyString: false
      })
      .nullable(),
    partnerEmail: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, {
        message: 'Email is invalid',
        excludeEmptyString: false
      })
      .nullable(),
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, {
        message: 'Email is invalid',
        excludeEmptyString: false
      })
      .email('Email is invalid')
      .required('Email is required')
      .test(
        'email check',
        'Email already exists',
        // (val) => !totalEmailList?.includes(val)
        (val) => !totalEmailList.map((item) => item.toLowerCase()).includes(val && val.toLowerCase())
      ),
    shippingZipcode: Yup.string()
      .notRequired()
      .matches(/^\d+$/, 'Zipcode must be a number')
      .min(5, 'Zipcode is not Valid')
      .max(5, 'Zipcode is not Valid'),
    billingZipcode: Yup.string().when('toggleAddress', {
      is: false,
      then: Yup.string()
        .notRequired()
        .matches(/^\d+$/, 'Zipcode must be a number')
        .min(5, 'Zipcode is not Valid')
        .max(5, 'Zipcode is not Valid'),
      otherwise: Yup.string()
    }),
    conceptId: Yup.string()
      .when('nationalId', {
        is: (nationalId) => nationalId && nationalId.length === 4,
        then: Yup
          .string()
          .required("Concetp ID is required"),
      }), //COT-9015:Disable "Create National ID" --- required concept id if national id is valid
  });

  const updateValidationSchema_concept = Yup.object().shape({
    name: Yup.string().required('National Account Name is required'),
    segment: Yup.string().required('Segment is required'),
    channel: Yup.string().required('Channel is required'),
    conceptName: Yup.string().required('Concept Name is required'),
    customerFirstName: Yup.string().required('First Name is required'),
    customerLastName: Yup.string().required('Last Name is required'),
    contact: Yup.string()
      .required('Contact is required')
      .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
        message: 'Invalid Phone Number',
        excludeEmptyString: false
      }),
    leadContcatNo: Yup.string()
      .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
        message: 'Invalid Phone Number',
        excludeEmptyString: false
      })
      .nullable(),
    partnerContcatNo: Yup.string()
      .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
        message: 'Invalid Phone Number',
        excludeEmptyString: false
      })
      .nullable(),
    leadEmail: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, {
        message: 'Email is invalid',
        excludeEmptyString: false
      })
      .nullable(),
    partnerEmail: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, {
        message: 'Email is invalid',
        excludeEmptyString: false
      })
      .nullable(),
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, {
        message: 'Email is invalid',
        excludeEmptyString: false
      })
      .email('Email is invalid')
      .required('Email is required')
      .test(
        'email check',
        'Email already exists',
        // (val) => !emailList?.includes(val)
        (val) =>!emailList.map((item) => item.toLowerCase()).includes(val && val.toLowerCase())
      ),
    shippingZipcode: Yup.string()
      .notRequired()
      .matches(/^\d+$/, 'Zipcode must be a number')
      .min(5, 'Zipcode is not Valid')
      .max(5, 'Zipcode is not Valid'),
    billingZipcode: Yup.string().when('toggleAddress', {
      is: false,
      then: Yup.string()
        .notRequired()
        .matches(/^\d+$/, 'Zipcode must be a number')
        .min(5, 'Zipcode is not Valid')
        .max(5, 'Zipcode is not Valid'),
      otherwise: Yup.string()
    })
  });

  const updateValidationSchema_loc = Yup.object().shape({
    conceptId: Yup.string().required(' Concept Id is required'),
    accountName: Yup.string().required('Account Name is required'),
    opcoSite: Yup.string().required('Site is required'),
    contact: Yup.string()
      .required('Contact is required')
      .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
        message: 'Invalid Phone Number',
        excludeEmptyString: false
      }),
    leadContcatNo: Yup.string()
      .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
        message: 'Invalid Phone Number',
        excludeEmptyString: false
      })
      .nullable(),
    partnerContcatNo: Yup.string()
      .matches(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/, {
        message: 'Invalid Phone Number',
        excludeEmptyString: false
      })
      .nullable(),
    leadEmail: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, {
        message: 'Email is invalid',
        excludeEmptyString: false
      })
      .nullable(),
    partnerEmail: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, {
        message: 'Email is invalid',
        excludeEmptyString: false
      })
      .nullable(),
    email: Yup.string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i, {
        message: 'Email is invalid',
        excludeEmptyString: false
      })
      .email('Email is invalid')
      .required('Email is required')
      .test(
        'email check',
        'Email already exists',
        // (val) => !emailList?.includes(val)
        (val) =>!emailList.map((item) => item.toLowerCase()).includes(val && val.toLowerCase())
      ),
    customerFirstName: Yup.string().required('First Name is required'),
    customerLastName: Yup.string().required('Last Name is required'),
    shippingZipcode: Yup.string()
      .notRequired()
      .matches(/^\d+$/, 'Zipcode must be a number')
      .min(5, 'Zipcode is not Valid')
      .max(5, 'Zipcode is not Valid'),
    billingZipcode: Yup.string().when('toggleAddress', {
      is: false,
      then: Yup.string()
        .notRequired()
        .matches(/^\d+$/, 'Zipcode must be a number')
        .min(5, 'Zipcode is not Valid')
        .max(5, 'Zipcode is not Valid'),
      otherwise: Yup.string()
    })
  });

  return {
    updateValidationSchema,
    updateValidationSchema_loc,
    updateValidationSchema_concept
  };
}
