const messages = {
  internalError: 'Please try again later',
  errorMessage: 'Something went wrong'
};

const setting = {
  footer: true,
  navBar: false,
  buttonLarge: '48',
  buttonSmall: '30',
  buttonBorderRadius: '3px'
};

export { messages, setting };
