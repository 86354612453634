import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DialogActions from '@mui/material/DialogActions';
import moment from 'moment';
import '../../components/CustomerForm/index.css';
import CustomerForm from './CustomerForm';
import ShippingForm from './ShippingForm';
import BillingForm from './BillingForm';
import LocationCustomerForm from './LocationCustomerForm';
import { createCustomerAPI } from '../../../utils/common';
import { Validate } from '../../../utils/validate';
import ContactInfo from './ContactInfo';
import loadingScreen from '../../../assets/loading.gif'
import { useDispatch, useSelector } from 'react-redux';
import { storeManualCreateNationalId } from '../../containers/templateComponent/redux/slice';



const dateformatter = (date) => moment(date).format('YYYY-MM-DD');
const steps_val = [
  'Customer Info',
  'Contact Info',
  'Shipping Form',
  'Billing forms'
];
const cmu_steps = ['Customer Info', 'Contact Info', 'Corporate Office'];
const initialValues = {
  accountName: '',
  AccountRecordType: 'CMU',
  segment: '',
  channel: '',
  contact: '',
  goLiveDate: '',
  shippingFirstName: '',
  shippingLastName: '',
  shippingAddress: '',
  shippingState: '',
  shippingZipcode: '',
  toggleAddress: false,
  billingFirstName: '',
  billingLastName: '',
  billingAddress: '',
  billingState: '',
  billingZipcode: '',
  email: '',
  name: '',
  opcoSite: '',
  conceptId: '',
  conceptName: '',
  customerFirstName: '',
  customerLastName: '',
  customerTitle: '',
  nationalId: '',
  parentAccountType: ''
};

const renderCMUStepContent = (
  step,
  values,
  setFieldValue,
  errors,
  touched,
  setValues,
  manualNationalId,
  handleBlur,
  setFieldError
) => {
  const formProps = {
    values,
    setFieldValue,
    errors,
    touched,
    setValues,
    handleBlur,
    setFieldError
  };

  switch (step) {
    case 0:
      return values.AccountRecordType === 'LOCATION' ? (
        <LocationCustomerForm {...formProps} />
      ) : (
        <CustomerForm {...formProps} manualNationalId={manualNationalId}/>
      );
    case 1:
      return <ContactInfo {...formProps} />;
    case 2:
      return <ShippingForm {...formProps} />;
    default:
      return <div>Not Found</div>;
  }
};
const renderStepContent = (step, values, setFieldValue, errors, touched, setValues, modalOpen,handleBlur) => {
  const formProps = { values, setFieldValue, errors, touched, modalOpen, handleBlur };

  switch (step) {
    case 0:
      return values.AccountRecordType === 'LOCATION'
        ? <LocationCustomerForm {...formProps} setValues={setValues} />
        : <CustomerForm {...formProps} setValues={setValues} />;
    case 1:
      return <ContactInfo {...formProps} />;
    case 2:
      return <ShippingForm {...formProps} />;
    case 3:
      return <BillingForm {...formProps} />;
    default:
      return <div>Not Found</div>;
  }
};

const CreateCustomer = ({
  modalOpen,
  closehandler,
  onSubmit,
  customerList,
  acctype,
  customerdetails,
  snackbarShowMessage,
  manualNationalId
}) => {
  const cusid = useParams();

  initialValues.name = acctype ? customerdetails.name : '';
  initialValues.nationalId = acctype ? customerdetails.nationalId : '';
  initialValues.AccountRecordType = acctype ? acctype : 'CMU';
  initialValues.toggleAddress = initialValues.AccountRecordType === 'CMU' ? true : false;
  initialValues.parentAccountType = customerdetails?.parentAccountType;

  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const { validationSchema, validationSchema_loc, validationSchema_concept } =
    Validate();

  const checkValidationSchema = (acctype) => {
    if (acctype === 'LOCATION') {
      return validationSchema_loc[activeStep]
    }
    else if (acctype === 'CONCEPT') {
      return validationSchema_concept[activeStep]
    }
    else {
      return validationSchema[activeStep];
    }
  }
  const currentValidationSchema = checkValidationSchema(acctype)

  const steps = initialValues.AccountRecordType !== 'CMU' ? steps_val : cmu_steps;
  const isLastStep = activeStep === steps.length - 1;


  const [loading, setLoading] = useState(false);
  function handleSubmit(values, actions) {
    setLoading(true);
    values['liveDate'] = !values.goLiveDate ? dateformatter(moment().add(90, 'd')) : dateformatter(values.goLiveDate);
    if (isLastStep) {
      actions.setSubmitting(true);
      createCustomerAPI(values, cusid, acctype)
        .then((res) => {
          if (res?.status === 200) {
            if (res?.data?.errorCode || res?.data?.errorMessage) {
              setLoading(false);
              closehandler();
              snackbarShowMessage({
                message: `Unable to Create the new Customers. Please try again later`,
                type: 'error'
              });
            } else {
              navigate(
                `new-onboarding/${res?.data?.data?.Customer?.customerId}`
              );
              setLoading(false)
            }
          }
        })
        .catch(() => {
          setLoading(false);
          snackbarShowMessage({
            message: `Unable to Create the new Customers. Please try again later`,
            type: 'error'
          });
        });
    } else {
      setLoading(false);
      setActiveStep(activeStep + 1);
      actions.setSubmitting(false);
      actions.setTouched({});
    }
  };

  function handleBack() {
    setActiveStep(activeStep - 1);
  };

  const renderTitle = () => {
    if (acctype === 'LOCATION') {
      return 'Create New Location'
    }
    else if (acctype === 'CONCEPT') {
      return 'Create New Concept'
    }
    else {
      return 'Create New Customer'
    }
  }

  const checkAccType = () => {
    if (acctype === 'LOCATION') {
      return (
        <LocationCustomerForm />
      )
    } else {
      return (
        <CustomerForm acctype={acctype} />
      )
    }
  }
  const dispatch = useDispatch();
  useEffect(() => {
    //if close modal will reset manualCreate
    if (!modalOpen) {
      dispatch(storeManualCreateNationalId({ manualCreate: false }));
    }
  }, [modalOpen]);
  return (
    <>
      <Stepper activeStep={activeStep} className="stepper-section">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel></StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="popup-title">
        {renderTitle()}
      </div>

      {activeStep === steps.length ? checkAccType() : (
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidationSchema}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            touched,
            setFieldValue,
            isSubmitting,
            values,
            setValues,
            handleBlur,
            setFieldError,
          }) => (
              <Form className="customer-form">
                {values.AccountRecordType === 'CMU' &&
                  renderCMUStepContent(
                    activeStep,
                    values,
                    setFieldValue,
                    errors,
                    touched,
                    setValues,
                    manualNationalId,
                    handleBlur,
                    setFieldError
                  )}
                {values.AccountRecordType !== 'CMU' &&
                  renderStepContent(
                    activeStep,
                    values,
                    setFieldValue,
                    errors,
                    touched,
                    setValues,
                    modalOpen
                  )}
                <DialogActions className="form-buttons">
                  {activeStep !== 0 && (
                    <Button onClick={handleBack} className="button" id="primary">
                      Back
                    </Button>
                  )}
                  <div className="form-div">
                    <Button id="secondary" onClick={closehandler}>
                      Cancel
                  </Button>

                    <div className="wrapper">
                      <Button
                        disabled={isSubmitting}
                        type="submit"
                        id="primary"
                        className="button"
                      >
                        {isLastStep ? 'Submit' : 'Next'}
                      </Button>
                    </div>

                  </div>


                </DialogActions>
                {loading && (<div className="loading-overlay"><img src={loadingScreen}></img></div>)}
              </Form>

            )}

        </Formik>

      )}

    </>
  );
};
export default CreateCustomer;
