import React, { useState, useEffect } from 'react';
import { axiosInstanceDashboard } from '../../../api/Axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  DialogActions,
  Button,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { useErrorHandler } from 'react-error-boundary';
import '../Popup/index.css';

const InventoryReadinessTrackerPopup = (props) => {
  const { popupopen, setpopupopen, confirmFunc, actionName } = props;
  function closehandler() {
    setpopupopen(false);
  }
  function confirmhandler(){
    confirmFunc()
    setpopupopen(false);
  }
  return (
    <div>
      <Dialog
        sx={{
          '& .MuiTypography-root': { fontFamily: 'inherit', fontWeight: 700 }
        }}
        open={popupopen}
        fullWidth={true}
        maxWidth={'md'}
        onClose={closehandler}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {actionName} Status Confirmation
          <IconButton
            aria-label="close"
            onClick={closehandler}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          This step should be completed after <span style={{color:'red',fontWeight:'bolder'}}>'Go-Live'</span>
          <br></br>
          <br></br>
          Do you still want to complete this step?
         
        </DialogContent>

        <DialogActions sx={{ boxShadow: '0 0 10' }}>
          <Button onClick={closehandler}>
            Cancel
          </Button>
          <Button id="primary" onClick={confirmhandler}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InventoryReadinessTrackerPopup;
