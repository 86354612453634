import { axiosInstanceDocument } from '../api/Axios';

export const uploadICTfile = async (customerID, acceptedFiles, templateID) => {
  let formData = new FormData();
  formData.append('bulkRequest', acceptedFiles);
  const response = axiosInstanceDocument
    .request({
      method: 'POST',
      url: `/item/bulkRequest/${customerID}`,
      data: formData
    });

  return response;
};
