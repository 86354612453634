import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Field, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { axiosInstanceDashboard } from '../../../api/Axios';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import _ from 'lodash'
import { Checkbox, FormControlLabel } from '@mui/material';
import { storeManualCreateNationalId } from '../../containers/templateComponent/redux/slice';
import { useDispatch, useSelector} from 'react-redux';
const CustomerForm = ({
  values,
  setFieldValue,
  errors,
  touched,
  setValues,
  formEditClass,
  showAccountOwner,
  hideFields,
  disabledField = {},
  manualNationalId,
  handleBlur,
  setFieldError,
  isExcuteConcierge,
  isUpdateCustomer,
}) => {
  const FORMCONTROL = 'form-control';
  const ISINVALID = ' is-invalid';
  const FORMTITLE = ' form-title';
  const [segmentList, setChannelList] = useState();
  const [totalCompletion,settotalCompletion] = useState(0)
  const fetchlist = async () => {
    await axiosInstanceDashboard
      .request({
        method: 'GET',
        url: '/getChannelDetails'
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setChannelList(response?.data?.data);
          }
        }
      });
  };
  const fetchTotalCompletion = () => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getCustomerTotalCompletion?customerId=${values?.customerId}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            settotalCompletion(_.get(response, 'data.data.Total Completion.totalCompletion', 0))
            
          }
        }
      });
  };

  useEffect(() => {
    fetchlist();
  }, []);

  useEffect(() => {
    if(values?.customerId){
      fetchTotalCompletion()
    }
  }, [values]);

  const list = segmentList?.['Channel Details'];

  const segmentdata = Object.keys(list || {})?.map((ele) => ({
    label: ele,
    value: ele
  }));

  const channelData = (selectedSegment) => {
    const val = [...new Set(list?.[selectedSegment])]?.map((ele) => ({
      label: ele,
      value: ele
    }));
    return val;
  };
  function className(FORMTITLE, param1, param2) {
    return FORMTITLE + (param1 && param2 ? ISINVALID : '');
  }
  const isLocationOrConceptValidtion = (recordType) => {
    return recordType === 'LOCATION' || recordType === 'CONCEPT';
  };
  function placeholder(formEditClass) {
    return !formEditClass ? 'Input text*' : '';
  }

  const renderSelectField = (name, options) => {
    let field = null;
    if (formEditClass) {
      field = (
        <Field
          name={name}
          type="text"
          placeholder={placeholder(formEditClass)}
          className={className(FORMCONTROL, errors[name], touched[name])}
        />
      );
    } else {
      field = (
        <Select
          id={name + (errors[name] && touched[name] ? ' is-invalid' : '')}
          name={name}
          label={name}
          options={options}
          value={
            values[name]
              ? {
                  label: values[name],
                  value: values[name]
                }
              : 'Select'
          }
          onChange={(value) => {
            if(name === 'segment'){
              setFieldValue('channel',[])
            }
            setFieldValue(name, value?.value);
          }}
          //COT-9015: if excute concierge can not edit concept ID
          isDisabled={isExcuteConcierge && name === 'conceptId' ? true : !!disabledField[name]}
        />
      );
    }
    //COT-9015:Disable "Create National ID" for the customers who have already created-- ability to update concept id and can change concept id to empty
    if(isUpdateCustomer &&  name === 'conceptId'){
      if(!_.isEmpty(values?.nationalId)){
        return (
        <>{field}
          <div className={className('required', errors[name], touched.name)}>
            *Required
          </div>
        </>)
      }
      return (
        <>{field}</>
      )
    }
    return (
      <>
        {field}
        {!formEditClass && (
          <div className={className('required', errors[name], touched.name)}>
            *Required
          </div>
        )}
      </>
    );
  };

  const isLocationOrConcept = isLocationOrConceptValidtion(
    values.AccountRecordType
  );
  const dispatch = useDispatch();
  const validateNationalId = (value) => {
    if (_.isEmpty(value)) {
      if(isUpdateCustomer) return null;
      return '*Required';
    };
    const reg = new RegExp('^[A-Z]{4}$', 'g');
    if (!reg.test(value)) {
      return 'National ID should be 4 capital letters.';
    }
    return null;
  }
  // console.log('errors.nationalId',errors);
  // console.log('formEditClass',formEditClass);
  // console.log('isExcuteConcierge',isExcuteConcierge);
  const [conceptList, setConceptList] = useState();
  const conceptData = [
    ...new Set(conceptList?.map((item) => item?.Concept_ID__c))
  ];
  const uniqueConcept = conceptData
    ?.filter((x) => x)
    ?.map((ele) => ({
      label: ele,
      value: ele
    }));
  const fetchConceptIds = async () => {
    await axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getConceptDetails?national_ID=${values?.nationalId}`
      })
      .then((response) => {
        if (response) {
          if (response?.data?.data?.Concept_Details?.totalSize > 0) {
            setConceptList(response?.data?.data?.Concept_Details?.records);
          } else {
            setFieldError('nationalId', 'National ID is not found.');
          }
        }
      });
  }
  useEffect(() => {
    {
      values?.nationalId && fetchConceptIds();
    }
  }, []);
  return (
    <div className="customer-form form-container">
      <Grid container rowSpacing={1} columnSpacing={3}>
        {isLocationOrConcept ? (
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div className={FORMTITLE}>National Account Name</div>
              <div className={`form-subTitle ${formEditClass}`}>
                <Field
                  name="name"
                  type="text"
                  disabled
                  placeholder={placeholder(formEditClass)}
                  className={FORMCONTROL}
                />
              </div>
            </div>
          </Grid>
        ) : (
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div className={className(FORMTITLE, errors.name, touched.name)}>
                National Account Name
              </div>
              <div className={`form-subTitle fullWidth ${formEditClass}`}>
                <Field
                  name="name"
                  type="text"
                  placeholder={placeholder(formEditClass)}
                  className={className(FORMCONTROL, errors.name, touched.name)}
                />
                {!formEditClass && (
                  <div
                    className={className('required', errors.name, touched.name)}
                  >
                    *Required
                  </div>
                )}
              </div>
            </div>
          </Grid>
        )}
        {showAccountOwner && (
          <Grid item xs={6}>
            <div className="form-header">
              <div className="form-title">Account Owner</div>
              <div
                className={`form-subTitle showAccountOwner ${formEditClass}`}
              >
                {values.accountOwner && (
                  <AccountCircleOutlinedIcon className="AccountCircleOutlinedIcon" />
                )}
                <Field
                  name="accountOwner"
                  type="text"
                  placeholder={placeholder(formEditClass)}
                  className={className(
                    'form-control',
                    errors.accountOwner,
                    touched.accountOwner
                  )}
                />
              </div>
              <ErrorMessage
                name="accountOwner"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </Grid>
        )}

        <Grid item xs={6} sm={6}>
          <div className="form-header">
            <div className={className(FORMTITLE, errors.parentAccountType, touched.parentAccountType)}>
              Account {isLocationOrConcept ? 'Record' : ''} Type
            </div>
            <div className="form-subTitle">
              {!isLocationOrConcept ? (
                renderSelectField('parentAccountType', [
                  { label: 'CMU', value: 'CMU' },
                  { label: 'LCC', value: 'LCC' }
                ])
              ) : (
                <Field
                  name="AccountRecordType"
                  type="text"
                  disabled
                  placeholder={placeholder(formEditClass)}
                  className={FORMCONTROL}
                />
              )}
            </div>
          </div>
        </Grid>
        {isLocationOrConcept && (
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div className={FORMTITLE}>National Id</div>
              <div className="form-subTitle">
                <Field
                  name="nationalId"
                  type="text"
                  disabled
                  placeholder={placeholder(formEditClass)}
                  className={FORMCONTROL}
                />
              </div>
            </div>
          </Grid>
        )}
        {values.AccountRecordType === 'CONCEPT' && (
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div
                className={className(
                  FORMTITLE,
                  errors.conceptName,
                  touched.conceptName
                )}
              >
                Concept Name
              </div>
              <div className="form-subTitle">
                <Field
                  name="conceptName"
                  type="text"
                  placeholder={placeholder(formEditClass)}
                  className={className(
                    FORMCONTROL,
                    errors.conceptName,
                    touched.conceptName
                  )}
                />
                {!formEditClass && (
                  <div
                    className={className(
                      'required',
                      errors.conceptName,
                      touched.conceptName
                    )}
                  >
                    {errors.conceptName && touched.conceptName ? (
                      <ErrorMessage
                        name="conceptName"
                        component="div"
                        className="invalid-feedback"
                      />
                    ) : (
                      '*Required'
                    )}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        )}
        <Grid item xs={6} sm={6}>
          <div className="form-header">
            <div
              className={className(FORMTITLE, errors.segment, touched.segment)}
            >
              Segment
            </div>
            <div className="form-subTitle">
              {renderSelectField('segment', segmentdata)}
            </div>
          </div>
        </Grid>
        <Grid item xs={6} sm={6}>
          <div className="form-header">
            <div
              className={className(FORMTITLE, errors.channel, touched.channel)}
            >
              Channel
            </div>
            <div className="form-subTitle">
              {renderSelectField('channel', channelData(values.segment))}
            </div>
          </div>
        </Grid>
        {!hideFields && (
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div className={FORMTITLE + (errors.goLiveDate ? ISINVALID : '')}>
                Estimated Go Live Date
              </div>
              <div className="form-subTitle">
                {showAccountOwner ? (
                  <DatePicker
                    selected={values?.goLiveDate}
                    disabled
                    dateFormat="MM/dd/yyyy"
                    minDate={moment().toDate()}
                    maxDate={new Date('12/31/9999')}
                    placeholderText={'MM/dd/yyyy'}
                    className={
                      'form-control goLiveDate' +
                      (errors.goLiveDate ? ' is-invalid' : '')
                    }
                    name="goLiveDate"
                    onChange={(date) => setFieldValue('goLiveDate', date)}
                  />
                ) : (
                  <DatePicker
                    selected={values?.goLiveDate}
                    dateFormat="MM/dd/yyyy"
                    minDate={moment().toDate()}
                    maxDate={new Date('12/31/9999')}
                    placeholderText={'MM/dd/yyyy'}
                    className={
                      'form-control goLiveDate' +
                      (errors.goLiveDate ? ' is-invalid' : '')
                    }
                    name="goLiveDate"
                    onChange={(date) => setFieldValue('goLiveDate', date)}
                  />
                )}
                {!formEditClass && (
                  <div className={'goLiveDate-required'}>
                    {moment().diff(moment(values.goLiveDate), 'days') > -90
                      ? 'The standard NCOB process for onboarding is with a 90 day/12 week runway'
                      : ''}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        )}
       
        {formEditClass && 
        (<Grid item xs={6} sm={6}>
          <div className="form-header">
            <div className={FORMTITLE}>Total Completion</div>
           
            <div className="form-subTitle">
              <lable style={{ fontWeight: 'bold', color: 'black' }}>
                  {totalCompletion}
                  {'%'}
              </lable>
            </div>
          </div>
        </Grid>
        )}
      </Grid>
      {manualNationalId && (
        <>
          <Grid container rowSpacing={1} columnSpacing={3}>
            <Grid item xs={6} sm={6}>
              <div className="form-header">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={values.manuallyCreateNationalId}
                      onChange={(e) => {
                        if (e.target) {
                          setFieldValue("manuallyCreateNationalId", e.target.checked);
                          setFieldValue("nationalId", "");
                          setFieldValue("conceptId", "");
                          dispatch(storeManualCreateNationalId({ manualCreate: e.target.checked }))
                        }
                      }}
                    />
                  }
                  label='Do you want to input NID?'
                />
              </div>
            </Grid>
            {/* <Grid item xs={6} sm={6}><span style={{ display: values.manuallyCreateNationalId ? "block" : "none" }} className='warning-msg'>Note: Natioanl ID and Concept ID can not be updated if created!</span></Grid> */}
          </Grid>
        </>
      )}
      {((manualNationalId && values.manuallyCreateNationalId) || showAccountOwner)  &&
        <Grid container rowSpacing={1} columnSpacing={3}>
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div
                className={className(
                  FORMTITLE,
                  errors.nationalId,
                  touched.nationalId
                )}
              >
                National ID
              </div>
              <div className="form-subTitle">
                {/* {showAccountOwner ? <Field
                  name="nationalId"
                  type="text"
                  disabled
                  className={FORMCONTROL}
                /> : */}
                  <Field
                    name="nationalId"
                    type="text"
                    className={FORMCONTROL}
                    disabled={isExcuteConcierge}
                    onChange={(e) => {
                      setFieldValue("nationalId", e.target.value);
                      setFieldValue("conceptId","");
                    }}
                    onBlur={(e) => {
                      handleBlur(e);
                      setConceptList([]);
                      if (values?.nationalId?.length === 4) {
                        fetchConceptIds();
                      }
                    }}
                    validate={(value) =>validateNationalId(value)}
                    autocomplete="off"
                  />
                {/* } */}
                {(manualNationalId || isUpdateCustomer) && (
                  <div
                    className={className(
                      'required',
                      errors.nationalId,
                      touched.nationalId
                    )}
                  >
                    {errors.nationalId && touched.nationalId ? (
                      <div className="invalid-feedback">{errors.nationalId}</div>
                    ) : (
                      '*Required'
                    )}
                  </div>
                 )}
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div
                className={className(FORMTITLE, errors.conceptId, touched.conceptId)}
              >
                Concept ID
              </div>
              <div className="form-subTitle">
                {renderSelectField('conceptId', uniqueConcept)}
              </div>
            </div>
          </Grid>
        </Grid>
      }
    </div>
  );
};

export default CustomerForm;
