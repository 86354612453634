import React, { useState, useEffect } from 'react';
import { SidebarData } from './SidebarData';
import MenuIcon from '@mui/icons-material/Menu';
import '../Sidebar/Sidebar.css';
import { useSelector } from 'react-redux';

const Sidebar = () => {
  const [sidebarOpen, setSideBarOpen] = useState(false);
  const [isAdminCheck, setIsAdminCheck] = useState(false);
  const [hrefLink, sethrefLink] = useState();
  const [checkCustomerPortal, setCheckCustomerPortal] = useState(null);
  const handleViewSidebar = (e) => {
    setSideBarOpen(!sidebarOpen);
  };
  const linktoPage = (val) => {
    window.location.href = val;
  };
  const userRole = useSelector((state) => state?.userRole?.data?.userRole);
  const sidebarClass = sidebarOpen ? 'sidebar-open' : 'sidebar';
  useEffect(() => {
    sethrefLink(window.location.pathname);
  }, []);
  React.useEffect(() => {
    // window.addEventListener('storage', () => {
      const isAdmin = localStorage.getItem('isAdmin');
      // const userRole = localStorage.getItem('userRole');
      if(userRole === 'Sysco NCOB Team'){
        setIsAdminCheck(true);
      }else{
        setIsAdminCheck(false);
      }
    // });
  }, [userRole]);
  if (hrefLink?.split('/')[1] === ('new-onboarding' || 'Dashboard'))
    sethrefLink('/');
  useEffect(() => {
    window.addEventListener('customerPortalUser', () => {
      setCheckCustomerPortal(localStorage.getItem('customerPortalUser'));
    });
  }, []);

  return (
    <div className={sidebarClass}>
      <div className="toggle-navbar" onClick={handleViewSidebar}>
        <MenuIcon />
      </div>

      <ul className="list">
        {SidebarData.map((value, index) => {
          if (
            (value?.checkIsAdmin && !isAdminCheck) ||
            (value?.isCustomerPortal && !checkCustomerPortal)
          ) {
            return;
          }
          return (
            <div
            data-testid="d"
              onClick={() => linktoPage(value.link)}
              className={hrefLink === value.link ? 'active' : ''}
              key={value.title}
            >
              <li className={sidebarOpen ? 'item-open' : 'item'}>
                <div id="icon">{value.icon}</div>
                <div id="title">{value.title}</div>
              </li>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
