import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  DialogTitle,
  Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomerForm from '../CustomerForm/CustomerForm';
import ShippingForm from '../CustomerForm/ShippingForm';
import BillingForm from '../CustomerForm/BillingForm';
import { Formik } from 'formik';
import '../../containers/SalesDashboard/index.css';
import '../../containers/templateComponent/index.css';
import { updateCustomerAPI } from '../../../utils/common';
import '../Modal/index.css';
import '../CustomerForm/index.css';
import '../UpdateCustomerModal/index.css';
import LocationCustomerForm from '../CustomerForm/LocationCustomerForm';
import { UpdateValidation } from '../../../utils/validate';
import moment from 'moment';
import ContactInfo from '../CustomerForm/ContactInfo';
import LeadAndPartnerFields from '../SalesCustomerInfo/LeadAndPartnerFields';
export default function UpdateCustomerModal(props) {
  const {
    modalopen,
    setmodalopen,
    initialValues,
    fetchlist,
    snackbarShowMessage,
    parentCustID,
    isExcuteConcierge,
  } = props;
  const {
    updateValidationSchema,
    updateValidationSchema_loc,
    updateValidationSchema_concept
  } = UpdateValidation(initialValues?.email);
  const formEditClass = 'editClass-modal';

  function closehandler() {
    setmodalopen(false);
  };

  const CustomButton = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: '#008CD2',
    borderRadius: '30px',
    '&:hover': {
      backgroundColor: '#008CD2'
    }
  }));
  const dateformatter = (date) => moment(date).format('YYYY-MM-DD');
  const handleSubmitVal = (values, actions) => {
    //COT-9015: Disable "Create National ID"--- set manuallyCreateNationalId is true, if national id and concept id are not empty
    if(values?.nationalId && values?.conceptId){
      values['manuallyCreateNationalId'] = true;
    }
    values['liveDate'] = dateformatter(values.goLiveDate);
    actions.setSubmitting(true);
    updateCustomerAPI(values)
      .then((res) => {
        if (res?.status === 200) {
          if (res?.data?.errorCode || res?.data?.errorMessage) {
            setmodalopen(false);
            snackbarShowMessage({
              message: `Unable to update the details. Please try again later`,
              type: 'error'
            });

            return false;
          }
          snackbarShowMessage({
            message: `Details Updated for ${values?.customerName}`,
            type: 'success'
          });

          fetchlist();
          setmodalopen(false);
        }
      })
      .catch((err) => {
        snackbarShowMessage({
          message: `Something went wrong. Please try again later`,
          type: 'error'
        });
      });
  };

  const checkValidationSchema = () => {
    if (initialValues.AccountRecordType === 'LOCATION') return updateValidationSchema_loc
    else if (initialValues.AccountRecordType === 'CONCEPT') return updateValidationSchema_concept
    else return updateValidationSchema
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      open={modalopen}
      onClose={closehandler}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className="update-customer-title">
        <b>Update Customer Details</b>
        <div data-testid="closeicon" className="closeDialog" onClick={closehandler}>
          {' '}
          <CloseIcon />{' '}
        </div>
      </DialogTitle>

      <Formik
        className="customer-form update-customer-main"
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={checkValidationSchema()}
        onSubmit={handleSubmitVal}
      >
        {({
          errors,
          touched,
          values,
          setValues,
          setFieldValue,
          handleSubmit,
          handleBlur,
          setFieldError,
        }) => {

          const gridVal = values.AccountRecordType === 'CMU' ? 3 : 3;
          return (
            <>
              <DialogContent sx={{ p: 0 }}>
                <Grid
                  container
                  sx={{
                    margin: '0px 0 50px 0',
                    padding: '20px 10px 40px 0px',
                    backgroundColor: '#fafafa'
                  }}
                >
                  <Grid
                    item
                    xs={gridVal}
                    sx={{
                      '& .MuiGrid-root': { maxWidth: '100%' },
                      display: 'flex',
                      maxWidth: '100%',
                      mt: 3
                    }}
                    className={`info-container popup-content div-separator  ${formEditClass}`}
                  >
                    {values.AccountRecordType === 'LOCATION' ? (
                      <LocationCustomerForm
                        values={values}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        touched={touched}
                        setValues={setValues}
                        showAccountOwner={true}
                        hideFields={parentCustID ? true : false}
                      />
                    ) : (
                      <CustomerForm
                        errors={errors}
                        touched={touched}
                        values={values}
                        disabledField={{ parentAccountType: true }}
                        setFieldValue={setFieldValue}
                        showAccountOwner={true}
                        hideFields={parentCustID ? true : false}
                        isExcuteConcierge={isExcuteConcierge}
                        handleBlur={handleBlur}
                        setFieldError={setFieldError}
                        isUpdateCustomer={true}
                      />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={gridVal}
                    sx={{
                      '& .MuiGrid-root': { maxWidth: '100%' },
                      display: 'flex',
                      maxWidth: '100%',
                      mt: 3
                    }}
                    className={`info-container popup-content div-separator  ${formEditClass}`}
                  >
                    <ContactInfo
                      errors={errors}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={gridVal}
                    sx={{
                      '& .MuiGrid-root': { maxWidth: '100%' },
                      display: 'flex',
                      maxWidth: '100%',
                      mt: 3
                    }}
                    className={`info-container popup-content div-separator  ${formEditClass}`}
                  >
                    <ShippingForm
                      errors={errors}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                  {values.AccountRecordType !== 'CMU' && (
                    <Grid
                      item
                      xs={gridVal}
                      sx={{
                        '& .MuiGrid-root': { maxWidth: '100%' },
                        display: 'flex',
                        maxWidth: '100%',
                        mt: 3
                      }}
                      className={`info-container popup-content div-separator  ${formEditClass}`}
                    >
                      <BillingForm
                        modalOpen={modalopen}
                        errors={errors}
                        touched={touched}
                        values={values}
                        setFieldValue={setFieldValue}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={gridVal}
                    sx={{
                      '& .MuiGrid-root': { maxWidth: '100%' },
                      display: 'flex',
                      maxWidth: '100%',
                      mt: 3
                    }}
                    className={`info-container popup-content div-separator  ${formEditClass}`}
                  >
                    <LeadAndPartnerFields
                      errors={errors}
                      touched={touched}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogActions className="customer-form">
                <Grid
                  className="form-buttons form-div"
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  gap="10px"
                  sx={{ mt: 1 }}
                >
                  <Grid
                    item
                    onClick={() => {
                      setmodalopen(false);
                    }}
                  >
                    <CustomButton
                      id="secondary"
                      variant="contained"
                      startIcon={<CancelIcon />}
                    >
                      Cancel
                    </CustomButton>
                  </Grid>
                  <Grid item className="updateIcon" onClick={handleSubmit}>
                    <CustomButton
                      type="submit"
                      variant="contained"
                      startIcon={<UpgradeIcon />}
                    >
                      Update
                    </CustomButton>
                  </Grid>
                </Grid>
              </DialogActions>
            </>
          );
        }}
      </Formik>
    </Dialog>
  );
}
