import { createSlice } from '@reduxjs/toolkit';

//Initializing state
const initialState = {};
let userData = {};

//Creating Reducers functions
export const templateSlice = createSlice({
  name: 'getAllActions',
  initialState,
  reducers: {
    getAllActions(state, action) {
      return { ...state, ...action.payload };
    },
    getAllActionsSuccess(state, action) {
      return { ...state, ...action.payload };
    },
    getAllActionsError(state, action) {
      return { ...state, ...action.payload };
    }
  }
});

//Action creators
export const {
  getAllActions,
  getAllActionsSuccess,
  getAllActionsError
} = templateSlice.actions;

export default templateSlice.reducer;
