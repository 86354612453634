import { createSlice } from '@reduxjs/toolkit';

//Initializing state
const initialState = {};
let userData = {};

//Creating Reducers functions
export const templateSlice = createSlice({
  name: 'getCustomerProgress',
  initialState,
  reducers: {  
    getCustomerProgress(state, action) {
      return { ...state, ...action.payload };
    },
    getCustomerProgressSuccess(state, action) {
      userData = action.payload;
      return {...state,...userData };
    },
    getCustomerProgressError(state,action) {
      return { ...state, ...action.payload };
    },
    resetGetCustomerProgress(_state,_action) {
      userData = {};
      return { ...initialState, ...userData };
    }
  },
});


//Action creators
export const { getCustomerProgress, getCustomerProgressSuccess, getCustomerProgressError, resetGetCustomerProgress} = templateSlice.actions;

export default templateSlice.reducer;