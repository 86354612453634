import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './index.css';
import { axiosInstanceDashboard } from '../../../api/Axios';
import _ from 'lodash';
import { Pagination } from '@mui/material';

export default function StatusList(props) {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const pageSize =  12;
  const [renderList, setRenderList] = useState([])
  const getStatusByConcept = () => {
    axiosInstanceDashboard.get('/getStatusByConcept').then((res) => {
      if (_.get(res, 'data.data.hasError')) {
        return;
      }
      let data = _.get(res, 'data.data.concept status', [])
      data.map(res=>{res.week = 'T+'+res.week})
      setList(data);
      setRenderList(data.slice((page - 1) * pageSize, page * pageSize));
    });
  };
  useEffect(() => {
    getStatusByConcept()
  }, [])
  let typeclassName = {
    'On Hold': 'bg-grey',
    'Requires Attention': 'bg-pink',
    'On Track': 'bg-green',
    'At Risk': 'bg-orange'
  };
  return (
    <div className="status-list">
      <div className="head-status-list">
        <h5>NCOB Status</h5>
        <div className="head-status-list-tit">
          <div className="head-status-list-tit-item">
            <span className="bg-pink"></span>
            <span>Requires Attention</span>
          </div>
          <div className="head-status-list-tit-item">
            <span className="bg-orange"></span>
            <span>At Risk</span>
          </div>
          <div className="head-status-list-tit-item">
            <span className="bg-green"></span>
            <span>On Track</span>
          </div>
          <div className="head-status-list-tit-item">
            <span className="bg-grey"></span>
            <span>On Hold</span>
          </div>
        </div>
      </div>
      <div className="body-status-list">
        <div className="status-list-head">
          <div className="status-list-first-item">Concept</div>
          <div className="status-list-second-item">Go Live Date</div>
          <div className="status-list-third-item">Week</div>
        </div>
        <div className="status-list-body">
          {_.map(renderList, (item) => (
            <div className="status-list-items">
              <div className="status-list-first-item">
                <span className={typeclassName[item.status]}></span>
                <Link target="_blank"
                  to={ `/new-onboarding/${item?.customerId}`}
                  style={{ textDecoration: 'none' }}
                >
                  {item.customerName}
              </Link>
              </div>
              <div className="status-list-second-item">{item.goLiveDate}</div>
              <div className="statuss-lit-third-item">{item.week}</div>
            </div>
          ))}
        </div>
        <Pagination
            style={{ marginTop: '16px' }}
            className="customPagination"
            size="small"
            page={page}
            count={Math.ceil(list.length / pageSize)}
            variant="outlined"
            shape="rounded"
            onChange={(_, page) => {
              setPage(page);
              setRenderList(list.slice((page - 1) * pageSize, page * pageSize))
            }}
            sx={{ padding: '20px', display: 'flex', justifyContent: 'center' }}
          />
      </div>
    </div>
  );
}
