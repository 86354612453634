import { axiosInstanceDocument } from '../api/Axios';

export const uploadTemplateFile = async ( acceptedFiles, templateID) => {
  let formData = new FormData();
  formData.append('file', acceptedFiles);
  const response = axiosInstanceDocument
    .request({
      method: 'POST',
      url: `/uploadTemplate/${templateID}`,
      data: formData
    });

  return response;
};