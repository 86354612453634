const tempcustomer = {
  data: {
    'Customer Progress': {
      customerID: 38,
      customerName: 'Satyen Bose',
      actionItems: [
        {
          actionId: 14,
          actionName: 'Customer Details',
          sectionName: 'Sales',
          startDate: '2022-12-12T09:53:37.335+00:00',
          modifiedDate: '2022-12-12T09:53:37.335+00:00',
          sectionStatus: 'Active',
          modifiedBy: 'Brad Shaw'
        },
        {
          actionId: 15,
          actionName: 'Business Event',
          sectionName: 'Sales',
          startDate: '2022-12-12T09:53:47.033+00:00',
          modifiedDate: '2022-12-12T09:53:47.033+00:00',
          sectionStatus: 'Active',
          modifiedBy: 'Brad Shaw'
        },
        {
          actionId: 1,
          actionName: 'Sales',
          sectionName: 'Sales',
          sectionStatus: 'ACTIVE',
          startDate: '2022-12-12T09:56:06.290+00:00',
          modifiedDate: '2022-12-12T09:56:06.290+00:00'
        }
      ],
      actionCompletedForCurrentSection: 3,
      completedSections: 1
    }
  },
  errorCode: null,
  errorMessage: null,
  errorRefId: null,
  hasError: false
};

export default tempcustomer;
