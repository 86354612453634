import React, { useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogActions } from '@mui/material';
import '../EnableSkip/index.css';
import '../CustomerForm/index.css';
import '../Modal/index.css';
import '../Select/index.css';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { withSnackbar } from '../SnackBar/SnackBar';


function EnableSkip(props) {
  const {isSkipActive,sectionSubmit, snackbarShowMessage } = props;
  const [open, setOpen] = React.useState(false);
  const [comment, setComment] = useState("")

  function handleCommentChange(event) {
    setComment(event.target.value);
  }

  function handleCommentClose() {
    setOpen(false);
    setComment('');
  }

  function handleSubmitComment() {
    if (comment != '') {
      snackbarShowMessage({
        message: 'Commented Successfully',
        type: 'success'
      });
      sectionSubmit(false, {"enableSkip": true,"comment": comment})
    } else {
      snackbarShowMessage({ message: 'No Comments added', type: 'error' });
    }
  }

  return (
    <>
    {isSkipActive && (
      <Box sx={{ m: 1, position: 'relative' }}>
        <Button
        
          sx={{
            '& .MuiButton-root': {
              fontSize: 14
            }
          }}
          className="buttons-1 skip-buttons-1"
          onClick={() =>  setOpen(!open)}
        >
          Skip this Step
        </Button>
      </Box>
    )}
           <Dialog
        open={open}
        onClose={handleCommentClose}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <DialogTitle>
          <b>Comment</b>
        </DialogTitle>
        <DialogContent>
          <TextField
         inputProps={{ "data-testid": "textbox" }}
            onChange={handleCommentChange}
            id="outlined-multiline-static"
            value={comment}
            margin="dense"
            fullWidth
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>

          <Box sx={{ m: 1, position: 'relative' }}>
              <Button
                sx={{ '& .MuiButton-root': { fontSize: 14 } }}
                id="secondary"
                className="buttons-1"
                onClick={handleCommentClose}
              >
                Cancel
              </Button>
            </Box>
            <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              id="primary"
              className="buttons-2"
                onClick={handleSubmitComment}
              >
                Submit
              </Button>
            </Box>
     
        </DialogActions>
      </Dialog>
      </>
  );

}

export default withSnackbar(EnableSkip);
