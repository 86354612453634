const emailTepmlateIdMapper = {
  1: 'Finance  Approval',
  2: 'Opsite Alignment',
  3: 'KickOFF',
  4: 'CST Alignment',
  5: 'Supplier OMCC',
  6: 'Credit and DPM analysis',
  7: 'Credit Applications',
  8: 'Account Owner Alignment',
  9: 'Corporate Credit Approval',
  10: 'Routing SetUp',
  11: 'EDI Setup',
  12: 'Sysco Pay Request',
  13: 'Shop Request',
  14: 'Propriety Items',
  15: 'Opening Order Items',
  16: 'MSA Stocking Items',
  17: 'Create Account',
  18: 'DPM Request',
  19: 'Customer Approval',
  20: 'Costing',
  21: 'Supplier Questionnaire',
  22: 'Supplier Doc to Risk Management',
  23: 'Supplier Agreements',
  24: 'Controlled Pricing setup',
  25: 'Simulated Pricing to VPNA',
  26: 'Sign Off on Simulated Pricing',
  27: 'OGM setup',
  28: 'Item Categorization',
  29: 'EDI Questionnaire',
  34: 'OMCC Intake Form',
  35: 'Special Orders',
  70: 'Inventory Management COE',
  72: 'Monitor PO Status',
  74: 'Inventory Transfer Complete',
  71: 'Final Internal Sign Off',
  69: 'Obtain Customer Approval for Transfer List',
  73: 'Inventory Disposition Plan',
  75: 'VPNA Remind Email'
};
export default emailTepmlateIdMapper;
