import React from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Scatter,
  ResponsiveContainer
} from 'recharts';
export default function ComposedCharts({
  chartProps = {},
  Xprops = { dataKey: 'name' },
  YProps = {},
  tooltip = true,
  legend = false,
  elements = []
}) {
  
  return (
    <ResponsiveContainer style={{zoom:1.25}}>
      <ComposedChart
        margin={{
          top: 58,
          right: 30,
          bottom: 58,
          left: 0
        }}
        {...chartProps}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis dataKey={'name'} {...Xprops} angle={315} textAnchor={"end"}/>
        <YAxis {...YProps} />
        {tooltip && <Tooltip/>}
        {legend &&<Legend />}
        {elements.map(({ type = 'Bar', props }) => {
          if (type === 'Bar') {
            return <Bar {...props} />;
          }
          if (type === 'Scatter') {
            return <Scatter {...props} />;
          }
        })}
      </ComposedChart>
    </ResponsiveContainer>
  );
}
