import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { Field, ErrorMessage } from 'formik';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { axiosInstanceDashboard } from '../../../api/Axios';
import Select from 'react-select';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import _ from 'lodash'

const LocationCustomerForm = ({
  values,
  setFieldValue,
  errors,
  touched,
  formEditClass,
  showAccountOwner,
  hideFields
}) => {
  const FORM_CONTROL = 'form-control';
  const FORM_TITLE = 'form-title';
  const IS_INVALID = ' is-invalid';

  function className(FORMTITLE, param1, param2) {
    return FORMTITLE + (param1 && param2 ? IS_INVALID : '');
  }
  function placeholder(str) {
    return !formEditClass ? str : '';
  }
  const [conceptList, setConceptList] = useState();
  const [siteId, setSiteId] = useState();
  const [totalCompletion,settotalCompletion] = useState(0)
  const fetchlist = async () => {
    await axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getConceptDetails?national_ID=${values?.nationalId}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setConceptList(response?.data?.data?.Concept_Details?.records);
          }
        }
      });
  };
  const fetchTotalCompletion = () => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getCustomerTotalCompletion?customerId=${values?.customerId}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            settotalCompletion(_.get(response, 'data.data.Total Completion.totalCompletion', 0))
            
          }
        }
      });
  };
  const fetchSiteId = async () => {
    await axiosInstanceDashboard
      .request({
        method: 'GET',
        url: '/getOpcoDetails'
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setSiteId(response?.data?.data?.['Opco Details']);
          }
        }
      });
  };

  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      values?.nationalId && fetchlist();
    }
    fetchSiteId();
  }, []);

  useEffect(() => {
    if(values?.customerId){
      fetchTotalCompletion()
    }
  }, [values]);
  const segmentdata = [
    ...new Set(conceptList?.map((item) => item?.Concept_ID__c))
  ];
  const uniqueConcept = segmentdata
    ?.filter((x) => x)
    ?.map((ele) => ({
      label: ele,
      value: ele
    }));
  const getSiteIds = siteId?.map((ele) => ({
    value: ele.siteNum,
    label: `${ele.siteNum} - ${ele.siteDescription}`
  }));
  useEffect(() => {
    if (formEditClass && siteId?.length) {
      const formsiteId = siteId?.find((e) => e?.siteNum === values.opcoSite);
      setFieldValue(
        'opcoSite',
        `${formsiteId?.siteNum} - ${formsiteId?.siteDescription}`
      );
    }
   
  }, [siteId]);
  const getConceptName = (val) =>
    conceptList?.find((ele) => ele?.Concept_ID__c === val)?.Name;

  function value_solve(param1, alternatevalue) {
    if (param1) return { label: param1, value: param1 };
    else return alternatevalue;
  }

  function Opco_value_solve(param1, alternatevalue) {
    if (param1){
      const formsiteId = siteId?.find((e) => e?.siteNum === param1);
      return { label: formsiteId?.siteNum + ' - '+formsiteId?.siteDescription, value: formsiteId?.siteNum };
    }else{
      return alternatevalue;
    }
  }

  return (
    <div className="customer-form form-container">
      <Grid container rowSpacing={1} columnSpacing={3}>
        {
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div className={FORM_TITLE}>National Account Name</div>
              <div className={`form-subTitle ${formEditClass}`}>
                <Field
                  name="name"
                  type="text"
                  disabled
                  placeholder="Input text*"
                  className={FORM_CONTROL}
                />
              </div>
            </div>
          </Grid>
        }
        {showAccountOwner && (
          <Grid item xs={6}>
            <div className="form-header">
              <div className="form-title">Account Owner</div>
              <div
                className={`form-subTitle showAccountOwner ${formEditClass}`}
              >
                {values.accountOwner && (
                  <AccountCircleOutlinedIcon className="AccountCircleOutlinedIcon" />
                )}
                <Field
                  name="accountOwner"
                  type="text"
                  placeholder={placeholder('Input text*')}
                  className={className(
                    'form-control',
                    errors.accountOwner,
                    touched.accountOwner
                  )}
                />
              </div>
              <ErrorMessage
                name="accountOwner"
                component="div"
                className="invalid-feedback"
              />
            </div>
          </Grid>
        )}
        <Grid item xs={6} sm={6}>
          <div className="form-header">
            <div className={FORM_TITLE}>Account Record Type</div>
            <div className={`form-subTitle ${formEditClass}`}>
              <Field
                name="AccountRecordType"
                type="text"
                disabled
                placeholder="Input text*"
                className={FORM_CONTROL}
              />
            </div>
          </div>
        </Grid>
        {
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div className={FORM_TITLE}>National Id</div>
              <div className={`form-subTitle ${formEditClass}`}>
                <Field
                  name="nationalId"
                  type="text"
                  disabled
                  placeholder={placeholder('XXXX')}
                  className={FORM_CONTROL}
                />
              </div>
            </div>
          </Grid>
        }

        <Grid item xs={6} sm={6}>
          <div className="form-header">
            <div
              className={className(
                FORM_TITLE,
                errors.conceptId,
                touched.conceptId
              )}
            >
              Concept Id
            </div>
            <div className={`form-subTitle ${formEditClass}`}>
              {formEditClass ? (
                <Field
                  name="conceptId"
                  type="text"
                  placeholder="Input text*"
                  className={'form-control'}
                />
              ) : (
                <Select
                  id="conceptId"
                  name="conceptId"
                  label="conceptId"
                  options={uniqueConcept}
                  value={value_solve(values.conceptId, 'Select Concept Id')}
                  onChange={(value) => {
                    setFieldValue('conceptId', value?.value);
                    setFieldValue('conceptName', getConceptName(value?.value));
                  }}
                />
              )}
              {!formEditClass && (
                <div
                  className={className(
                    'required',
                    errors.conceptId,
                    touched.conceptId
                  )}
                >
                  *Required
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={6} sm={6}>
          <div className="form-header">
            <div
              className={className(
                FORM_TITLE,
                errors.conceptName,
                touched.conceptName
              )}
            >
              Concept Name
            </div>
            <div className={`form-subTitle ${formEditClass}`}>
              <Field
                name="conceptName"
                type="text"
                disabled
                placeholder=""
                className={FORM_CONTROL}
              />
            </div>
          </div>
        </Grid>

        <Grid item xs={6} sm={6}>
          <div className="form-header">
            <div
              className={className(
                FORM_TITLE,
                errors.accountName,
                touched.accountName
              )}
            >
              Account Name
            </div>
            <div className={`form-subTitle ${formEditClass}`}>
              <Field
                name="accountName"
                type="text"
                placeholder="Input text*"
                className={className(
                  FORM_CONTROL,
                  errors.accountName,
                  touched.accountName
                )}
              />
              {!formEditClass && (
                <div
                  className={className(
                    'required',
                    errors.accountName,
                    touched.accountName
                  )}
                >
                  {errors.accountName && touched.accountName ? (
                    <ErrorMessage
                      name="accountName"
                      component="div"
                      className="invalid-feedback"
                    />
                  ) : (
                    '*Required'
                  )}
                </div>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={6} sm={6}>
          <div className="form-header">
            <div
              className={className(
                FORM_TITLE,
                errors.opcoSite,
                touched.opcoSite
              )}
            >
              Site
            </div>
            <div className={`form-subTitle ${formEditClass}`}>
              {formEditClass ? (
                <Field
                  name="opcoSite"
                  type="text"
                  placeholder="Input text*"
                  className={'form-control'}
                />
              ) : (
                <Select
                  id="opcoSite"
                  name="opcoSite"
                  label="opcoSite"
                  options={getSiteIds}
                  value = {Opco_value_solve(values.opcoSite, 'Select Site Id')}
                  onChange={(value) => {
                    setFieldValue('opcoSite', value.value);
                  }}
                />
              )}
              {!formEditClass && (
                <div
                  className={className(
                    'required',
                    errors.opcoSite,
                    touched.opcoSite
                  )}
                >
                  *Required
                </div>
              )}
            </div>
          </div>
        </Grid>
        {!hideFields && (
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div
                className={FORM_TITLE + (errors.goLiveDate ? IS_INVALID : '')}
              >
                Estimated Go Live Date
              </div>
              <div className={`form-subTitle ${formEditClass}`}>
                {showAccountOwner ? (
                  <DatePicker
                    selected={values?.goLiveDate}
                    disabled
                    dateFormat="MM/dd/yyyy"
                    minDate={moment().toDate()}
                    maxDate={new Date('12/31/9999')}
                    placeholderText={'MM/DD/YYYY'}
                    className={
                      'form-control goLiveDate' +
                      (errors.goLiveDate ? ' is-invalid' : '')
                    }
                    name="goLiveDate"
                    onChange={(date) => setFieldValue('goLiveDate', date)}
                  />
                ) : (
                  <DatePicker
                    selected={values?.goLiveDate}
                    dateFormat="MM/dd/yyyy"
                    minDate={moment().toDate()}
                    maxDate={new Date('12/31/9999')}
                    placeholderText={'MM/DD/YYYY'}
                    className={
                      'form-control goLiveDate' +
                      (errors.goLiveDate ? ' is-invalid' : '')
                    }
                    name="goLiveDate"
                    onChange={(date) => setFieldValue('goLiveDate', date)}
                  />
                )}
                {!formEditClass && (
                  <div className={'goLiveDate-required'}>
                    {moment().diff(moment(values.goLiveDate), 'days') > -90
                      ? 'The standard NCOB process for onboarding is with a 90 day/12 week runway'
                      : ''}
                  </div>
                )}
              </div>
            </div>
          </Grid>
        )}
        { formEditClass && 
        (<Grid item xs={6} sm={6}>
            <div className="form-header">
              <div className={FORM_TITLE}>Total Completion</div>
              <div className="form-subTitle">
                <lable style={{ fontWeight: 'bold', color: 'black' }}>
                    {totalCompletion}
                    {'%'}
                </lable>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default LocationCustomerForm;
