import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { styled } from '@mui/material/styles';
import '../Mail/index.css';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Chip from '@mui/material/Chip';
import BackupIcon from '@mui/icons-material/Backup';
import { getEmailTemplateRequest, sendEmailRequest, sendVPNAEmailRequest } from './redux/request';
import ViewDocuments from '../ViewDocuments';
import { axiosInstanceDocument, axiosInstanceEmail } from '../../../api/Axios';
import { mapping } from '../../../Helper/mapping';
import emailTemplateIdMapper from '../../../Helper/emailTemplateIdMapper';
import _ from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './VPNAEmail.css';
import { axiosInstanceEmailNonBff } from '../../../api/Axios';

const CustomButton = styled(Button)(({ theme }) => ({
    color: 'white',
    backgroundColor: '#008CD2',
    borderRadius: '30px',
    '&:hover': {
        backgroundColor: '#008CD2'
    }
}));
// mail mode: normal | resend
export default function VPNAEmail({
    snackbarShowMessage,
    mode = 'normal',
    mailValues,
    customerdetails,
    downloadeddocstatus,
    setmodalopen,
    setopenEmailDetails,
    goToInitView,
    isFromTrigger
}) {
    const [mailId, setMailId] = useState(0);

    const activestep = useSelector((state) => state?.storeActiveStep);

    const customerID  = customerdetails?.customerId;
    const [ccVisible, setCcVisible] = useState(false);
    const [mailSending, setMailSending] = useState(false);
    const syscoSalesContact = 'Sysco Sales Contact';
    const mandatoryDoc = ['Customer contact information', 'Operating Site Alignment & Heat Map', 'Deep Dive Template',
        'Item list', 'MSA/LOI', 'Customer Accounts', 'Customer Vendor List'];
    const requiredDoc = downloadeddocstatus ? mandatoryDoc.filter((doc) => !Object.values(downloadeddocstatus)
        .some((value) => value.docName === doc)) : mandatoryDoc;
    const salesContactEmail = customerdetails ?.customerContactResponses?.filter(item => item.contactType === syscoSalesContact)
    .map(item => item.email);
    const [values, setValues] = useState({
        to: [],
        cc: [],
        subject: '',
        content: ''
    });
    useEffect(() => {
        setValues(mailValues);
        if (mode === 'resend') {
            setCcVisible(true);
        }
    }, [mailValues]);
    const [files, setFiles] = useState([]);
    const [openDocuments, setOpenDocuments] = useState(false);
    const [customerFiles, setCustomerFiles] = useState([]);
    const [fileattachdone, setfileattachdone] = useState([]);
    const acctype = useSelector((state) => state?.AccountType?.AccountType);
    //the information in activestep not correct except actionID,actionName,so need filter origin mapping data again
    // let confirmAction = mapping[acctype]?.find(
    //     (action) => action?.actionName === activestep?.actionName
    // )
    // const mailattachflag = confirmAction?.mailattachflag;
    // const maildocmandatory = confirmAction?.maildocmandatory;
    const mailattachflag = false;
    const maildocmandatory = false;
    // const current = useSelector((state) => state?.storeActiveStep);
    const activeMailTemplateID = 75; //hardcode template id when send vpna email
    const activeId = 0;  //hardcode action id when send vpna email
    // const isSkipActive = mapping[acctype]?.find(
    //     (action) => action?.actionName === activeSection?.actionName
    // )?.enableSkip;
    useEffect(() => {
        if (mailattachflag) {
            setfileattachdone(JSON.parse(localStorage.getItem(`docid${customerID}`)))
            setFiles(JSON.parse(localStorage.getItem(`docname${customerID}`)));
        }
    }, [mailattachflag]);

    function handleViewFiles(flag) {
        setOpenDocuments(flag);
    }
    const handleAttach = (file) => {
        let checkUniqueDoc = files.filter(res => {
            return res.docName === file.docName
        })
        if (checkUniqueDoc.length > 0) {
            setfileattachdone(_.remove(fileattachdone, ((n) => {
                return n !== checkUniqueDoc[0]?.docStatusId
            })))
            setFiles(_.remove(files, ((n) => {
                return n.docStatusId !== checkUniqueDoc[0]?.docStatusId
            })))
        } else {
            setfileattachdone([...fileattachdone, file.docStatusId]);
            setFiles((files) => [...(files || []), file]);
        }
    };

    useEffect(() => {
        if (mailId && mode === 'normal') {
            getEmailTemplateRequest(mailId).then((r) => {
                const response = r?.data;
                if (!(response?.errorMessage || response?.errorCode)) {
                    //removed empty if block

                    const to = [],
                        cc = [];
                    
                    if (salesContactEmail && salesContactEmail?.length !== 0) {
                        to.push(...salesContactEmail); 
                    } else {
                        if (response?.data?.Email?.toName)
                            to.push(response?.data?.Email?.toName);
                    }
                    if (response?.data?.Email?.ccName) {
                        cc.push(response?.data?.Email?.ccName);
                        setCcVisible(true);
                    }
                    let content = response?.data?.Email?.emailBody;
                    if(requiredDoc?.length !== 0){
                        content = `${content} \nThese documents are required to be uploaded: \n ${requiredDoc}.`;
                        content = `${content} \nPlease refer this link to upload: ${window.location.origin}${window.location.pathname}.`
                    }else{
                        content = `${content} \nPlease refer this link: ${window.location.origin}${window.location.pathname}.`
                    }
                    setValues((prevState) => {
                        return {
                            ...prevState,
                            to,
                            cc,
                            subject: `Send Alerts - ${customerdetails.customerName}`,
                            content,            
                             };
                    });
                }
            });
        }
    }, [mode, acctype, mailId]);
    const fetchemailTemplate = () => {
        // if (!activeMailTemplateID) return
        axiosInstanceEmailNonBff
            .request({
                method: 'GET',
                url: `/getAllEmailTemplate`
            })
            .then((response) => {
                if (response) {
                    if (response.data) {
                        const targetMailId = _.get(
                            _.find(
                                response.data.data.Email,
                                ({ templateName }) =>
                                    templateName.trim() ===
                                    emailTemplateIdMapper[activeMailTemplateID].trim()
                            ),
                            'emailTemplateId'
                        );
                        setMailId(targetMailId);
                    }
                }
            });
    };
    useEffect(() => {
        fetchemailTemplate();
    }, [activeMailTemplateID]);

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleOpenModal = () => {
        axiosInstanceDocument
            .request({
                method: 'GET',
                url: `/downloadDocuments/${customerID}`
            })
            .then((response) => {
                if (response?.data?.errorCode === null) {
                    setCustomerFiles(response?.data?.data?.['Document Downloaded']);
                    handleViewFiles(true);
                } else {
                    snackbarShowMessage({
                        message: response?.data?.errorMessage,
                        type: 'error'
                    });
                }
            });
    };

    const toggleVisible = (type) => {
        if (type === 'cc') setCcVisible((state) => !state);
    };

    const handleRemoveFile = (file) => {
        const { docName, docStatusId } = file
        const shouldRemoveFileList = [
            {
                templateName: 'OMCC Intake Form',
                actionName: 'OMCC Intake Form'
            },
            {
                templateName: 'Special Orders',
                actionName: 'Special Orders'
            },
            {
                templateName: 'OGM Setup',
                actionName: 'OGM Setup'
            }
        ];
        if (docName === 'Order Guide' && activestep.actionName === 'Customer Approval') {
            snackbarShowMessage({
                message: `Order Guide Doc is Mandatory`,
                type: 'warning'
            });
        }
        const targetRemoveFile = _.find(shouldRemoveFileList, (item) => {
            return item.templateName.trim() === docName && activestep.actionName === item.actionName
        });
        if (targetRemoveFile) {
            if (localStorage.getItem(`docid${customerID}`))
                localStorage.removeItem(`docid${customerID}`);
            if (localStorage.getItem(`docname${customerID}`))
                localStorage.removeItem(`docname${customerID}`);
            snackbarShowMessage({
                message: `${targetRemoveFile.actionName} Doc is Mandatory`,
                type: 'warning'
            });
        }


        const newaaray = fileattachdone.filter((obj) => obj !== docStatusId);
        setfileattachdone(newaaray);
        const updatedList = files.filter(function (obj) {
            return obj.docStatusId !== docStatusId;
        });
        setFiles(updatedList);
    };
    const currentstepstatus = useSelector(
        (state) =>
            state?.getCustomerProgress?.data?.[
                'Customer Progress'
            ]?.actionItems?.find((item) => item.actionId === activeId)?.sectionStatus
    );
    const checkMailValidation = (values, temp) => {
        const attachmentOrNot = _.find(mapping[acctype], { attachment: true });
        let error = true;
        const toVal = values?.to
            ?.toString()
            ?.split(',')
            ?.filter((n) => n);
        const ccVal = values?.cc
            ?.toString()
            ?.split(',')
            ?.filter((n) => n);
        const validEmailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        const validateEmail = (emails) =>
            emails?.map((ele) => _.trim(ele).match(validEmailRegex));
        if (!toVal?.length) {
            snackbarShowMessage({
                message: `Please provide the To Mail Id`,
                type: 'error'
            });
            error = false;
        } else if (!validateEmail(toVal).every(Boolean)) {
            snackbarShowMessage({
                message: `Please provide the valid To Mail Id's`,
                type: 'error'
            });
            error = false;
        } else if (!validateEmail(ccVal).every(Boolean)) {
            snackbarShowMessage({
                message: `Please provide the valid CC Mail Id's`,
                type: 'error'
            });
            error = false;
        } else if (!values?.subject) {
            snackbarShowMessage({
                message: 'Please provide the subject details',
                type: 'error'
            });
            error = false;
        } else if (!values?.content) {
            snackbarShowMessage({
                message: 'Please provide the Message Content',
                type: 'error'
            });
            error = false;
        } else if (
            maildocmandatory === 'Yes' &&
            temp.documentTemplateIds.length === 0 &&
            attachmentOrNot
        ) {
            snackbarShowMessage({
                message: 'Document not attached in Mail.',
                type: 'error'
            });
            error = false;
        }
        setMailSending(error);
        return error;
    };

    const handleSend = () => {
        const removeWords = [
            'Hello,',
            'Hi,',
            'Hii,',
            'Hey,',
            'Dear,',
            'Dear Team,',
            'Thanks,',
            'Thanks & Regards,',
            'Regards,',
            'Hello',
            'Hi',
            'Hii',
            'Hey',
            'Dear',
            'Dear Team',
            'Thanks',
            'Thanks & Regards',
            'Regards'
        ];
        let regex = new RegExp(
            '\\b(' + removeWords.join('|').replace(',', '\\b,?\\b') + ')\\b',
            'gi'
        );

        let emailContent = values?.content
            // .replace(regex, '')
            // .replace(',', '')
            // .replace(',', ''); //This is temporary we need to work here
        setMailSending(true);
        const to = values?.to?.toString();
        const cc = values?.cc?.toString();
        const emailSubject = values?.subject;
        // const attachmentIds = (mailattachflag&&localStorage.getItem(`docid${customerID}`))
        //   ? JSON.parse(localStorage.getItem(`docid${customerID}`)).concat(
        //       fileattachdone
        //     )
        //   : fileattachdone;
        const attachmentIds = fileattachdone
        let temp = {
            emailTemplateID: mailId,
            customerId: customerID,
            emailSubject,
            emailBody: emailContent,
            toName: to,
            ccName: cc,
            documentTemplateIds: attachmentIds,
            emailInfoRequests: [
                {
                    emailId: 0,
                    toName: to,
                    ccName: cc,
                    emailSubject,
                    emailBody: emailContent,
                    customerId: customerID,
                    actionId: activeId,
                    attachmentIds: attachmentIds.join(',')
                }
            ]
        };
        let json_data = JSON.stringify(temp);
        if (checkMailValidation(values, temp)) {
            sendEmailRequest(json_data).then((r) => {
                setMailSending(false);
                if (r?.data) {
                    const response = r?.data;
                    if (response?.errorCode || response?.errorMessage) {
                        snackbarShowMessage({
                            message: 'Email Not Sent',
                            type: 'error'
                        });
                    } else {
                        // if (current.fallbackScreenactivated) {
                        //     if (localStorage.getItem(`docid${customerID}`))
                        //         localStorage.removeItem(`docid${customerID}`);
                        //     if (localStorage.getItem(`docname${customerID}`))
                        //         localStorage.removeItem(`docname${customerID}`);
                        //     sectionSubmit(false, false, false);
                        // } else if (
                        //     currentstepstatus === 'COMPLETED' ||
                        //     currentstepstatus === 'Completed'
                        // ) {
                        //     sectionSubmit(false, false, false, false, true);
                        // } else if (currentstepstatus === 'Skipped') {
                        //     sectionSubmit(false, { enableSkip: true });
                        // } else if (
                        //     current.fallbackScreen &&
                        //     currentstepstatus !== 'Awaiting'
                        // ) {
                        //     sectionSubmit(false, false, true);
                        // } else if (mode === 'resend') {
                        //     sectionSubmit(false, false, true);
                        // } else
                        //  sectionSubmit(false, false, false);

                        snackbarShowMessage({
                            message: 'Email Sent',
                            type: 'success'
                        });
                        setmodalopen && setmodalopen(false);
                        setopenEmailDetails && setopenEmailDetails(false);
                    }
                }
            });
        }
    };
const backTo = ()=>{
    goToInitView();
}
    return (
        <>
        {!isFromTrigger && 
        <div className="vpna-mail-top">
          <Tooltip placement="bottom" title="back">
            <ArrowBackIcon className="arrbackIcon" onClick={backTo}/></Tooltip></div>}
        <div className="mail-template">
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <Input
                    id="mail-to"
                    value={values?.to}
                    data-testid={'to-input'}
                    onChange={handleChange('to')}
                    startAdornment={<InputAdornment position="start">To</InputAdornment>}
                // endAdornment={
                //   <InputAdornment position="end">

                //   </InputAdornment>
                // }
                />
            </FormControl>
            {/* {ccVisible && ( */}
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <Input
                    id="mail-cc"
                    value={ccVisible ? values?.cc : ''}
                    data-testid={'cc-input'}
                    onChange={handleChange('cc')}
                    startAdornment={
                        <InputAdornment position="start">
                            <IconButton
                                aria-label="cc"
                                edge="end"
                                size="small"
                                data-testid="cc-btn"
                                onClick={() => toggleVisible('cc')}
                            >
                                Cc
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            {/* )} */}
            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <Input
                    id="mail-subject"
                    value={values?.subject}
                    data-testid={'subject-input'}
                    onChange={handleChange('subject')}
                    startAdornment={
                        <InputAdornment position="start">Subject: </InputAdornment>
                    }
                />
            </FormControl>

            <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                <TextField
                    id="standard-multiline-static"
                    data-testid={'content-input'}
                    placeholder="Type here..."
                    multiline
                    rows={5}
                    variant="standard"
                    value={values?.content}
                    onChange={handleChange('content')}
                    InputProps={{
                        style: {
                            diplay: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        },
                        startAdornment: (
                            <InputAdornment
                                position="start"
                                style={{ marginBottom: 12, marginRight: 4 }}
                            >
                                Message:
                            </InputAdornment>
                        )
                    }}
                />
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ mt: 1 }}
                >
                    <Grid item>
                        <IconButton
                            edge="end"
                            aria-label="attachment"
                            data-testid="attach-btn"
                            onClick={handleOpenModal}
                        >
                            <AttachFileIcon />
                        </IconButton>

                        {files &&
                            files.map((file) => {
                                return (
                                    <>
                                        <Chip
                                            icon={<BackupIcon />}
                                            sx={{ mt: 0 }}
                                            label={file.fileName}
                                            variant="outlined"
                                            onDelete={() => {
                                                handleRemoveFile(file);
                                            }}
                                        />
                                    </>
                                );
                            })}
                    </Grid>
                    <Grid item sx={{ display: 'flex' }}>
                        {/* <EnableSkip
                            isSkipActive={isSkipActive}
                            sectionSubmit={sectionSubmit}
                        /> */}
                        <CustomButton
                            className="send-button"
                            variant="contained"
                            startIcon={<SendIcon />}
                            onClick={handleSend}
                            data-testid="send-btn"
                            disabled={mailSending}
                        >
                            {mailSending ? 'Sending...' : 'Send'}
                        </CustomButton>
                    </Grid>
                </Grid>
            </FormControl>

            <ViewDocuments
                open={openDocuments}
                onClose={handleViewFiles}
                mode="select"
                selected={files}
                handleAttach={handleAttach}
                customerFiles={customerFiles}
                emailattachflag={true}
                fileattachdone={fileattachdone}
                isFromVPNA={true}
            />
        </div>
        </>
    );
}
