import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import store from './modules/store';
import GlobalStyles from './styles/GlobalStyles';
import { BrowserRouter as Router } from 'react-router-dom';

// import './styles/fontawesome';
import './index.css';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '4c457f83-6b62-41d1-988a-34eea9206ffa',
    clientToken: 'pubc3d9384083ce630331d8cdb3d4787736',
    site: 'datadoghq.com',
    service:'ncob_ui',
    env:process.env.REACT_APP_ENV_ID,
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'mask-user-input'
});
    
datadogRum.startSessionReplayRecording();

ReactDOM.render(
  
  <React.StrictMode>
    <Provider store={store}>
    
      <App />
    
      <GlobalStyles />
    </Provider>,
    
  </React.StrictMode>,
  
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
