import { axiosInstanceDocument } from '../api/Axios';

export const downloadFile = (templateId, customerId, docName,docStatusId,fileName = "download.xlsx") => {

  axiosInstanceDocument
    .request({
      method: 'GET',
      // url: `/downloadDocument/${templateId}/${customerId}`, change api as /downloadDocument is deprecated
      url: `/downloadTrackerDocument/${templateId}/${customerId}/${docName=='MSA/LOI'?'MSALOI':docName}/${docStatusId}`,
      responseType: "blob"
    }).then((response) => {
      const filename = response?.headers['content-disposition']
      const contentType = response?.headers['content-type']
      const url = window.URL.createObjectURL(new Blob([response?.data],{type: contentType}));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename.replace('attachment; filename=', ''));
      link.click();
    });
};
