import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import ExcellentExport from 'excellentexport';
import { Button } from '@mui/material';

import DownloadIcon from '@mui/icons-material/Download';
import { useParams } from 'react-router-dom';
import { getConciergeIntegrationAPI } from '../../../utils/ItemCategorizationAPI';
import TableBuilder from '../TableBuilder';
import { LINK_SALESFORCE } from '../../../constant/api';

const ConcierTable = ({ status }) => {
  const { customerID } = useParams();
  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    getConciergeIntegrationAPI(customerID).then((res) => {
      setDataSource(_.get(res, 'data.data.Request Statuses', []));
    });
  }, [customerID]);
  const tableRef = useRef(null);
  const columns = [
    {
      name: 'siteNo',
      title: 'Site No'
    },
    {
      name: 'siteName',
      title: 'Site Name'
    },
    {
      name: 'location',
      title: 'Location'
    },
    {
      name: 'salesforceAccountId',
      title: 'Salesforce Account ID',
      render:(value,record) =>{
        const complited = _.template(LINK_SALESFORCE);
        const link = complited({ ['SalesForceTaskId']: value })
        return (
          <a
            style={{ color: '#1976d2', display: 'block' }}
            href={link}
            target="_blank" rel="noreferrer"
          >{value}</a>
        )
       },
    },
    {
      name: 'accountId',
      title: 'Account ID'
    },
    {
      name: 'creditAppStatus',
      title: 'Credit App Status'
    },
    {
      name: 'taxStatus',
      title: 'Tax Status'
    },
    {
      name: 'conciergeSR',
      title: 'Concierge SR',
      render:(value,record) =>{
        const complited = _.template(LINK_SALESFORCE);
        const link = complited({ ['SalesForceTaskId']: value })
        return (
          <a
            style={{ color: '#1976d2', display: 'block' }}
            href={link}
            target="_blank" rel="noreferrer"
          >{value}</a>
        )
       },
    },
    {
      name: 'srStatus',
      title: 'SR Status'
    }
  ];
  function exportExcel() {
    if (tableRef.current) {
      ExcellentExport.convert(
        {
          openAsDownload: true,
          filename: `Concierge_Integration_${customerID}`,
          format: 'xlsx'
        },
        [
          {
            from: { table: tableRef.current },
            name: 'Sheet number 1',
            fixValue: (value,row, column)=>{
              if( row !==0 && (column === 3 || column === 7)){
                if(value && value.includes('</a>')){
                  return value.replace(/(<\/?a.*?>)|(<\/?span.*?>)/g, '');
                }else{
                  return value;
                }
              }else{
                return value;
              }
            }
          }
        ]
      );
    }
  }
  return (
    <div className="table-container">
      <div className="table-head-block">
        <div className="table-head-title">Concierge Integration {status}</div>
        <Button
          onClick={exportExcel}
          startIcon={<DownloadIcon />}
          variant="outlined"
        >
          Export
        </Button>
      </div>
      <TableBuilder columns={columns} ref={tableRef} dataSource={dataSource} />
    </div>
  );
};

export default ConcierTable;
