import React, {useState,useEffect} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogActions, Grid } from '@mui/material';
import { uploadImage } from '../../../utils/uploadImage';
import '../CustomerForm/index.css';
import '../Modal/index.css';
import  '../Select/index.css';
import UploadPictureDocuments from './UploadPictureDocuments';
import {useParams} from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
export default function UploadPicture(props) {
    const { onClose, open, viewImage } = props;
    const [invalidimagetype,setinvalidimagetype] =useState(false);
    const [invalidimagesize,setinvalidimagesize] =useState(false);
    const [fileuploadedsuccess,setfileuploadedsuccess] =useState(false);

    const [base64String, setbase64String] = useState('');
    const { customerID,parentCustID } = useParams();
    const [uploading, setUploading] = useState(false);
   
    let id = customerID
    if((parentCustID  && customerID) || !parentCustID)
    id=customerID
    else
    id=parentCustID;

    const [output , setOutput] = useState(null);
 
    const uploadimage =(image) =>{
        setUploading(true);
        imagevalidation(image) ;
       
       !invalidimagesize && !invalidimagetype && (
      uploadImage(id,image).then((res) =>
      {
        if(res.data.data)
        {
            if(res.data.data.LogoUploadResponse.response == 'Uploaded Successfully')
            setfileuploadedsuccess(true);
            setTimeout(() => {
                onClose(false);
                viewImage();
                setfileuploadedsuccess(false);
              }, 2000);
        }
        setUploading(false);
      }
      ) 
       );
    };
    const handleClose = () => {
        onClose(false);
        setinvalidimagesize(false);
        setinvalidimagetype(false);
    };

 
    const imagevalidation =(image) =>{
        if (!image?.name?.match(/\.(jpg|jpeg|png|gif)$/)) 
            setinvalidimagetype(true);
        else
        setinvalidimagetype(false);
        if((image?.size/(1024*1024)) > 2 || image?.size/1024 <= 0)
            setinvalidimagesize(true);
        else
        setinvalidimagesize(false);
      };
 

  useEffect(() => {
    if(output){
        const reader = new FileReader();
   
        reader.onloadend = () =>{
             setbase64String(reader.result.replace('data:','').replace(/^.+,/, ''));
           
        };
        reader.readAsDataURL(output);
       
    }
   
  });
 
  
  
    return (
        <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={'xs'}>
            <Grid item xs={12} sm={12} className="View-document-popup customer-form">
            <DialogTitle>Upload Photo</DialogTitle>
            <div className='popup-container'>
                    <Grid item xs={12} sm={12}>
                    <UploadPictureDocuments output={output} setOutput={setOutput} invalidimagetype={invalidimagetype} invalidimagesize={invalidimagesize} imagevalidation={imagevalidation} />
                    </Grid>
                    { invalidimagetype && <div className='error'>Please select valid image type</div>}
            { invalidimagesize && <div className='error'>Please select valid image size</div>}
            { fileuploadedsuccess && <div className='error'>Image Uploaded successfully</div>}
            </div>
          

            <DialogActions className='form-buttons'>
                <div className='form-div' sx={{ Pr:0 }}>
                <Button onClick={handleClose} id="secondary" data-testid="close-btn" className="button">Close</Button>
                <Button onClick={()=>{uploadimage(output);}} data-testid="submit-btn" id="primary" className="button" disabled={uploading}>
                    {uploading ? 'Uploading...' : 'Upload'}
                </Button>
                        {uploading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: 'white',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '80%',
                                    marginTop: '-12px',
                                    // marginLeft: '-12px'
                                }}
                    />
                )}
                
                </div>
            </DialogActions>
            </Grid>
        </Dialog>
        
    );
}

