import { put, call } from 'redux-saga/effects';
import { getAllActionsSuccess, getAllActionsError } from './slice';
import requestTemplate from './requestTemplate';

export function* handlegetAllActions(action) {
  try {
    const response = yield call(requestTemplate, action.payload || 'CMU');
    const newresponse = response.data.data;
    for (let i = 9; i < 13; i++) {
      const tempsectionResponse = [
        {
          actionId: newresponse['Action Details'][i].sectionId,
          actionName: newresponse['Action Details'][i].sectionName
        }
      ];
      newresponse['Action Details'][i].sectionResponse = tempsectionResponse;
      newresponse['Action Details'][i].totalNumberOfSection = 1;
    }
    yield put(getAllActionsSuccess(response.data.data));
  } catch (error) {
    yield put(getAllActionsError(error));
  }
}
