import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import '../Select/index.css';
import './index.css';
import { Paper, Table, TableCell, TableContainer, TableRow,TableBody, IconButton } from '@mui/material';
import _ from 'lodash';
const CustomAcordion = (props) => {
  const { uploadedfiles,setuploadedfiles,setResponseStatus } = props;
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const removeAcceptFile = (docName, file) => {
    let acceptedFiles = uploadedfiles[docName]?.acceptedFiles;
    let documentID = uploadedfiles[docName]?.documentID
    let acceptFiles = acceptedFiles.filter(item => item.name !== file.name);
    delete uploadedfiles[docName];
    if (!_.isEmpty(acceptFiles)) {
      setuploadedfiles({
        ...uploadedfiles,
        [docName]: {
          acceptedFiles: acceptFiles,
          documentID: documentID,
        }
      })
    } else {
      setuploadedfiles({ ...uploadedfiles });
    }
    //COT-8819: defect- if there are some errors after upload, if remove all files for one doc type from UI, Then click upload, upload button always loading
    setResponseStatus({});
  }
  return (
    <div style={{ padding: '20px'}} className="accordion-container-accept">
      {Object.keys(uploadedfiles).length > 0 && (
        <div>
          <Accordion>
            <AccordionSummary
              expandIcon={null}
              disabled
              aria-controls="panel1a-content"
              id="accordion-header"
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%'
                }}
              >
                <div>Name</div>
                {/* <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div>Expand All</div>
                  <div>
                    <KeyboardDoubleArrowDownIcon />
                  </div>
                </div> */}
              </div>
            </AccordionSummary>
          </Accordion> 
          {Object.keys(uploadedfiles).map((name) => {
            return (
              <div key={name} >
                <Accordion expanded={expanded === name} onChange={handleChange(name)}>
                  <AccordionSummary
                    sx={{
                      backgroundColor: '#FAFAFA;',
                      height: 32
                    }}
                    expandIcon={<ExpandMoreIcon style={{ color: '#008CD2' }} />}
                    aria-controls="panel1bh-content"
                    id={`${name}`}
                  >
                    <span style={{ color: '#008CD2' }}>{name}</span>
                  </AccordionSummary>
                  <AccordionDetails 
                    // sx={{'&. MuiAccordionDetails-root': { pt: 1, pb: 1, pr: 1, pl: 1 }}}
                  >
                    {/* <span style={{ color: '#008CD2' }}>
                      {' '}
                      {file.name}
                    </span> */}
                      <TableContainer component={Paper} sx={{ maxHeight: 400, marginBottom: 2 }}>
                        <Table aria-label="table" size="small" stickyHeader sx={{ '& .MuiTableCell-root': { padding: '6px' } }} key={name}>
                          <TableBody>
                            {uploadedfiles[name] && uploadedfiles[name]?.acceptedFiles.map((file,index) => {
                              return (
                                <TableRow sx={{ '& .MuiTableCell-root': { pt: 1, pb: 1, pr: 1, pl: 1 } }} key={name - `${file.name}`} >
                                  <TableCell width="90%" className={`${index === uploadedfiles[name]?.acceptedFiles?.length-1?'unset-border-bottom':'' }`} style={{ color: '#008CD2' }}>
                                    {' '}
                                    {file.name}
                                  </TableCell>
                                  <TableCell width="10%" className={`${index === uploadedfiles[name]?.acceptedFiles?.length-1?'unset-border-bottom':'' }`} style={{textAlign:'right'}}>
                                    <IconButton onClick={() => removeAcceptFile(name,file)}>
                                      <DeleteIcon />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              )
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomAcordion;
