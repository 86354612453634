import {axiosInstanceEmailNonBffchat} from '../api/Axios';

export async function getConversations(parentCustID) {
  try {
    const response = await axiosInstanceEmailNonBffchat.request({
      method: 'GET',
      url: `/ChatMessage/${parentCustID}`
    });
 
    const responseData = response?.data?.data?.Message;
    return responseData;
  } catch (error) {
    console.error(error);
  }
}