import { call, put } from 'redux-saga/effects';
import { getUserRoleSuccess, getUserRoleError } from './slice';
import requestTemplate from './request';

export function* handleUserRole(action) {
  try {
    const list = yield call(requestTemplate,action);
    yield put(getUserRoleSuccess(list?.data));
  } catch (error) { 
    yield put(getUserRoleError(error));
  }
}
