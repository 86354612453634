import { createSlice } from '@reduxjs/toolkit';

//Initializing state
const initialState = {page:1};

//Creating Reducers functions
export const paginationSlice = createSlice({
  name: 'updatePagination',
  initialState,
  reducers: {
    updatePaginationPage(state, action) {
      return { ...state, ...action.payload };
    }
  }
});

//Action creators
export const {
    updatePaginationPage
} = paginationSlice.actions;

export default paginationSlice.reducer;
