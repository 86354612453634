import React, { useState, useEffect,useCallback } from 'react';
import '../DocumentUpload/index.css';
import Card from '@mui/material/Card';
import LinkedCameraIcon from '@mui/icons-material/LinkedCamera';
import './index.css';
import { useDropzone } from 'react-dropzone';



const FileInput = ({output, setOutput,invalidimagetype,invalidimagesize,imagevalidation}) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const onDrop = useCallback(
    (acceptedFiles) => {
     setSelectedImage(acceptedFiles[0])
     imagevalidation(acceptedFiles[0])
    },
    []
  );
  const { getRootProps,  getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.csv',
        '.xlsx',
        '.xls'
      ],
      'application/vnd.ms-excel': ['.csv', '.xlsx', '.xls'],
      'application/pdf': ['.pdf','.jpeg','.jpg','.png', '.docx', '.doc']
    }
  });
  useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
      
      setOutput(selectedImage);
    }
  }, [selectedImage]);

 
  return (
    <>
     
    <div {...getRootProps()} >
    <input  {...getInputProps()} data-testid="input"/>
      
       <label htmlFor="select-image" className="documentUploadClass UploadPictureMain">
        <span variant="contained" color="primary" component="span">
          {
            (selectedImage && imageUrl && !invalidimagetype && !invalidimagesize ) ? (
              <Card className="notfileUpload">
                <img src={imageUrl} alt={selectedImage?.name} className="img-notfileUpload"/>
                
            
              </Card>
            ) :
             (
              <Card className="notfileUpload">
              <span className='PictureIcon' htmlFor="select-image">
                {
                <LinkedCameraIcon  color="primary" />}
              </span>
              
          <span className="uploadIcon">
                Drag JPG, PNG here <br/> or click to  <b>browse</b>
              </span>
          </Card>
            )
          }
             
              
        </span>
      </label>
      </div>
      

      
    </>
  );
};

export default FileInput;

