import React from 'react';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import _ from 'lodash';
import { Button, MenuItem, TextField } from '@mui/material';
const FormBuilder = ({
  configs,
  initialValues,
  validationSchema,
  onSubmit,
  onReset,
  operationsProps = {}
}) => {
  const {
    errors,
    handleChange,
    values,
    handleReset,
    touched,
    setFieldValue,
    isSubmitting,
    setValues,
    handleBlur,
    handleSubmit
  } = useFormik({ initialValues, enableReinitialize: true, onSubmit, validationSchema, validateOnChange: true });

  return (
    <form onSubmit={handleSubmit} onReset={handleReset}>
      <Grid spacing={2} container>
        {_.map(
          configs,
          ({
            layout = { sm: 4, xs: 4 },
            name,
            helperText,
            type = 'input',
            title,
            label,
            disabled = false,
            options = [],
            extraProps = {},
            onChange,
            required = false,
            placeholder = 'input Text'
          }) => {
            const hadTouched = _.get(touched, name);
            const props = {
              value: _.get(values, name),
              disabled,
              name,
              required,
              label,
              size: 'small',
              id: name, 
              InputLabelProps: {
                style: {
                  fontFamily: 'MyriadPro,Serif',
                  height: '30px',
                  lineHeight: '30px',
                  fontSize: '14px',
                  marginTop: '-7px',
                }
              },
              InputProps: {
                style: {
                  fontFamily: 'MyriadPro,Serif',
                  height: '30px',
                  lineHeight: '30px',
                  fontSize: '14px'
                }
              },
              onBlur: handleBlur,
              onChange: (e) => {
                if (onChange) {
                  onChange(e, setFieldValue)
                }
                handleChange(e);
              },
              placeholder,
              error: hadTouched && Boolean(_.get(errors, name)),
              helperText: (hadTouched && _.get(errors, name)) || helperText,
              ...extraProps
            };

            if (type === 'select') {
              props.select = true;
              props.multiple = true;
              props.children = _.map(options, ({ label, value }) => {
                return (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                );
              });
            } else {
              props.type = type;
            }

            return (
              <Grid key={name} item {...layout}>
                <div>{title}</div>
                <TextField fullWidth {...props} />
              </Grid>
            );
          }
        )}
        <Grid item xs={4} sm={4}>
          <Button
             style={{
              borderRadius: '20px',
              height: 30,
              marginRight: 10
            }}
            variant="outlined"
            {..._.get(operationsProps, 'cancel.props', {})}
            onClick={() => {
              _.isFunction(onReset) && onReset()
              handleReset();
              setValues({...values, ..._.mapValues(_.pick(values, _.map(configs, 'name')), (c) => {
               if (_.isArray(c)) {
                return [];
               }
               return ''
              })});
            }}
          >
            {_.get(operationsProps, 'cancel.text', 'Clear')}
          </Button>
          <Button
            variant="contained"
            color='primary'
            disabled={isSubmitting}
            style={{
              borderRadius: '20px',
              height: 30,
            }}
            type="submit"
            {..._.get(operationsProps, 'submit.props')}
          >
            {_.get(operationsProps, 'submit.text', 'Submit')}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default FormBuilder;
