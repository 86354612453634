import { axiosInstanceDocument } from '../api/Axios';

export const downloadTemplate = (templateId, docName) => {
  axiosInstanceDocument
    .request({
      method: 'GET',
      url: `/downloadTemplate/${templateId}`,
      responseType: 'blob'
    }).then((response) => {

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${docName ? docName : 'download'}.xlsx`);

      link.click();
    });
};
