import {axiosInstanceEmailNonBffchat} from '../api/Axios';

export async function postNewchat(parentCustID,message,conversationID) {
  const values = {
    
  "custID": parentCustID,
  "message": message,
  "conversationID": conversationID?conversationID:""
  }
  try {
    const response = await axiosInstanceEmailNonBffchat.request({
      method: 'POST',
      url: `/ChatMessage`,
      data: values
    });
    // console.log(response)
    const responseData = response.data.data.Message;
    return responseData;
  } catch (error) {
    console.error(error);
  }
}