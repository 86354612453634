import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';

import { axiosInstanceDashboard } from '../../../api/Axios';
import { useParams } from 'react-router-dom';
import { format } from '../../../utils/dateFormatter';
import _ from 'lodash'
const CustomerPortalfields2 = ({ errors, touched, formEditClass }) => {
  const [list, setlist] = useState({});
  const { parentCustID, customerID } = useParams();
  const custID = customerID ? customerID : parentCustID;
  function className(FORMTITLE, param1, param2) {
    return FORMTITLE + (param1 && param2 ? ISINVALID : '');
  }

  const ISINVALID = ' is-invalid';
  const FORMTITLE = ' form-title';

  const fetchlist = () => {
    axiosInstanceDashboard

      .request({
        method: 'GET',

        url: `/getCustomerDetails?id=${custID}`
      })

      .then((response) => {
        if (response) {
          if (response.data) {
            setlist(_.get(response, 'data.data.Customer', {}));
          }
        }
      });
  };
  useEffect(() => {
    fetchlist();
  }, []);
  return (
    <div className="customer-form form-container2">
      <div className="customer-form form-container">
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={11} sm={11}>
            <div className="form-header">
              <div
                className={className(
                  FORMTITLE,
                  errors.customerFirstName,
                  touched.customerFirstName
                )}
              >
                Account Owner<br></br>
                <lable style={{ fontWeight: 'bold', color: 'black' }}>
                  {list?.accountOwner}
                </lable>
              </div>
            </div>
          </Grid>

          <Grid item xs={4} sm={5}>
            <div className="form-header">
              <div
                className={className(FORMTITLE, errors.email, touched.email)}
              >
                Start Date:<br></br>
                <lable style={{ fontWeight: 'bold', color: 'black' }}>
                  {format(list?.startDate ? list?.startDate : '')}
                </lable>
              </div>
            </div>
          </Grid>
          <Grid item xs={4} sm={6}>
            <div className="form-header">
              <div
                className={className(FORMTITLE, errors.email, touched.email)}
              >
                Go-Live Date:<br></br>
                <lable style={{ fontWeight: 'bold', color: 'black' }}>
                  {format(list?.goLiveDate ? list?.goLiveDate : '')}
                </lable>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CustomerPortalfields2;
