import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useParams } from 'react-router-dom';
import { axiosInstanceDashboard } from '../../../api/Axios';
import _ from 'lodash'
const CustomerPortalfields = ({ errors, touched, formEditClass }) => {
  const [list, setlist] = useState({});
  const [totalCompletion, settotalCompletion] = useState(0)
  const { parentCustID, customerID } = useParams();
  const custID = customerID ? customerID : parentCustID;
  function className(FORMTITLE, param1, param2) {
    return FORMTITLE + (param1 && param2 ? ISINVALID : '');
  }

  const ISINVALID = ' is-invalid';
  const FORMTITLE = ' form-title';

  const fetchlist = () => {
    axiosInstanceDashboard

      .request({
        method: 'GET',

        url: `/getCustomerDetails?id=${custID}`
      })

      .then((response) => {
        if (response) {
          if (response.data) {
            setlist(_.get(response, 'data.data.Customer', {}));
          }
        }
      });
  };
  const fetchTotalCompletion = () => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getCustomerTotalCompletion?customerId=${custID}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            settotalCompletion(_.get(response, 'data.data.Total Completion.totalCompletion', 0))
            
          }
        }
      });
  };

  useEffect(() => {
    fetchlist();
    fetchTotalCompletion();
  }, []);

  return (
    <div className="customer-form form-container">
      <div className="customer-form form-container">
        <Grid container rowSpacing={2} columnSpacing={3}>
          <Grid item xs={11} sm={11}>
            <div className="form-header">
              <div
                className={className(
                  FORMTITLE,
                  errors.customerFirstName,
                  touched.customerFirstName
                )}
              >
                Website:<br></br>
                <lable style={{ fontWeight: 'bold', color: 'black' }}>
                  {list?.website}
                </lable>
              </div>
            </div>
          </Grid>
          <Grid item xs={4} sm={4}>
            <div className="form-header">
              <div
                className={className(
                  FORMTITLE,
                  errors.customerLastName,
                  touched.customerLastName
                )}
              >
                No. of Locations:<br></br>
                <lable style={{ fontWeight: 'bold', color: 'black' }}>
                  {list?.totalNumberOfLocation}
                </lable>
              </div>
            </div>
          </Grid>

          <Grid item xs={4} sm={4}>
            <div className="form-header">
              <div
                className={className(
                  FORMTITLE,
                  errors.contact,
                  touched.contact
                )}
              >
                No of Opsites:<br></br>
                <lable style={{ fontWeight: 'bold', color: 'black' }}>
                  {list?.totalOpSite}
                </lable>
              </div>
            </div>
          </Grid>
          <Grid item xs={4} sm={4}>
            <div className="form-header">
              <div
                className={className(FORMTITLE, errors.email, touched.email)}
              >
                No of Prop Items:
                <br></br>
                <label style={{ fontWeight: 'bold', color: 'black' }}>
                  {list?.propItems}
                </label>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className="form-header">
            <div className={FORMTITLE}>
                Total Completion:<br></br>
                <lable style={{ fontWeight: 'bold', color: 'black' }}>
                  {/* {((list?.totalCompletedSection / 12) * 100).toFixed(0)} */}
                  {totalCompletion}
                  {'%'}
                </lable>
              </div>
            </div>
          </Grid>
          <Grid item xs={6} sm={6}>
            <div className="form-header">
              <div
                className={className(FORMTITLE, errors.email, touched.email)}
              >
                Status:<br></br>
                <lable style={{ fontWeight: 'bold', color: 'black' }}>
                  {list?.progressStatus}
                </lable>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CustomerPortalfields;
