import React, { useState, useEffect } from 'react';
import './index.css';
import { Divider, Button, FormControl, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { axiosInstanceDashboard } from '../../../api/Axios';
import { updatecustomerdetailsAPI } from '../../../utils/updatecustomerdetailsAPI';
import _ from 'lodash'
export default function DynamicInput(props) {
  const { fields, sectionSubmit, closeHandler} = props;
  const [values, setValues] = useState({});
  const [flag, setflag] = useState(false);
  const [list, setlist] = useState({});
  
  const { customerID } = useParams();
  let creditAnalyst = 'creditAnalyst';
  let dpmAnalyst = 'dpmAnalyst';
  const currentstep = useSelector((state) => state?.storestep?.actionId)
  const custobj = {
     customerRequest:{},
    customerAddressRequests: [],
    contactRequests: []
  };
  const addfieldstoObject = () => {
    if(currentstep===24)
    {
    for (let key in values) {
      let temp = key.replace(' ', '');
      if (temp.toLowerCase() === creditAnalyst.toLowerCase())
        custobj.customerRequest['creditAnalyst'] = values[key];
      if (temp.toLowerCase() === dpmAnalyst.toLowerCase())
        custobj.customerRequest['dpmAnalyst'] = values[key];
    }
  }
  if(currentstep===29)
  {for (let key in values)
    {
      custobj.customerRequest['aeAlignment'] = values[key];
    }
   
  }
  };
  const fetchlist = () => {
    axiosInstanceDashboard
      .request({
        method: 'GET',
        url: `/getCustomerDetails?id=${customerID}`
      })
      .then((response) => {
        if (response) {
          if (response.data) {
            setlist(_.get(response, 'data.data.Customer', {}));
          }
        }
      });
  };

  useEffect(() => {
    if (list?.customerId) {
      addfieldstoObject();
      updatecustomerdetailsAPI(custobj,list.customerId);
    }
  }, [list]);

  useEffect(() => {
    if (flag === true) {
      fetchlist();
     
      setTimeout(()=>sectionSubmit(false,false),[2000]);
    }
  }, [flag]);

  useEffect(() => {
    if (fields) {
      let obj = {};
      fields.forEach((field) => {
        obj[field] = '';
      });
      setValues(obj);
    }
  }, [fields]);

  function handleSubmit () {
    if(Object.values(values).every(value => !!value))
      { 
        setflag(true);
      } 
  };

  function handleChange (e) {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className="dynamic-input">
      <br />
      {fields?.map((field) => {
        return (
          <div key={field}>
            <FormControl
              sx={{ m: 1, width: '40%' }}
              variant="outlined"
              key={field}
            >
              <span>{field}</span>
              <TextField
              
                size="small"
                name={field}
                value={values[field]}
                onChange={handleChange}
              />
            </FormControl>
          </div>
        );
      })}

      <Divider sx={{ marginTop: 16 }} variant="fullWidth" />
      <div className="button-wrapper">
        <Button
          sx={{ '& .MuiButton-root': { fontSize: 14 } }}
          id="secondary"
          className="buttons-1"
          onClick={closeHandler}
        >
          Cancel
        </Button>
        <Button
          id="secondary"
          className="buttons-2"
          data-testid="submit-btn"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}
