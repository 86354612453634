import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import './index.css';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AddBusinessOutlinedIcon from '@mui/icons-material/AddBusinessOutlined';

const RequestToCreate = ({ heading, subheading, iconType }) => {
  return (
    <Card
      className="toptwo"
      sx={{ boxShadow: '1px 1px 4px rgba(106, 115, 123, 0.15)' }}
    >
      <CardContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '400px',
            justifyContent: 'space-between'
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '320px'
            }}
          >
            <div style={{ width: '50px' }}>
              {iconType === 'concept' ? (
                <StorefrontIcon color="primary" fontSize="large" />
              ) : (
                <AddBusinessOutlinedIcon color="primary" fontSize="large" />
              )}
            </div>
            <div>
              <div>
                <span
                  style={{
                    fontFamily: 'MyriadPro',
                    fontSize: '15px',
                    fontWeight: '600',
                    letterSpacing: '0.4px'
                  }}
                >
                  {heading}
                </span>
              </div>
              <div>
                <span
                  style={{
                    fontFamily: 'MyriadPro',
                    fontSize: '12px',
                    fontWeight: '400',
                    color: '#A1A1A1',
                    letterSpacing: '0.4px'
                  }}
                >
                  {subheading}
                </span>
              </div>
            </div>
          </div>

          <div>
            <div>
              <div
                style={{
                  padding: '12px',
                  height: '8px',
                  width: '60px',
                  border: '1px solid #008cd2',
                  borderRadius: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: '28px'
                }}
              >
                <span
                  style={{
                    fontFamily: 'MyriadPro',
                    fontSize: '13px',
                    fontWeight: '400',
                    color: '#008cd2'
                  }}
                >
                  Request
                </span>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default RequestToCreate;
