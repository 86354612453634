import { put, call } from 'redux-saga/effects';
import { getCustomerProgressSuccess, getCustomerProgressError } from './slice';
import requestTemplate from './requestTemplate';

export function* handleGetCustomerProgressRequest(action) {
    try {
      const response = yield call(requestTemplate, action);
      yield put(getCustomerProgressSuccess(response.data));    
    } catch (error) {
      yield put(getCustomerProgressError(error)); 
    }
}
