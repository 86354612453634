import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import { axiosInstanceDocument } from '../../../../api/Axios';
import DocumentUpload from '../../DocumentUpload/index'
import { downloadTemplate } from '../../../../utils/downloadTemplate';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
    Button, Dialog,
    DialogTitle,
    DialogActions,
    IconButton,
} from '@mui/material';
import { uploadTemplateFile } from '../../../../utils/uploadTemplateFile';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import usePagination from '../../../containers/Home/Pagination';
import { withSnackbar } from '../../SnackBar/SnackBar';
export function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis?.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis?.map((el) => el[0]);
}
const TemplateHeadCells = () => [
    {
        id: 'templateName',
        numeric: false,
        disablePadding: true,
        label: 'Name'
    },
    {
        id: 'fileName',
        numeric: false,
        disablePadding: true,
        label: 'File Name'
    },
    {
        id: 'actions',
        numeric: true,
        disablePadding: false,
        label: 'Actions',
    }
]


export function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort, } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {TemplateHeadCells()?.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        // padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false} >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            IconComponent={KeyboardArrowDownIcon}
                            size="large"
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))
                }
            </TableRow>
        </TableHead >
    );
}

function TemplateAdminDashboard({ searchList, updateHandler, filterList, snackbarShowMessage, sectionSubmit }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('templateName');
    const [selected, setSelected] = useState([]);
    const [value, setValue] = useState();
    const [open, setOpen] = useState(false);
    const [visible, setVisible] = useState(false);
    const [uploadedfiles, setuploadedfiles] = useState({});
    const [responseStatus, setResponseStatus] = useState({});
    const [templateName, setTemplateName] = useState([])
    function closehandler() {
        setVisible(false);
    };

    useEffect(() => {
        handleChange(null, 1);
    }, [searchList]);

    // useEffect(() => {
    //     axiosInstanceDocument
    //         .request({
    //             method: 'GET',
    //             url: `/getAllMasterTemplate`,
    //         })
    //         .then((response) => {
    //             if (response.data) {
    //                 setTemplateName(response.data.data.DOCUMENT)
    //             }
    //         })
    // }, []);

    function submithandler(Template) {
        const numOfFiles = Object.keys(uploadedfiles).length;
        if (numOfFiles) {
          Object.keys(uploadedfiles).forEach((key) => {
            const acceptedFile = uploadedfiles[key].acceptedFiles[0];
            uploadTemplateFile(acceptedFile, Template).then((response) => {
              if (response?.data) {
                const { errorMessage, data } = response?.data;
                const keyResponse = data?.['Template upload']?.response;
                const responseMessage = errorMessage || keyResponse;
                setResponseStatus((prev) => ({ ...prev, [key]: responseMessage }));
              }
            });
          });
        } else {
          snackbarShowMessage({
            message: `No file is atttached. Please upload the document`,
            type: 'error',
          });
        }
        setOpen(false);
      }
    const formatErrorMessage =(failedFile)=>{
    let message=[];
    if(failedFile){
        for (const [key, value] of Object.entries(failedFile)) {
        if(value[1])
        message.push(value[1]);
        }
    }
        return message.length !== 0 ?message.join(";"): null;
    }  
    useEffect(() => {
        let failedFile = {};
        if (
            Object.keys(uploadedfiles).length &&
            Object.keys(responseStatus).length === Object.keys(uploadedfiles).length
        ) {

            let count = 0;
            for (const [key, value] of Object.entries(responseStatus)) {
                if (
                    value === 'Template Uploaded Successfully'
                ) {
                    count++;
                    delete responseStatus[key];
                }else{
                    failedFile[key] = value;
                  }
            }

            if (count == Object.keys(uploadedfiles).length) {
                snackbarShowMessage({
                    message: `Template Uploaded Successfully`,
                    type: 'success'
                });
                setuploadedfiles({});
            } else if (count > 0) {
                if (count < Object.keys(uploadedfiles).length) {
                    if(formatErrorMessage(failedFile)){
                        snackbarShowMessage({
                          message: formatErrorMessage(failedFile),
                          type: 'warning'
                        });
                      }else{
                        snackbarShowMessage({
                            message: `Some Documents weren't uploaded`,
                            type: 'warning'
                        });
                    }
                }
            } else if (count == 0) {
                if (formatErrorMessage(failedFile)) {
                    snackbarShowMessage({
                      message: formatErrorMessage(failedFile),
                      type: 'error'
                    });
                }else{
                    snackbarShowMessage({
                        message: `None of the Documents were uploaded. Unable to proceed`,
                        type: 'error'
                    });
                }
            }
        }
    }, [responseStatus]);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = searchList?.map((n) => n.name);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    function getKeybyValue(object, value) {
        return Object.keys(object).find((key) => object[key] === value);
    }
    const PER_PAGE = 10;
    let [paginationPage, setPaginationPage] = useState(1);
    const count = searchList && Math.ceil(searchList?.length / PER_PAGE);
    const _DATA = usePagination(
        stableSort(searchList, getComparator(order, orderBy)),
        PER_PAGE
    );
    const handleChange = (e, p) => {
        setPaginationPage(p);
        _DATA.jump(p);
    }
    const editTemplateCell = (value) => {
        setValue(value);
        setOpen(!open)
    }
   
    const deleteTemplate = async (itemId) => {
        axiosInstanceDocument
            .request({
                method: 'DELETE',
                url: `/deleteTemplate/${itemId}`,
            })
            .then((response) => {
                if (response?.data?.errorMessage) {
                    snackbarShowMessage({
                        message: response?.data?.errorMessage,
                        type: 'error'
                    })
                }
                if (response?.data?.data) {
                    snackbarShowMessage({
                        message: response?.data?.data?.['deleteTemplate'].response,
                        type: 'success'
                    })
                }
                else {
                    snackbarShowMessage({
                        message: 'Template not found',
                        type: 'error'
                    })
                }
            })
        axiosInstanceDocument
            .request({
                method: 'GET',
                url: `/getAllMasterTemplate`,
            })
            .then((response) => {
                if (response.data) {
                    setTemplateName(response.data.data.DOCUMENT)
                }
            })
        setVisible(false);
    }

    const handleDialog = (val) => {
        setValue(val)
        setVisible(true);
    }
    return (
        <Box sx={{ width: '100%', mb: '50px' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer>
                    <Table
                        className="admin-table"
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={'medium'}
                    >
                        <EnhancedTableHead
                            data-testid="enhancedtable"
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={searchList?.length}
                            value={''}
                            searchList={searchList}
                            filterList={filterList}
                        />
                        <TableBody>
                        {_DATA && 
                            _DATA.currentData()?.map((template, index) => {
                                return (
                                    <>
                                        <TableRow key={template.templateId}>
                                            <TableCell
                                                component="th"
                                                id={index}
                                                scope="row"
                                            // padding="none"
                                            >
                                                {template.templateName}
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                id={index}
                                                scope="row"
                                            // padding="none"
                                            >
                                                {template.path ? template.path.replace("ncob-template/", "") : template.path}
                                            </TableCell>
                                            <TableCell align="right" scope='row' component="h6">

                                                <>
                                                    <IconButton
                                                        onClick={() => editTemplateCell(template.templateId)}>
                                                        <EditOutlinedIcon />
                                                    </IconButton>

                                                    <IconButton disabled={template.path === null} onClick={() => downloadTemplate(template.templateId, template.templateName)}>
                                                        <DownloadIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        disabled={template.path === null}
                                                        onClick={() => handleDialog(template.templateId)}>
                                                        <DeleteIcon />
                                                    </IconButton>

                                                </>
                                                {value === template.templateId &&
                                                    <Dialog

                                                        sx={{
                                                            '& .MuiPaper-root': {
                                                                width: { sm: 467, md: 467 },
                                                                height: { sm: 120, md: 120 }
                                                            },
                                                            '& .MuiDialogContent-root .content': {
                                                                width: { sm: 467, md: 467 },
                                                                height: { sm: 120, md: 120 }
                                                            },
                                                            '& .MuiTypography-root': { fontFamily: 'inherit', fontWeight: 700 },
                                                            '& .MuiDialogActions-root': {
                                                                boxShadow: '0px 0px 10px rgb(0 0 0 /0.25)'
                                                            }
                                                        }}
                                                        open={visible}
                                                        onClose={closehandler}
                                                        aria-labelledby="responsive-dialog-title"
                                                    >
                                                        <DialogTitle
                                                            id="responsive-dialog-title"
                                                            sx={{ borderBottom: '1px solid #00000017' }}
                                                        >
                                                            Are you sure to delete this template?
                                                            <IconButton
                                                                aria-label="close"
                                                                onClick={closehandler}
                                                                sx={{
                                                                    position: 'absolute',
                                                                    right: 8,
                                                                    top: 8,
                                                                    color: (theme) => theme.palette.grey[500]
                                                                }}
                                                            >
                                                                <CloseIcon />
                                                            </IconButton>
                                                        </DialogTitle>
                                                        <DialogActions>
                                                            <Button id="secondary" onClick={closehandler}>
                                                                Cancel
                                                            </Button>
                                                            <Button
                                                                className="button"
                                                                id="primary"
                                                                onClick={() => {

                                                                    deleteTemplate(value)
                                                                }}
                                                            >
                                                                Confirm
                                                            </Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                }
                                            </TableCell>
                                        </TableRow>

                                        {
                                            value === template.templateId && <TableRow>
                                                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                                        <Box sx={{ margin: 0 }}>
                                                            {/* <TextField
                                                            //onChange={handleCommentChange}
                                                            id="outlined-multiline-static"
                                                            //value={comment}
                                                            margin="dense"
                                                            fullWidth
                                                            multiline
                                                            rows={12}
                                                        > */}
                                                            <DocumentUpload
                                                                doc={template.templateName}
                                                                documentID={getKeybyValue(templateName, template.templateName)}
                                                                setuploadedfiles={setuploadedfiles}
                                                                uploadedfiles={uploadedfiles}
                                                                snackbarShowMessage={snackbarShowMessage}
                                                                fromTemplateAdminDashboard={true}
                                                            />

                                                            {/* </TextField> */}
                                                            <div className="button-wrapper">
                                                                <Button sx={{ '& .MuiButton-root': { fontSize: 14 } }} id="secondary" className="buttons-1" onClick={() => { setOpen(false); }}> Cancel </Button>
                                                                <Button id="primary" className="buttons-2" aria-label='submitbuttonn' onClick={() => submithandler(template.templateId)}
                                                                >
                                                                    Upload
                                                                </Button>
                                                            </div>
                                                        </Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        }

                                    </>
                                );
                            })}
                        </TableBody>
                    </Table>
                    {searchList?.length === 0 && (
                        <p style={{ display: 'flex', justifyContent: 'center' }}>
                            No Results Found
                        </p>
                    )}
                </TableContainer>
                <Stack spacing={2}>
                    <Pagination
                        className="customPagination"
                        count={count}
                        size="large"
                        page={paginationPage}
                        variant="outlined"
                        shape="rounded"
                        onChange={handleChange}
                        sx={{ padding: '20px', display: 'flex', justifyContent: 'center' }}
                    />
                </Stack>
            </Paper>
        </Box >
    );
}

export default withSnackbar(TemplateAdminDashboard);
