import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Box, Pagination, IconButton, TableSortLabel, CircularProgress } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import './CustomerTable.css';
import CustomModal from '../../components/Modal/index';
import { DatePicker } from '@mui/x-date-pickers';
import { axiosInstanceDashboard } from '../../../api/Axios';
import dayjs from 'dayjs';
import usePagination from '../../containers/Home/Pagination';
import { getComparator, stableSort } from '../../components/AdminDashboard/EmailAdminDashboard'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import _ from 'lodash';
function Row(props) {
  const {
    fileattachdone,
    row,
    customerId,
    snackbarShowMessage,
    onClose,
    emailattachflag,
    handleAttach
  } = props;
  const newColorCodes = {
    'On Track': {
      color: '#56B146',
      bgColor: '#E6F4E4'
    },
    'At Risk': {
      color: '#FF8A00',
      bgColor: '#FFEED9'
    },
    'Not Started': {
      color: 'white',
      bgColor: '#515050'
    },
    'Steady State': {
      color: '#008CD2',
      bgColor: '#E5F3FA'
    },
    'On Hold': {
      color: '#6A737B',
      bgColor: '#F1F2F2'
    },
    'Requires Attention': {
      color: 'red',
      bgColor: '#ead1d5'
    }
  };
  const getHexCode = newColorCodes[row?.status];
  function changeName(row){
    switch (row.customerType){
      case 'CMU':
        return row.customerName;
      case 'CONCEPT':
        return row.conceptName;
      case 'LOCATION':
        return row.accountName;
      case 'LCC':
        return row.customerName;
      default:
        return row.customerName;
    }
  }
  return (
    <>
      {row && (
        <>
          <TableRow sx={{ '& .MuiTableCell-root': { pt: 1, pb: 1, pr: 1,pl: 1 } }} key={row.customerId}>
            <TableCell component="th" scope="row">
              <Link
                  to={
                    row?.status === 'Steady State'
                      ? `/Dashboard/${row?.customerId}`
                      : `/new-onboarding/${row?.customerId}`
                  }
                  target="_blank"
                  style={{ textDecoration: 'none' }}
                >
                  {/* {changeName(row)} */}
                  {row.customerName}
              </Link>
            </TableCell>
            <TableCell>{row?.ncobLead}</TableCell>
            <TableCell>{row?.ncobPartner}</TableCell>
            <TableCell>
              {row?.customerEmailId}
            </TableCell>
            <TableCell>{row?.createDate}</TableCell>
            <TableCell>
              {row?.goLiveDate}
            </TableCell>
            <TableCell>
              {row?.segment}
            </TableCell>
            <TableCell>
              {row?.customerType}
            </TableCell>
            <TableCell>
            <div
              className="status-text"
              style={{
                backgroundColor: `${getHexCode?.bgColor}`,
                color: `${getHexCode?.color}`,
                width:'max-content'
              }}
            >
              {row?.status}
            </div>
            </TableCell>
            <TableCell>
              {row?.totalCompletion?row?.totalCompletion+`%`:null}
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  );
}
const headCells = [
  {
    id: 'customerName',
    label: 'Customer Name',
    isSort: true,
    numeric: false,
  },
  {
    id: 'ncobLead', 
    label: 'NCOB Lead',
    // isSort: true,
    numeric: false,
  },
  {
    id: 'ncobPartner', 
    label: 'NCOB Partner',
    // isSort: true,
    numeric: false,
  },
  {
    id: 'customerEmailId',
    label: 'Customer Email Id',
    isSort: true,
    numeric: false,
  },
  {
    id: 'createDate',
    label: 'Created Date',
    isSort: true,
    isSort: true,
    numeric: false,
  },
  {
    id: 'goLiveDate',
    numeric: false,
    label: 'Go Live Date',
    isSort: true,
    numeric: false,
  },
  {
    id: 'segment',
    numeric: false,
    label: 'Segment',
    isSort: true,
    numeric: false,
  },
  {
    id: 'customerType',
    numeric: false,
    label: 'Customer Type',
    isSort: true,
    numeric: false,
  },
  {
    id: 'status',
    numeric: false,
    label: 'Status',
    isSort: true,
    numeric: false,
  },
  {
    id: 'totalCompletion',
    numeric: false,
    label: 'Total Completion',
    isSort: true,
    numeric: false,
  }
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ '& .MuiTableCell-root': { pt: 1, pb: 1, pl: 1, pr: 1 } }}>
        {
          headCells?.map((headCell) => {
            return (<TableCell
              key={headCell.id}
              align="left"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.isSort ?
                (
                  <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                    IconComponent={KeyboardArrowDownIcon}
                    size="large"
                  >
                    <b>{headCell.label}</b>
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>)
                : (<b>{headCell.label}</b>)}
            </TableCell>)
          })}
      </TableRow></TableHead>
  )
}

function CustomerTable(props) {
  const {snackbarShowMessage } = props;
  const initialValues = {
    customerName: '',
    ncoblead: '',
    ncobpartner: '',
    createDateFrom: '',
    createDateTo: '',
    liveDateFrom: '',
    liveDateTo: '',
    segment: [],
    customerType: [],
    status: [],
    totalCompletion: '',
  };
  const dateInitialValues = {
    createdDateFrom: dayjs().subtract(1, 'month'),
    createdDateTo: dayjs().toDate(),
    goLiveDateFrom: dayjs().toDate(),
    goLiveDateTo: dayjs().add(1, 'month'),
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [isSubmit, setIsSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [modalopen, setmodalopen] = useState(false);
  const [createDateFrom, setCreateDateFrom] = useState(null);
  const [createDateTo, setCreateDateTo] = useState(null);
  const [liveDateFrom, setLiveDateFrom] = useState(null);
  const [liveDateTo, setLiveDateTo] = useState(null);
  const [createDateFromMax, setCreateDateFromMax] = useState(null);
  const [createDateFromMin, setCreateDateFromMin] = useState(null);
  const [createDateToMax, setCreateDateToMax] = useState(null);
  const [createDateToMin, setCreateDateToMin] = useState(null);
  const [liveDateFromMax, setLiveDateFromMax] = useState(null);
  const [liveDateFromMin, setLiveDateFromMin] = useState(null);
  const [liveDateToMax, setLiveDateToMax] = useState(null);
  const [liveDateToMin, setLiveDateToMin] = useState(null);
  let [page, setPage] = useState(1);
  const PER_PAGE = 15;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] =useState('customerName');
  const [pagination, setPagination] = useState({ pageNum: 1, pageSize: PER_PAGE});
  const [sorting, setSorting] = useState({orderBy: "customerName", orderFlag: order === 'asc'?true:false});
  const [customerData,setCustomerData] = useState([]);
  const [filterList,setfilterList] = useState ({})
  const [triggerConditions,setTriggerConditions] = useState(null);
  //Transfer sorting and pagination to Backend
  useEffect(() => {
    let formData;
    if(triggerConditions === null){
      formData = initialValues;
    }else{
      formData = triggerConditions;
    }
    fetchList({...formData,...pagination,...sorting});
  }, [pagination.pageNum, pagination.pageSize, sorting.orderBy, sorting.orderFlag,triggerConditions]);
  useEffect(() => {
    fetchFilterList();
  }, [])
  const count = (customerData && Math.ceil(customerData?.totalCount / PER_PAGE)) || 0;
  //Transfer sorting and pagination to Backend
  // const _DATA = usePagination(
    // stableSort(customerData, getComparator(order, orderBy)),
  //   PER_PAGE
  // );
  const handlePageChange = (e, p) => {
    //Transfer sorting and pagination to Backend
    // setPage(p);
    // _DATA.jump(p);
    
    // let currPageIndex = p;
    // if (pagination.pageSize !== p) {
    //   currPageIndex = 1;
    // }
    setPagination({
      ...pagination,
      pageNum: p,
      // pageSize: p,
    });
    window.scrollTo(0, 0);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPagination({
      ...pagination,
      pageNum: 1,
    });
    setSorting({...sorting,orderBy:property,orderFlag:isAsc?false:true}); //Transfer sorting and pagination to Backend
  }
  const fetchList = (formData) => {
    setIsSubmit(true);
    axiosInstanceDashboard({
      method: 'POST',
      url: '/getCustomerData',
      data: formData,
    }).then((response) => {
      if (response.status === 200) {
        setCustomerData(response?.data?.data?.Customer?response?.data?.data?.Customer:[]);
        // setPage(1);
        // _DATA.resetCurrentPage(1);
      }
    }).finally(() => {
      setIsSubmit(false);
    });
  };

  const fetchFilterList = () => {
    setIsSubmit(true);
    axiosInstanceDashboard({
      method: 'GET',
      url: '/getFilterConditions',
    }).then((response) => {
      if (response.status === 200) {
        setfilterList(_.get(response,'data.data.Conditions'))
      }
    }).finally(() => {
      setIsSubmit(false);
    });
  };
  function handleChange(e) {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };
  const handleDateChange = (date, label, range) => {
    //only pick one month
    if (date) {
      if (label === 'createDate') {
        if (range === "to") {
          setCreateDateFromMin(dayjs(date).subtract(1, 'month'));
          setCreateDateFromMax(dayjs(date));
          setCreateDateTo(dayjs(date));
        }
        if (range === 'from') {
          setCreateDateToMin(dayjs(date));
          setCreateDateToMax(dayjs(date).add(1, 'month'));
          setCreateDateFrom(dayjs(date));
          setCreateDateTo(dayjs(date).add(1, 'month'));
        }
      }
      if (label === 'liveDate') {
        if (range === "to") {
          setLiveDateFromMin(dayjs(date).subtract(1, 'month'));
          setLiveDateFromMax(dayjs(date));
          setLiveDateTo(dayjs(date));
        }
        if (range === 'from') {
          setLiveDateToMin(dayjs(date));
          setLiveDateToMax(dayjs(date).add(1, 'month'));
          setLiveDateFrom(dayjs(date));
          setLiveDateTo(dayjs(date).add(1, 'month'));
        }
      }

    }
  };
  const onReset = () => {
    setFormValues(initialValues);
    setCreateDateFrom(null);
    setCreateDateTo(null);
    setLiveDateFrom(null);
    setLiveDateTo(null);
    setCreateDateFromMax(null);
    setCreateDateFromMin(null);
    setCreateDateToMax(null);
    setCreateDateToMin(null);
    setLiveDateFromMax(null);
    setLiveDateFromMin(null);
    setLiveDateToMax(null);
    setLiveDateToMin(null);
    // fetchList(initialValues);//Transfer sorting and pagination to Backend
    setTriggerConditions(null);
    setPagination({pageNum: 1, pageSize: PER_PAGE})
  }
  const dateFormatter = date => date && dayjs(date).format('YYYY-MM-DD');
  const handleSubmit = (event) => {
    event.preventDefault();

    if(formValues?.customerName?.length > 0 && formValues?.customerName?.length < 4){
      snackbarShowMessage({
        message: `customer name need at least four charactor to filter`,
        type: 'error',
      });
      return
    }
    // if(formValues?.ncobLead?.length > 0){
      // snackbarShowMessage({
      //   message: `customer contact need at least four charactor to filter`,
      //   type: 'error',
      // });
      // return
    //   if(!(/^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/.test(formValues?.ncobLead))){
    //     snackbarShowMessage({
    //       message: `please enter valid customer contact xxx-xxx-xxxx or (xxx)xxx-xxxx`,
    //       type: 'error',
    //     });
    //     return
    //   }
    // }
    
    // if(formValues?.ncobPartner?.length > 0 ){
      // snackbarShowMessage({
      //   message: `customer email need at least four charactor to filter`,
      //   type: 'error',
      // });
      // return
    //   if(!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i.test(formValues?.ncobPartner))){
    //     snackbarShowMessage({
    //       message: `please enter valid Partner`,
    //       type: 'error',
    //     });
    //     return
    //   }
    // }
    let createDateGroup = [{name:'Start Of Create Date',value:createDateFrom},{name:'End Of Create Date',value:createDateTo}]
    let liveDateGroup = [{name:'Start Of Go-Live Date ',value:liveDateFrom},{name:'End Of Go-Live Date',value:liveDateTo}]
    let createfilter = createDateGroup.filter(res => res.value)
    let livefilter = liveDateGroup.filter(res => res.value)
    if(createfilter.length == 1){
      snackbarShowMessage({
        message: `need choose ${createDateGroup.filter(res=>res.name != createfilter[0].name)[0]?.name}`,
        type: 'error',
      });
      return
    }
    if(livefilter.length == 1){
      snackbarShowMessage({
        message: `need choose ${liveDateGroup.filter(res=>res.name != livefilter[0].name)[0]?.name}`,
        type: 'error',
      });
      return
    }
    const formData = {
      ...formValues,
      createDateFrom: dateFormatter(createDateFrom),
      createDateTo: dateFormatter(createDateTo),
      liveDateFrom: dateFormatter(liveDateFrom),
      liveDateTo: dateFormatter(liveDateTo)
    };
    setTriggerConditions(formData);
    setPagination({ pageNum: 1, pageSize: PER_PAGE});
    // fetchList({...formData, pageNum: 1, pageSize: PER_PAGE,...sorting});
  }
  const onToggleModal = updateState => {
    updateState(prevState => {
      return !prevState;
    });
  }
  return (
    <div className="customer-table">
      <div className="filter-contanier">
        <form className="form" onSubmit={handleSubmit} onReset={onReset}>
          <Box>
            <Grid container columns={16} sx={{ flexWrap: 'wrap', justifyContent: 'flex-start' }}>
              <TextField
                  sx={{height: '50px'  }}
                label="Customer Name"
                onChange={handleChange}
                name="customerName"
                className="filter-contanier-input filter-margin"
              />
              <TextField
                 sx={{height: '50px'  }}
                label="NCOB Lead"
                onChange={handleChange}
                // name="customerPoc"
                name="ncoblead"
                className="filter-contanier-input filter-margin"
                value={formValues.ncoblead}
              />
              <TextField
                 sx={{height: '50px'  }}
                label="NCOB Partner"
                onChange={handleChange}
                // name="customerEmailId"
                name="ncobpartner"
                className="filter-contanier-input filter-margin"
                value={formValues.ncobpartner}
              />
              <FormControl className="filter-margin"
              >
                <InputLabel id="select-label">Segment</InputLabel>
                <Select
                  sx={{height: '50px'  }}
                  label='Segment'
                  id='select-label'
                  name='segment'
                  onChange={handleChange}
                  className="filter-contanier-select"
                  multiple
                  value={formValues.segment}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {filterList?.segment && filterList?.segment.length>0 && 
                    filterList?.segment.map((res,index)=>{
                      return(
                        <MenuItem value={res} key={index}>{res}</MenuItem>
                        )
                    })
                  }
                </Select>
              </FormControl>
              <FormControl className="filter-margin"
              >
                <InputLabel id="select-label">Customer Type</InputLabel>
                <Select
                  sx={{height: '50px'  }}
                  label='Customer Type'
                  id='select-label'
                  name='customerType'
                  onChange={handleChange}
                  className="filter-contanier-select"
                  multiple
                  value={formValues.customerType}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="CMU-CMU">CMU</MenuItem>
                  <MenuItem value="LCC-LCC">LCC</MenuItem>
                  <MenuItem value="CMU-CONCEPT">CMU-CONCEPT</MenuItem>
                  <MenuItem value="CMU-LOCATION">CMU-LOCATION</MenuItem>
                  <MenuItem value="LCC-CONCEPT">LCC-CONCEPT</MenuItem>
                  <MenuItem value="LCC-LOCATION">LCC-LOCATION</MenuItem>

                  {/* {filterList?.customerType && filterList?.customerType.length>0 && 
                    filterList?.customerType.map((res,index)=>{
                      return(
                        <MenuItem value={res} key={index}>{res}</MenuItem>
                        )
                    })
                  } */}
                </Select>
              </FormControl>
              <FormControl className="filter-margin">
                <InputLabel id="select-label">Status</InputLabel>
                <Select
                  sx={{height: '50px'  }}
                  label='Status'
                  id='select-label'
                  name='status'
                  onChange={handleChange}
                  className="filter-contanier-select"
                  multiple
                  value={formValues.status}
                >
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem> */}
                  
                  {filterList?.progressStatus && filterList?.progressStatus.length>0 && 
                    filterList?.progressStatus.map((res,index)=>{
                      return(
                        <MenuItem value={res} key={index}>{res}</MenuItem>
                        )
                    })
                  }
                </Select>
              </FormControl>
              <FormControl className="filter-margin">
                <InputLabel id="select-label">Total Completion</InputLabel>
                <Select
                  sx={{height: '50px'  }}
                  label='Total Completion'
                  id='select-label'
                  name='totalCompletion'
                  onChange={handleChange}
                  className="filter-contanier-select"
                  value={formValues.totalCompletion}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value='0-25'>0-25%</MenuItem>
                  <MenuItem value='25-50'>25%-50%</MenuItem>
                  <MenuItem value='50-75'>50%-75%</MenuItem>
                  <MenuItem value='75-100'>75%-100%</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid container spacing={2} sx={{ justifyContent: 'space-between', paddingTop: 2 }} >
              <Grid item>
                <div className='datepicker'>
                  <DatePicker
                    sx={{height: '50px'  }}
                    label="Created Date"
                    name="createDateFrom"
                    value={createDateFrom}
                    onChange={(newValue, label) => {
                      handleDateChange(newValue, 'createDate', 'from')
                      setCreateDateFrom(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />
                    }
                    minDate={createDateFromMin}
                    maxDate={createDateFromMax}
                  />
                  <span className='datepicker-to'>to</span>
                  <DatePicker
                    sx={{height: '50px'  }}
                    label="Created Date"
                    name="createDateTo"
                    value={createDateTo}
                    onChange={(newValue) => {
                      handleDateChange(newValue, 'createDate', 'to')
                      setCreateDateTo(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    minDate={createDateToMin}
                    maxDate={createDateToMax}
                  />
                </div>
              </Grid>
              <Grid item style={{marginRight: '1.7%'}}>
                <div className='datepicker'>
                  <DatePicker
                    sx={{height: '50px'  }}
                    label="Go Live Date"
                    name="liveDateFrom"
                    value={liveDateFrom}
                    onChange={(newValue) => {
                      handleDateChange(newValue, 'liveDate', 'from')
                      setLiveDateFrom(newValue);
                    }}
                    minDate={liveDateFromMin}
                    maxDate={liveDateFromMax}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <span className='datepicker-to'>to</span>
                  <DatePicker
                    sx={{height: '50px'  }}
                    label="Go Live Date"
                    name="liveDateTo"
                    value={liveDateTo}
                    onChange={(newValue) => {
                      handleDateChange(newValue, 'liveDate', 'to')
                      setLiveDateTo(newValue);
                    }}
                    minDate={liveDateToMin}
                    maxDate={liveDateToMax}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container sx={{ justifyContent: 'flex-end' }} style={{marginTop: '12px',paddingRight: '1.7%'}}> 
              <div className="flex align-items-center justify-end form-btn">
                <Button
                  type="submit"
                  // variant="contained"
                  id="primary"
                  disabled={isSubmit}
                  style={{
                    padding: 6
                  }}
                >
                  
                  {isSubmit ? 'Searching...' : 'Search'}
                </Button>
                {isSubmit && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: 'white',
                          position: 'absolute',
                          left:{ md:'85%',lg:'90%',xl:'93%' }
                        }}
                      />
                    )}
                <Button
                  type="reset"
                  id="primary"
                  disabled={isSubmit}
                  style={{
                    padding: 6,
                    marginLeft: 10,
                  }}
                >Reset</Button>
              </div>
            </Grid>
          </Box>
        </form>
      </div>
      <div className="flex align-items-center" style={{ marginBottom: "20px", marginTop: "15px" }}>
        <Button ariant="contained" id="primary" onClick={() => onToggleModal(setmodalopen)} >
          Create New Customer
        </Button>
      </div>
      {/* {customer &&customerData?.length !== 0 && ( */}
        <div className="table-contanier">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <EnhancedTableHead order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
              <TableBody>
                {!_.isEmpty(customerData) && customerData?.customerList &&
                  customerData?.customerList.map((row) => (
                    <Row
                      key={`${row.customerId} - ${Math.random()}`}
                      row={row}
                    // customerId={customerId}
                    // snackbarShowMessage={snackbarShowMessage}
                    // onClose={onClose}
                    />
                  ))}
              </TableBody>
            </Table>
            {(customerData?.customerList?.length === 0 || _.isEmpty(customerData))&& (
            <p style={{ display: 'flex', justifyContent: 'center' }}>
              No Results Found
            </p>
          )}
          </TableContainer>
          <Pagination
            style={{ marginTop: '16px' }}
            className="customPagination"
            count={count}
            size="large"
            page={pagination.pageNum}
            variant="outlined"
            shape="rounded"
            onChange={handlePageChange}
            sx={{ padding: '20px', display: 'flex', justifyContent: 'center' }}
          />
        </div>
        {/* )} */}

      <CustomModal
        modalopen={modalopen}
        setmodalopen={setmodalopen}
      // customerList={_DATA.currentData()}
      snackbarShowMessage={snackbarShowMessage}
        manualNationalId={true}
      />
    </div>
  )
}
export default CustomerTable;