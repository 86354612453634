import { createSlice } from '@reduxjs/toolkit';

//Initializing state
const initialState = { AccountType: 'CMU' };

//Creating Reducers functions
export const accounttypeslice = createSlice({
  name: 'accountype',
  initialState,
  reducers: {
    updateAccounttype(state, action) {
      return { AccountType: `${action.payload}` };
    }
  }
});

//Action creators
export const { updateAccounttype } = accounttypeslice.actions;

export default accounttypeslice.reducer;
