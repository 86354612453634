import { call, put } from 'redux-saga/effects';
import { getCustomerListSuccess, getCustomerListError } from './slice';
import requestTemplate from './request';

export function* handleCustomerList() {
  try {
    const list = yield call(requestTemplate);
    yield put(getCustomerListSuccess(list?.data));
  } catch (error) {
    yield put(getCustomerListError(error));
  }
}
