import React from 'react';
import Grid from '@mui/material/Grid';
import { Field, ErrorMessage } from 'formik';

const BillingForm = ({ values, formEditClass, modalOpen }) => {
  const FORMCONTROL = 'form-control'
  return (
    <div className="customer-form form-container">
      <div className="popup-title form2">Billing Address</div>
      
        {modalOpen ? (
          <div className="popup-title billing">
          <label>
            <Field type="checkbox" name="toggleAddress" />
            Same as Shipping Address?
          </label>
          </div>
        ):values?.toggleAddress?(
          <div className="popup-title billing">
            <label>
              Same as Shipping Address
            </label>
          </div>):''}
     
      
      <div className="customer-form form-container">
        <Grid container rowSpacing={2} columnSpacing={3}>
          {!values?.toggleAddress && (
            <>
              <Grid item xs={6} sm={6}>
                <div className="form-header">
                  <div className="form-title">First Name</div>
                  <div className="form-subTitle fullWidth">
                    <Field
                      name="billingFirstName"
                      type="text"
                      placeholder={!formEditClass ? "Input text*" : ''}
                      className={FORMCONTROL}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <div className="form-header">
                  <div className="form-title">Last Name</div>
                  <div className="form-subTitle">
                    <Field
                      name="billingLastName"
                      type="text"
                      placeholder={!formEditClass ? "Input text*" : ''}
                      className={FORMCONTROL}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <div className="form-header">
                  <div className="form-title">Address</div>
                  <div className="form-subTitle">
                    <Field
                      name="billingAddress"
                      type="text"
                      placeholder={!formEditClass ? "Input text*" : ''}
                      className={'form-control address'}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <div className="form-header">
                  <div className="form-title">State</div>
                  <div className="form-subTitle">
                    <Field
                      name="billingState"
                      type="text"
                      placeholder={!formEditClass ? "Input text*" : ''}
                      className={FORMCONTROL}
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={6} sm={6}>
                <div className="form-header">
                  <div className="form-title">Zip Code</div>
                  <div className="form-subTitle">
                    <Field
                      name="billingZipcode"
                      type="text"
                      placeholder={!formEditClass ? "Input text*" : ''}
                      className={FORMCONTROL}
                    />
                  </div>
                  <ErrorMessage
                    name="billingZipcode"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default BillingForm;
