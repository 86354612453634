import FormBuilder from '../FormBuilder';
import StatusList from '../StatusList';
import Charts from '../Charts';
import { useEffect, useState } from 'react';
import ComposedCharts from '../ComposedCharts';
import styles from './index.module.css';
import _, { reject } from 'lodash';
import { axiosInstanceDashboard } from '../../../api/Axios';
import { withSnackbar } from '../SnackBar/SnackBar';

const ChartsDashboard = ({ snackbarShowMessage }) => {
  const initialValues = {
    sites: [],
    segments: [],
    salesLead: '',
    concepts: '',
    goLiveDateFrom: '',
    goLiveDateTo: '',
    market: [],
    regions: [],
  };
  const [values, setInitialValues] = useState(_.cloneDeep(initialValues));
  const [conceptBySegmentData, setConceptBySegment] = useState([]);
  const [propItemsData, setPropItemsData] = useState([]);
  const [locationByConceptData, setLocationByConcept] = useState([]);
  const [conceptLocationBySiteData, setConceptLocationBySite] = useState({});
  const [siteFor, setSiteFor] = useState('concept');
  const [conceptLocationByRegionData, setConceptLocationByRegion] = useState(
    {}
  );
  const [regionFor, setRegionFor] = useState('concept');
  const concept = {
    disposition: {
      title: 'Concept by Segment',
      cx: 220,
      cy: 280,
      innerRadius: 60,
      outerRadius: 80,
      type: 'PieChart',
      COLORS: ['#FFBC70', '#70CFFF', '#A2D699', '#F7DE78', '#A27DF2', '#FC7573'],
      legend: true,
    },
    data: conceptBySegmentData
  };
  const propItems = {
    disposition: {
      title: 'Props Items by Concept (TOP 10)',
      width: 220,
      height: 280,
      type: 'BarChart',
      layout: 'vertical',
      legend: false,
      margin: {
        top: 30,
        right: 0,
        left: 20,
        bottom: 0
      },
      element: [
        {
          name: 'PropItems',
          color: '#F7DE78'
        }
      ]
    },
    data: propItemsData
  };
  const locationByConcept = {
    disposition: {
      title: 'Location By Concept (TOP 10)',
      width: 220,
      height: 280,
      type: 'BarChart',
      layout: 'vertical',
      legend: false,
      margin: {
        top: 30,
        right: 0,
        left: 20,
        bottom: 0
      },
      element: [
        {
          name: 'Location',
          color: '#A27DF2'
        }
      ]
    },
    data: locationByConceptData
  };

  const top10Configs = [
    {
      title: 'Concepts & Locations by Region (Top 10)',
      relations: [
        {
          label: 'Location',
          value: 'location',
          color: '#A2D699'
        },
        {
          label: 'Concept',
          value: 'concept',
          color: '#56B146'
        }
      ],
      name: 'region',
      key: 'region',
      chartsConfig: {
        chartProps: {
          data: _.get(conceptLocationByRegionData, regionFor)
        },
        Xprops: {
          interval: 0
        },
        elements: [
          {
            type: 'Bar',
            props: {
              fill: '#A2D699',
              dataKey: 'locations'
            }
          },
          {
            type: 'Scatter',
            props: {
              fill: '#56B146',
              dataKey: 'concept',
              shape: 'diamond'
            }
          }
        ]
      }
    },
    {
      title: 'Concepts & Locations by Site (Top 10)',
      relations: [
        {
          label: 'Location',
          value: 'location',
          color: '#70CFFF'
        },
        {
          label: 'Concept',
          value: 'concept',
          color: '#008CD2'
        }
      ],
      name: 'site',
      key: 'site',
      chartsConfig: {
        chartProps: {
          data: _.get(conceptLocationBySiteData, siteFor)
        },
        Xprops: {
          interval: 0
        },
        elements: [
          {
            type: 'Bar',
            props: {
              fill: '#70CFFF',
              dataKey: 'locations'
            }
          },
          {
            type: 'Scatter',
            props: {
              fill: '#008CD2',
              dataKey: 'concept',
              shape: 'diamond'
            }
          }
        ]
      }
    }
  ];
  const [market, setMarket] = useState([]);
  const [regions, setRegions] = useState([]);
  const [opSite, setOpsite] = useState([]);
  const [segment, setSegment] = useState([]);
  const layout = {
    sm: 12,
    xs: 12
  };
  const extraProps = {
    SelectProps:{
      multiple:true
    }
  }
  const configs = [
    {
      title:'Market',
      label:'Market',
      name: 'market',
      layout,
      type: 'select',
      options: market,
      extraProps,
      onChange: (e, setFieldValue) => {
        setFieldValue('regions', []);
        setFieldValue('sites', []);
        let marketList = e.target.value
        let regionsList = [];
        marketList.map((marketListItem) =>{
          const regions = _.map(
            _.get(_.find(market, { value: marketListItem }), 'regions'),
            (item) => ({
              ...item,
              label: _.get(item, 'region'),
              value: _.get(item, 'region')
            })
          )
          regionsList.push(...regions)
        })
        
        setRegions(regionsList);
        setOpsite([]);
      }
    },
    {
      title: 'Region',
      label:'Region',
      name: 'regions',
      type: 'select',
      extraProps,
      layout,
      disabled: _.isEmpty(regions),
      options: regions,
      onChange: (e, setFieldValue) => {
        setFieldValue('sites', []);
        let regionsList = e.target.value
        let siteList = [];
        regionsList.map((regionsListItem) =>{
          const sites = _.map(
            _.get(_.find(regions, { value: regionsListItem }), 'sites'),
            (item) => ({
              ...item,
              label: _.get(item, 'site_desc'),
              value: _.get(item, 'site_nbr')
            })
          )
          siteList.push(...sites)
        })
        
        setOpsite(siteList);
      }
    },
    {
      title:'Go Live Date From',
      label: '',
      layout,
      name: 'goLiveDateFrom',
      type: 'date',
      placeholder: '',
    },
    {
      title:'To',
      label: '',
      layout,
      name: 'goLiveDateTo',
      type: 'date',
      placeholder: ''
    },
    {
      title: 'OpSite',
      label:'OpSite',
      name: 'sites',
      type: 'select',
      extraProps,
      layout,
      disabled: _.isEmpty(opSite),
      options: opSite
    },
    {
      title: 'Segment',
      label:'Segment',
      name: 'segments',
      layout,
      type: 'select',
      extraProps,
      options: _.map(segment, (item) => ({ label: item, value: item }))
    },
    {
      title: 'Sales Lead',
      label:'Sales Lead',
      layout,
      name: 'salesLead',
      type: 'input'
    },
    {
      layout,
      label:'Concept',
      title: 'Concept',
      name: 'concepts',
      type: 'input'
    }
  ];
  const handleRelation = (name, relation) => {
    if (name === 'region') {
      setRegionFor(relation);
      return;
    }
    setSiteFor(relation);
  };
  const getAllConceptOrLocationList = () => {
    return new Promise((resolve, reject) => {
      axiosInstanceDashboard.get('/getAllMarketDetails').then((res) => {
        // console.log(res);
        if (_.get(res, 'data.data.hasError')) {
          reject();
          return;
        }
        resolve();
        setMarket(
          _.map(_.get(res, 'data.data.Market Region Opco Details'), (item) => ({
            ...item,
            label: _.get(item, 'market'),
            value: _.get(item, 'market')
          }))
        );
      });
    });
  };
  const getChartsData = (
    segments = segment,
    payload = {
      sites: [],
      goLiveDateFrom: null,
      goLiveDateTo:null,
      segments: [],
      concepts: [],
      salesLeads: [],
      markets:[],
      regions: [],
      sites: []
    }
  ) => {
    return axiosInstanceDashboard
      .post('/getChartsList', payload)
      .then((res) => {
        if (_.get(res, 'data.data.hasError')) {
          return;
        }
        let {
          conceptBySegment,
          conceptLocationByRegion: { conceptByRegion, locationByRegion },
          conceptLocationBySite: { conceptBySite, locationBySite },
          locationByConcept: Locations,
          propItemByConcept
        } = _.get(res, 'data.data.Chart Dashboard', {});
        // Locations[0].concept = 'sihcihsocisisisisiss'
        Locations = _.slice(Locations, 0, 10);
        propItemByConcept = _.slice(propItemByConcept, 0, 10);
        setConceptBySegment(
          _.map(
            _.filter(conceptBySegment, (item) => {
              return _.includes(segments, item.segment);
            }),
            (item) => {
              return {
                name: item.segment,
                value: item.concept || 0
              };
            }
          )
        );
     
        setPropItemsData(
          _.filter(
            _.map(propItemByConcept, (item) => {
              return {
                name: item.concept,
                PropItems: item.propItems || 0
              };
            }),
            'name'
          )
        );
        setLocationByConcept(
          _.filter(
            _.map(Locations, (item) => {
              return {
                name: item.concept,
                Location: item.locations || 0
              };
            }),
            'name'
          )
        );
        setConceptLocationBySite({
          concept: _.map(conceptBySite, (item) => ({
            ...item,
            name: item.siteDesc
            
          })),
          location: _.map(locationBySite, (item) => ({
            ...item,
            name: item.siteDesc
          }))
        });
        setConceptLocationByRegion({
          concept: _.map(conceptByRegion, (item) => ({
            ...item,
            name: item.region
          })),
          location: _.map(locationByRegion, (item) => ({
            ...item,
            name: item.region
          }))
        });
        if (_.every([conceptByRegion, Locations, locationBySite,conceptBySite, locationByRegion, propItemByConcept, conceptBySegment], _.isEmpty)) {
          snackbarShowMessage({
            message: `No Data Found. Adjust filters and try again.`,
            type: 'error'
          })
        }
      });
  };
  const getSegmentData = () => {
    return new Promise((reslove, reject) => {
      axiosInstanceDashboard
        .request({
          method: 'GET',
          url: '/getChannelDetails'
        })
        .then((res) => {
          if (_.get(res, 'data.data.hasError')) {
            reject();
            return;
          }
          const segments = _.keys(_.get(res, 'data.data.Channel Details'));
          reslove(segments);
          setSegment(segments);
        });
    });
  };
  const [statusCount, setStatusCount] = useState({});
  const getCusterDetailStatusCount = () => {
    axiosInstanceDashboard.get('/getCusterDetailStatusCount').then((res) => {
      // console.log(res);
      if (_.get(res, 'data.data.hasError')) {
        return;
      }
      setStatusCount(_.get(res, 'data.data.Status Count'))
    });
  };
  useEffect(() => {
    Promise.all([getAllConceptOrLocationList(), getSegmentData()]).then(
      ([concepts, segments]) => {
        getChartsData(segments);
      }
    );
    getCusterDetailStatusCount();
  }, []);

  const handleReset = () => {
    setRegions([]);
    setOpsite([]);
    getChartsData();
  };
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const filters = {
      sites: values.sites ? [values.sites] : [],
      goLiveDateFrom: null,
      goLiveDateTo:null,
      segments: [],
      concepts: [],
      salesLeads: [],
      markets:[],
      regions: [],
      sites: []
    };
    if (values.goLiveDateFrom) {
      filters.goLiveDateFrom = values.goLiveDateFrom;
    }
    if (values.goLiveDateTo) {
      filters.goLiveDateTo = values.goLiveDateTo;
    }
    filters.segments = values.segments ? values.segments : [];
    filters.concepts = values.concepts ? [values.concepts] : [];
    filters.salesLeads = values.salesLead ? [values.salesLead] :[];
    filters.markets = values.market ? values.market:[];
    filters.regions = values.regions ? values.regions:[];
    filters.sites = values.sites ?values.sites:[];
    getChartsData(segment, filters).then(() => {
      setSubmitting(false);
    },(rej)=>{
      setSubmitting(false);
    });
  };

  return (
    <div className="chartcontainer">
      <div className="chartContainer-dashboard">
        <div className="chart-left">
        <div className="filters-tit">Filters</div>
          <FormBuilder
            configs={configs}
            initialValues={values}
            onSubmit={handleSubmit}
            onReset={handleReset}
            operationsProps={{
              submit: { text: 'Search' },
              cancel: { text: 'Cancel' }
            }}
          />
        </div>
        <div className="chart-center">
        <div className="chart-center-top">
            <div className="chart-center-top-item">
              <div className="sub01">
                <p className="p-sub01">Not Started</p>
                <p className="p-sub02">{_.get(statusCount, 'notStarted', 0)}</p>
              </div>
              <div className="lineSub"></div>
              <div className="sub02">
                <p className="p-sub01">On Hold</p>
                <p className="p-sub02">{_.get(statusCount, 'onHold', 0)}</p>
              </div>
            </div>
            <div className="chart-center-top-item">
              <div>
                <p className="p-sub01">In Progress</p>
                <p className="p-sub02 blueColor">{_.get(statusCount, 'inProgress', 0)}</p>
              </div>
            </div>
            <div className="chart-center-top-item">
              <div>
                <p className="p-sub01">Hypercare</p>
                <p className="p-sub02 pinkColor">{_.get(statusCount, 'hyperCare', 0)}</p>
              </div>
            </div>
        </div>
          <div className="chart-center-bottom">
            <div className="chart-center-bottom-top">
              {_.map(top10Configs, (config) => {
                return (
                  <div
                    className="chart-center-bottom-top-item"
                    key={config.key}
                  >
                    <div className={styles.header}>
                      <div>{config.title}</div>
                      <div className={styles.relation}>
                        {_.map(config.relations, (relation) => {
                          return (
                            <div
                              className={styles.relationItem}
                              key={relation.value}
                              onClick={() => {
                                handleRelation(config.name, relation.value);
                              }}
                            >
                              <i
                                style={{ backgroundColor: relation.color }}
                                className={styles.statusIcon}
                              />
                              {relation.label}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <ComposedCharts {...config.chartsConfig} />
                  </div>
                );
              })}
            </div>
            <div className="chart-center-bottom-bottom">
              <div className="chart-center-bottom-bottom-item">
                <Charts chartsObj={locationByConcept} />
              </div>
              <div className="chart-center-bottom-bottom-item">
                <Charts chartsObj={propItems} />
              </div>
              <div className="chart-center-bottom-bottom-item">
                <Charts chartsObj={concept} />
              </div>
            </div>
          </div>
        </div>
        <div className="chart-right">
          <StatusList />
        </div>
      </div>
    </div>
  );
};

export default withSnackbar(ChartsDashboard);
