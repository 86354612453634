
import {mapping} from '../Helper/mapping'
import _ from 'lodash';

export function CardClickCheckShowError(actionName,acctype,stepStatus,item,docstatus) {
  if(item?.actionId === 15 && item?.actionName === 'Pre-Req Docs' && item?.bundledWithDocUsedByClickCard){
    let notUploadFile = [];
    if (docstatus) {
      for (var m of item?.bundledWithDocUsedByClickCard) {
        if (docstatus.findIndex(item => item.docName === m) === -1) {
          notUploadFile.push(m);
        }
      }
      if (notUploadFile?.length > 0) {
        return {
          passValidation: false,
        }
      } else {
        return {
          passValidation: true,
        }
      }
    }else{
      return {
        passValidation:false,
      }
    }
  }else if (item?.bundledWithDocUsedByClickCard){
    const dependentdoc = _.filter(docstatus,(key)=>{return key?.docName === item.bundledWithDocUsedByClickCard})
    if(dependentdoc.length === 0){
      return {
        passValidation:false,
        validationMsg:`Warning: ${item.bundledWithDocUsedByClickCard} needs to be uploaded`
      }
    }else{
      let value = _.filter(dependentdoc, (o)=> { return o.isApproved === 'Approved'; });
      if(value.length === 0){
        return {
          passValidation:false,
          validationMsg:`Warning: ${item.bundledWithDocUsedByClickCard} needs to be approved before this step.`
        }
      }else{
        return {
          passValidation:true,
        }
      }
    }
    
  }else{
    return {
      passValidation:true,
    }
  }
}
  