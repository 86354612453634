import { Avatar, Box, Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import '../OfflineChatter/index.css';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import RequestPopup from '../Popup/index';
import ForumIcon from '@mui/icons-material/Forum';
import SendIcon from '@mui/icons-material/Send';
import { getConversations } from '../../../utils/getConversations';
import { useNavigate,useParams, useLocation } from 'react-router-dom';
import { postNewchat } from '../../../utils/postNewchat';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SubChat from './subChat';

export default function ChatRoom({ isSubChatEnable, conversationid }) {
  const { customerID } = useParams();
  const navigate = useNavigate()
  const [replyClick, SetReplyClick] = useState(false);
  const [chatRenderAgain, setChatRenderAgain] = useState(false);
  const [replymessage, setreplymessage] = useState([
    {
      id: 1,
      value: ''
    },
    {
      id: 2,
      value: ''
    }
  ]);
  const [conversations, setconversations] = useState('');
  const [currentClick, setCurrrentClick] = useState('');
  const [popup, setpopup] = useState(false);
  const [message, setmessage] = useState('');
  useEffect(async () => {
    if(!customerID || customerID == 'undefined'){
      navigate(`/PageNotFound`);
    }
    const res = await getConversations(customerID);
    setconversations(res);
  }, [replyClick, chatRenderAgain, popup]);

  const result = conversations
    ? Object.entries(conversations)
        .map(([key, value]) => ({ id: key, val: value }))
        .sort(function (a, b) {
          const [year, month, day, hours, minutes, seconds] =
            a.val[0].modifiedDate.split('.');
          const [year2, month2, day2, hours2, minutes2, seconds2] =
            b.val[0].modifiedDate.split('.');

            const dateA = new Date(year, month - 1, day, hours, minutes, seconds);
            const dateB = new Date(
            year2,
            month2 - 1,
            day2,
            hours2,
            minutes2,
            seconds2
          );

          return dateA - dateB;
        })
    : null;
 
  function dateformatter(dateString) {
    const [year, month, day, hours, minutes, seconds] = dateString.split('.');

    const date = new Date(year, month - 1, day, hours, minutes, seconds);

    return date.toLocaleDateString().concat(' ', date.toLocaleTimeString());
  }
  function chatreplyhandler(custid, message, conversationid) {
    
   message && postNewchat(custid, message, conversationid);
  }
  const valuehandler = (chatid) => (event) => {
    let count = 0;
    const newState = replymessage.map((obj) => {
      if (obj.id === chatid) {
        count++;
        return { ...obj, value: event.target.value };
      } else return obj;
    });
    if (count == 0) newState.push({ id: chatid, value: event.target.value });

    setreplymessage(newState);
  };

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  const Newchathandler = () => {
    setpopup(true);
  };
  const handleSend = (customerID, message, conversationID) => {
    chatreplyhandler(customerID, message, conversationID);
    const newState = replymessage.map((obj) => {
      if (obj.id === conversationID) {
        return { ...obj, value: '' };
      } else return obj;
    });
    setreplymessage(newState);
    setTimeout(() => {
      SetReplyClick(false);
    }, 1000);
  };

  const handleKeyDown = (event, customerID, message, conversationID) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSend(customerID, message, conversationID);
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, []);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  const geturl = query.get('redirect');
  return (
    <Box className="chatBox" style={{ padding: '16px' }}>
      {geturl && (
        <div
          className="Header"
          onClick={() => {
            window.location.href = geturl;
          }}
          style={{ fontSize: '16px' }}
        >
          <ArrowBackIcon /> Dashboard
        </div>
      )}
      {result?.length > 0 ? (
        result?.map((chat, index) => {
          return (
            <Box sx={{ paddingLeft: 2 }}>
              <Box
                ref={messagesEndRef}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  fontFamily: 'MyriadPro'
                }}
                key={chat.val[0].conversationID}
              >
                <Avatar
                  alt="Recepient Profile Pic"
                  sx={{
                    width: 40,
                    height: 40,
                    mt: 1.5,
                    color: '#000',
                    backgroundColor: '#bdbdbd7a'
                  }}
                  src={chat.imageURL}
                />
                <Typography>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      backgroundColor: '#fff',
                      borderRadius: '0.4rem 0.4rem 0 0',
                      fontFamily: 'MyriadPro',
                      border: '1px solid #ebebeb',
                      p: 2,
                      mt: 1,
                      ml: { sm: 2, xs: 2 },
                      mr: { sm: 2, xs: 2 },
                      display: 'inline-block',
                      wordBreak: 'break-word',
                      width: 1200
                    }}
                  >
                    <div className="sendename">
                      {chat.val[0].modifiedBy}&nbsp;&nbsp;
                      <span className="sendertime">
                        {' '}
                        {dateformatter(chat.val[0].modifiedDate)}
                      </span>
                    </div>
                    <div className="senderMessage"> {chat.val[0].message}</div>
                    {!isSubChatEnable && chat.val.length > 1 && (
                      <div
                        data-testid="morereplies"
                        onClick={() => {
                          setChatRenderAgain(!chatRenderAgain);
                          setCurrrentClick(chat.val[0].conversationID);
                        }}
                      >
                        {chat.val.length - 1} replies from you and others
                      </div>
                    )}
                    {chatRenderAgain &&
                      currentClick === chat.val[0].conversationID && (
                        <SubChat
                          isSubChatEnable={true}
                          conversationid={chat.val[0].conversationID}
                          data={chat.val}
                        />
                      )}
                  </Typography>
                  {!isSubChatEnable && (
                    <Typography
                      data-testid="replybutton"
                      variant="subtitle1"
                      className={`${
                        currentClick === chat.val[0].conversationID
                          ? 'replyChat activeReplyChat'
                          : 'replyChat'
                      }`}
                      sx={{
                        ml: { sm: 2, xs: 2 },
                        mr: { sm: 2, xs: 2 },
                        display: 'flex',
                        alignItems: 'center',
                        color: '#252423',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        SetReplyClick(true);
                        setCurrrentClick(chat.val[0].conversationID);

                        if (replyClick)
                          document
                            .getElementById(
                              `chatText${chat.val[0].conversationID}`
                            )
                            .focus();
                      }}
                    >
                      {(!replyClick ||
                        (replyClick &&
                          currentClick !== chat.val[0].conversationID)) && (
                        <>
                          <SubdirectoryArrowLeftIcon />
                          &nbsp;Reply
                        </>
                      )}
                      {replyClick &&
                        currentClick === chat.val[0].conversationID && (
                          <div className="texst-box">
                            <input
                              type="text"
                              id={`chatText${chat.val[0].conversationID}`}
                              className="chatText"
                              placeholder="Reply"
                              data-testid="input"
                              onChange={valuehandler(
                                chat.val[0].conversationID
                              )}
                              key={chat.val[0].conversationID}
                              value={
                                replymessage.find(
                                  (item) =>
                                    item.id == chat.val[0].conversationID
                                )?.value
                              }
                              onKeyDown={(e) => {
                                handleKeyDown(
                                  e,
                                  customerID,
                                  replymessage.find(
                                    (item) =>
                                      item.id == chat.val[0].conversationID
                                  )?.value,
                                  chat.val[0].conversationID
                                );
                              }}
                            />
                            <SendIcon
                              right
                              data-testid="sendbutton"
                              key={chat.val[0].conversationID}
                              onClick={(e) => {
                                handleSend(
                                  customerID,
                                  replymessage.find(
                                    (item) =>
                                      item.id == chat.val[0].conversationID
                                  )?.value,
                                  chat.val[0].conversationID
                                );
                              }}
                            />
                          </div>
                        )}
                    </Typography>
                  )}
                </Typography>
              </Box>
            </Box>
          );
        })
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            fontFamily: 'MyriadPro'
          }}
        >
          <Typography>
            <Typography
              variant="subtitle1"
              sx={{
                backgroundColor: '#fff',
                borderRadius: '0.4rem 0.4rem 0 0',
                fontFamily: 'MyriadPro',
                border: '1px solid #ebebeb',
                p: 2,
                mt: 1,
                ml: { sm: 2, xs: 2 },
                mr: { sm: 2, xs: 2 },
                display: 'inline-block',
                wordBreak: 'break-word',
                width: 1200,
                height: 100
              }}
            >
              <div className="senderMessage"> No Conversations Found</div>
            </Typography>
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          bgcolor: 'white',
          width: '100%',
          position: 'fixed',
          bottom: 0,
          zIndex: 1,
          pb: 1,
          pt: 1,
          ml: '5vw'
        }}
      >
        {popup && (
          <RequestPopup
            popupopen={popup}
            headingtitle={'Ask a Question'}
            setpopupopen={setpopup}
            Textfield={true}
            message={message}
            setmessage={setmessage}
          />
        )}
        <Button
          data-testid="newconvbutton"
          onClick={() => {
            Newchathandler();
          }}
          variant="contained"
          startIcon={<ForumIcon />}
          sx={{ textTransform: 'capitalize', backgroundColor: '#008CD2' }}
        >
          New conversation
        </Button>
      </Box>
    </Box>
  );
}
